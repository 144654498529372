// @flow
import * as React from "react";
import { useEffect } from "react";
import { useStore } from "effector-react";
import _ from "lodash";

import type {
  TAppointment_id,
  LayoutFieldValue,
  AppointmentWithDetails
} from "../../domain/entities/Appointment";
import nanoid from "nanoid";
import type { LayoutDetailed, TLayout_id } from "../../domain/entities/Layout";
import { SidebarContainer, StyledSidebarContainer } from "../sidebar/SidebarContainer";
import { ShortcutsFixedLabel } from "./ShortcutsFixedLabel";
import { ShortcutsForLayout } from "./ShortcutsForLayout";
import { ShortcutsForField } from "./ShortcutsForField";
import { ShortcutsCreate } from "./ShortcutsCreate";
import { ShortcutsEdit } from "./ShortcutsEdit";
import {
  closeShortcutsSidebar,
  openShortcutsForLayout,
  shortcutsSidebarSectionStore
} from "../../stores/shortcuts/shortcutsSections";
import { editingShortcutStore } from "../../stores/shortcuts/editingShortcut";
import type { ShortcutsSections } from "../../stores/shortcuts/shortcutsSections";
import {
  currentFieldStore,
  fieldsWithShortcutsStore,
  openShortcuts
} from "../../stores/shortcuts/fieldShortcuts";
import { useFieldValue } from "../appointment-widgets/useFieldValue";
import { getTextValue } from "../appointment-widgets/getTextValue";
import { setFieldValue } from "../../stores/appointmentFieldValues";
import { fetchShortcutsEffect } from "../../stores/shortcuts/shortcutsShared";
import { tokenStore } from "../../stores/auth";
import { useServiceDescription } from "../appointment-page/useServiceDescription";
import { getAppointment_id } from "../../domain/entities/Appointment";
import {
  isHaveServicesShortcuts,
  servicesShortcuts
} from "../../stores/shortcuts/servicesShortcuts";
import { getLayout_id } from "../../domain/entities/Layout";
import type { ChecklistShortcut } from "../../domain/entities/Shortcut";
import {
  currentChecklistStore,
  currentFieldStore as currentFieldChecklistStore,
  openShortcuts as openShortcutsForChecklist,
  isCurrentChecklists,
  checklistShortcut
} from "../../stores/shortcuts/checklistShortcuts";
import { openShortcuts as openServicesShortcuts } from "../../stores/shortcuts/servicesShortcuts";
import ShortcutsForChecklist from "./ShortcutsForChecklist";
import {
  appointmentExaminationsStore,
  appointmentMedicationStore,
  appointmentRecommendationsStore
} from "../../stores/decisionSupportStores";
import { useChecklist } from "../decision-support/useChecklist";
import {
  openShortcuts as openPrescribedServicesShortcuts,
  isCurrentPrescribedServices,
  isHavePrescribedServicesShortcuts, prescribedServicesShortcuts
} from '../../stores/shortcuts/prescribedServicesShortcuts';
import {
  openShortcuts as openAppointmentServicesShortcuts,
  isCurrentAppointmentServices,
  isHaveAppointmentServicesShortcuts, appointmentServicesShortcuts
} from '../../stores/shortcuts/appointmentServicesShortcuts';
import serviceStore from '../../stores/serviceStore';
import {observer} from 'mobx-react';
import apiv2 from '../../apiv2';
import {dispatchEvent} from "../../utils/useEvents";

const checklistDestination = {
  examination_checklist: "Обследования",
  recommendation_checklist: " Рекомендации",
  medication_checklist: "Медикаментозное лечение "
};

const ShortcutsEditForField = props => {
  const { field_id, editingShortcut } = props;

  const token = useStore(tokenStore);

  const onSubmit = async (name, value) => {
    if (!token) return;
    const creationOptions = {
      destination_type: "field",
      field_id: field_id,
      name: name,
      value: value
    };
    try {
      await apiv2.shortcuts.putShortcut(editingShortcut.id, creationOptions);
      await fetchShortcutsEffect({ token, layout_id: props.layout_id });
      openShortcuts(field_id);
    } catch (e) {
      console.log(e);
    }
  };

  const onDeleteShortcut = async () => {
    if (!token) return;
    try {
      await apiv2.shortcuts.deleteShortcut(editingShortcut.id);
      await fetchShortcutsEffect({ token, layout_id: props.layout_id });
      openShortcuts(field_id);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ShortcutsEdit
      editingShortcut={editingShortcut}
      onSubmit={onSubmit}
      onDeleteShortcut={onDeleteShortcut}
      openShortcuts={() => openShortcuts(field_id)}
    />
  );
};

const ShortcutsEditForServices = props => {
  const { editingShortcut } = props;

  const token = useStore(tokenStore);

  const onSubmit = async (name, value) => {
    if (!token) return;
    const creationOptions = { destination_type: "services", name: name, value: value };
    try {
      await apiv2.shortcuts.putShortcut(editingShortcut.id, creationOptions);
      await fetchShortcutsEffect({ token, layout_id: props.layout_id });
      openServicesShortcuts();
    } catch (e) {
      console.log(e);
    }
  };

  const onDeleteShortcut = async () => {
    if (!token) return;
    try {
      await apiv2.shortcuts.deleteShortcut(editingShortcut.id);
      await fetchShortcutsEffect({ token, layout_id: props.layout_id });
      openServicesShortcuts();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ShortcutsEdit
      editingShortcut={editingShortcut}
      onSubmit={onSubmit}
      onDeleteShortcut={onDeleteShortcut}
      openShortcuts={openServicesShortcuts}
    />
  );
};

const ShortcutsEditForPrescribedServices = props => {
  const { editingShortcut } = props;

  const token = useStore(tokenStore);

  const onSubmit = async (name, value) => {
    if (!token) return;
    const creationOptions = { destination_type: "prescribed_services", name: name, services: value };
    try {
      await apiv2.shortcuts.putShortcut(editingShortcut.id, creationOptions);
      await fetchShortcutsEffect({ token, layout_id: props.layout.id });
      openPrescribedServicesShortcuts();
    } catch (e) {
      console.log(e);
    }
  };

  const onDeleteShortcut = async () => {
    if (!token) return;
    try {
      await apiv2.shortcuts.deleteShortcut(editingShortcut.id);
      await fetchShortcutsEffect({ token, layout_id: props.layout.id });
      openPrescribedServicesShortcuts();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ShortcutsEdit
      isServices
      editingShortcut={editingShortcut}
      onSubmit={onSubmit}
      onDeleteShortcut={onDeleteShortcut}
      openShortcuts={openPrescribedServicesShortcuts}
    />
  );
};

const ShortcutsEditForAppointmentServices = props => {
  const { editingShortcut } = props;

  const token = useStore(tokenStore);

  const onSubmit = async (name, value) => {
    if (!token) return;
    const creationOptions = { destination_type: "appointment_services", name: name, services: value };
    try {
      await apiv2.shortcuts.putShortcut(editingShortcut.id, creationOptions);
      await fetchShortcutsEffect({ token, layout_id: props.layout.id });
      openAppointmentServicesShortcuts();
    } catch (e) {
      console.log(e);
    }
  };

  const onDeleteShortcut = async () => {
    if (!token) return;
    try {
      await apiv2.shortcuts.deleteShortcut(editingShortcut.id);
      await fetchShortcutsEffect({ token, layout_id: props.layout.id });
      openAppointmentServicesShortcuts();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ShortcutsEdit
      isAppointmentServices
      editingShortcut={editingShortcut}
      onSubmit={onSubmit}
      onDeleteShortcut={onDeleteShortcut}
      openShortcuts={openAppointmentServicesShortcuts}
    />
  );
};

const ShortcutsEditForChecklist = props => {
  const { editingShortcut, appointment_id } = props;
  const fieldName = useStore(currentFieldChecklistStore);

  const token = useStore(tokenStore);

  const onSubmit = async name => {
    if (!token) return;
    const creationOptions = { destination_type: fieldName, name: name };
    try {
      // $FlowFixMe
      await apiv2.shortcuts.putShortcut(editingShortcut.id, creationOptions);
      await fetchShortcutsEffect({ token, layout_id: props.layout_id });
      // $FlowFixMe
      openShortcutsForChecklist({ fieldName, appointment_id });
    } catch (e) {
      console.log(e);
    }
  };

  const onDeleteShortcut = async () => {
    if (!token) return;
    try {
      await apiv2.shortcuts.deleteShortcut(editingShortcut.id);
      await fetchShortcutsEffect({ token, layout_id: props.layout_id });
      // $FlowFixMe
      openShortcutsForChecklist({ fieldName, appointment_id });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ShortcutsEdit
      editingShortcut={editingShortcut}
      isChecklist
      fieldName={fieldName}
      onSubmit={onSubmit}
      onDeleteShortcut={onDeleteShortcut}
      // $FlowFixMe
      openShortcuts={() => openShortcutsForChecklist({ fieldName, appointment_id })}
    />
  );
};

const ShortcutsCreateForField = props => {
  const { field_id } = props;
  const token = useStore(tokenStore);
  const result = useFieldValue(props.appointment_id, props.field_id);
  const initialValue = getTextValue(result, props.fieldValue);

  const onSubmit = async (name, value) => {
    if (!token) return;
    const creationOptions = {
      destination_type: "field",
      field_id,
      name: name,
      value: value
    };
    try {
      await apiv2.shortcuts.postShortcut(creationOptions);
      await fetchShortcutsEffect({ token, layout_id: props.layout_id });
      openShortcuts(field_id);
    } catch (e) {
      console.log(e);
    }
  };

  return <ShortcutsCreate initialValue={initialValue} onSubmit={onSubmit} />;
};

const ShortcutsCreateForChecklist = props => {
  const { layout_id, appointment_id } = props;
  const token = useStore(tokenStore);
  const checklist = useStore(currentChecklistStore);
  const fieldName = useStore(currentFieldChecklistStore);

  useEffect(() => {
    if (!checklist || checklist.length < 1) {
      closeShortcutsSidebar();
    }
  }, [checklist]);

  const onSubmit = async (name, value, checklist) => {
    if (!token) return;

    const creationOptions = {
      destination_type: fieldName,
      layout_id,
      name,
      token,
      checklist: checklist
    };

    try {
      // $FlowFixMe
      await apiv2.shortcuts.postShortcut(creationOptions);
      await fetchShortcutsEffect({ token, layout_id: props.layout_id });
      // $FlowFixMe
      openShortcutsForChecklist({ fieldName, appointment_id });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    // $FlowFixMe
    <ShortcutsCreate onSubmit={onSubmit} fieldName={fieldName} checklist={checklist} isChecklist />
  );
};

const ShortcutsCreateForServices = props => {
  const token = useStore(tokenStore);
  const { appointment, layout } = props;
  const [serviceDescription] = useServiceDescription(
    appointment && getAppointment_id(appointment),
    appointment.services_description || layout.default_services_description || ""
  );

  const onSubmit = async (name, value) => {
    if (!token) return;
    const creationOptions = { destination_type: "services", name: name, value: value };
    try {
      await apiv2.shortcuts.postShortcut(creationOptions);
      await fetchShortcutsEffect({ token, layout_id: getLayout_id(props.layout) });
      openServicesShortcuts();
    } catch (e) {
      console.log(e);
    }
  };

  return <ShortcutsCreate initialValue={serviceDescription} onSubmit={onSubmit} />;
};

const ShortcutsCreateForPrescribedServices = props => {
  const token = useStore(tokenStore);
  const { appointment, layout } = props;
  const [serviceDescription] = useServiceDescription(
    appointment && getAppointment_id(appointment),
    appointment.services_description || layout.default_services_description || ""
  );

  const onSubmit = async (name, value, services) => {
    if (!token) return;
    const creationOptions = { destination_type: "prescribed_services", name: name, layout_id: getLayout_id(layout), services };
    try {
      await apiv2.shortcuts.postShortcut(creationOptions);
      await fetchShortcutsEffect({ token, layout_id: getLayout_id(layout) });
      openPrescribedServicesShortcuts();
    } catch (e) {
      console.log(e);
    }
  };

  return <ShortcutsCreate initialValue={serviceDescription} onSubmit={onSubmit} isServices />;
};

const ShortcutsCreateForAppointmentServices = props => {
  const token = useStore(tokenStore);
  const { appointment, layout } = props;
  const [serviceDescription] = useServiceDescription(
    appointment && getAppointment_id(appointment),
    appointment.services_description || layout.default_services_description || ""
  );

  const onSubmit = async (name, value, services) => {
    if (!token) return;
    const creationOptions = { destination_type: "appointment_services", name: name, layout_id: getLayout_id(layout), services };
    try {
      await apiv2.shortcuts.postShortcut(creationOptions);
      await fetchShortcutsEffect({ token, layout_id: getLayout_id(layout) });
      openAppointmentServicesShortcuts();
    } catch (e) {
      console.log(e);
    }
  };

  return <ShortcutsCreate initialValue={serviceDescription} onSubmit={onSubmit} isAppointmentServices />;
};

const ShortcutsForFieldField = props => {
  const token: any = useStore(tokenStore);
  const currentField = useStore(currentFieldStore);
  const field = useStore(
    fieldsWithShortcutsStore.map(shortcutsMap => currentField && shortcutsMap[currentField])
  );
  const result = useFieldValue(props.appointment_id, props.field.field.id);
  const value = getTextValue(result, props.fieldValue);

  if (!field) {
    return null;
  }

  const onInsert = shortcut => {
    const newValue = value ? value + "\n" + shortcut.value : shortcut.value;
    setFieldValue({
      field_type: "textarea",
      appointment_id: props.appointment_id,
      field_id: props.field.field.id,
      value: newValue
    });
  };

  const reloadShortcuts = () => fetchShortcutsEffect({ token, layout_id: props.layout_id });

  return (
    <ShortcutsForField
      name={field.field.name}
      shortcuts={field.shortcuts}
      onInsert={onInsert}
      reloadShortcuts={reloadShortcuts}
      transitionId={props.field.field.id}
    />
  );
};

const ShortCutsForChecklistWrapper = props => {
  const token: any = useStore(tokenStore);
  const field = useStore(currentFieldChecklistStore);
  const { addChecklist: addChecklistExamination } = useChecklist(
    appointmentExaminationsStore,
    props.appointment_id
  );
  const { addChecklist: addChecklistMedication } = useChecklist(
    appointmentMedicationStore,
    props.appointment_id
  );
  const { addChecklist: addChecklistRecommendation } = useChecklist(
    appointmentRecommendationsStore,
    props.appointment_id
  );

  const addChecklistFunctionsByName = {
    examination_checklist: addChecklistExamination,
    medication_checklist: addChecklistMedication,
    recommendation_checklist: addChecklistRecommendation
  };

  const shortcuts = useStore(
    checklistShortcut.map(
      shortcuts =>
        field &&
        shortcuts
          .filter(el => el.destination_type === field)
          .reduce((value, item) => {
            return [...value, ...item.shortcuts];
          }, [])
    )
  );

  useEffect(() => {
    if (!shortcuts || shortcuts.length < 1) {
      openShortcutsForLayout();
    }
  }, [shortcuts]);

  const reloadShortcuts = () => fetchShortcutsEffect({ token, layout_id: props.layout_id });

  const name = field ? checklistDestination[field] : "";

  const onInsert = (shortcut: ChecklistShortcut) => {
    shortcut.value.forEach(item => {
      // $FlowFixMe
      addChecklistFunctionsByName[field]({
        guid: nanoid(),
        name: item.name,
        ...(item.items &&
            item.items.length > 0 && {
              items: item.items?.map(el => ({...el, guid: nanoid()}))
            }),
        is_checked: true,
        comment: item.comment
      });
      if (field === 'examination_checklist') {
        if (item.items.length) {
          item.items.forEach(i => dispatchEvent("examinationCheckChange", {
            item: i,
            value: true
          }));
        }
      }
    });
  };

  return (
    <ShortcutsForChecklist
      reloadShortcuts={reloadShortcuts}
      name={name}
      onInsert={onInsert}
      // $FlowFixMe
      transitionId={shortcuts[0]?.id || "checklist"}
      // $FlowFixMe
      shortcuts={shortcuts}
    />
  );
};

const ShortcutsForServices = props => {
  const token: any = useStore(tokenStore);
  const { appointment, layout } = props;
  const [serviceDescription, changeServiceDescription] = useServiceDescription(
    appointment && getAppointment_id(appointment),
    appointment.services_description || layout.default_services_description || ""
  );
  const shortcuts = useStore(servicesShortcuts);

  const onInsert = shortcut => {
    const newValue = serviceDescription
      ? serviceDescription + "\n" + shortcut.value
      : shortcut.value;
    changeServiceDescription(newValue);
  };

  const reloadShortcuts = () =>
    fetchShortcutsEffect({ token, layout_id: getLayout_id(props.layout) });

  return (
    <ShortcutsForField
      name={"Услуги"}
      shortcuts={shortcuts[0].shortcuts}
      onInsert={onInsert}
      reloadShortcuts={reloadShortcuts}
      transitionId={"services"}
    />
  );
};

const ShortcutsForPrescribedServices = observer(props => {
  const token: any = useStore(tokenStore);

  const shortcuts = useStore(
    prescribedServicesShortcuts.map(
      shortcuts =>
        shortcuts
          .filter(el => el.destination_type === "prescribed_services")
          .reduce((value, item) => {
            return [...value, ...item.shortcuts];
          }, [])
    )
  );

  const onInsert = shortcut => {
    if (shortcut && shortcut.value) {
      dispatchEvent("prescribedServicesShortcutInsert", shortcut.value);
    }
  };

  const reloadShortcuts = () =>
    fetchShortcutsEffect({ token, layout_id: getLayout_id(props.layout) });

  return (
    <ShortcutsForField
      name={"Направления"}
      shortcuts={shortcuts}
      onInsert={onInsert}
      reloadShortcuts={reloadShortcuts}
      transitionId={"prescribed_services"}
    />
  );
});

const ShortcutsForAppointmentServices = observer(props => {
  const token: any = useStore(tokenStore);

  const shortcuts = useStore(
    appointmentServicesShortcuts.map(
      shortcuts =>
        shortcuts
          .filter(el => el.destination_type === "appointment_services")
          .reduce((value, item) => {
            return [...value, ...item.shortcuts];
          }, [])
    )
  );

  const onInsert = shortcut => {
    if (shortcut && shortcut.value) {
      dispatchEvent("appointmentServicesShortcutInsert", shortcut.value);
    }
  };

  const reloadShortcuts = () =>
    fetchShortcutsEffect({ token, layout_id: getLayout_id(props.layout) });

  return (
    <ShortcutsForField
      name={"Оказанные услуги"}
      shortcuts={shortcuts}
      onInsert={onInsert}
      reloadShortcuts={reloadShortcuts}
      transitionId={"appointment_services"}
    />
  );
});

export const Shortcuts = (props: {
  appointment: AppointmentWithDetails,
  appointment_id: TAppointment_id,
  layout_id: TLayout_id,
  layout: LayoutDetailed,
  fieldValuesMap: { [id: number]: LayoutFieldValue }
}) => {
  const fieldsWithShortcuts = useStore(fieldsWithShortcutsStore);
  const shortcutsSidebarSection = useStore(shortcutsSidebarSectionStore);
  const currentField = useStore(currentFieldStore);
  const editingShortcut = useStore(editingShortcutStore);
  const field = useStore(
    fieldsWithShortcutsStore.map(shortcutsMap => currentField && shortcutsMap[currentField])
  );
  const _isHaveServicesShortcuts = useStore(isHaveServicesShortcuts);
  const isCurrentFieldChecklists = useStore(isCurrentChecklists);
  const isCurrentFieldPrescribedServices = useStore(isCurrentPrescribedServices);
  const isCurrentFieldAppointmentServices = useStore(isCurrentAppointmentServices);

  useEffect(() => {
    closeShortcutsSidebar();
  }, [props.appointment_id]);

  const getSidebarSection = (shortcutsSection: ?ShortcutsSections) => {
    if (!shortcutsSection) return null;

    switch (shortcutsSection) {
      case "forLayout":
        return <ShortcutsForLayout appointment_id={props.appointment_id} />;
      case "forField":
        return currentField ? (
          <ShortcutsForFieldField
            appointment_id={props.appointment_id}
            layout_id={props.layout_id}
            field={field}
            fieldValue={props.fieldValuesMap[currentField]}
          />
        ) : _isHaveServicesShortcuts ? (
          <ShortcutsForServices appointment={props.appointment} layout={props.layout} />
        ) : (
          <ShortcutsForLayout appointment_id={props.appointment_id} />
        );
      case "forChecklist":
        return (
          <ShortCutsForChecklistWrapper
            layout_id={props.layout_id}
            appointment_id={props.appointment_id}
          />
        );
      case "forPrescribedServices":
        return (
          <ShortcutsForPrescribedServices appointment={props.appointment} layout={props.layout} />
        );
      case "forAppointmentServices":
        return (
          <ShortcutsForAppointmentServices appointment={props.appointment} layout={props.layout}/>
        );
      case "create":
        if (isCurrentFieldPrescribedServices) {
          return (
            <ShortcutsCreateForPrescribedServices appointment={props.appointment} layout={props.layout}  />
          )
        }
        if (isCurrentFieldAppointmentServices) {
          return (
            <ShortcutsCreateForAppointmentServices appointment={props.appointment} layout={props.layout}/>
          )
        }
        if (currentField) {
          return (
            <ShortcutsCreateForField
              fieldValue={props.fieldValuesMap[currentField]}
              appointment_id={props.appointment_id}
              field_id={currentField}
              layout_id={props.layout_id}
            />
          );
        } else if (isCurrentFieldChecklists) {
          return (
            <ShortcutsCreateForChecklist
              appointment_id={props.appointment_id}
              layout_id={props.layout_id}
            />
          );
        } else {
          return (
            <ShortcutsCreateForServices appointment={props.appointment} layout={props.layout} />
          );
        }
      case "edit":
        if (!editingShortcut) {
          return <ShortcutsForLayout appointment_id={props.appointment_id} />;
        }
        if (isCurrentFieldPrescribedServices) {
          return (
            <ShortcutsEditForPrescribedServices
              appointment={props.appointment}
              layout={props.layout}
              editingShortcut={editingShortcut}
            />
          )
        }
        if (isCurrentFieldAppointmentServices) {
          return (
            <ShortcutsEditForAppointmentServices
              appointment={props.appointment}
              layout={props.layout}
              editingShortcut={editingShortcut}
            />
          )
        }
        if (currentField) {
          return (
            <ShortcutsEditForField
              field_id={currentField}
              layout_id={props.layout_id}
              editingShortcut={editingShortcut}
            />
          );
        } else if (isCurrentFieldChecklists) {
          return (
            <ShortcutsEditForChecklist
              appointment_id={props.appointment_id}
              layout_id={props.layout_id}
              editingShortcut={editingShortcut}
            />
          );
        } else {
          return (
            <ShortcutsEditForServices
              layout_id={props.layout_id}
              editingShortcut={editingShortcut}
            />
          );
        }
      default:
        return null;
    }
  };

  return (
    <>
      {!_.isEmpty(fieldsWithShortcuts) && <ShortcutsFixedLabel onClick={openShortcutsForLayout} />}
      <SidebarContainer
        isShown={!!shortcutsSidebarSection}
        overrides={{ StyledSidebarContainer: { component: StyledSidebarContainer } }}
      >
        {getSidebarSection(shortcutsSidebarSection)}
      </SidebarContainer>
    </>
  );
};
