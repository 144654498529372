import { useEffect } from "react";

export const VoximplantSupportWebView = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = `
      window.VoxKitWidgetSettings = JSON.parse(localStorage.getItem('voxKitWidgetSettings') || '{}');

      const l = function () {
        const t = function () {
          "object" == typeof VoxKitWidget && "object" == typeof VoxKitWidgetSettings && VoxKitWidget.init(VoxKitWidgetSettings);
              
          let intervalId;
            
          const openVoximplant = () => {
            const element = document.querySelector('.widget-icon__closed');
            
            if (!element) {
              return;
            }

            element.click();
            window.ReactNativeWebView?.postMessage('ready');
            clearInterval(intervalId);
          }

          intervalId = setInterval(openVoximplant, 200);
        }
        const e = document.createElement("script");

        e.type = "text/javascript"
        e.async = !0
        e.src = "https://kit.voximplant.com/static/widgets/web-chat.js?" + (new Date).valueOf()
        e.readyState ?
          e.onreadystatechange = function () {
          "loaded" !== e.readyState && "complete" !== e.readyState || (e.onreadystatechange = null, t())
        } : e.onload = function () {
          t();
        };

        const a = document.getElementsByTagName("script")[0];
        a.parentNode.insertBefore(e, a);
      };
      l();
    `;

    document.head.appendChild(script);
  }, []);

  return null;
};
