import { useState, useEffect, useCallback } from "react";
import { useStore } from "effector-react";
import { keyBy } from "lodash";

import { tokenStore } from "../../stores/auth";
import { useApiRequest } from '../../apiv2/useApiRequest';
import apiv2 from '../../apiv2';
import appointmentMobxStore from "../../stores/AppointmentStore";

const newFolder = { isLayout: false, key: `folder-new`, name: "Новое событие", id: -1 };
const withoutFolderLayout = { id: 0, key: "without-layout", name: "Без события" };

export const useFolderSelection = ({
  layoutId,
  patientId,
  shouldRefresh = true,
  withoutFolderOption = false
}) => {
  const token = useStore(tokenStore);
  const openingCheckupAppointments = appointmentMobxStore.getOpeningCheckupAppointments();
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedFolderType, setSelectedFolderType] = useState("");
  const { data: layouts, error } = useApiRequest(async () => {
    if (patientId && layoutId) {
      let response = await apiv2.appointments.getFolders({ layoutId: layoutId || "", patientId: patientId || "" });
      if (openingCheckupAppointments.length && !response.f_layouts.some((layouts) => layouts.name === "Чекап")) {
        response.folders.push(...openingCheckupAppointments);
      }
      return response;
    }
  }, [token, layoutId, patientId, shouldRefresh]);

  const getKeyedFolders = useCallback(
    () => layouts && layouts.folders.map(l => ({ ...l, key: `folder-${l.id}` })),
    [layouts]
  );

  useEffect(() => {
    if (layouts) {
      setFolders(getKeyedFolders());
    }
  }, [getKeyedFolders, layouts]);

  useEffect(() => {
    setFolders([]);
    setSelectedFolder(null);
    setSelectedFolderType(null);
  }, [layoutId]);

  useEffect(() => {
    const openingCheckupByLayout = layouts?.f_layouts.find((layout) => layout.name === "Чекап") || null;
    const openingCheckupsByFolder = layouts?.folders.filter((folder) => folder.name.startsWith("Чекап")) || [];

    if (openingCheckupByLayout) {
      onFolderTypeChange(`layout-${openingCheckupByLayout.id}`);
    }
    if (openingCheckupsByFolder.length) {
      setSelectedFolder(`folder-${openingCheckupsByFolder[0].id}`);
    }
  }, [layouts]);

  if (!layouts || error) {
    const emptyResult = { folders: {}, folderTypes: {} };
    return emptyResult;
  }

  const folderLayouts = (withoutFolderOption
    ? [withoutFolderLayout, ...layouts.f_layouts]
    : layouts.f_layouts
  ).map(l => ({
    ...l,
    key: `layout-${l.id}`
  }));

  const folderTypes = keyBy(folderLayouts, "key");
  const folderTypesOrder = folderLayouts.map(layout => `${layout.key}`);
  const foldersById = keyBy(folders, "key");
  const foldersOrder = folders.map(layout => `${layout.key}`);

  const onFolderTypeChange = folderType => {
    if (folderType) {
      setSelectedFolderType(folderType);
      setSelectedFolder(newFolder.key);
      setFolders([newFolder, ...getKeyedFolders()]);
    } else {
      setFolders(getKeyedFolders());
      setSelectedFolder(null);
      setSelectedFolderType(null);
    }
  };

  const onFolderChange = folder => {
    if (!folder) {
      setFolders(getKeyedFolders());
      setSelectedFolder(null);
      setSelectedFolderType(null);
    } else {
      setSelectedFolder(folder);
    }
  };

  return {
    folders: foldersById,
    folderTypes,
    foldersOrder,
    folderTypesOrder,
    selectedFolder,
    selectedFolderType,
    onFolderChange,
    onFolderTypeChange,
    withoutFolderLayoutKey: `layout-${withoutFolderLayout.id}`
  };
};
