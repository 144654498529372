// @flow
import React from "react";
import styled from "styled-components";
import o, { type ExtractOverridesProps } from "react-overrides";
import { PXSmall } from "../styleguide/typography";
import { colors } from "../styleguide/colors";

export const Container = styled.button`
  background: none;
  border: none;

  padding: ${props => (props.containerNotPadding ? "0" : "10px 10px")};
  margin: ${props => (props.containerNotPadding ? "0" : "-10px 0px")};

  display: flex;
  align-items: center;

  cursor: pointer;
`;

const IconContainer = styled.div`
  & + p {
    margin-left: ${props => (props.containerNotPadding ? "0" : "8px")};
  }
`;

const Icon: React$ComponentType<any> = React.Fragment;

const Text = styled(PXSmall)`
  color: ${props => (props.color ? colors[props.color] : colors.gray500)};
  flex-shrink: 0;

  ${Container}:hover & {
    color: ${colors.lightMagenta};
  }
`;

export const CommonHintedButton = (props: {
  onClick?: () => mixed,
  hideIcon?: boolean,
  modifiers?: string,
  color?: string,
  containerNotPadding?: boolean,
  overrides?: ExtractOverridesProps<{
    Container: typeof Container,
    IconContainer: typeof IconContainer,
    Icon: typeof Icon,
    Text: typeof Text
  }>
}) => {
  const { onClick, color, hideIcon, containerNotPadding, modifiers } = props;
  return (
    <Container containerNotPadding={containerNotPadding} {...o} onClick={onClick}>
      {!hideIcon && (
        <IconContainer containerNotPadding={containerNotPadding} {...o}>
          <Icon {...o} />
        </IconContainer>
      )}
      <Text color={color} modifiers={modifiers ? modifiers : "left"} {...o} />
    </Container>
  );
};
