import React from "react";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";

export const FormInputNumber = (props) => {
  const newProps = {...props};
  delete newProps.subInfo;

  const handleOnChange = (onChange) => (event) => {
    const value = parseInt(event.target.value) || 0;

    if (value < props.min) {
      return onChange(props.min.toString());
    }
    if (value > props.max) {
      return onChange(props.max.toString());
    }

    onChange(value);
  }

  return (
    <>
      <Controller
        name={props.name}
        control={props.control}
        render={({
          field: { onChange, value },
          fieldState: { error },
        }) => (
          <TextField
            type="number"
            helperText={error ? error.message : props.subInfo ? props.subInfo : null}
            error={!!error}
            onChange={handleOnChange(onChange)}
            value={value || ''}
            fullWidth
            variant="outlined"
            inputProps={{
              readOnly: props.readOnly
            }}
            {...newProps}
          />
        )}
      />
    </>
  );
};
