import React from "react";
import { observer } from "mobx-react";
import { useStore } from "effector-react";
import { Box, LinearProgress, Button, Avatar as MuiAvatar } from "@mui/material";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

import { CorporateCard } from "./CorporateCard";
import { CorporateTitle } from "../styled/CorporateTypography.styled";
import { CorporateWrapper } from "../styled/CorporateWrapper.styled";
import corporateNewsStore from "../stores/corporateNewsStore";
import { CorporateComment } from "./CorporateComment";
import { currentUser } from "../../stores/auth";
import { breakpoints } from "../styled/common";
import { shortName } from "../utils/shortName";

const TextFieldStyled = styled(TextField)(
  () => `
  .MuiInputBase-input {
    padding: 0;
    height: 36px;
    font-size: 14px;
    padding: 0 12px;
  }
`
);

const ProgressBar = styled(Box)(
  ({ theme }) => `
    position: relative;
    height: 65px;
    overflow: hidden;
    .MuiLinearProgress-root {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
    ${theme.breakpoints.down("xl")} {
      height: 60px;
    }
    ${theme.breakpoints.down("lg")} {
      height: 55px;
    }
  `
);

const CardActionsContainer = styled(Box)(
  () =>
    `
      display: flex;
      align-items: center;
      gap: 12px;
      // justify-content: space-between;
      
      .MuiTextField-root {
        width: calc(50% - 44px);
      }
      
      @media screen and (max-width: 580px) {
        .MuiAvatar-root {
          display: none;
        }
        
        .MuiTextField-root {
          width: auto;
          flex: 1;
        }
      }
      
      @media screen and (max-width: 420px) {
        flex-direction: column;
        
        .MuiTextField-root {
          width: 100%;
        }
        
        .MuiButtonBase-root {
          width: 100%;
        }
      }
    `
);

const CommentList = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 24px 0 12px;
  max-height: 480px;
  overflow-y: auto;
`;

const Avatar = styled(MuiAvatar)`
  width: 36px;
  height: 36px;
  font-size: 0.9rem;
  text-transform: uppercase;
  ${breakpoints.desktop} {
    width: 30px;
    height: 30px;
    font-size: 0.8rem;
  }
  ${breakpoints.tablet} {
    width: 25px;
    height: 25px;
    font-size: 0.7rem;
  }
`;

export const CorporateComments = observer(props => {
  const user = useStore(currentUser);

  if (!corporateNewsStore.isInitialized) {
    return null;
  }

  return (
    <CorporateWrapper>
      <CorporateCard>
        <CorporateTitle>Комментарии</CorporateTitle>
        <CommentList>
          {
            corporateNewsStore.isCommentsLoading ? (
              <ProgressBar>
                <LinearProgress />
              </ProgressBar>
            ) : (
              corporateNewsStore.newsComments.length > 0 ? (
                  corporateNewsStore.newsComments.map(comment => (
                    <CorporateComment key={comment.id} comment={comment}/>
                  ))
              ) : "Нет новостей для новости"
            )
          }
        </CommentList>
        <CardActionsContainer>
          <Avatar src={user?.doctor?.photo}>
            {shortName({ lastName: user?.doctor?.last_name, firstName: user?.doctor?.first_name })}
          </Avatar>
          <TextFieldStyled placeholder="Написать комментарий..."/>
          <Button variant="contained">Отправить</Button>
        </CardActionsContainer>
      </CorporateCard>
    </CorporateWrapper>
  );
});
