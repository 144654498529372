// @flow
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { Router } from "react-router-dom";
import { history } from "./utils/history";
import LogRocket from "logrocket";
import * as Sentry from "@sentry/react";
import { ruRU } from '@mui/x-date-pickers/locales';

import "moment/locale/ru";
import {
  createTheme, ThemeProvider,
} from "@mui/material/styles";
import { colors } from "./components/styleguide/colors";
import { VERSION } from './version';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";



declare var module: {
  hot: {
    accept(path?: string, callback?: () => void): void
  }
};

if (module.hot) {
  module.hot.accept();
}

if (process.env.NODE_ENV === "production" && typeof Sentry !== "undefined") {
  console.log("Sentry started", location.origin);
  Sentry.init({
    dsn: location.hostname === "asya.fomin-clinic.ru"
      ? "https://3cadc3a27a972abf8889adcbf06dd14c@sentry.fomin-clinic.ru/5"
      : "https://1a341fe854c5e2685da85116108a0ea3@sentry.fomin-clinic.ru/11",
    release: VERSION,
    integrations: [
      Sentry.reactRouterV4BrowserTracingIntegration({ history }),
      Sentry.replayIntegration()
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^https:\/\/asya\.fomic-clinic\.ru/, /^https:\/\/asya-dev\.dev\.fomic-clinic\.ru/],
  });
}

if (process.env.NODE_ENV === "production") {
  if (location.host.startsWith("asya.")) {
    LogRocket.init("hn5tf3/mis-prod");
  }
  if (location.host.startsWith("asya-dev.")) {
    LogRocket.init("hn5tf3/mis-test");
  }
}

const THEME = createTheme(
  {
    components: {
      MuiTooltip: {
        defaultProps: {
          arrow: true,
        },
      },
    },
    typography: {
      allVariants: {
        fontFamily: "Graphik",
      }
    },
    palette: {
      primary: {
        main: colors.lightMagenta,
      },
      success: {
        main: colors.lightMagenta,
      },
      grey: {
        main: colors.gray400,
      },
      black: {
        main: colors.darkBlack,
      },
    },
  },
  ruRU,
);

const queryClient = new QueryClient();

const root = document.getElementById("root");
if (root) {
  createRoot(root).render(
    <ThemeProvider theme={THEME}>
      <QueryClientProvider client={queryClient}>
        <Router history={history}>
          <App />
        </Router>
      </QueryClientProvider>
    </ThemeProvider>
  );
}
