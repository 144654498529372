// @flow

import {
  MethodEditSelectComponent,
  MethodViewSelectComponent,
  // IncubatorViewSelectComponent,
  // IncubatorEditSelectComponent,
  OocytesAssessmentViewSelectComponent,
  OocytesAssessmentEditSelectComponent,
  ZygoteAssessmentEditSelectComponent,
  ZygoteAssessmentViewSelectComponent,
  HelpHatchEditSelectComponent,
  HelpHatchViewSelectComponent,
  CrushingEditSelectComponent,
  CrushingViewSelectComponent,
  // InseminationEnvironmentViewSelectComponent,
  // InseminationEnvironmentEditSelectComponent,
  // OilInseminationViewSelectComponent,
  // OilInseminationEditSelectComponent,
  // FrozenOocyteViewSelectComponent,
  // FrozenOocyteEditSelectComponent,
  MorphologyViewSelectComponent,
  MorphologyEditSelectComponent,
  FifthDayViewSelectComponent,
  FifthDayEditSelectComponent,
  SixthDayViewSelectComponent,
  SixthDayEditSelectComponent,
  SeventhDayViewSelectComponent,
  SeventhDayEditSelectComponent,
  StatusViewSelectComponent,
  StatusEditSelectComponent,
  FinalAssessmentViewSelectComponent,
  FinalAssessmentEditSelectComponent,
  BiopsyStatusViewSelectComponent,
  BiopsyStatusEditSelectComponent,
  BiopsyResultViewSelectComponent,
  BiopsyResultEditSelectComponent,
  IncubatorCultivationEditSelectComponent,
  IncubatorCultivationViewSelectComponent,
  // CultivationEnvironmentEditSelectComponent,
  // CultivationEnvironmentViewSelectComponent,
  // CultivationOilViewSelectComponent,
  // CultivationOilEditSelectComponent,
  // DifficultiesEditSelectComponent,
  // DifficultiesViewSelectComponent,
  CriopreservationEnvEditSelectComponent,
  CriopreservationEnvViewSelectComponent,
  BiopsyTypeEditSelectComponent,
  BiopsyTypeViewSelectComponent,
  CryoCarrierViewSelectComponent,
  CryoCarrierEditSelectComponent,
  ColorEditSelectComponent,
  ColorViewSelectComponent,
  SurvivalsEditSelectComponent,
  SurvivalsViewSelectComponent,
  FragmentationViewSelectComponent,
  FragmentationEditSelectComponent,
  FolderViewSelectComponent,
  FolderEditSelectComponent,
  DayEditSelectComponent,
  DayViewSelectComponent,
  AssessmentAfterDefrostViewSelectComponent,
  AssessmentAfterDefrostEditSelectComponent,
  PgtStatusViewSelectComponent,
  PgtStatusEditSelectComponent,
  PrevHatchViewSelectComponent,
  PrevHatchEditSelectComponent,
  DefrostEnvEditSelectComponent,
  DefrostEnvViewSelectComponent,
  OocytesTypeEditSelectComponent,
  OocytesTypeViewSelectComponent,
  NotationViewSelectComponent,
  NotationEditSelectComponent
} from "./EmbryosTableSelect";

import { CalendarTextEdit } from "./EmbryosCalendarEdit";
import { WideTextEdit } from "./EmbryosTableTextEdit";

import {
  WomanIdentifiersEditSelect,
  ManIdentifiersEditSelect
} from "./EmbryosTableIdentifierSelect";

import {
  VIEW_TYPES,
  allEmbryosColumns,
  cryopreservationsColumns,
  cryoStorageColumns,
  inseminationCultivationColumns,
  oocytesInseminationCultivationColumns,
  biopsyColumns,
  defrostsColumns
} from "./constants";
import { isObject } from "lodash";

import { medicalFileType, documentsTypes } from "./types";
import dayjs from "dayjs";
import { READABLE_DATE_TIME_FORMAT } from "../../utils/dates";

export const formatServerDate = (date: string) => {
  if (!date) return "";
  const parts = date.split("-");
  return `${parts[2]}.${parts[1]}.${parts[0]}`;
};

export const toServerDate = (date: string) => {
  if (!date) return "";
  const parts = date.split(".");
  if (parts.length < 2) return date;
  return `${parts[2]}-${parts[1]}-${parts[0]}`;
};

/* const formatTime = (date: string) => {
  if (!date) return "";
  let dt = date.split(".")[0];
  dt = dt.split(":");
  return `${dt[0]}:${dt[1]}`;
}; */

const getRowId = (item: any, id: number) => ({
  value: id,
  entityId: item.id,
  canReindex: true
});

const getFolderField = (item: any) => ({
  value: item.folder,
  entityId: item.id,
  field: "folder_id",
  DataViewer: FolderViewSelectComponent,
  DataEditor: FolderEditSelectComponent,
  print: false
});

const getFolderTransferField = (item: any) => ({
  value: item.folder_transfer ? item.folder_transfer.id : null,
  entityId: item.id,
  field: "folder_transfer_id",
  DataViewer: FolderViewSelectComponent,
  DataEditor: FolderEditSelectComponent,
  print: false
});

const getAttemptIdField = (item: any) => ({
  value: item.attempt_id,
  entityId: item.id,
  field: "attempt_id",
  isMinimal: true,
  DataEditor: WideTextEdit,
  isInteger: true,
  readOnly: true,
  print: true
});

const getIdField = (item: any) => ({
  value: item.id,
  entityId: item.id,
  readOnly: true,
  field: "id",
  isMinimal: true,
  print: true
});

const getDateStartField = (item: any) => ({
  field: "date_start",
  value: formatServerDate(item.date_start),
  entityId: item.id,
  DataEditor: CalendarTextEdit,
  print: true
});

const getBiopsyResultField = (item: any) => ({
  field: "biopsy_result",
  value: item.biopsy_result,
  entityId: item.id,
  DataViewer: BiopsyResultViewSelectComponent,
  DataEditor: BiopsyResultEditSelectComponent,
  print: true
});

const getCryoEnvField = (item: any) => ({
  field: "crio_env",
  value: item.crio_env,
  entityId: item.id,
  DataEditor: CriopreservationEnvEditSelectComponent,
  DataViewer: CriopreservationEnvViewSelectComponent,
  print: true
});

/* const getFrozenOocyteField = (item: any) => ({
  field: "frozen_oocyte",
  value: item.frozen_oocyte,
  entityId: item.id,
  DataEditor: FrozenOocyteEditSelectComponent,
  DataViewer: FrozenOocyteViewSelectComponent
}); */

const getWomanIdentifierField = (item: any) => ({
  field: "woman_medical_file",
  unsync: true,
  print: true,
  value: item.woman_medical_file
    ? item.woman_medical_file.short_name || item.woman_medical_file.number
    : item.woman_identifier,
  entityId: item.id,
  DataEditor: WomanIdentifiersEditSelect,
  readOnly: item.folder === null,
  processUpdateValue: updateValue => {
    if (!updateValue) {
      return { woman_medical_file_id: null, woman_identifier: null };
    } else if (updateValue.type === medicalFileType.medicalFile) {
      return { woman_medical_file_id: updateValue.value, woman_identifier: null };
    } else {
      return { woman_medical_file_id: null, woman_identifier: updateValue.value };
    }
  },
  processUpdate: originalData => {
    if (originalData.woman_medical_file) {
      return { woman_medical_file_id: originalData.woman_medical_file.id, woman_identifier: null };
    }

    return {
      woman_identifier: originalData.woman_identifier,
      woman_medical_file_id: null
    };
  }
});

const getManIdentifierField = (item: any) => ({
  field: "man_medical_file",
  unsync: true,
  print: true,
  value: item.man_medical_file
    ? item.man_medical_file.short_name || item.man_medical_file.number
    : item.man_identifier,
  entityId: item.id,
  DataEditor: ManIdentifiersEditSelect,
  readOnly: item.folder === null,
  processUpdateValue: updateValue => {
    if (!updateValue) {
      return { man_medical_file_id: null, man_identifier: null };
    } else if (updateValue.type === medicalFileType.medicalFile) {
      return { man_medical_file_id: updateValue.value, man_identifier: null };
    } else {
      return { man_medical_file_id: null, man_identifier: updateValue.value };
    }
  },
  processUpdate: originalData => {
    if (originalData.man_medical_file) {
      return { man_medical_file_id: originalData.man_medical_file.id, man_identifier: null };
    }

    return {
      man_identifier: originalData.man_identifier,
      man_medical_file_id: null
    };
  }
});

const getMethodField = (item: any) => ({
  field: "method",
  value: item.method,
  entityId: item.id,
  DataEditor: MethodEditSelectComponent,
  DataViewer: MethodViewSelectComponent,
  print: true
});

/* const getIncubatorInseminationField = (item: any) => ({
  field: "incubator_insemination",
  value: item.incubator_insemination,
  entityId: item.id,
  DataViewer: IncubatorViewSelectComponent,
  DataEditor: IncubatorEditSelectComponent
}); */

const getOocytesAssessmentField = (item: any) => ({
  field: "oocytes_assessment",
  value: item.oocytes_assessment,
  entityId: item.id,
  DataEditor: OocytesAssessmentEditSelectComponent,
  DataViewer: OocytesAssessmentViewSelectComponent,
  print: true
});

const getZygoteAssessmentField = (item: any) => ({
  field: "zygote_assessment",
  value: item.zygote_assessment,
  entityId: item.id,
  DataEditor: ZygoteAssessmentEditSelectComponent,
  DataViewer: ZygoteAssessmentViewSelectComponent,
  print: true
});

const getDayField = (item: any) => ({
  field: "day",
  value: item.day,
  entityId: item.id,
  DataEditor: DayEditSelectComponent,
  DataViewer: DayViewSelectComponent,
  print: true
});

const getFifthDayField = (item: any) => ({
  field: "fifth_day",
  value: item.fifth_day,
  entityId: item.id,
  DataEditor: FifthDayEditSelectComponent,
  DataViewer: FifthDayViewSelectComponent,
  print: true
});

const getSixthDayField = (item: any) => ({
  field: "sixth_day",
  value: item.sixth_day,
  entityId: item.id,
  DataEditor: SixthDayEditSelectComponent,
  DataViewer: SixthDayViewSelectComponent,
  print: true
});

const getHelpHatchField = (item: any) => ({
  value: item.help_hatch,
  entityId: item.id,
  field: "help_hatch",
  DataEditor: HelpHatchEditSelectComponent,
  DataViewer: HelpHatchViewSelectComponent,
  print: false
});

const getSeventhDayField = (item: any) => ({
  field: "seventh_day",
  value: item.seventh_day,
  entityId: item.id,
  DataEditor: SeventhDayEditSelectComponent,
  DataViewer: SeventhDayViewSelectComponent,
  print: true
});

const getStatusField = (item: any) => ({
  field: "status",
  value: item.status,
  entityId: item.id,
  DataEditor: StatusEditSelectComponent,
  DataViewer: StatusViewSelectComponent,
  print: true
});

const getCurrentStatusField = (item: any) => ({
  field: "current_status",
  value: item.current_status,
  entityId: item.id,
  DataEditor: StatusEditSelectComponent,
  DataViewer: StatusViewSelectComponent,
  print: true
});

const getAssessmentAfterDefrostField = (item: any) => ({
  field: "assessment_after_defrost",
  value: item.assessment_after_defrost,
  entityId: item.id,
  DataEditor: AssessmentAfterDefrostEditSelectComponent,
  DataViewer: AssessmentAfterDefrostViewSelectComponent,
  print: true
});

/* const getCultivationEnvField = (item: any) => ({
  field: "cultivation_env",
  value: item.cultivation_env,
  entityId: item.id,
  DataEditor: CultivationEnvironmentEditSelectComponent,
  DataViewer: CultivationEnvironmentViewSelectComponent
}); */

// const getTransferDateField = (item: any) => ({
//   field: "transfer_date",
//   readOnly: true,
//   value: formatServerDate(item.transfer_date),
// entityId: item.id,
//   DataEditor: CalendarTextEdit
// });

const getDateCrioField = (item: any) => ({
  field: "date_crio",
  value: formatServerDate(item.date_crio),
  entityId: item.id,
  DataEditor: CalendarTextEdit,
  print: true
});

/* const getInseminationEnvField = (item: any) => ({
  field: "insemination_env",
  value: item.insemination_env,
  entityId: item.id,
  DataViewer: InseminationEnvironmentViewSelectComponent,
  DataEditor: InseminationEnvironmentEditSelectComponent
}); */

const getFragmentationField = (item: any) => ({
  field: "fragmentation",
  value: item.fragmentation,
  entityId: item.id,
  DataViewer: FragmentationViewSelectComponent,
  DataEditor: FragmentationEditSelectComponent,
  print: true
});

const getFinalAssessmentField = (item: any) => ({
  readOnly: true,
  field: "final_assessment",
  value: item.final_assessment,
  entityId: item.id,
  DataEditor: FinalAssessmentEditSelectComponent,
  DataViewer: FinalAssessmentViewSelectComponent,
  print: true
});

const getDefrostDateField = (item: any) => ({
  field: "defrost_date",
  value: formatServerDate(item.defrost_date),
  entityId: item.id,
  DataEditor: CalendarTextEdit,
  print: true
});

const getCrioDateField = (item: any) => ({
  field: "date_crio",
  value: formatServerDate(item.date_crio),
  entityId: item.id,
  DataEditor: CalendarTextEdit,
  print: true
});

const getSurvivalField = (item: any) => ({
  value: item.survival,
  entityId: item.id,
  field: "survival",
  DataEditor: SurvivalsEditSelectComponent,
  DataViewer: SurvivalsViewSelectComponent,
  print: true
});

const getTankField = (item: any) => ({
  field: "tank",
  value: item.tank,
  entityId: item.id,
  DataEditor: WideTextEdit,
  isShortDebounce: true,
  print: true
});

const getCrushingField = (item: any) => ({
  field: "crushing",
  value: item.crushing,
  entityId: item.id,
  DataEditor: CrushingEditSelectComponent,
  DataViewer: CrushingViewSelectComponent,
  print: true
});

const getHolderField = (item: any) => ({
  field: "holder",
  value: item.holder,
  entityId: item.id,
  DataEditor: WideTextEdit,
  // isInteger: true,
  isShort: true,
  isShortDebounce: true,
  print: true
});

const getDaysOfCultivationBeforeCryoField = (item: any) => ({
  readOnly: true,
  field: "days_of_cultivation_before_cryo",
  value: item.days_of_cultivation_before_cryo,
  entityId: item.id,
  isInteger: true,
  DataEditor: WideTextEdit,
  print: true
});

/* const getOilInseminationField = (item: any) => ({
  field: "oil_insemination",
  value: item.oil_insemination,
  entityId: item.id,
  DataViewer: OilInseminationViewSelectComponent,
  DataEditor: OilInseminationEditSelectComponent
}); */

const getIncubatorCultivationField = (item: any) => ({
  field: "incubator_cultivation",
  value: item.incubator_cultivation,
  entityId: item.id,
  DataEditor: IncubatorCultivationEditSelectComponent,
  DataViewer: IncubatorCultivationViewSelectComponent,
  print: true
});

const getBiopsyTypeField = (item: any) => ({
  field: "biopsy_type",
  value: item.biopsy_type,
  entityId: item.id,
  DataEditor: BiopsyTypeEditSelectComponent,
  DataViewer: BiopsyTypeViewSelectComponent,
  print: false
});

const getStackField = (item: any) => ({
  field: "stack",
  value: item.stack,
  entityId: item.id,
  DataEditor: WideTextEdit,
  isShortDebounce: true,
  print: true
});

/* const getOilCultivationField = (item: any) => ({
  field: "oil_cultivation",
  value: item.oil_cultivation,
  entityId: item.id,
  DataEditor: CultivationOilEditSelectComponent,
  DataViewer: CultivationOilViewSelectComponent
}); */

const getColorField = (item: any) => ({
  field: "color",
  value: item.color,
  entityId: item.id,
  DataEditor: ColorEditSelectComponent,
  DataViewer: ColorViewSelectComponent,
  print: true
});

/* const getDefrostTimeField = (item: any) => ({
  value: item.defrost_time ? formatTime(item.defrost_time) : "",
  entityId: item.id,
  field: "defrost_time",
  isTime: true,
  DataEditor: WideTextEdit
}); */

const getMorphologyField = (item: any) => ({
  value: item.morphology,
  entityId: item.id,
  field: "morphology",
  DataEditor: MorphologyEditSelectComponent,
  DataViewer: MorphologyViewSelectComponent,
  print: true
});

const getCryoCarrierField = (item: any) => ({
  field: "cryo_carrier",
  value: item.cryo_carrier,
  entityId: item.id,
  DataEditor: CryoCarrierEditSelectComponent,
  DataViewer: CryoCarrierViewSelectComponent,
  print: true
});

const getCryoCarrierNumberField = (item: any) => ({
  field: "cryo_carrier_number",
  value: item.cryo_carrier_number,
  entityId: item.id,
  DataEditor: WideTextEdit,
  isInteger: true,
  isShort: true,
  print: true
});

const getTubeLabellingField = (item: any) => ({
  field: "tube_labeling",
  value: item.tube_labeling,
  entityId: item.id,
  DataEditor: WideTextEdit,
  print: true
});

const getBiopsyStatusField = (item: any) => ({
  field: "biopsy_status",
  value: item.biopsy_status,
  entityId: item.id,
  DataViewer: BiopsyStatusViewSelectComponent,
  DataEditor: BiopsyStatusEditSelectComponent,
  print: true
});

/* const getDifficultiesField = (item: any) => ({
  field: "difficulties",
  value: item.difficulties,
  entityId: item.id,
  DataEditor: DifficultiesEditSelectComponent,
  DataViewer: DifficultiesViewSelectComponent
}); */

const getMgStatusField = (item: any) => ({
  field: "mg_status",
  value: item.mg_status,
  entityId: item.id,
  DataEditor: WideTextEdit,
  readOnly: true,
  print: false
});

const getMgStatusDateField = (item: any) => ({
  field: "mg_status_date",
  value: item.mg_status_date ? dayjs(item.mg_status_date).format(READABLE_DATE_TIME_FORMAT) : '',
  entityId: item.id,
  DataEditor: WideTextEdit,
  readOnly: true,
  print: false
});

const getMgSequencingResultField = (item: any) => ({
  field: "mg_sequencing_result",
  value: item.mg_sequencing_result,
  entityId: item.id,
  DataEditor: WideTextEdit,
  readOnly: true,
  print: false
});
const getFeaturesOfBiopsyField = (item: any) => ({
  field: "features_of_biopsy",
  value: item.features_of_biopsy,
  entityId: item.id,
  DataEditor: WideTextEdit,
  print: false
});

const getNumberOfCellsInBiopsyField = (item: any) => ({
  field: "number_of_cells_in_biopsy",
  value: item.number_of_cells_in_biopsy,
  entityId: item.id,
  isInteger: true,
  isShort: true,
  DataEditor: WideTextEdit,
  print: true
});

const getPgtStatusField = (item: any) => ({
  field: "pgt_status",
  value: item.pgt_status,
  entityId: item.id,
  DataViewer: PgtStatusViewSelectComponent,
  DataEditor: PgtStatusEditSelectComponent,
  print: true
});

const getPrevHatchField = (item: any) => ({
  field: "prev_hatch",
  value: item.prev_hatch,
  entityId: item.id,
  DataViewer: PrevHatchViewSelectComponent,
  DataEditor: PrevHatchEditSelectComponent,
  print: true
});

const getDefrostEnvField = (item: any) => ({
  field: "defrost_env",
  value: item.defrost_env,
  entityId: item.id,
  DataEditor: DefrostEnvEditSelectComponent,
  DataViewer: DefrostEnvViewSelectComponent,
  print: true
});

const getOocytesTypeField = (item: any) => ({
  field: "oocytes_type",
  value: item.oocytes_type,
  entityId: item.id,
  DataEditor: OocytesTypeEditSelectComponent,
  DataViewer: OocytesTypeViewSelectComponent,
  print: true
});

const getNotationField = (item: any) => ({
  field: "notation",
  value: item.notation,
  entityId: item.id,
  DataEditor: NotationEditSelectComponent,
  DataViewer: NotationViewSelectComponent,
  print: true
});

export const getAllEmbryonTableData = (data: any[]): any => {
  return data.map(item => [
    getIdField(item),
    getFinalAssessmentField(item),
    getOocytesAssessmentField(item),
    getMethodField(item),
    getIncubatorCultivationField(item),
    getZygoteAssessmentField(item),
    getCrushingField(item),
    getFifthDayField(item),
    getSixthDayField(item),
    getSeventhDayField(item),
    getBiopsyStatusField(item),
    getTankField(item),
    getStackField(item),
    getHolderField(item),
    getStatusField(item),
    getFolderField(item)
  ]);
};

export const getActualData = (data: Array<any>): any => {
  return data.map(item => [
    getAttemptIdField(item),
    getStatusField(item),
    getFinalAssessmentField(item),
    getBiopsyResultField(item),
    getTankField(item),
    getStackField(item),
    getHolderField(item),
    getDateStartField(item),
    getDateCrioField(item),
    getManIdentifierField(item),
    getWomanIdentifierField(item),
    getMethodField(item),
    getBiopsyStatusField(item)
  ]);
};

// Биопсия
export const getBiopsyData = (data: Array<any>): any => {
  return data.map(item => [
    // getDateStartField(item),
    getCryoCarrierNumberField(item),
    getWomanIdentifierField(item),
    getManIdentifierField(item),
    getPgtStatusField(item),
    getDateCrioField(item),
    getFinalAssessmentField(item),
    getDaysOfCultivationBeforeCryoField(item),
    getTubeLabellingField(item),
    getNumberOfCellsInBiopsyField(item),
    getPrevHatchField(item),
    getBiopsyTypeField(item),
    getFeaturesOfBiopsyField(item),
    getMgStatusField(item),
    getMgStatusDateField(item),
    getMgSequencingResultField(item),
  ]);
};

// Криоконсервация
export const getCryopreservationsData = (data: Array<any>) => {
  return data.map<any>(item => [
    getWomanIdentifierField(item),
    getManIdentifierField(item),
    getDateCrioField(item),
    getDaysOfCultivationBeforeCryoField(item),
    getFinalAssessmentField(item),
    getColorField(item),
    getCryoEnvField(item),
    getCryoCarrierField(item),
    getCryoCarrierNumberField(item),
    getPgtStatusField(item),
    getTankField(item),
    getStackField(item),
    getHolderField(item),
    getCurrentStatusField(item)
  ]);
};

// Криохранилище
export const getCryoStorageData = (data: Array<any>) => {
  return data.map<any>(item => [
    getWomanIdentifierField(item),
    getManIdentifierField(item),
    getDateCrioField(item),
    getFinalAssessmentField(item),
    getDaysOfCultivationBeforeCryoField(item),
    getCryoCarrierNumberField(item),
    getCryoEnvField(item),
    getCryoCarrierField(item),
    getColorField(item),
    getPgtStatusField(item),
    getTankField(item),
    getStackField(item),
    getHolderField(item),
    getCurrentStatusField(item)
  ]);
};

// Разморозка
export const getDefrostsData = (data: Array<any>): any => {
  return data.map(item => [
    getCryoCarrierNumberField(item),
    getWomanIdentifierField(item),
    getManIdentifierField(item),
    getCrioDateField(item),
    getFinalAssessmentField(item),
    getDaysOfCultivationBeforeCryoField(item),
    getDefrostDateField(item),
    getDefrostEnvField(item),
    getSurvivalField(item),
    getFragmentationField(item),
    getAssessmentAfterDefrostField(item),
    getHelpHatchField(item),
    getCurrentStatusField(item),
    getFolderTransferField(item)
  ]);
};

// Оплодотворение и культивация
export const getInseminationData = (data: Array<any>) => {
  return data.map<any>((item, index) => [
    getRowId(item, index + 1),
    getWomanIdentifierField(item),
    getManIdentifierField(item),
    getDateStartField(item),
    getMethodField(item),
    getOocytesAssessmentField(item),
    getOocytesTypeField(item),
    getMorphologyField(item),
    getZygoteAssessmentField(item),
    getCrushingField(item),
    getDayField(item),
    getFifthDayField(item),
    getSixthDayField(item),
    getSeventhDayField(item),
    getNotationField(item),
    getStatusField(item),
    getCurrentStatusField(item),
    getCryoCarrierNumberField(item),
    getPgtStatusField(item),
    getFolderField(item),
  ]);
};

// Оплодотворение и культивация (ооцитов)
export const getOocytesInseminationData = (data: Array<any>) => {
  return data.map<any>((item, index) => [
    getRowId(item, index + 1),
    getWomanIdentifierField(item),
    getDateStartField(item),
    getOocytesAssessmentField(item),
    getOocytesTypeField(item),
    getMorphologyField(item),
    getStatusField(item),
    getCurrentStatusField(item),
    getCryoCarrierNumberField(item),
    getFolderField(item),
  ]);
};

export const getTableColumns = (filter: $Values<typeof VIEW_TYPES>) => {
  switch (filter) {
    case VIEW_TYPES.ALL: {
      return allEmbryosColumns;
    }
    case VIEW_TYPES.CRYOPRESERVATIONS: {
      return cryopreservationsColumns;
    }
    case VIEW_TYPES.CULTIVATION_AND_FERTILIZATION: {
      return inseminationCultivationColumns;
    }
    case VIEW_TYPES.OOCYTES_CULTIVATION_AND_FERTILIZATION: {
      return oocytesInseminationCultivationColumns;
    }
    case VIEW_TYPES.BIOPSIES: {
      return biopsyColumns;
    }
    case VIEW_TYPES.DEFROSTS: {
      return defrostsColumns;
    }
    case VIEW_TYPES.CRYOSTORAGE: {
      return cryoStorageColumns;
    }
    default:
      return [];
  }
};

export const getTableData = (
  filter: $Values<typeof VIEW_TYPES>,
  data: any,
  readOnly?: boolean
): any => {
  let res = [];

  switch (filter) {
    case VIEW_TYPES.ALL: {
      res = getAllEmbryonTableData(data);
      break;
    }
    case VIEW_TYPES.CRYOPRESERVATIONS: {
      res = getCryopreservationsData(data);
      break;
    }
    case VIEW_TYPES.CULTIVATION_AND_FERTILIZATION: {
      res = getInseminationData(data);
      break;
    }
    case VIEW_TYPES.OOCYTES_CULTIVATION_AND_FERTILIZATION: {
      res = getOocytesInseminationData(data);
      break;
    }
    case VIEW_TYPES.BIOPSIES: {
      res = getBiopsyData(data);
      break;
    }
    case VIEW_TYPES.DEFROSTS: {
      res = getDefrostsData(data);
      break;
    }
    case VIEW_TYPES.ACTUAL: {
      res = getActualData(data);
      break;
    }
    case VIEW_TYPES.CRYOSTORAGE: {
      res = getCryoStorageData(data);
      break;
    }
    default:
      break;
  }

  if (readOnly) {
    res = res.map(row => row.map(cell => ({ ...cell, readOnly })));
  }

  return res;
};

export function getOffsetRect(element: HTMLElement) {
  return {
    width: element.offsetWidth,
    height: element.offsetHeight,
    left: element.offsetLeft,
    top: element.offsetTop
  };
}

export const constructTableRowPayload = (row: Array<any>, originalData: any) => {
  return row.reduce(
    (acc, item) => {
      if (!item.field) {
        return acc;
      }
      if (item.isInteger) {
        return { ...acc, [item.field]: parseInt(item.value, 10) };
      } else if (item.processUpdate) {
        return { ...acc, ...item.processUpdate(originalData) };
      }

      return { ...acc, [item.field]: item.value };
    },
    { id: row[0].entityId }
  );
};

export const constructTableServerPayload = (data: any) => {
  if (data.date_start) {
    data.date_start = toServerDate(data.date_start);
  }

  if (data.transfer_date) {
    data.transfer_date = toServerDate(data.transfer_date);
  }

  if (data.date_crio) {
    data.date_crio = toServerDate(data.date_crio);
  }

  if (data.folder_id) {
    data.folder_id = isObject(data.folder_id) ? data.folder_id.id : data.folder_id;
  }

  if (data.folder_transfer_id) {
    data.folder_transfer_id = isObject(data.folder_transfer_id)
      ? data.folder_transfer_id.id
      : data.folder_transfer_id;
  }

  return data;
};

export const createDocumentLinks = (medicalFileId: number) => [
  {
    title: "Сопроводительное / Женщина",
    to: `/embryos/${medicalFileId}/print/documents/${documentsTypes.coverLetterWoman}?needEdit=true`
  },
  {
    title: "Сопроводительное / Женщина + Курьер",
    to: `/embryos/${medicalFileId}/print/documents/${documentsTypes.coverLetterWomanCourier}?needEdit=true`
  },
  {
    title: "Сопроводительное / Пара",
    to: `/embryos/${medicalFileId}/print/documents/${documentsTypes.coverLetterPair}?needEdit=true`
  },
  {
    title: "Сопроводительное / Пара + курьер",
    to: `/embryos/${medicalFileId}/print/documents/${documentsTypes.coverLetterPairCourier}?needEdit=true`
  }
];

export const createFolderDocumentLinks = (medicalFileId: number, folderId: number) => [
  {
    title: "Эмбриопротокол по 803 приказу",
    to: `/embryos/${folderId}/${medicalFileId}/print/documents/${documentsTypes.embryosProtocol}?needEdit=true`
  },
  {
    title: "Криоперенос по 803 приказу",
    to: `/embryos/${folderId}/${medicalFileId}/print/documents/${documentsTypes.cryoTransfer}?needEdit=true`
  }
];
