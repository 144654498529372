// @flow
import { createEffect, createEvent, createStore } from "effector";
import type { User } from "../domain/entities/User";
import connectLocalStorage from "effector-localstorage";
import { getLastNameWithInitials } from "../domain/services/doctor";
import LogRocket from "logrocket";
import * as Sentry from "@sentry/react";
import _ from "lodash";
import { AvailableApplicationFlavors, getApplicationFlavor } from "../utils/getApplicationFlavor";
import { clearAllCache } from '../apiv2/apiService';
import { reInitVoximplant, setVoximplantSupportData } from "../utils/voximplantSupport";
import oldAuth from '../apiv2/entities/oldAuth';

const tokenLocalStorage = connectLocalStorage("kdf_ems_token");
export const tokenStore = createStore<?string>(tokenLocalStorage.init());

export const setToken = createEvent<string>("setToken");

export const logout = createEvent<*>("logout");

const sentryScope = Sentry.getCurrentScope();

const onLogout = async () => {
  const token = tokenStore.getState();
  const clearVoximplantSupport = () => {
    setVoximplantSupportData({
      client_id: Date.now()
    });
    if (window.VoxKitWidget) {
      reInitVoximplant();
    }
  }
  if (!token) {
    clearVoximplantSupport();
    return;
  }
  try {
    await oldAuth.logout();
    sentryScope.clear();
    clearVoximplantSupport();
  } catch (e) {
    console.log(e);
  }
};

logout.watch(() => onLogout());
tokenStore.watch((state) => {
  tokenLocalStorage.watcher(state);
});
tokenStore.on(setToken, (state, newToken) => {
  return newToken;
});
tokenStore.on(logout, () => null);

export const getCurrentUserEffect = createEffect("get current user").use(async () => await oldAuth.getCurrentUser());

export const currentUser = createStore<?User>(null);

export const currentUserDoctorId = currentUser.map<any>(user => {
  if (user && user.doctor) {
    const doctor = (user.doctor: any);
    return doctor.id;
  }
  return null;
});

currentUser.on(getCurrentUserEffect.done, (oldUser, { result: newUser }) => {
  newUser = (newUser: User);

  if (_.isEqual(oldUser, newUser)) {
    return oldUser;
  }

  if (newUser) {
    if (!oldUser || (oldUser && oldUser.id !== newUser.id)) {
      if (process.env.NODE_ENV === "production") {
        LogRocket.identify(newUser.id, {
          // $FlowFixMe
          name: newUser.doctor ? getLastNameWithInitials(newUser.doctor) : newUser.username
        });
      }
    }
    // Sentry context
    if (process.env.NODE_ENV === "production") {
      sentryScope.setUser({
        id: newUser.id,
        username: newUser.username
      });

      sentryScope.setTag('user_id', newUser.id);
      sentryScope.setTag('username', newUser.username);
      sentryScope.setTag('doctor_id', newUser.doctor?.id);

      sentryScope.setContext('Doctor Data', {
        id: newUser.doctor?.id,
        name: getLastNameWithInitials(newUser.doctor),
        organizations: newUser.doctor?.organizations,
        cities: newUser.doctor?.cities,
        specialities: newUser.doctor?.specialities.map(spec => JSON.stringify(spec)),
        schedule_step: newUser.doctor?.schedule_step
      });
    }
    const oldClientId = window.VoxKitWidgetSettings.client_data.client_id;
    setVoximplantSupportData(
      {
        client_id: newUser.doctor ? newUser.doctor.id : newUser.id,
        client_phone: newUser.username,
        client_display_name: newUser.doctor ? getLastNameWithInitials(newUser.doctor) : newUser.username,
        client_avatar: newUser.doctor.photo,
        client_email: {},
      },
      true,
      true
    );
    if (parseInt(oldClientId) !== (newUser.doctor ? newUser.doctor.id : newUser.id)) {
      reInitVoximplant();
    }
  }
  setInterval(() => {
    clearAllCache();
  }, 1000 * 60 * 60 * 8)
  return newUser;
});

tokenStore.on(getCurrentUserEffect.fail, () => {
  clearAllCache();
  return null;
});

currentUser.on(logout, () => {
  clearAllCache();
  return null;
});

tokenStore.watch(token => {
  if (token) {
    getCurrentUserEffect({ token });
  }
});

if (getApplicationFlavor() !== AvailableApplicationFlavors.TELEMED) {
  // TODO: Отключение refresh-token для telemed. Убрать когда добавят использование рефреш токена.
  const refreshToken = async () => {
    const token = tokenStore.getState();
    if (!token) {
      return;
    }
    try {
      const result = await oldAuth.postRefreshToken(token);
      setToken(result.token);
    } catch (e) {
      console.log(e);
    }
  };

  setInterval(refreshToken, 10 * 60 * 1000);
}
