import React from "react";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";

export const FormInputText = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({
        field: { onChange, value },
        fieldState: { error },
      }) => {
        if (value === null) {
          onChange({target: {value: ''}})
        }
        const newProps = {...props};
        const needSubmitEnter = props.needSubmitEnter;
        const submit = props.submit;
        delete newProps.submit;
        delete newProps.needSubmitEnter;
        delete newProps.transformValue;
        return (
          <TextField
            helperText={error ? error.message : props.subInfo ? props.subInfo : null}
            error={!!error}
            onChange={onChange}
            value={props.transformValue ? props.transformValue(value || '') : value || ''}
            fullWidth
            onKeyUp={(submit && needSubmitEnter) && ((e) => {
              if (e.code === "Enter") {
                submit();
              }
            })}
            inputProps={
              { readOnly: props.readOnly, }
            }
            variant="outlined"
            {...newProps}
          />
        )
      }}
    />
  );
};
