// @flow
import * as React from "react";
import { useState, useMemo } from "react";
import type { Shortcut, ChecklistShortcut } from "../../domain/entities/Shortcut";
import { ModalPortal } from "../modal/ModalPortal";
import { useConfirm } from "../alert-warning/useConfirm";
import { AlertWarning } from "../alert-warning/AlertWarning";
import { AlertWarningPrimaryButton, AlertWarningWhiteButton } from "../buttons/AlertButton";
import { HeadingH3 } from "../styleguide/typography";
import { BackButton } from "../aside/BackButton";
import { SidebarHeader } from "../sidebar/SidebarHeader";
import { SidebarLayout } from "../sidebar/SidebarLayout";
import { SidebarFade } from "../sidebar/SidebarFade";
import { useSidebarFade } from "../sidebar/useSidebarFade";
import {
  ShortcutsFormLayout,
  ShortcutsDefaultInputField,
  ShortcutsTextareaField,
  ShortcutsPrimaryButton,
  ShortcutsClearButton,
  ShortcutsChecklistField, ShortcutsPrescribedServicesField
} from "./ShortcutsFormLayout";
import { closeShortcutsSidebar } from "../../stores/shortcuts/shortcutsSections";
import type { TChecklistDestination } from "../../domain/value-objects/TShortcuts";

export const ShortcutsEdit = (props: {
  editingShortcut: Shortcut | ChecklistShortcut,
  onSubmit: (name: string, value: string) => mixed,
  onDeleteShortcut: () => mixed,
  openShortcuts: () => mixed,
  isChecklist?: boolean,
  isServices?: boolean,
  isAppointmentServices?: boolean,
  fieldName?: TChecklistDestination
}) => {
  const {
    editingShortcut,
    onSubmit,
    onDeleteShortcut,
    isChecklist,
    isServices,
    isAppointmentServices,
    fieldName,
    openShortcuts
  } = props;

  const [name, setName] = useState(editingShortcut.name);
  const [value, setValue] = useState(editingShortcut.value);

  const shouldStartTransition = useSidebarFade();

  const cancelConfirm = useConfirm();
  const deleteConfirm = useConfirm();

  const onCancel = async () => {
    if (!cancelConfirm.requestConfirm) {
      return;
    }

    try {
      const confirmResult = await cancelConfirm.requestConfirm();
      if (!confirmResult) {
        return;
      }
      openShortcuts();
    } catch (e) {
      console.log(e);
    }
  };

  const onClose = async () => {
    if (!cancelConfirm.requestConfirm) {
      return;
    }

    try {
      const confirmResult = await cancelConfirm.requestConfirm();
      if (!confirmResult) {
        return;
      }
      closeShortcutsSidebar();
    } catch (e) {
      console.log(e);
    }
  };

  const onDelete = async () => {
    if (!deleteConfirm.requestConfirm) {
      return;
    }

    try {
      const confirmResult = await deleteConfirm.requestConfirm();
      if (!confirmResult) {
        return;
      }
      onDeleteShortcut();
    } catch (e) {
      console.log(e);
    }
  };

  const backButton = <BackButton text="Заготовки поля " handleBackButtonClick={onCancel} />;
  const header = (
    <SidebarHeader onClose={onClose} title="Редактировать заготовку" breadcrumbs={backButton} />
  );

  const fields = useMemo(() => {
    if (isServices) {
      return (
        <>
          <ShortcutsDefaultInputField
            legend="Название заготовки"
            value={name}
            handleChange={setName}
          />
          {/* $FlowFixMe */}
          <ShortcutsPrescribedServicesField
            /* $FlowFixMe */
            fieldName={fieldName}
            /* $FlowFixMe */
            services={editingShortcut.value}
            legend="Содержание заготовки"
          />
        </>
      );
    }
    if (isAppointmentServices) {
      return (
        <>
          <ShortcutsDefaultInputField
            legend="Название заготовки"
            value={name}
            handleChange={setName}
          />
          {/* $FlowFixMe */}
          <ShortcutsPrescribedServicesField
            /* $FlowFixMe */
            fieldName={fieldName}
            /* $FlowFixMe */
            services={editingShortcut.value}
            legend="Содержание заготовки"
          />
        </>
      );
    }
    if (isChecklist) {
      return (
        <>
          <ShortcutsDefaultInputField
            legend="Название заготовки"
            value={name}
            handleChange={setName}
          />
          {/* $FlowFixMe */}
          <ShortcutsChecklistField
            fieldName={fieldName}
            checklist={value}
            legend="Содержание заготовки"
          />
        </>
      );
    } else {
      return (
        <>
          <ShortcutsDefaultInputField
            legend="Название заготовки"
            value={name}
            handleChange={setName}
          />
          {/* $FlowFixMe */}
          <ShortcutsTextareaField legend="Текст заготовки" value={value} handleChange={setValue} />
        </>
      );
    }
  }, [isChecklist, name, value]);

  const buttons = (
    <>
      {/* $FlowFixMe */}
      <ShortcutsPrimaryButton disabled={!name || !value} onClick={() => onSubmit(name, value)}>
        Сохранить
      </ShortcutsPrimaryButton>
      <ShortcutsClearButton onClick={onDelete}>Удалить</ShortcutsClearButton>
    </>
  );

  const cancelAlertWarning = (
    <AlertWarning
      isNeedConfirm={cancelConfirm.isNeedConfirm}
      sendConfirm={cancelConfirm.sendConfirm}
      heading={<HeadingH3 modifiers="center">Изменения не будут сохранены</HeadingH3>}
      cancelButton={onClick => (
        <AlertWarningWhiteButton onClick={onClick}>Отменить</AlertWarningWhiteButton>
      )}
      confirmButton={onClick => (
        <AlertWarningPrimaryButton onClick={onClick}>Хорошо</AlertWarningPrimaryButton>
      )}
    />
  );

  const deleteAlertWarning = (
    <AlertWarning
      isNeedConfirm={deleteConfirm.isNeedConfirm}
      sendConfirm={deleteConfirm.sendConfirm}
      heading={<HeadingH3 modifiers="center">Вы точно хотите удалить заготовку?</HeadingH3>}
      cancelButton={onClick => (
        <AlertWarningWhiteButton onClick={onClick}>Отменить</AlertWarningWhiteButton>
      )}
      confirmButton={onClick => (
        <AlertWarningPrimaryButton onClick={onClick}>Удалить</AlertWarningPrimaryButton>
      )}
    />
  );

  return (
    <>
      <SidebarFade>
        {shouldStartTransition && (
          <SidebarLayout header={header}>
            <ShortcutsFormLayout fields={fields} buttons={buttons} />
          </SidebarLayout>
        )}
      </SidebarFade>
      <ModalPortal>
        {cancelAlertWarning}
        {deleteAlertWarning}
      </ModalPortal>
    </>
  );
};
