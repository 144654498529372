// @flow
import * as React from "react";
import styled from "styled-components";

import { colors } from "../styleguide/colors";
import CloseIcon from "./icons/close.svg";

const Container = styled.div`
  background-color: ${colors.white};
  border-radius: 3px;
  position: relative;
  max-width: 100%;

  @media (max-width: 768px) {
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
  }
`;

const Close = styled.button`
  width: 23px;
  height: 23px;
  background: url(${CloseIcon}) no-repeat;
  background-size: contain;

  border: none;
  cursor: pointer;

  position: absolute;
  top: 37px;
  right: 39px;

  @media (max-width: 768px) {
    top: 20px;
    right: 20px;
  }
`;

export const ModalWithCloseButtonLayout = (props: {
  handleClose?: () => mixed,
  hiddenCloseButton?: boolean,
  children: React.Node
}) => {
  return (
    <Container>
      {!props.hiddenCloseButton && (
        <Close onClick={props.handleClose} />
      )}

      {props.children}
    </Container>
  );
};

export const ModalLayout = (props: { children: React.Node }) => {
  return <Container>{props.children}</Container>;
};
