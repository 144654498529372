import { withModifiers } from "with-modifiers";
import styled from "styled-components";
import { breakpoints, corporateWrapperPalette } from "./common";

const commonModifiers = {
  withoutPadding: () => `
    ${breakpoints.all} {
      padding: 0;
    }
  `
};

export const CorporateWrapper = withModifiers({
  ...commonModifiers
})(
  modifier => styled.div`
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.04);
    background: ${corporateWrapperPalette.white};
    border-radius: 20px;
    padding: 20px;
    ${breakpoints.desktop} {
      padding: 15px;
    }
    ${breakpoints.tablet} {
      padding: 10px;
    }

    ${modifier};
  `
);

export const CorporateControlPanelWrapper = withModifiers({
  ...commonModifiers,
  fixedHeight: () => `
    ${breakpoints.sm} {
      height: 55px;
    }
  `
})(
  modifier => styled(CorporateWrapper)`
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 12px;
    padding: 0 15px;
    height: 65px;
    ${breakpoints.desktop} {
      padding: 0 10px;
    }
    ${breakpoints.xl} {
      height: 60px;
    }
    ${breakpoints.lg} {
      height: 55px;
    }
    ${breakpoints.sm} {
      height: auto;
    }
    ${breakpoints.sm} {
      padding: 0 10px 10px;
    }

    ${modifier};
  `
);

export const CorporateMiniCardWrapper = withModifiers({})(
  modifier => styled(CorporateWrapper)`
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    min-height: 370px;
    ${breakpoints.desktop} {
      min-height: 320px;
    }
    ${breakpoints.tablet} {
      min-height: 270px;
    }
    ${breakpoints.all} {
      padding: 0;
    }

    ${modifier};
  `
);

export const CorporateAccordionWrapper = styled(CorporateWrapper)`
  box-shadow: none;
  overflow: hidden;
  border-radius: 12px;
  ${breakpoints.all} {
    padding: 0;
  }
  > .MuiPaper-root {
    background-color: transparent;
  }
  .MuiCollapse-root {
    .MuiAccordionDetails-root {
      padding-top: 0;
      padding-right: 0;
    }
  }
  .MuiButtonBase-root {
    &:hover {
      background-color: rgba(216, 216, 216, 0.15);
    }
  }
`;
