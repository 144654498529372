// @flow
import React from "react";
import { CorporateWrapper } from "../../styled/CorporateWrapper.styled";
import { CorporateCard } from "../../components/CorporateCard";
import { CorporateSubTitle } from "../../styled/CorporateTypography.styled";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  .MuiFormControl-root {
    padding: 24px 0 12px;
  }
`;

export const CorporateFeedBackWidget = () => {
  return (
    <CorporateWrapper modifiers={"withoutPadding"}>
      <CorporateCard title="Обратная связь" enableCollapseContent>
        <Container>
          <CorporateSubTitle>
            Напишите нам, если у вас есть предложения или пожелания
          </CorporateSubTitle>
          <TextField placeholder="Ваши пожелания" />
          <Button variant="contained">Отправить</Button>
        </Container>
      </CorporateCard>
    </CorporateWrapper>
  );
};
