import React, { useEffect, useState } from "react";
import {
  Checkbox, Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Typography
} from "@mui/material";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import _ from "lodash"

const ChipsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-left: 11px;

  @media (max-width: 768px) {
      gap: 8px;
  }
`;

export const FormInputChips = (props) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState('');

  const handleSelectMultiple = (value) => {
    const isPresent = selectedItems.indexOf(value);
    if (isPresent !== -1) {
      const remaining = selectedItems.filter((item) => item !== value);
      setSelectedItems(remaining);
    } else {
      setSelectedItems((prevItems) => [...prevItems, value]);
    }
  };

  const handleSelect = (value) => {
    setSelectedItem(value);
  };

  useEffect(() => {
    if (props.settedValues[props.name] && !_.isEqual(props.settedValues[props.name], selectedItems)) {
      if (props.multiple) {
        setSelectedItems(props.settedValues[props.name]);
      } else {
        setSelectedItem(props.settedValues[props.name]);
      }
    }
  }, [props.settedValues]);

  useEffect(() => {
    props.setValue(props.name, selectedItems);
  }, [selectedItems]);

  useEffect(() => {
    props.setValue(props.name, selectedItem);
  }, [selectedItem]);

  return (
    <FormControl className={'form-input-chips'} error={!!props.control._formState.errors[props.name]} size={"small"} variant={"outlined"}>
      <FormLabel component="legend" className="not-transform">{props.label} {props.required && "*"}</FormLabel>
      {!!props.subInfo && <Typography variant="caption">{props.subInfo}</Typography>}
      <ChipsWrapper className={`form-input-multi-checkbox ${props.className}`}>
        {props.options.map((option) => {
          return (
            <FormControlLabel
              key={option.value}
              className={"form-input-multi-checkbox__item"}
              control={
                <Controller
                  control={props.control}
                  name={props.name}
                  render={() => {
                    return (
                      <Chip color={(props.multiple && selectedItems.includes(option.value)) || (!props.multiple && selectedItem === option.value) ? "primary" : 'default'} label={option.label} disabled={props.readOnly}
                            onClick={() => props.multiple ? handleSelectMultiple(option.value) : handleSelect(option.value)} />
                    );
                  }}
                />
              }
            />
          );
        })}
      </ChipsWrapper>
    </FormControl>
  );
};
