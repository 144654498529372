import React, { useMemo } from "react";
import {MaterialReactTable, MRT_ExpandAllButton, MRT_ExpandButton, useMaterialReactTable} from "material-react-table";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { trimLongTextValueField } from "../../utils/tableUtils";
import {
  appointmentExaminationsStore,
  appointmentMedicationStore,
  appointmentRecommendationsStore, setAppointmentExaminations, setAppointmentMedication, setAppointmentRecommendations
} from "../../stores/decisionSupportStores";
import { dispatchEvent } from "../../utils/useEvents";
import {Box, Stack} from '@mui/material';
import { getChecklistGuid } from "../../domain/entities/Checklist";
import { CheckboxLabel } from "../decision-support/CheckboxLabel";
import CheckedIcon from "../../assets/icons/checkbox-hover.inline.svg";
import styled from "styled-components";

export const ChecklistTypes = {
  examination: "examination",
  recommendation: "recommendation",
  medication: "medication"
};

const storeByChecklistType = {
  [ChecklistTypes.examination]: appointmentExaminationsStore,
  [ChecklistTypes.recommendation]: appointmentRecommendationsStore,
  [ChecklistTypes.medication]: appointmentMedicationStore,
};

const setterByChecklistType = {
  [ChecklistTypes.examination]: setAppointmentExaminations,
  [ChecklistTypes.recommendation]: setAppointmentRecommendations,
  [ChecklistTypes.medication]: setAppointmentMedication,
};

const appointmentChecklistNameByChecklistType = {
  [ChecklistTypes.examination]: "examination_checklist",
  [ChecklistTypes.recommendation]: "recommendation_checklist",
  [ChecklistTypes.medication]: "medication_checklist",
};

const FlexBlock = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;

const CheckedIconElement = styled(CheckedIcon)`
    transform: scale(0.75);
`;

export default function CheckUpTable(props) {
    const { checklist, checklistType, appointment, grouping, notColumns = [], containerStyle = {} } = props;
    const appointmentId = appointment.id;

    const appendItemToChecklist = (store, item, setter) => {
      const storeState = store.getState();
      const checklists = storeState[appointmentId] || appointment[appointmentChecklistNameByChecklistType[checklistType]]?.items || [];

      const isAddedItem = checklists.find((checklist) => {
        if (checklist.items) {
          return !!checklist.items.find((element) => element.name === item.name)
        } else {
          return checklist.name === item.name
        }
      })

      if (isAddedItem) {
        return
      }

      if (item.parent) {
        const {parent, appointment, group, ...itemRest} = item;
        const parentInChecklistIndex = checklists.findIndex(element => element.name === parent.name);

        if (parentInChecklistIndex >= 0) {
          const [parentInChecklist] = checklists.splice(parentInChecklistIndex, 1);
          const itemInParentIndex = parentInChecklist.items.findIndex(element => element.name === itemRest.name);

          if (itemInParentIndex >= 0) {
            const [itemInParent] = parentInChecklist.items.splice(itemInParentIndex, 1);

            for (let key in itemInParent) {
              itemInParent[key] = itemRest[key];
            }

            const newItem = {...itemInParent};
            parentInChecklist.items.splice(itemInParentIndex, 0, newItem);
            parentInChecklist.items = [...parentInChecklist.items];
          } else {
            parentInChecklist.items = [...parentInChecklist.items, itemRest];
          }
          checklists.splice(parentInChecklistIndex, 0, {...parentInChecklist});

          setter({...storeState, [appointmentId]: [...checklists]});

          if (checklistType === ChecklistTypes.examination) {
            dispatchEvent("examinationCheckChange", {
              item: itemRest,
              value: true
            });
          }
        } else {
          const { appointment, group, items, ...parentRest } = parent;

          parentRest.items = [
            itemRest
          ];
          checklists.push(parentRest);
          setter({...storeState, [appointmentId]: [...checklists]});

          if (checklistType === ChecklistTypes.examination) {
            dispatchEvent("examinationCheckChange", {
              item: itemRest,
              value: true
            });
          }
        }
      } else {
        const {appointment, group, items, ...itemRest} = item;
        const itemInChecklist = checklists.find(item => item.name === itemRest.name);

        if (!itemInChecklist) {
          checklists.push(itemRest);
        }

        setter({...storeState, [appointmentId]: [...checklists]});

        if (checklistType === ChecklistTypes.examination) {
          dispatchEvent("examinationCheckChange", {
            item: itemRest,
            value: true
          });
        }
      }
    };

    const columns = useMemo(() => {
      return [
        {
          accessorKey: 'appointment', //access nested data with dot notation
          header: 'Приём',
          size: 200,
        },
        {
          accessorKey: 'group', //access nested data with dot notation
          header: 'Группа',
          size: 200,
        },
        {
          accessorKey: 'name',
          header: 'Наименование',
          size: 200,
          enableGrouping: false,
          enableHiding: false,
          Cell: ({ row }) => null
        }
      ].filter((item) => !notColumns.includes(item.accessorKey));
    }, [checklist, checklistType]);

    const data = useMemo(() => {
      const items = [];

      for (let key in checklist) {
        for (let item of checklist[key].items) {
          if (item.items && item.items.length) { //item.is_heading &&
            for (let subitem of item.items) {
              if (!subitem.is_checked) {
                return;
              }
              subitem.appointment = key;
              subitem.group = item.name;
              subitem.parent = item;
              items.push(subitem);
            }
          } else {
            item.appointment = key;
            item.group = "Без группы";
            items.push(item);
          }
        }
      }

      return items;
    }, [checklist]);

    const table = useMaterialReactTable({
      columns: columns,
      data: data,
      enableFullScreenToggle: false,
      enableGrouping: true,
      enablePagination: false,
      enableBottomToolbar: false,
      enableFilters: false,
      enableTopToolbar: false,
      groupedColumnMode: "remove",
      initialState: {
        density: 'compact',
        grouping: grouping || ['appointment', 'group'],
        columnVisibility: { name: false }
      },
      muiTablePaperProps: {
        elevation: 0
      },
      muiTableContainerProps: {
        style: containerStyle
      },
      muiExpandButtonProps: ({ row }) => ({
        style: {
          color: row.getIsGrouped() ? 'inherit' : 'transparent'
        }
      }),
      displayColumnDefOptions: {
        'mrt-row-expand': {
          Cell: ({ row, table }) => {
            return (
              <>
                {
                  row.groupingValue ?
                    <>
                      <MRT_ExpandButton row={row} table={table}/>
                      {
                        trimLongTextValueField({
                          value: row.groupingValue,
                          numberOfCharacters: 100
                        })
                      }
                    </>
                  :
                    <FlexBlock>
                      <MRT_ExpandButton row={row} table={table}/>
                      {(appointment[appointmentChecklistNameByChecklistType[checklistType]] && !!appointment[appointmentChecklistNameByChecklistType[checklistType]].items.find((item) => {
                        if (item.items) {
                          return !!item.items.find((element) => element.name === row.original.name)
                        } else {
                          return item.name === row.original.name
                        }
                      })) && (
                        <CheckedIconElement />
                      )}
                      {row.original.name}
                    </FlexBlock>
                }
              </>
            );
          },
          Header: ({table}) => {
            const { grouping } = table.getState();
            return (
              <Stack direction="row" alignItems="center">
                <MRT_ExpandAllButton table={table} />
                <Box>{
                  [...grouping, "name"].map(key => columns.find(column => column.accessorKey === key).header).join(", ")
                }</Box>
              </Stack>
            );
          },
          enableResizing: false,
        },
      },
      muiTableBodyRowProps: ({row}) => ({
        style: {
          cursor: row.getIsGrouped() ? "default" : "pointer"
        },
        onClick: () => {
          const item = row.original;
          if (row.getIsGrouped()) {
            return;
          }
          appendItemToChecklist(storeByChecklistType[checklistType], item, setterByChecklistType[checklistType]);
        }
      }),
      localization: MRT_Localization_RU,
      isMultiSortEvent : ( ) => true,
    });

    return (
      <MaterialReactTable table={table}/>
    );
}
