// @flow
import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import _ from "lodash";
import useReactRouter from "use-react-router";
import { getPatientLastNameWithInitials } from "../../domain/services/patient";
import ticketStore, {
  AREAS_FIELD,
  getTicketOptionsByAccessorKey,
  TICKET_SEGMENTS,
  TICKET_SEGMENTS_URL
} from "../../stores/ticketStore";
import doctorsStore from "../../stores/doctorsStore";
import { DynamicForm, backToHistory } from "../common/dynamic-form/DynamicForm";
import { TicketComments } from "./TicketComments";
import { DynamicFormContainer } from "../common/dynamic-form/styled/Container.styled";
import { TicketDMSInfo } from "./TicketDMSInfo";
import { useToast } from "../toast/useToast";
import { useMediaQuery } from "@mui/material";

export const TicketEditPage = observer(() => {
  const isMobileDevices = useMediaQuery("(max-width: 768px)");
  const [doctors, setDoctors] = useState([]);
  const [disabledField, setDisabledFields] = useState(null);
  const toastManager = useToast();
  const {
    history,
    match: { params }
  } = useReactRouter();
  const ticketParams = TICKET_SEGMENTS_URL[ticketStore.segment];

  useEffect(() => {
    (async () => {
      setTimeout(async () => {
        if (!ticketParams.doctorAssignee) {
          return false;
        }
        const doctorAssignee = ticketParams.doctorAssignee();
        const response = await doctorsStore.fetchAllDoctorsList({ filtersParams: { id__in: doctorAssignee } });
        setDoctors(response.data || []);
      }, 300);
    })();
  }, []);

  const editSchema = useMemo(
    () => {
      if (ticketParams.editSchema) {
        return ticketParams.editSchema.map((item) => {
          if (disabledField?.hasOwnProperty(item.name)) {
            item.readOnly = disabledField[item.name];
            item.disabled = disabledField[item.name];
          }
          return item;
        });
      }
      return [
        {
          type: "text",
          name: "title",
          label: "Заголовок",
          disabled: true
        },
        {
          type: "text",
          name: "area",
          label: "Область",
          disabled: true,
          transformValue: (value) => {
            if (AREAS_FIELD[value]) {
              return AREAS_FIELD[value];
            }
            return value;
          }
        },
        {
          type: "text",
          name: "theme",
          label: "Тема",
          disabled: true
        },
        {
          type: "textarea",
          name: "description",
          label: "Описание"
        },
        {
          type: "select",
          name: "assignee",
          label: "Исполнитель",
          options: doctors.map(doctor => {
            return {
              value: doctor.id,
              label: getPatientLastNameWithInitials(doctor)
            };
          })
        },
        {
          type: "text",
          name: "author_text",
          label: "Автор текстом, если нет пользователя"
        },
        {
          type: "text",
          name: "author__username",
          label: "Автор",
          disabled: true
        },
        {
          type: "select",
          name: "status",
          label: "Статус",
          options: getTicketOptionsByAccessorKey("status")
        },
        {
          type: "select",
          name: "priority",
          label: "Приоритет",
          options: getTicketOptionsByAccessorKey("priority")
        },
        {
          type: "date",
          name: "created_at",
          label: "Дата отправления",
          disabled: true
        },
        {
          type: "date",
          name: "updated_at",
          label: "Дата последнего обновления",
          disabled: true
        },
        {
          type: "textarea",
          name: "progress_report",
          label: "Отчёт о проделанной работе"
        }
        // {
        //   type: "textarea",
        //   name: "report",
        //   label: "Отчёт о проделанной работе"
        // }
      ].map(item => {
        if (ticketParams.hiddenFieldForEditing.includes(item.name)) {
          item.hiddenField = true;
        }
        if (ticketParams.disabledFieldsForEditing.includes(item.name)) {
          item.readOnly = true;
          item.disabled = true;
        }
        return item;
      })
    },
    [ticketStore.segment, doctors, disabledField]
  );

  const fetchData = async () => {
    const fields = editSchema.map(item => item.name);
    await ticketStore.getTicket(fields);
  };

  const handleOnSubmitEdit = async data => {
    const isSuccess = await ticketStore.postEditedTicket(data);
    if (isSuccess) {
      backToHistory(null, history);
      ticketStore.setTicket(null);
      ticketStore.clearComments();
      ticketStore.DMSPrescribedServices = [];
    } else {
      toastManager.showToast(
        <div>
          Произошла ошибка при сохранение
        </div>,
        {
          duration: 20000
        }
      );
    }
  };

  useEffect(() => {
    (async () => {
      ticketStore.setTicketID(params.id);
      await fetchData();
    })();
  }, []);

  const handleOnBack = () => {
    ticketStore.clearComments();
    ticketStore.DMSPrescribedServices = [];
  };

  const defaultData = useMemo(() => _.cloneDeep(ticketStore.ticket), [ticketStore.ticket]);

  const watcherOnChangeField = (updatedData, reset, changedKey) => {
    /** Расширять при необходимости */
    if (ticketStore.segment !== TICKET_SEGMENTS.MANAGER_DMS.name) {
      return;
    }
    const view = TICKET_SEGMENTS.MANAGER_DMS.handlerWatcherOnChangeField(updatedData, reset, changedKey);
    if (view?.disabledFields && Object.keys(view.disabledFields).length) {
      setDisabledFields({ ...disabledField, ...view.disabledFields });
    }
  }

  return (
    <>
      {defaultData && (
        <>
          <DynamicFormContainer style={{
            maxWidth: ticketStore.segment === TICKET_SEGMENTS.MANAGER_DMS.name ? "980px" : "1440px",
            margin: !isMobileDevices ? "20px auto" : 0
          }}>
            <DynamicForm
              additionalBlock={ticketStore.segment === TICKET_SEGMENTS.MANAGER_DMS.name && (
                <TicketDMSInfo />
              )}
              callbackOnClose={handleOnBack}
              data={defaultData}
              changeHandler={watcherOnChangeField}
              submitHandler={handleOnSubmitEdit}
              fields={editSchema}
              title={ticketStore.segment === TICKET_SEGMENTS.MANAGER_DMS.name ? 'Редактирование заявки' :"Редактирование обращения"}
            />
          </DynamicFormContainer>
          <TicketComments />
        </>
      )}
    </>
  );
});
