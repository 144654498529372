import photo1 from "../../../assets/image/corporate-portal/companyInfo/photo1.png";
import photo2 from "../../../assets/image/corporate-portal/companyInfo/photo2.png";
import photo3 from "../../../assets/image/corporate-portal/companyInfo/photo3.png";

export const generatedCompanyInfoData = [
  {
    title: "Описание компании",
    template: `
      <img src="${photo1}" alt="photo1" />
      <img src="${photo2}" alt="photo1" />
      <img src="${photo3}" alt="photo1" />
      <h2>Как всё начиналось</h2>
      <p>Наша история началась в 2011 году. Тогда в Твери мы открыли свою первую клинику всего на 4 амбулаторных кабинета. Затем там же появились еще 2 клиники и большой многопрофильный центр. В 2014 году была основана генетическая лаборатория “Medical Genomics”. Сейчас она крупнейшая в России. Помимо пренатальной диагностики и преимплантационного генетического тестирования при беременности здесь проводятся медико-генетические анализы, установление отцовства и родства, криминалистический анализ ДНК, генеалогические исследования.</p>
      <h2>Масштабирование</h2>
      <p>В 2017 году мы успешно открыли первую клинику за пределами родного региона - в Калуге. После этого начали масштабирование по России. Открыли клинику и большой госпиталь в Москве, медицинские центры в Белгороде, Пензе, Краснодаре, Перми, Санкт-Петербурге, Воронеже, Уфе. В конце 2022 года открылся большой многопрофильный госпиталь в Сочи.</p>
      <p>Ускорить рост компании получилось за счет привлечения инвестиций, в 2018 году нашим партнером стал инвестфонд “ТилТех капитал”, который создали основатель компании “ВкусВилл” Андрей Кривенко, владелец компании “Агама” Юрий Алашеев и глава компании “ХимРар” Андрей Иващенко.</p>
      <h2>Сегодня</h2>
      <p>Клиника Фомина сегодня — это 20 медицинских центров с собственной IT инфраструктурой в 12 городах России, которые оснащены собственными лабораториями, экспертными аппаратами УЗИ, оборудованием для минимально инвазивной хирургии. Наши врачи пишут научные статьи, являются докторами и кандидатами медицинских наук.</p>
      <p>В мае 2023 года Клиника Фомина приобрела центр доказательной медицины «Рассвет». Приобретенный актив оснащен всем необходимым оборудованием для проведения диагностики и лечения любой сложности. Сделка максимально отвечала долгосрочным и стратегическим планам сети и ускорила её движение в сторону многопрофильности.</p>
    `
  },
  {
    title: "Миссия и цели компании",
    template: `
      <h2>Заголовок 1</h2>
      <p>Тепловой удар — это патологическое состояние, которое может возникнуть при длительном воздействии высокой температуры на организм человека. Оно характеризуется повышением температуры тела, нарушением работы сердечно-сосудистой системы и других органов. Тепловой удар может привести к серьёзным последствиям для здоровья, поэтому важно знать, как его предотвратить.</p>
      <h2>Заголовок 2</h2>
      <img src="${photo2}" alt="photo1" />
      <p>Если вы заметили, что у кого-то из окружающих людей появились признаки теплового удара, немедленно вызовите скорую помощь. До приезда врачей переместите пострадавшего в прохладное место, обеспечьте ему доступ свежего воздуха и дайте выпить воды.</p>
    `
  },
  {
    title: "История компании",
    template: `
      <img src="${photo3}" alt="photo1" />
      <h2>Заголовок 1</h2>
      <p>Гипертония — это хроническое заболевание, при котором повышается артериальное давление. Это может быть вызвано различными факторами, такими как наследственность, стресс, неправильное питание и образ жизни. Гипертония является одним из самых распространённых заболеваний сердечно-сосудистой системы, и она может привести к таким серьёзным осложнениям, как инфаркт и инсульт.</p>
      <h2>Заголовок 2</h2>
      <p>Для диагностики гипертонии необходимо обратиться к врачу-кардиологу или терапевту. Врач проведёт осмотр, назначит анализы и обследования, такие как измерение артериального давления, ЭКГ, УЗИ сердца и другие. На основании полученных результатов врач поставит диагноз и назначит лечение.</p>
    `
  }
];
