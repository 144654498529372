import React from "react";
import { observer } from "mobx-react";
import corporateNewsStore from "../stores/corporateNewsStore";
import { CorporateCard } from "./CorporateCard";
import { Box, CardActionArea, CardContent, CardMedia } from "@mui/material";
import { CorporateSubTitle, CorporateTitle } from "../styled/CorporateTypography.styled";
import { CorporateLikeCounter } from "./activity/CorporateLikeCounter";
import { CorporateViewCounter } from "./activity/CorporateViewCounter";
import { CorporateMiniCardWrapper } from "../styled/CorporateWrapper.styled";
import { styled } from "@mui/material/styles";
import { breakpoints } from "../styled/common";
import { Link } from "react-router-dom";

const LinkStyled = styled(Link)(
  () =>
    `
      text-decoration: none;
    `
);
const CardMediaStyled = styled(CardMedia)(
  () =>
    `
      height: 180px;
      ${breakpoints.desktop} {
        height: 160px;
      }
      ${breakpoints.tablet} {
        height: 140px;
      }
    `
);
const CardContentStyled = styled(CardContent)(
  () =>
    `
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 12px;
      &:last-child {
        padding-bottom: 12px;
      }
      ${breakpoints.tablet} {
        gap: 5px;
      }
    `
);

const CardInfoContainer = styled(Box)(
  () =>
    `
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      ${breakpoints.tablet} {
        gap: 5px;
      }
    `
);

const TrimmedText = styled("div")`
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  span {
    display: inline;
  }
`;

const CardActionsContainer = styled(Box)(
  () =>
    `
      display: flex;
      justify-content: space-between;
      padding: 12px;
    `
);

export const CorporateMiniCardNews = observer(props => {
  const {
    news: { id, isNewsLiked, numberOfLike, ...newsData }
  } = props;

  const handleChangeLikeCounter = () => {
    corporateNewsStore.changeLikeCounter({ id, isNewsLiked });
  };

  return (
    <CorporateMiniCardWrapper>
      <CorporateCard>
        <CardActionArea>
          <LinkStyled to={`/corporate-portal/news/${id}`}>
            <CardMediaStyled image={newsData.media.src} title={newsData.media.alt} />
            <CardContentStyled>
              <CardInfoContainer>
                <CorporateSubTitle modifiers={["md", "pink"]}>
                  {newsData.category}
                </CorporateSubTitle>
                <CorporateSubTitle modifiers={["md", "gray"]}>
                  {newsData.date} {newsData.time}
                </CorporateSubTitle>
              </CardInfoContainer>
              <CardInfoContainer>
                <CorporateTitle modifiers={"sm"}>{newsData.name}</CorporateTitle>
                <TrimmedText>
                  <CorporateSubTitle>{newsData.description}</CorporateSubTitle>
                </TrimmedText>
              </CardInfoContainer>
            </CardContentStyled>
          </LinkStyled>
        </CardActionArea>
        <CardActionsContainer>
          <CorporateLikeCounter
            value={numberOfLike}
            active={isNewsLiked}
            onClick={handleChangeLikeCounter}
          />
          <CorporateViewCounter value={newsData.numberOfView} />
        </CardActionsContainer>
      </CorporateCard>
    </CorporateMiniCardWrapper>
  );
});
