// @flow
import React from "react";
import styled, { css } from "styled-components";
import { rgba } from "polished";
import dragIcon from "./icons/drag.svg";

const hoverContainer = css`
  background-color: ${rgba("#d8d8d8", 0.15)};
`;

const hoverButtonsContaier = css`
  opacity: 1;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  padding: 8px 30px;
  align-items: flex-start;
  @media (max-width: 768px) {
    padding: 8px 20px;
  }
  @media (max-width: 480px) {
    padding: 8px 15px;
  }

  &:hover {
    ${hoverContainer};
  }

  ${({ preserveHover }) =>
    preserveHover &&
    css`
      ${hoverContainer};
    `}
`;

const WrapperCommon = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 0;
  width: 100%;

  @media (max-width: 1280px) and (min-width: 769px) {
    flex-direction: column;
  }
  @media (max-width: 768px) {
    padding-left: ${(props) => props.paddingLeft && `10px`};
  }
`;

const IconContainer = styled.div`
  align-self: start;
`;

const TitleContainer = styled.div`
  word-break: break-word;
  flex-basis: 70%;
`;

const HintContainer = styled.div``;

const WrapperLeft = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
`;

const WrapperRight = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  flex-shrink: 0;
  @media (max-width: 768px) {
    button > p {
      display: none;
    }
  }
`;
const WrapperFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 0;

  @media (max-width: 1280px) {
    max-width: 100%;
  }
`;

const RightBlockContainer = styled.div`
  display: flex;
  transition: opacity 0.2s;
  align-items: center;
  margin-left: 44px;
  gap: 12px;

  @media (max-width: 1280px) {
    margin-left: 32px;
  }
`;

const HeaderBottomContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
`;
const ButtonsContainer = styled.div`
  align-self: center;

  @media (min-width: 769px) {
    opacity: ${props => (props.buttonsAlwaysVisible ? 1 : 0)};
    ${Container}:hover & {
      ${hoverButtonsContaier}
    }
  }

  ${({ preserveHover }) =>
    preserveHover &&
    css`
      ${hoverButtonsContaier};
    `}
`;

const DragIcon = styled.div`
  width: 24px;
  height: 24px;
  background: url(${dragIcon});
  background-size: cover;
`;

const DragIconContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  @media (min-width: 769px) {
    opacity: 0;
    ${Container}:hover > & {
      opacity: 1;
    }
  }
  transition: opacity;
`;

export const PointTemplate = (props: {
  title: React$Node,
  icon: ?React$Node,
  hint?: React$Node,
  buttons?: React$Node,
  price?: number,
  count?: number,
  isDraggable?: boolean,
  dragHandleProps?: Object,
  preserveHover?: boolean,
  isChecked?: boolean,
  buttonsAlwaysVisible?: boolean
}) => {
  const {
    title,
    rightBlock,
    buttonsAlwaysVisible,
    headerBottom,
    icon,
    isChecked,
    hint,
    buttons,
    isDraggable,
    dragHandleProps,
    preserveHover
  } = props;
  return (
    <Container preserveHover={preserveHover}>
      {isDraggable && (
        <DragIconContainer {...dragHandleProps}>
          <DragIcon />
        </DragIconContainer>
      )}
      <WrapperCommon paddingLeft={isDraggable}>
        <WrapperFlex>
          <IconContainer>{icon}</IconContainer>
          <WrapperLeft>
            <TitleContainer>{title}</TitleContainer>
            <WrapperFlex>
              {headerBottom && (
                <HeaderBottomContainer isChecked={isChecked} preserveHover={preserveHover}>
                  {headerBottom}
                </HeaderBottomContainer>
              )}
              {hint && <HintContainer>{hint}</HintContainer>}
            </WrapperFlex>
          </WrapperLeft>
        </WrapperFlex>
        <WrapperRight bigGap={true}>
          {rightBlock && (
            <RightBlockContainer isChecked={isChecked}>{rightBlock}</RightBlockContainer>
          )}
          {buttons && (
            <ButtonsContainer
              buttonsAlwaysVisible={buttonsAlwaysVisible}
              preserveHover={preserveHover}>
              {buttons}
            </ButtonsContainer>
          )}
        </WrapperRight>
      </WrapperCommon>
    </Container>
  );
};
