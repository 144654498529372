// @flow
import * as React from "react";
import {Avatar, AvatarPlaceholder, InnerContainer, Text, TextContainer} from './greetingCommon';
import {HeadingH2} from '../styleguide/typography';
import {observer} from 'mobx-react';
import authStore from '../../stores/authStore';
import chatStore from '../../stores/chatStore';
import styled from "styled-components";
import { AddButton } from "../buttons/AddButton";
import { ContainerWrapper } from './greetingCommon';
import { colors } from "../styleguide/colors";
import plusIcon from "../buttons/icons/plus.svg";
import accountStore from "../../stores/accountStore";

const AddButtonContainer = styled.div`
  border-radius: 4px;
  padding: 0 16px;
  height: 44px;
  box-sizing: border-box;
  border: 1px solid rgba(38, 38, 38, 0.10);
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  
  &:hover {
    border-color: ${colors.magenta};
  }
`;

const Icon = styled.div`
  width: 24px;
  height: 24px;
  background: url(${plusIcon}) no-repeat;
  background-size: contain;
  opacity: 0.5;
  transition: all 0.15s ease-in-out;

  ${AddButtonContainer}:hover & {
    opacity: 1;
  }
`;

const TextButtonContainer = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: ${colors.black};
  transition: all 0.15s ease-in-out;
  
  ${AddButtonContainer}:hover & {
    span {
      color: ${colors.magenta}
    }
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const Greeting = observer((props: {|
  firstName: string,
  lastName?: string,
  avatar: ?string,
|}) => {
  const { firstName, lastName } = props;

  return (
    <Container>
      <ContainerWrapper>
        {props.avatar ? (
          <Avatar avatar={props.avatar} />
        ) : (
          <AvatarPlaceholder>
            {(lastName ? lastName.slice(0, 1) : "") + firstName.slice(0, 1)}
          </AvatarPlaceholder>
        )}
        <InnerContainer>
          <HeadingH2>Профиль</HeadingH2>
          <TextContainer>
            <Text as="span">
              Добрый день, {firstName}!
            </Text>
          </TextContainer>
        </InnerContainer>
      </ContainerWrapper>
      { accountStore.activeTab === 'services' && (
        <AddButton
          onClick={() => accountStore.setShowAccountAddServiceModal(true)}
          overridesDefaultButton={{
            Container: {
              component: AddButtonContainer
            },
            Icon: {
              component: Icon
            },
            TextContainer: {
              component: TextButtonContainer
            }
          }}>
          Добавить услугу
        </AddButton>
      )}
    </Container>
  );
});

export const GreetingContentAccount = observer(() => {
  const user = authStore.user;

  if (!user) {
    return null;
  }

  return (
    <Greeting
      firstName={user.doctor.first_name}
      lastName={user.doctor.last_name}
      avatar={user.doctor.photo}
      unreadMessagesCount={chatStore.unreadMessagesCount}
    />
  );
});
