import React from "react";
import { CorporateControlPanelWrapper } from "../styled/CorporateWrapper.styled";
import { CorporateCard } from "./CorporateCard";
import { Box, LinearProgress, Unstable_Grid2 as Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { CorporateResponsiveTabs } from "./CorporateResponsiveTabs";
import { CorporateSearchInput } from "./CorporateSearchInput";
import { CorporateBackButton } from "./CorporateBackButton";

const ProgressBar = styled(Box)(
  ({ theme }) => `
    position: relative;
    height: 65px;
    overflow: hidden;
    .MuiLinearProgress-root {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
    ${theme.breakpoints.down("xl")} {
      height: 60px;
    }
    ${theme.breakpoints.down("lg")} {
      height: 55px;
    }
  `
);

export const CorporateControlPanel = ({
  loading = false,
  tabs = [],
  indexSelectedTab,
  handleOnChangeSelectedTab,
  backButton = null,
  searchField = null
}) => {
  return (
    <CorporateControlPanelWrapper
      modifiers={[
        !backButton && !backButton?.isShow && !tabs.length && "withoutPadding",
        !!backButton && backButton.isShow && "fixedHeight"
      ]}>
      <CorporateCard>
        {!tabs.length ? (
          <ProgressBar>
            <LinearProgress />
          </ProgressBar>
        ) : (
          <Grid container spacing={1} flexWrap={{ sm: "nowrap" }} alignItems="center">
            <Grid xs={12}>
              <CorporateResponsiveTabs
                loading={loading}
                initialTabs={tabs}
                indexSelectedTab={indexSelectedTab}
                changeSelectedTab={handleOnChangeSelectedTab}
              />
            </Grid>
            {!!searchField && (
              <Grid xs={12} sm={8}>
                <CorporateSearchInput
                  label={searchField.label}
                  value={searchField.value}
                  setValue={searchField.onChange}
                />
              </Grid>
            )}
          </Grid>
        )}
        {!!backButton && backButton.isShow && (
          <CorporateBackButton withWrapper text={backButton.text} path={backButton.path} />
        )}
      </CorporateCard>
    </CorporateControlPanelWrapper>
  );
};
