import {
  FILTERS_FIELDS_REGISTRATION_LOYALTY,
  WidgetRegistrationLoyalty
} from "../../widgets/WidgetRegistrationLoyalty";
import { FILTERS_FIELDS_WIDGET_NPS, WidgetNPS } from "../../widgets/WidgetNPS";
import { FILTERS_FIELDS_WIDGET_TAGS, WidgetTags } from "../../widgets/WidgetTags";
import { FILTERS_FIELDS_WIDGET_APPOINTMENT_TYPE, WidgetAppointmentType } from "../../widgets/WidgetAppointmentType";
import { FILTERS_FIELDS_WIDGET_STEPS, WidgetSteps } from "../../widgets/WidgetSteps";
import { FILTERS_FIELDS_WIDGET_RATE, WidgetRate } from "../../widgets/WidgetRate";
import React from "react";
import { FILTERS_FIELDS_WIDGET_PAYMENT_SUMMARY, WidgetPaymentSummary } from "../../widgets/WidgetPaymentSummary";
import {
  FILTERS_FIELDS_WIDGET_CONCIERGE_ANALYTICS_METRIC,
  WidgetConciergeAnalyticsMetric
} from "../../widgets/WidgetConciergeAnalyticsMetric";
import { FILTERS_FIELDS_WIDGET_STORIES_ANALYTICS, WidgetStoriesAnalytics } from "../../widgets/WidgetStoriesAnalytics";
import { FILTERS_FIELDS_WIDGET_DOCTORS_IN_CHAT, WidgetDoctorsInChat } from "../../widgets/WidgetDoctorsInChat";
import { FILTERS_FIELDS_WIDGET_CSAT, WidgetCSAT } from "../../widgets/WidgetCSAT";
import { FILTERS_FIELDS_WIDGET_RPR, WidgetRPR } from "../../widgets/WidgetRPR";
import { FILTERS_FIELDS_WIDGET_CES, WidgetCES } from "../../widgets/WidgetCES";
import { FILTERS_FIELDS_WIDGET_CONTENT_ANALYTICS, WidgetContentAnalytics } from "../../widgets/WidgetContentAnalytics";
import { FILTERS_FIELDS_WIDGET_FIRST_ONLINE_APP_SALE_ANALYTICS, WidgetFirstOnlineAppSaleAnalytics } from "../../widgets/WidgetFirstOnlineAppSaleAnalytics";
import { FILTERS_FIELDS_WIDGET_RATE_BY_SOURCE, WidgetRateBySource } from "../../widgets/WidgetRateBySource";
import { FILTERS_FIELDS_WIDGET_AVERAGE_RATING_BY_REVIEWS, WidgetAverageRatingByReviews } from "../../widgets/WidgetAverageRatingByReviews";
import { FILTERS_FIELDS_WIDGET_TOTAL_REVIEWS, WidgetTotalReviews } from "../../widgets/WidgetTotalReviews";
import { FILTERS_FIELDS_WIDGET_INTERNAL_RATE, WidgetInternalRate } from "../../widgets/WidgetInternalRate";

/** Валидация фильтров по созданию виджета
 * abortEarly: true - возвращает первую найденную ошибку / false - возвращает все найденные ошибки; */
export const filterValidation = ({ validateSchema, filtersDataDiagram, abortEarly = false, setErrors }) => {
  validateSchema
    .validate(filtersDataDiagram, { abortEarly })
    .catch((error) => setErrors(error.errors));
};

export const OPTIONS_FIELDS_WIDGET = [
  {value: 'widgetRegistrationLoyalty', label: 'Виджет по отзывам сервиса отзывы за баллы "регистрация в программе лояльности"'},
  {value: 'widgetNPS', label: 'Виджет по отзывам "NPS"'},
  {value: 'widgetTags', label: 'Виджет по отзывам "по тэгам"'},
  {value: 'widgetAppointmentType', label: 'Виджет по отзывам сервиса отзывы за баллы "первичные/вторичные"'},
  {value: 'widgetSteps', label: 'Виджет по отзывам сервиса отзывы за баллы "шаги"'},
  {value: 'widgetRate', label: 'Виджет по отзывам "оценки"'},
  {value: 'widgetPaymentSummary', label: 'Виджет по онлайн платежам'},
  {value: 'widgetConciergeAnalyticsMetric', label: 'Виджет по консьержу'},
  {value: 'widgetStoriesAnalytics', label: 'Виджет по сторисам'},
  {value: 'widgetDoctorsInChat', label: 'Виджет по врачам подключенных к чатам'},
  {value: 'widgetCSAT', label: 'Виджет по отзывам сервиса отзывы за баллы "CSAT"'},
  {value: 'widgetRPR', label: 'Виджет по отзывам сервиса отзывы за баллы "RPR"'},
  {value: 'widgetCES', label: 'Виджет по отзывам сервиса отзывы за баллы "CES"'},
  {value: 'widgetContentAnalytics', label: 'Виджет по контенту'},
  {value: 'widgetFirstOnlineAppSaleAnalytics', label: 'Виджет по акции "Первая онлайн консультация любого специалиста из приложения со скидкой 50%"'},
  {value: 'widgetRateBySource', label: 'Виджет по отзывам "оценки" (с группировкой по площадкам)'},
  {value: 'widgetAverageRatingByReviews', label: 'Виджет "Средний рейтинг по площадкам"'},
  {value: 'widgetTotalReviews', label: 'Виджет с общим количеством отзывов и средним рейтингом по площадкам (светофор)'},
  {value: 'widgetInternalRate', label: 'Виджет по отзывам "Внутренние оценки"'},
]

export const ALL_FIELDS_FOR_WIDGETS = {
  widgetRegistrationLoyalty: FILTERS_FIELDS_REGISTRATION_LOYALTY,
  widgetNPS: FILTERS_FIELDS_WIDGET_NPS,
  widgetTags: FILTERS_FIELDS_WIDGET_TAGS,
  widgetAppointmentType: FILTERS_FIELDS_WIDGET_APPOINTMENT_TYPE,
  widgetSteps: FILTERS_FIELDS_WIDGET_STEPS,
  widgetRate: FILTERS_FIELDS_WIDGET_RATE,
  widgetPaymentSummary: FILTERS_FIELDS_WIDGET_PAYMENT_SUMMARY,
  widgetConciergeAnalyticsMetric: FILTERS_FIELDS_WIDGET_CONCIERGE_ANALYTICS_METRIC,
  widgetStoriesAnalytics: FILTERS_FIELDS_WIDGET_STORIES_ANALYTICS,
  widgetDoctorsInChat: FILTERS_FIELDS_WIDGET_DOCTORS_IN_CHAT,
  widgetCSAT: FILTERS_FIELDS_WIDGET_CSAT,
  widgetRPR: FILTERS_FIELDS_WIDGET_RPR,
  widgetCES: FILTERS_FIELDS_WIDGET_CES,
  widgetContentAnalytics: FILTERS_FIELDS_WIDGET_CONTENT_ANALYTICS,
  widgetFirstOnlineAppSaleAnalytics: FILTERS_FIELDS_WIDGET_FIRST_ONLINE_APP_SALE_ANALYTICS,
  widgetRateBySource: FILTERS_FIELDS_WIDGET_RATE_BY_SOURCE,
  widgetAverageRatingByReviews: FILTERS_FIELDS_WIDGET_AVERAGE_RATING_BY_REVIEWS,
  widgetTotalReviews: FILTERS_FIELDS_WIDGET_TOTAL_REVIEWS,
  widgetInternalRate: FILTERS_FIELDS_WIDGET_INTERNAL_RATE,
}

export const ALL_GENERATE_WIDGETS = {
  widgetRegistrationLoyalty: (filters, key, grid, rewriteFilters) => <WidgetRegistrationLoyalty rewriteFilters={rewriteFilters} key={key} keyWidget={key} grid={grid} filtersData={filters} localeStorageName="widgetRegistrationLoyalty" />,
  widgetNPS: (filters, key, grid, rewriteFilters) => <WidgetNPS key={key} rewriteFilters={rewriteFilters} keyWidget={key} grid={grid} filtersData={filters} localeStorageName="widgetNPS" />,
  widgetTags: (filters, key, grid, rewriteFilters) => <WidgetTags key={key} rewriteFilters={rewriteFilters} keyWidget={key} grid={grid} filtersData={filters} localeStorageName="widgetTags" />,
  widgetAppointmentType: (filters, key, grid, rewriteFilters) => <WidgetAppointmentType key={key} rewriteFilters={rewriteFilters} keyWidget={key} grid={grid} filtersData={filters} localeStorageName="widgetAppointmentType" />,
  widgetSteps: (filters, key, grid, rewriteFilters) => <WidgetSteps rewriteFilters={rewriteFilters} key={key} keyWidget={key} grid={grid} filtersData={filters} localeStorageName="widgetSteps" />,
  widgetRate: (filters, key, grid, rewriteFilters) => <WidgetRate rewriteFilters={rewriteFilters} key={key} keyWidget={key} grid={grid} filtersData={filters} localeStorageName="widgetRate" />,
  widgetPaymentSummary: (filters, key, grid, rewriteFilters) => <WidgetPaymentSummary rewriteFilters={rewriteFilters} key={key} keyWidget={key} grid={grid} filtersData={filters} localeStorageName="widgetPaymentSummary" />,
  widgetConciergeAnalyticsMetric: (filters, key, grid, rewriteFilters) => <WidgetConciergeAnalyticsMetric rewriteFilters={rewriteFilters} key={key} keyWidget={key} grid={grid} filtersData={filters} localeStorageName="widgetConciergeAnalyticsMetric" />,
  widgetStoriesAnalytics: (filters, key, grid, rewriteFilters) => <WidgetStoriesAnalytics rewriteFilters={rewriteFilters} key={key} keyWidget={key} grid={grid} filtersData={filters} localeStorageName="widgetStoriesAnalytics" />,
  widgetDoctorsInChat: (filters, key, grid, rewriteFilters) => <WidgetDoctorsInChat rewriteFilters={rewriteFilters} key={key} keyWidget={key} grid={grid} filtersData={filters} localeStorageName="widgetDoctorsInChat" />,
  widgetCSAT: (filters, key, grid, rewriteFilters) => <WidgetCSAT rewriteFilters={rewriteFilters} key={key} keyWidget={key} grid={grid} filtersData={filters} localeStorageName="widgetCSAT" />,
  widgetRPR: (filters, key, grid, rewriteFilters) => <WidgetRPR rewriteFilters={rewriteFilters} key={key} keyWidget={key} grid={grid} filtersData={filters} localeStorageName="widgetRPR" />,
  widgetCES: (filters, key, grid, rewriteFilters) => <WidgetCES rewriteFilters={rewriteFilters} key={key} keyWidget={key} grid={grid} filtersData={filters} localeStorageName="widgetCES" />,
  widgetContentAnalytics: (filters, key, grid, rewriteFilters) => <WidgetContentAnalytics rewriteFilters={rewriteFilters} key={key} keyWidget={key} grid={grid} filtersData={filters} localeStorageName="widgetContentAnalytics" />,
  widgetFirstOnlineAppSaleAnalytics: (filters, key, grid, rewriteFilters) => <WidgetFirstOnlineAppSaleAnalytics rewriteFilters={rewriteFilters} key={key} keyWidget={key} grid={grid} filtersData={filters} localeStorageName="widgetFirstOnlineAppSaleAnalytics" />,
  widgetRateBySource: (filters, key, grid, rewriteFilters) => <WidgetRateBySource rewriteFilters={rewriteFilters} key={key} keyWidget={key} grid={grid} filtersData={filters} localeStorageName="widgetRateBySource" />,
  widgetAverageRatingByReviews: (filters, key, grid, rewriteFilters) => <WidgetAverageRatingByReviews rewriteFilters={rewriteFilters} key={key} keyWidget={key} grid={grid} filtersData={filters} localeStorageName="widgetAverageRatingByReviews" />,
  widgetTotalReviews: (filters, key, grid, rewriteFilters) => <WidgetTotalReviews rewriteFilters={rewriteFilters} key={key} keyWidget={key} grid={grid} filtersData={filters} localeStorageName="widgetTotalReviews" />,
  widgetInternalRate: (filters, key, grid, rewriteFilters) => <WidgetInternalRate rewriteFilters={rewriteFilters} key={key} keyWidget={key} grid={grid} filtersData={filters} localeStorageName="widgetInternalRate" />
}

export const GET_OPTIONS_FOR_WIDGET = (keys = []) => {
  return OPTIONS_FIELDS_WIDGET.filter((item) => keys.includes(item.value));
}

export const GET_FIELDS_FOR_WIDGETS = (keys = []) => {
  const data = {};
  Object.keys(ALL_FIELDS_FOR_WIDGETS).forEach((key) => {
    if (keys.includes(key)) {
      data[key] = ALL_FIELDS_FOR_WIDGETS[key];
    }
  })
  return data;
}

export const GET_GENERATE_WIDGETS = (keys = []) => {
  const data = {};
  Object.keys(ALL_GENERATE_WIDGETS).forEach((key) => {
    if (keys.includes(key)) {
      data[key] = ALL_GENERATE_WIDGETS[key];
    }
  })
  return data;
}

export const REVIEW_KEYS = [
  'widgetRegistrationLoyalty',
  'widgetNPS',
  'widgetTags',
  'widgetAppointmentType',
  'widgetSteps',
  'widgetRate',
  'widgetCSAT',
  'widgetRPR',
  'widgetCES',
  'widgetRateBySource',
  'widgetAverageRatingByReviews',
  'widgetTotalReviews',
  'widgetInternalRate'
]

export const PAYMENT_KEYS = [
  'widgetPaymentSummary',
]

export const DASHBOARD_KEYS = [
  'widgetConciergeAnalyticsMetric',
  'widgetPaymentSummary',
  'widgetRate',
  'widgetStoriesAnalytics',
  'widgetDoctorsInChat',
  'widgetContentAnalytics',
  'widgetFirstOnlineAppSaleAnalytics',
  'widgetFirstOnlineAppSaleAnalytics'
]
