import React from "react";
import {
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Controller } from "react-hook-form";

export const FormInputCheckbox = (props) => {
  return (
    <FormControlLabel
      control={
        <Controller
          name={props.name}
          error={props.error}
          control={props.control}
          render={({
            field: { onChange, value },
          }) => (
            <Checkbox
              {...props}
              size="small"
              disabled={props.readOnly}
              checked={!!value}
              onChange={onChange}
            />
          )}
        />
      }
      label={props.label}
    />
  );
};
