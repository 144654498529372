// @flow
import React from "react";
import { makeAutoObservable } from "mobx";
import type { Notice } from "../apiv2/entities/notices";
import Binder from "../utils/binder";
import { useToast } from "../components/toast/useToast";
import { socketErrorHandler } from "../utils/sockerErrorHandler";
import apiv2 from "../../src/apiv2";
import { NoticeToast } from "../components/notices/NoticeToast";

const toastManager = useToast();

const MessageType = {
  NEW_NOTICE: "new_notice"
};

export const NOTICE_TYPES = {
  INFO: "info",
  ANALYSIS: "analysis",
  QUESTIONNAIRE: "questionnaire",
  LINK: "link"
};

export const NOTICE_TYPES_RU = {
  [NOTICE_TYPES.INFO]: "Информация",
  [NOTICE_TYPES.ANALYSIS]: "Анализ",
  [NOTICE_TYPES.QUESTIONNAIRE]: "Анкета",
  [NOTICE_TYPES.LINK]: "Внешняя ссылка"
};

class NoticesStore {
  socket = null;
  notices = [];
  // unreadNotices = [];
  isInitialized = false;

  constructor() {
    Binder.bind(this, NoticesStore);
    makeAutoObservable(this);
  }

  async initialize() {
    if (!this.isInitialized) {
      await this.fetchAllNotices();
      this.isInitialized = true;
      if (this.unreadNoticesCount) {
        toastManager.showToast(<NoticeToast title="У Вас есть непрочитанные уведомления" />, {
          duration: 10000
        });
      }
    }
  }

  initWS() {
    this.socket = new WebSocket(apiv2.notices.getSocketUrl().toString());

    this.socket.onopen = () => {
      console.info("connection to notices channel established");
    };

    this.socket.onclose = socketErrorHandler(this.socket, () => this.initWS());

    this.socket.onmessage = event => {
      const message = JSON.parse(event.data);

      switch (message.type) {
        case MessageType.NEW_NOTICE: {
          toastManager.showToast(<NoticeToast text={message.data.title} />, {
            duration: 10000
          });
          /** В сообщении нет полного тела уведомления, пришлось перезапрашиваться */
          this.fetchAllNotices();
          break;
        }
        default:
          break;
      }
    }

  }

  get readedNotices() {
    return this.notices.filter(notice => notice.is_read);
  }

  get unreadNotices() {
    return this.notices.filter(notice => !notice.is_read);
  }

  get unreadNoticesCount() {
    return this.unreadNotices.length;
  }

  async createNotice(noticeData: Omit<Notice, "id">) {
    try {
      return await apiv2.notices.createNotice(noticeData);
    } catch (e) {
      console.log(e);
    }
  }

  async fetchAllNotices() {
    try {
      let response = await apiv2.notices.getNotices();
      if (response) {
        this.notices = response;
      }
      return response;
    } catch (e) {
      console.log(e);
    }
  }

  async fetchUnreadNotices() {
    try {
      let response = await apiv2.notices.getUnreadNotices();
      if (response) {
        // TODO: Вернуть если будем использовать их получение таким способом
        // this.unreadNotices = response;
      }
      return response;
    } catch (e) {
      console.log(e);
    }
  }

  async readNotice(noticeId) {
    try {
      const response = await apiv2.notices.readNotice(noticeId);
      this.notices = this.notices.map(notice => {
        if (notice.id === noticeId) {
          notice.is_read = true;
        }
        return notice;
      });
      return response;
    } catch (e) {
      console.log(e);
    }
  }

  async makeNoticeUnread(noticeId) {
    try {
      return await apiv2.notices.unreadNotice(noticeId);
    } catch (e) {
      console.log(e);
    }
  }

  async editNotice(noticeId: Number, noticeData: Notice) {
    try {
      return await apiv2.notices.updateNotice(noticeId, noticeData);
    } catch (e) {
      console.log(e);
    }
  }

  async deleteNotice(noticeId) {
    try {
      return await apiv2.notices.deleteNotice(noticeId);
    } catch (e) {
      console.log(e);
    }
  }
}

export default new NoticesStore();
