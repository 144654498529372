// @flow
import React, { useLayoutEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { styled } from "@mui/material/styles";
import useReactRouter from "use-react-router";
import { CorporateWrapper } from "../../styled/CorporateWrapper.styled";
import NewsIcon from '../../../assets/icons/corporate-portal/navigation/news.inline.svg';
import EmployeesIcon from '../../../assets/icons/corporate-portal/navigation/community.inline.svg';
import DocumentsIcon from '../../../assets/icons/corporate-portal/navigation/documents.inline.svg';
import CompanyIcon from '../../../assets/icons/corporate-portal/navigation/company.inline.svg';

const NavigationContainer = styled(List)(
  ({ theme }) => `
    .MuiListItemIcon-root {
      min-width: auto;
    }
    padding-block: 20px;
    ${theme.breakpoints.down("lg")} {
      padding-block: 15px;
    }
    ${theme.breakpoints.down("sm")} {
      padding-block: 10px;
    }
    ${theme.breakpoints.down("md")} {
      .MuiListItemIcon-root {
        min-width: auto;
      }
    }
    .MuiButtonBase-root {
      gap: 8px;
      .MuiListItemText-root .MuiTypography-root {
        font-size: 16px;
        ${theme.breakpoints.down("lg")} {
          font-size: 15px;
        }
        ${theme.breakpoints.down("sm")} {
          font-size: 14px;
        }
      }
      .MuiListItemIcon-root svg {
        width: 20px;
        height: 20px;
      }
      &.Mui-selected {
        pointer-events: none;
        color: ${theme.palette.primary.main};
        background: transparent;
        .MuiListItemIcon-root svg path {
          fill: ${theme.palette.primary.main};
        }
      }
    }
  `
);

export const CorporateNavigation = () => {
  const theme = useTheme();
  const isShowName = useMediaQuery(theme.breakpoints.up("md"));
  const { history } = useReactRouter();
  // ToDo при появлении сторы, вынести
  const [activeNavigationItem, setActiveNavigationItem] = useState(location.pathname);

  const navigationItems = [
    { path: "/corporate-portal/news", name: "Новости", icon: <NewsIcon /> },
    { path: "/corporate-portal/employees", name: "Сотрудники", icon: <EmployeesIcon /> },
    { path: "/corporate-portal/documents", name: "Документы", icon: <DocumentsIcon /> },
    { path: "/corporate-portal/company", name: "Компания", icon: <CompanyIcon /> }
  ];
  useLayoutEffect(() => {
    const foundNavigationItem = navigationItems.find(
      item => location.pathname.indexOf(item.path) !== -1
    );
    setActiveNavigationItem(foundNavigationItem?.path || "");
  }, [location.pathname]);

  return (
    <CorporateWrapper modifiers={"withoutPadding"}>
      <NavigationContainer>
        {navigationItems.map(item => (
          <ListItem key={item.path} disablePadding>
            <ListItemButton
              selected={item.path === activeNavigationItem}
              onClick={() => history.push(item.path)}>
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              {isShowName && <ListItemText primary={item.name} />}
            </ListItemButton>
          </ListItem>
        ))}
      </NavigationContainer>
    </CorporateWrapper>
  );
};
