// @flow
import * as React from "react";
import styled from "styled-components";
import { HeadingH6 } from "../styleguide/typography";
import { RoundedRectangleWithShadow } from "../styleguide/RoundedRectangleWithShadow";
import newAppointmentIcon from "./icons/new-appointment.svg";
import newAppointmentIconColor from "./icons/new-appointment-color.svg";
import { ButtonWithTooltip } from "../buttons/ButtonWithTooltip";
import { useStore } from "effector-react";
import {
  changeDataDeleteSchedule,
  dataStore,
  recordingChatAllowed,
  recordingUnscheduledAppointmentAllowed
} from "../schedule-page/scheduleStore";
import chat from "./icons/chat.svg";
import { DeleteButtonIcon } from "../buttons/DeleteButtonIcon";
import { isSameOrAfterDate } from "../../domain/services/schedule";
import RcTooltip from "rc-tooltip";
import { useMediaQuery } from "@mui/material";
import { withModifiers } from "with-modifiers";

const Container = styled(RoundedRectangleWithShadow)`
  padding: 14px 0 10px 0;
  @media (max-width: 768px) {
    padding: 20px 0;
  }
  @media (max-width: 480px) {
    padding: 15px 0;
  }
`;

const ContentContainer = styled.div`
  margin-top: 12px;
  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 8px;
  @media (max-width: 768px) {
    margin-right: 0;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 22px;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
  @media (max-width: 480px) {
    padding: 0 15px;
  }
`;

const Title = withModifiers({
  mobile: () => `
    flex-direction: column;
    ${HeadingH6}:last-child {
      font-size: 14px;
    }
  `
})(
  modifier => styled.div`
    display: flex;

    ${modifier};
  `
);

const ChatIcon = styled.div`
  width: 20px;
  height: 20px;
  background: url(${chat}) no-repeat;
  background-size: contain;
`;

const ScheduleDayLayout = React.forwardRef<
  {
    title: string,
    organizations?: string,
    children: React.Node,
    date: string,
    showNewAppointmentButton?: boolean,
    onInsertSchedule: () => mixed
  },
  HTMLElement
>((props, ref) => {
  const isMobile = useMediaQuery("(max-width: 576px)");
  const scheduleStore = useStore(dataStore);
  const deleteScheduleButtonClick = () => {
    changeDataDeleteSchedule(props.date);
  }
  const showDeleteButton = isSameOrAfterDate(props.date) && scheduleStore.items.find((slotItem) => slotItem.date === props.date).schedule.length > 0;
  return (
    <Container ref={ref}>
      <Header>
        <Title modifiers={[isMobile && "mobile"]}>
          <HeadingH6 modifiers="capitalize">{props.title}</HeadingH6>
          {props.organizations && props.organizations !== "" && (
            <HeadingH6 modifiers={["capitalize", "gray"]}>
              {!isMobile && <span>&nbsp; / &nbsp;</span>}
              {props.organizations}
            </HeadingH6>
          )}
        </Title>
        <RightContainer>
          {(scheduleStore.isEditSchedule && (process.env.APP_FLAVOR === "telemed") && showDeleteButton) && (
            <DeleteButtonIcon onClick={deleteScheduleButtonClick} />
          )}
          {!scheduleStore.isEditSchedule && recordingChatAllowed(props.date) && (
            <RcTooltip animation="zoom" placement={"bottom"} overlay={<span>Запись в чат разрешена</span>}>
              <ChatIcon />
            </RcTooltip>
          )}
          {recordingUnscheduledAppointmentAllowed(props.date) && props.showNewAppointmentButton && !scheduleStore.isEditSchedule && process.env.APP_FLAVOR === 'default' && (
            <ButtonWithTooltip
              icon={newAppointmentIcon}
              iconHover={newAppointmentIconColor}
              tooltip="Приём вне расписания"
              onClick={props.onInsertSchedule}
            />
          )}
        </RightContainer>
      </Header>
      {props.children.length && (
        <ContentContainer>
          {props.children}
        </ContentContainer>
      )}
    </Container>
  );
});

ScheduleDayLayout.displayName = "ScheduleDayLayout";

export { ScheduleDayLayout };
