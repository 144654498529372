import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { Avatar as MuiAvatar, Box, Drawer } from "@mui/material";
import corporateEmployeesStore from "../stores/corporateEmployeesStore";
import { styled } from "@mui/material/styles";
import { CorporateCard } from "./CorporateCard";
import { CorporateWrapper } from "../styled/CorporateWrapper.styled";
import { breakpoints } from "../styled/common";
import { scrollbars } from "../../components/chats/style-elements";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { shortName } from "../utils/shortName";
import { CorporateTextBlock } from "../modules/employee/CorporateTextBlock";
import { CorporateUserCardBlock } from "../modules/employee/CorporateUserCardBlock";
import { CorporateInformationBlock } from "../modules/employee/CorporateInformationBlock";

const BLOCK_TYPES = {
  INFORMATION: "information",
  USER_CARD: "user_card",
  TEXT: "text"
};

const Avatar = styled(MuiAvatar)(
  ({ theme }) => `
    box-sizing: border-box;
    width: 220px;
    height: 220px;
    font-size: 5rem;
    text-transform: uppercase;
    background-color: ${theme.palette.background.layout};
    color: ${theme.palette.text.gray};
    border: 20px solid ${theme.palette.wrapper.white};
    ${breakpoints.desktop} {
      width: 150px;
      height: 150px;
      font-size: 3rem;
      border: 10px solid ${theme.palette.wrapper.white};
    }
    ${breakpoints.tablet} {
      width: 100px;
      height: 100px;
      font-size: 2rem;
      border: 5px solid ${theme.palette.wrapper.white};
    }
  `
);

const DrawerContentContainer = styled(Box)(
  ({ theme }) => `
    position: relative;
    box-sizing: border-box;
    width: 870px;
    height: 100%;
    padding: 30px;
    background: ${theme.palette.background.layout};
    display: flex;
    gap: 25px;
    margin-left: 50px;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.04);
    ${breakpoints.desktop} {
      width: 650px;
      padding: 40px 25px 25px;
      gap: 20px;
    }
    ${breakpoints.md} {
      width: 100vw;
      margin-left: 0;
    }
    ${breakpoints.tablet} {
      padding: 40px 10px 10px;
      gap: 10px;
    }
  `
);

const Content = styled(Box)(
  () => `
    box-sizing: border-box;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 24px;
    height: 100%;
    overflow: auto;
    .MuiPaper-root .MuiCardHeader-root {
      margin: 0;
      .MuiCardHeader-content {
        padding: 20px;
        ${breakpoints.desktop} {
          padding: 15px;
        }   
        ${breakpoints.tablet} {
          padding: 10px;
        }
        .MuiTypography-root {
          font-size: 18px;
          font-weight: 700;
          ${breakpoints.desktop} {
            font-size: 16px;
          }
          ${breakpoints.tablet} {
            font-size: 14px;
          }
        }
      }
    }
    ${breakpoints.desktop} {
      gap: 20px;
    }
    ${breakpoints.tablet} {
      gap: 10px;
    }

    ${scrollbars};
  `
);

const BlockContainer = styled(Box)`
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${breakpoints.desktop} {
    padding: 15px;
  }
  ${breakpoints.tablet} {
    padding: 10px;
    gap: 10px;
  }
`;

const InformationElement = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const CloseButtonContainer = styled(Box)(
  ({ theme }) => `
    background: ${theme.palette.background.magenta};
    position: absolute;
    width: 50px;
    top: 30px;
    left: 0;
    z-index: 1000000;
    border-radius: 20px 0 0 20px;
    .MuiButtonBase-root {
      color: ${theme.palette.text.white};
    }
    ${breakpoints.md} {
      width: auto;
      top: 0;
      left: auto;
      right: 0;
      background: transparent;
      border-radius: 0;
      .MuiButtonBase-root {
        color: ${theme.palette.text.gray};
      }
    }
  `
);

const DrawerContent = ({ photo, ...otherProps }) => {
  const blocks = useMemo(() => {
    return [
      {
        title: "Контактная информация",
        type: BLOCK_TYPES.INFORMATION,
        contents: [
          {
            label: "Фамилия",
            value: otherProps.contact_information.last_name
          },
          {
            label: "Имя",
            value: otherProps.contact_information.first_name
          },
          {
            label: "Отчество",
            value: otherProps.contact_information.middle_name
          },
          {
            label: "День рождения",
            value: otherProps.contact_information.birthdate
          },
          {
            label: "Рабочий телефон",
            value: otherProps.contact_information.work_phone_number
          },
          {
            label: "Телефон",
            value: otherProps.contact_information.personal_phone_number
          },
          {
            label: "Telegram",
            value: otherProps.contact_information.telegram
          },
          {
            label: "WhatsApp",
            value: otherProps.contact_information.whats_app
          },
          { label: "Город", value: otherProps.contact_information.city }
        ]
      },
      {
        title: "Рабочая информация",
        type: BLOCK_TYPES.INFORMATION,
        contents: [
          {
            label: "Подразделение",
            value: otherProps.working_information.subdivision
          },
          {
            label: "Тип мед персонала",
            value: otherProps.working_information.type_medical_staff
          },
          {
            label: "Должность",
            value: otherProps.working_information.position
          },
          {
            label: "Направление деятельности",
            value: otherProps.working_information.specialization
          }
        ]
      },
      {
        title: "Дополнительная информация",
        type: BLOCK_TYPES.USER_CARD,
        contents: [
          {
            label: "Подчиненные",
            value: otherProps.additional_information.subordinates
          },
          {
            label: "Руководитель",
            value: otherProps.additional_information.supervisor
          }
        ]
      },
      {
        title: "Интересы",
        type: BLOCK_TYPES.TEXT,
        value: "Выбирайте интересы и найдите друзей, разделяющих ваши увлечения"
      }
    ];
  }, []);

  return (
    <DrawerContentContainer>
      <Avatar src={photo}>
        {shortName({
          lastName: otherProps.contact_information.last_name,
          firstName: otherProps.contact_information.first_name
        })}
      </Avatar>
      <Content>
        {blocks.map((block) => (
          <CorporateWrapper key={block.title} modifiers={"withoutPadding"}>
            <CorporateCard title={block.title} showDivider>
              <BlockContainer>
                {block.type === BLOCK_TYPES.INFORMATION &&
                  block.contents.map((content) => (
                    <InformationElement key={content.label}>
                      <CorporateInformationBlock label={content.label} value={content.value} />
                    </InformationElement>
                  ))}
                {block.type === BLOCK_TYPES.USER_CARD &&
                  block.contents.map((content) => (
                    <InformationElement key={content.label}>
                      <CorporateUserCardBlock label={content.label} value={content.value} />
                    </InformationElement>
                  ))}
                {block.type === BLOCK_TYPES.TEXT && (
                  <InformationElement>
                    <CorporateTextBlock value={block.value} />
                  </InformationElement>
                )}
              </BlockContainer>
            </CorporateCard>
          </CorporateWrapper>
        ))}
      </Content>
    </DrawerContentContainer>
  );
};

export const CorporateProfileEmployee = observer(() => {
  const CloseButton = () => {
    return (
      <CloseButtonContainer>
        <IconButton onClick={corporateEmployeesStore.closeProfileEmployee}>
          <CloseIcon />
        </IconButton>
      </CloseButtonContainer>
    );
  };

  return (
    <Drawer
      anchor="right"
      open={!!corporateEmployeesStore.isShowProfileEmployee}
      onClose={corporateEmployeesStore.closeProfileEmployee}
      PaperProps={{
        sx: {
          background: "transparent",
          boxShadow: "none"
        }
      }}>
      <CloseButton />
      {!!corporateEmployeesStore.profileEmployeeData && (
        <DrawerContent {...corporateEmployeesStore.profileEmployeeData} />
      )}
    </Drawer>
  );
});
