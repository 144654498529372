import { request } from "../../../api/utils";
import apiv2 from '../../../apiv2';
import _ from 'lodash';
import serviceStore from "../../../stores/serviceStore";

export const getServiceCategories = organizationId =>
    apiv2.services.getServiceCategories(organizationId);

const serviceToNum = (service) => {
    return parseInt(service.ministry_of_health_code.replace(/\D/g,''));
}

const prescribedSectionServices = {}

export const getServices = async ({ organizationId, searchQuery, favorites = false, id = null, forPrescribedSection = false }) => {
  const params = {};

  if (favorites) {
    params.only_favorites = true;
  }
  // if (searchQuery) {
  //   params.search = searchQuery;
  // }

  let services = await apiv2.services.getOrganizationServices(organizationId, params);
  if (!searchQuery) {
      if (id) {
          return services.filter((s) => s.id === id);
      }
      return services;
  }

  if (forPrescribedSection) {
      if (!prescribedSectionServices[organizationId]) {
          if (organizationId === 30) {
              services = services.filter((s) => !serviceStore.consultationsCategoriesIds.includes(s.category_id) || (s.ministry_of_health_code.startsWith('B01') && s.ministry_of_health_code.endsWith('.001')));
              prescribedSectionServices[organizationId] = services;
          } else {
              const servicesResult = [];
              for (const s of services) {
                if (!!s.ministry_of_health_code && serviceStore.consultationsCategoriesIds.includes(s.category_id)) {
                  const servCat = serviceStore.categoriesObject[s.category_id];
                  const consultCat = serviceStore.consultationsCategories.find((cs) => servCat.category_code.startsWith(cs.category_code));
                  if (consultCat) {
                    if (!consultCat.mainService) {
                      consultCat.mainService = s;
                    } else if (serviceToNum(consultCat.mainService) > serviceToNum(s)) {
                      consultCat.mainService = s;
                    }
                  }
                } else {
                  servicesResult.push(s);
                }
              }
              for (const consultCat of serviceStore.consultationsCategories) {
                  if (consultCat.mainService) {
                      servicesResult.push(consultCat.mainService);
                  }
              }

              prescribedSectionServices[organizationId] = servicesResult;
          }
      }
      // ToDo возможно, нужно сделать, чтотпри выводе в компоненте, удаляем слово "первичный" и "первичная" - если речь про "B01."
      services = prescribedSectionServices[organizationId];
  }

  searchQuery = searchQuery.toLowerCase();

  services = services.filter((s) => s.name.toLowerCase().includes(searchQuery) || s.kdf_code?.toLowerCase()?.startsWith(searchQuery));
  return services;
};

export const addServiceToFavorties = (organizationId, serviceId) => {
    apiv2.services.clearCacheOrganizationServices(organizationId, {only_favorites: true});
    return request({
        method: "POST",
        url: `/organizations/${organizationId}/services/${serviceId}/favorites/`
    });
}

export const removeServiceFromFavorties = (organizationId, serviceId) => {
    apiv2.services.clearCacheOrganizationServices(organizationId, {only_favorites: true});
    return request({
        method: "DELETE",
        url: `/organizations/${organizationId}/services/${serviceId}/favorites/`
    });
}

export const addAppointmentService = ({ appointmentId, serviceId, count, comment, teeth }) =>
  request({
    method: "POST",
    url: `/appointments/${appointmentId}/services/`,
    data: {
      service_id: serviceId,
      count,
      comment,
      teeth,
    }
  });


export const removeAppointmentService = ({ appointmentId, serviceId }) =>
    request({
        method: "DELETE",
        url: `/appointments/${appointmentId}/services/`,
        data: {
            service_id: serviceId
        }
    });

export const addAppointmentPrescribedService = ({ appointmentId, serviceId, count, date_range, comment, completed_appointment, teeth }) =>
  request({
    method: "POST",
    url: `/appointments/prescribed-services/?appointment_id=${appointmentId}`,
    data: {
      service_id: serviceId,
      count,
      comment,
      teeth,
      completed_appointment_id: completed_appointment,
      date_range: { value: date_range?.dates || date_range },
    }
  });

export const removeAppointmentPrescribedService = ({ appointmentId, serviceId }) =>
    request({
        method: "DELETE",
        url: `/appointments/prescribed-services/?appointment_id=${appointmentId}`,
        data: {
            service_id: serviceId
        }
    });


const PolicyDescriptionData = {}
export const getPolicyDescription = async (patientId) => {
    if (PolicyDescriptionData[patientId]) {
        return PolicyDescriptionData[patientId];
    }
    const data = await request({
        method: "GET",
        url: `/policy-descriptions/${patientId}/`,
    });
    PolicyDescriptionData[patientId] = data;
    return data;
}

