// @flow
import React from "react";
import { PointsContainer } from "./PointsContainer";
import type { AppointmentWithDetails } from "../../domain/entities/Appointment";
import { getAppointment_id } from "../../domain/entities/Appointment";
import nanoid from "nanoid";
import { getChecklistGuid } from "../../domain/entities/Checklist";
import { PointTemplate } from "./PointTemplate";
import { CheckboxLabel } from "./CheckboxLabel";
import { useChecklist } from "./useChecklist";
import { appointmentRecommendationsStore } from "../../stores/decisionSupportStores";
import { useList } from "effector-react";
import { PointButtons } from "./PointButtons";
import { PointHint } from "./PointHint";
import { PointTitle } from "./PointTitle";

import { AddPointContainer, AddPointText, NewPointInputContainer } from "./AddPointContainer";
import { NewPointInput } from "./NewPointInput";
import { AddPointButton } from "./AddPointButton";
import { AddingHOC } from "./AddingHOC";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

export const AppointmentRecommendation = (props: { appointment: AppointmentWithDetails }) => {
  const { appointment } = props;
  const appointment_id = getAppointment_id(appointment);

  const defaultRecommendationChecklists =
    (appointment.recommendation_checklist && appointment.recommendation_checklist.items) || [];

  const {
    addChecklist,
    toggleChecklist,
    setComment,
    remove,
    removeComment,
    reorder,
    store: checklistStore
  } = useChecklist(
    appointmentRecommendationsStore,
    appointment_id,
    defaultRecommendationChecklists
  );

  const list = useList(checklistStore, (checklist, index) => (
    <Draggable key={checklist.guid} draggableId={checklist.guid.toString()} index={index}>
      {provided => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <PointTemplate
            icon={
              <CheckboxLabel
                modifiers={(!checklist.is_checked && "disabled") || undefined}
                onClick={() => toggleChecklist(getChecklistGuid(checklist))}
              />
            }
            buttons={
              <PointButtons
                comment={checklist.comment}
                setComment={value => setComment({ value, id: getChecklistGuid(checklist) })}
                removeComment={() => removeComment(getChecklistGuid(checklist))}
                remove={() => remove(getChecklistGuid(checklist))}
              />
            }
            hint={
              checklist.comment && (
                <PointHint disabled={!checklist.is_checked}>{checklist.comment}</PointHint>
              )
            }
            title={<PointTitle disabled={!checklist.is_checked} text={checklist.name} />}
            isDraggable
            dragHandleProps={provided.dragHandleProps}
          />
        </div>
      )}
    </Draggable>
  ));

  return (
    <>
      <PointsContainer>
        <DragDropContext
          onDragEnd={result =>
            reorder({ start: result.source.index, end: result.destination.index })
          }>
          <Droppable droppableId="medications">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {list}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </PointsContainer>
      <AddingHOC>
        {({ value, onChange, isAdding, setAdding }) => {
          const adding = () => {
            setAdding(false);
            addChecklist({
              guid: nanoid(),
              name: value,
              is_checked: true
            });
          };
          return (
            <>
              {isAdding && (
                <NewPointInputContainer>
                  <NewPointInput
                    value={value}
                    onChange={onChange}
                    onBlur={adding}
                    onEnter={adding}
                  />
                </NewPointInputContainer>
              )}
              {!isAdding && (
                <AddPointContainer withHover onClick={() => setAdding(true)} withText>
                  <AddPointButton />
                  <AddPointText>Добавить рекомендацию</AddPointText>
                </AddPointContainer>
              )}
            </>
          );
        }}
      </AddingHOC>
    </>
  );
};
