import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class PrescribedServices {
  apiService;
  constructor() {
    this.apiService = new ApiService('/api', null, null, API_URL);
  }

  getPrescribedServicesReportOne({filter_params, meta = {}, values_params, order_by_params, annotate_mtm_params, is_after_get}) {
    return this.apiService.post(`prescribed-service-report/by-patient`, {}, {filter_params, current_page: meta.current_page, per_page: meta.per_page, values_params, order_by_params, annotate_mtm_params, is_after_get}, {}, true, "", true);
  }

  getPrescribedServicesReportTwo({filter_params, meta = {}, values_params, order_by_params, annotate_mtm_params, is_after_get}) {
    return this.apiService.post(`prescribed-service-report/by-doctor`, {}, {filter_params, current_page: meta.current_page, per_page: meta.per_page, values_params, order_by_params, annotate_mtm_params, is_after_get}, {}, true, "", true);
  }

  getPrescribedServicesReportThree({filter_params, meta = {}, values_params, order_by_params, annotate_mtm_params, is_after_get}) {
    return this.apiService.post(`prescribed-service-report/by-speciality`, {}, {filter_params, current_page: meta.current_page, per_page: meta.per_page, values_params, order_by_params, annotate_mtm_params, is_after_get}, {}, true, "", true);
  }
}

export default new PrescribedServices();
