import React, { useEffect, useMemo, useState } from "react";
import voxInformationPatientStore, { TEMPLATE_TEXTS, TEMPLATES } from "../../stores/voxInformationPatientStore";
import styled from "styled-components";
import { observer } from "mobx-react";
import { Button, Tooltip } from "@mui/material";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import dayjs from "dayjs";
import { READABLE_DATE_TIME_FORMAT } from "../../utils/dates";
import { DynamicForm } from "../common/dynamic-form/DynamicForm";
import TextField from "@mui/material/TextField";
import { applicationFeatureEnabled, FEATURE } from "../../utils/applicationFeatures";

const VoxInformationPatientPage = styled.div`

`;

const InfoBlockTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
`;

const InfoBlock = styled.div`
  ${InfoBlockTitle} {
    margin-bottom: 15px;
  }
`;

const InfoItem = styled.div`
    box-sizing: border-box;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    width: 100%;
    @media (max-width: 768px) {
      gap: 4px;
    }
`;

const InfoBlockContent = styled.div`
  display: flex;
  gap: 10px;
  ${props => props.contentDirection === "row"
  ? `
      flex-direction: row;
      justify-content: space-between;
    `
  : `
      flex-direction: column;
    `
};
  flex-wrap: wrap;
  @media (max-width: 768px) {
    gap: 13px;
  }
  ${props => props.contentBacklight
  ? `
        gap: 0 !important;
        ${CommunicationBlock} {
          padding: 8px 6px;
          &:nth-child(2n + 1) {
            background: #ededed;
            &:not(:first-child) {
              border-top: 1px solid #EBEBEB;
            }
            &:not(:last-child) {
              border-bottom: 1px solid #EBEBEB;
            }
          }
        }
      `
  : ``};
`;

const InfoItemValue = styled.div`
    word-break: break-word;
`;

const CommunicationBlock = styled.div`
`;


const SendMessageBlock = styled.div`
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const InfoItemLabel = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const InfoBlockContainer = ({ title = "", contentDirection = "", contentBacklight = "", children }) => {
  return (
    <InfoBlock>
      {!!title && <InfoBlockTitle>{title}</InfoBlockTitle>}
      <InfoBlockContent contentDirection={contentDirection} contentBacklight={contentBacklight}>{children}</InfoBlockContent>
    </InfoBlock>
  )
}

export const VoxInformationPatient = observer(() => {
  const [countCommunication, setCountCommunication] = useState(5);
  const [countScheduleFuture, setCountScheduleFuture] = useState(5);
  const [activeTab, setActiveTab] = React.useState('1');
  // const [activeTab, setActiveTab] = React.useState('1');

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    (async () => {
      const phone_number = new URLSearchParams(location.search).get('phone_number');
      if (phone_number) {
        voxInformationPatientStore.setPhoneNumber(new URLSearchParams(location.search).get('phone_number'));
        await voxInformationPatientStore.initialize();
      }
    })()
  }, []);

  const defaultData = {
    phone_number: '',
  }

  const fields = useMemo(
    () => [
      {
        key: "phone_number",
        type: "phoneNumber",
        name: "phone_number",
        label: "Телефон пациента",
        xs: 12
      },
    ],
    []
  );

  const COMMUNICATION_STATUS = {
    CREATED: "Создано для отправки",
    SENT: "Отправлено",
    DELIVERED: "Доставлено",
    READ: "Прочитано",
    UNDELIVERED: "Отправлено, но не доставлено",
    CANCELLED: "Отменено",
    EXPIRED: "Истекло время ожидания",
    FAILED: "Произошла ошибка",
    TOO_MANY_MAILING: "Слишком много рассылок в месяц"
  }

  const submitHandler = (data) => {
    voxInformationPatientStore.setPhoneNumber(data.phone_number.replaceAll(' ', '').replaceAll('+', ''));
    voxInformationPatientStore.initialize();
  }

  return (
    <VoxInformationPatientPage>
      {voxInformationPatientStore.phone_number ? (
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={activeTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Информация" value="1" />
                <Tab label="Рассылки" value="2" />
                <Tab label="Записи" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              {!!voxInformationPatientStore.medicalFile && (
                <>
                  <InfoBlockContainer title="Информация о пациенте">
                    <InfoItem>
                      <InfoItemLabel>Фио пациента:</InfoItemLabel>
                      <InfoItemValue>{voxInformationPatientStore.medicalFile.patient.last_name} {voxInformationPatientStore.medicalFile.patient.first_name} {voxInformationPatientStore.medicalFile.patient.middle_name}</InfoItemValue>
                    </InfoItem>
                    <InfoItem>
                      <InfoItemLabel>Дата рождения:</InfoItemLabel>
                      <InfoItemValue>{voxInformationPatientStore.medicalFile.patient.birthdate}</InfoItemValue>
                    </InfoItem>
                    <InfoItem>
                      <InfoItemLabel>Номер телефона:</InfoItemLabel>
                      <InfoItemValue>{voxInformationPatientStore.medicalFile.patient.phone_number}</InfoItemValue>
                    </InfoItem>
                    <InfoItem>
                      <InfoItemLabel>Организации в которых обслуживается:</InfoItemLabel>
                      <InfoItemValue>{voxInformationPatientStore.medicalFile.organizations.join(', ')}</InfoItemValue>
                    </InfoItem>
                    { voxInformationPatientStore.medicalFile.badge.length > 0 && (
                      <InfoItem>
                        <InfoItemLabel>Badge пациента:</InfoItemLabel>
                        <InfoItemValue>{voxInformationPatientStore.medicalFile.badge.join(', ')}</InfoItemValue>
                      </InfoItem>
                    )}
                    <InfoItem>
                      <InfoItemLabel>Есть полис ДМС:</InfoItemLabel>
                      <InfoItemValue>{voxInformationPatientStore.medicalFile.policies.length ? 'Да' : 'Нет'}</InfoItemValue>
                    </InfoItem>
                  </InfoBlockContainer>
                </>
              )}
              <Button variant={"outlined"} sx={{mt: 1}} onClick={() => voxInformationPatientStore.setPhoneNumber(null)}>Ввести другой номер</Button>

              <SendMessageBlock>
                <InfoBlockTitle>Отмена приема</InfoBlockTitle>
                <Tooltip
                  title={TEMPLATE_TEXTS[TEMPLATES.MESSAGE_INITIATION_CANCEL]}
                  slotProps={{
                    tooltip: {
                      sx: { whiteSpace: "pre-wrap"}
                    }
                  }}
                >
                  <Button variant={"outlined"} sx={{mt: 1}} onClick={() => voxInformationPatientStore.sendMessageInitiationCancel()}>Отправить сообщение о отмене приема</Button>
                </Tooltip>
              </SendMessageBlock>

              <SendMessageBlock>
                <InfoBlockTitle>Перенос приема</InfoBlockTitle>
                <Tooltip
                  title={TEMPLATE_TEXTS[TEMPLATES.MESSAGE_INITIATION_TRANSFER]}
                  slotProps={{
                    tooltip: {
                      sx: { whiteSpace: "pre-wrap"}
                    }
                  }}
                >
                  <Button variant={"outlined"} sx={{mt: 1}} onClick={() => voxInformationPatientStore.sendMessageInitiationTransfer()}>Отправить сообщение о переносе приема</Button>
                </Tooltip>
              </SendMessageBlock>

              <SendMessageBlock>
                <InfoBlockTitle>Открытие расписания (лист ожидания)</InfoBlockTitle>
                <Tooltip
                  title={TEMPLATE_TEXTS[TEMPLATES.MESSAGE_INITIATION_OPENING_SCHEDULE]}
                  slotProps={{
                    tooltip: {
                      sx: { whiteSpace: "pre-wrap"}
                    }
                  }}
                >
                  <Button variant={"outlined"} sx={{mt: 1}} onClick={() => voxInformationPatientStore.sendMessageInitiationOpeningSchedule()}>Отправить сообщение о открытом расписании</Button>
                </Tooltip>
              </SendMessageBlock>

              <SendMessageBlock>
                <InfoBlockTitle>Освободившийся слот (лист ожидания)</InfoBlockTitle>
                <Tooltip
                  title={TEMPLATE_TEXTS[TEMPLATES.MESSAGE_INITIATION_VACANT_SLOT]}
                  slotProps={{
                    tooltip: {
                      sx: { whiteSpace: "pre-wrap"}
                    }
                  }}
                >
                  <Button variant={"outlined"} sx={{mt: 1}} onClick={() => voxInformationPatientStore.sendMessageInitiationVacantSlot()}>Отправить сообщение о освободившемся слоте</Button>
                </Tooltip>
              </SendMessageBlock>

              <SendMessageBlock>
                <InfoBlockTitle>Подготовка к процедурам</InfoBlockTitle>
                <TextField
                  onChange={(event) => voxInformationPatientStore.setLinkToInstructions(event.target.value)}
                  value={voxInformationPatientStore.linkToInstructions || ''}
                  fullWidth
                  variant="outlined"
                  multiline
                  maxRows={5}
                  placeholder="Укажите ссылку на инструкцию"
                />
                <Tooltip
                  title={TEMPLATE_TEXTS[TEMPLATES.MESSAGE_INITIATION_PREPARATION_PROCEDURES].replaceAll("{{link}}", voxInformationPatientStore.linkToInstructions || "")}
                  slotProps={{
                    tooltip: {
                      sx: { whiteSpace: "pre-wrap"}
                    }
                  }}
                >
                  <Button variant={"outlined"} sx={{mt: 1}} onClick={() => voxInformationPatientStore.sendMessageInitiationPreparationProcedures()}>Отправить сообщение о подготовке к процедурам</Button>
                </Tooltip>
              </SendMessageBlock>

              <SendMessageBlock>
                <InfoBlockTitle>Инициация сообщений пациенту</InfoBlockTitle>
                <TextField
                  onChange={(event) => voxInformationPatientStore.setDynamicText(event.target.value)}
                  value={voxInformationPatientStore.dynamicText || ''}
                  fullWidth
                  variant="outlined"
                  multiline
                  maxRows={5}
                  placeholder={TEMPLATE_TEXTS[TEMPLATES.MESSAGE_INITIATION_DYNAMIC_TEXT].replaceAll("{{message}}", "Тут будет вводимый текст из поля")}
                />
                <Tooltip
                  title={TEMPLATE_TEXTS[TEMPLATES.MESSAGE_INITIATION_DYNAMIC_TEXT].replaceAll("{{message}}", voxInformationPatientStore.dynamicText || "")}
                  slotProps={{
                    tooltip: {
                      sx: { whiteSpace: "pre-wrap"}
                    }
                  }}
                >
                  <Button variant={"outlined"} sx={{mt: 1}} onClick={() => voxInformationPatientStore.sendDynamicMessagePatient()}>Отправить динамическое сообщение</Button>
                </Tooltip>
              </SendMessageBlock>

              {!!applicationFeatureEnabled(FEATURE.ENABLE_VOX_TEMPLATE_T_FEEDBACK_NEW) && (
                <SendMessageBlock>
                  <InfoBlockTitle>Сообщение с ссылкой на обратную связь</InfoBlockTitle>
                  <TextField
                    onChange={(event) => voxInformationPatientStore.setFeedbackData("last_name", event.target.value)}
                    value={voxInformationPatientStore.feedbackData.last_name || ''}
                    fullWidth
                    variant="outlined"
                    placeholder="Укажите фамилию пациента"
                  />
                  <TextField
                    onChange={(event) => voxInformationPatientStore.setFeedbackData("first_name", event.target.value)}
                    value={voxInformationPatientStore.feedbackData.first_name || ''}
                    fullWidth
                    variant="outlined"
                    placeholder="Укажите имя пациента"
                  />
                  <TextField
                    onChange={(event) => voxInformationPatientStore.setFeedbackData("link", event.target.value)}
                    value={voxInformationPatientStore.feedbackData.link || ''}
                    fullWidth
                    variant="outlined"
                    multiline
                    maxRows={5}
                    placeholder="Укажите ссылку для обратной связи"
                  />
                  <Tooltip
                    title={
                      TEMPLATE_TEXTS[TEMPLATES.T_FEEDBACK_NEW]
                        .replace("{{last_name}}", voxInformationPatientStore.feedbackData?.last_name || "")
                        .replace("{{first_name}}", voxInformationPatientStore.feedbackData?.first_name || "")
                        .replace("{{link}}", voxInformationPatientStore.feedbackData?.link || "")
                    }
                    slotProps={{
                      tooltip: {
                        sx: { whiteSpace: "pre-wrap"}
                      }
                    }}
                  >
                    <Button variant={"outlined"} sx={{mt: 1}} onClick={() => voxInformationPatientStore.sendFeedbackNew()}>Отправить ссылку на обратную связь</Button>
                  </Tooltip>
                </SendMessageBlock>
              )}
            </TabPanel>
            <TabPanel value="2">
              {voxInformationPatientStore.communications.length > 0 && (
                <InfoBlockContainer title="Рассылки пациенту" contentBacklight>
                  {voxInformationPatientStore.communications.slice(0, countCommunication).map((item) => (
                    <CommunicationBlock key={item.id}>
                      <InfoItem>
                        <InfoItemLabel>Дата рассылки:</InfoItemLabel>
                        <InfoItemValue>{dayjs(item.communication_mailing.date).format(READABLE_DATE_TIME_FORMAT)}</InfoItemValue>
                      </InfoItem>
                      <InfoItem>
                        <InfoItemLabel>Токен рассылки:</InfoItemLabel>
                        <InfoItemValue>{item.communication_mailing.token}</InfoItemValue>
                      </InfoItem>
                      <InfoItem>
                        <InfoItemLabel>Статус доставки:</InfoItemLabel>
                        <InfoItemValue>{COMMUNICATION_STATUS[item.status]}</InfoItemValue>
                      </InfoItem>
                      <InfoItem>
                        <InfoItemLabel>Текст рассылки:</InfoItemLabel>
                        <InfoItemValue>{item.templateWithVariables}</InfoItemValue>
                      </InfoItem>
                    </CommunicationBlock>
                  ))}
                  {countCommunication <= voxInformationPatientStore.communications.length && (
                    <Button variant={"outlined"} sx={{mt: 1}} onClick={() => setCountCommunication(countCommunication + 5)}>Загрузить еще</Button>
                  )}
                </InfoBlockContainer>
              )}
            </TabPanel>
            <TabPanel value="3">
              {voxInformationPatientStore.scheduleFuture.length > 0 && (
                <InfoBlockContainer title="Записи пациента" contentBacklight>
                  {voxInformationPatientStore.scheduleFuture.slice(0, countScheduleFuture).map((item) => (
                    <CommunicationBlock key={item.id}>
                      <InfoItem>
                        <InfoItemLabel>Дата записи:</InfoItemLabel>
                        <InfoItemValue>{dayjs(item.datetime).format(READABLE_DATE_TIME_FORMAT)}</InfoItemValue>
                      </InfoItem>
                      <InfoItem>
                        <InfoItemLabel>Организация:</InfoItemLabel>
                        <InfoItemValue>{item.organization.name}</InfoItemValue>
                      </InfoItem>
                      <InfoItem>
                        <InfoItemLabel>Доктор:</InfoItemLabel>
                        <InfoItemValue>{item.doctor_organization.doctor.last_name} {item.doctor_organization.doctor.first_name} {item.doctor_organization.doctor.middle_name}</InfoItemValue>
                      </InfoItem>
                      <InfoItem>
                        <InfoItemLabel>Специализации врача:</InfoItemLabel>
                        <InfoItemValue>{item.doctor_organization.doctor.specialities.map((speciality) => speciality.name).join(', ')}</InfoItemValue>
                      </InfoItem>
                      { item.online_link_for_patient && (
                        <InfoItem>
                          <InfoItemLabel>Ссылка на онлайн звонок:</InfoItemLabel>
                          <InfoItemValue>{item.online_link_for_patient}</InfoItemValue>
                        </InfoItem>
                      )}
                      <InfoItem>
                        <InfoItemLabel>Тип приема:</InfoItemLabel>
                        <InfoItemValue>{item.type}</InfoItemValue>
                      </InfoItem>
                    </CommunicationBlock>
                  ))}
                  {countScheduleFuture <= voxInformationPatientStore.scheduleFuture.length && (
                    <Button variant={"outlined"} sx={{mt: 1}} onClick={() => setCountScheduleFuture(countScheduleFuture + 5)}>Загрузить еще</Button>
                  )}
                </InfoBlockContainer>
              )}
            </TabPanel>
          </TabContext>
        </Box>
      ) : (
        <Box sx={{ margin: 2 }}>
          <DynamicForm
            textButton="Сохранить"
            submitHandler={submitHandler}
            data={defaultData}
            fields={fields}
            hiddenButtonCancel={true}
          />
        </Box>
      )}
    </VoxInformationPatientPage>
  )
})
