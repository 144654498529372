import { makeAutoObservable } from 'mobx';
import moment from 'moment';
import apiv2 from '../apiv2';
import {
  currentUser as eff_currentUser,
  getCurrentUserEffect as eff_getCurrentUserEffect,
  setToken as eff_setToken,
  tokenStore as eff_tokenStore
} from './auth';
import { isValidNumberFunction } from "../components/form-elements/PhoneInputWithIcon";
import { reInitVoximplant, setVoximplantSupportData } from "../utils/voximplantSupport";
import { getLastNameWithInitials } from "../domain/services/doctor";
import React from "react";
import { useToast } from "../components/toast/useToast";

class AuthStore {
  token = null;
  refreshToken = null;
  user = null;
  nextSendTimeMoment = null;
  _nextSendTimeInterval = null;
  secondsToNextSend = 0;
  isSendButtonActive = false;
  isCodeRequested = false;
  fieldErrors = {
    phone: '',
    code: '',
  }

  constructor() {
    makeAutoObservable(this);
    this.setUser = this.setUser.bind(this);
    this.setToken = this.setToken.bind(this);
    this.cleanFieldError = this.cleanFieldError.bind(this);
    this.resetState = this.resetState.bind(this);

    eff_currentUser.watch(this.setUser);
    eff_tokenStore.watch(this.setToken); // Подписываемся на изменения токена в старой сторе
  }

  setUser(newUser) {
    this.user = newUser;
  }

  updateUser(data) {
    this.user = { ...this.user, ...data };
  }

  setToken(newToken) {
    this.token = newToken;
    if (newToken === null) {
      this.resetState();
    }
  }

  resetState() {
    this._clearNextSendTimeInterval();
    this.token = null;
    this.refreshToken = null;
    this.user = null;
    this.nextSendTimeMoment = null;
    this.secondsToNextSend = 0;
    this.isSendButtonActive = false;
    this.isCodeRequested = false;
    this.fieldErrors = {
      phone: '',
      code: '',
    };
  }

  _clearNextSendTimeInterval() {
    if (this._nextSendTimeInterval) {
      clearInterval(this._nextSendTimeInterval);
    }
    this._nextSendTimeInterval = null;
  }

  _recalcNextSendTime() {
    if (!this.nextSendTimeMoment) {
      return;
    }

    this.secondsToNextSend = this.nextSendTimeMoment.diff(moment(), 'seconds');

    if (this.secondsToNextSend > 0 && !this._nextSendTimeInterval) {
      this._nextSendTimeInterval = setInterval(() => {
        this._recalcNextSendTime();
      }, 500);
    }

    if (this.secondsToNextSend <= 0) {
      this.isSendButtonActive = true;
      this._clearNextSendTimeInterval();
    }
  }

  _reinitNextSendTimer(timestamp) {
    this.nextSendTimeMoment = moment(timestamp);
    this._clearNextSendTimeInterval();
    this._recalcNextSendTime();
  }

  _validatePhoneNumber(phone) {
    const validationResult = isValidNumberFunction(phone);
    this.fieldErrors.phone = !validationResult;

    return validationResult;
  }

  _cleanupPhoneNumber(phone) {
    return phone.replace(/[^0-9]/gi, '');
  }

  cleanFieldError(field) {
    this.fieldErrors[field] = '';
  }

  *sendSms(phone) {
    if (!this._validatePhoneNumber(phone)) {
      return;
    }
    const cleanedPhone = this._cleanupPhoneNumber(phone);
    this.isSendButtonActive = false;

    try {
      const {next_send_time} = yield apiv2.auth.sendSms(cleanedPhone);

      this._reinitNextSendTimer(next_send_time);
      this.isCodeRequested = true;
    } catch(e) {
      switch(e.code) {
        case 400: {
          this.fieldErrors.phone = 'Неверный формат номера';
          return;
        }
        case 429: {
          this._reinitNextSendTimer(e.result.additional_info.next_send_time);
          this.isCodeRequested = true;
          return;
        }
        default: {
          console.error(e);
          return;
        }
      }
    }
  }

  *login(phone, code) {
    try {
      const cleanedPhone = this._cleanupPhoneNumber(phone);
      const {token, refresh_token} = yield apiv2.auth.login({phone: cleanedPhone, code});
      this.token = token;
      this.refreshToken = refresh_token;
      eff_setToken(this.token); // Устанавливаем токен для старой сторы.
      this.user = yield apiv2.auth.getCurrentUser();
      setVoximplantSupportData({
        client_id: this.user.doctor ? this.user.doctor.id : this.user.id,
        client_phone: this.user.username,
        client_display_name: this.user.doctor ? getLastNameWithInitials(this.user.doctor) : this.user.username,
      });
      if (window.VoxKitWidget) {
        reInitVoximplant();
      }
      this.isCodeRequested = false;
    } catch(e) {
      switch(e.code) {
        case 400: {
          this.fieldErrors.code = 'Неверный код';
          return;
        }
        default: {
          console.error(e);
          return;
        }
      }
    }
  }

  async editUser(data) {
    const toastManager = useToast();
    try {
      this.user = await apiv2.auth.editCurrentUserDoctor(data);
      await eff_getCurrentUserEffect({ token: this.token }); // Этот эффект получает, и устанавливает пользователя для старой сторы, через асинхронный реквест внутри. В конструкторе мы подписаны на ее изменение. Токен тут передается в реквест.
      toastManager.showToast(
        <div>
          Изменения сохранены
        </div>,
        {
          duration: 5000
        }
      );
      return this.user;
    } catch(e) {
      console.error(e);
      toastManager.showToast(
        <div>
          Произошла ошибка
        </div>,
        {
          duration: 5000
        }
      );
    }
  }
}

export default new AuthStore();
