// @flow
import React from "react";
import { CorporatePortalLayout } from "./components/layouts";
import { Redirect, Route, Switch } from "react-router-dom";
import { CorporateNewsPage } from "./pages/CorporateNewsPage";
import { CorporateDetailNewsPage } from "./pages/CorporateDetailNewsPage";
import { CorporateEmployeesPage } from "./pages/CorporateEmployeesPage";
import { CorporateDocumentsPage } from "./pages/CorporateDocumentsPage";
import { CorporateNewsLayout } from "./components/layouts/CorporateNewsLayout";
import { CorporateCompanyPage } from "./pages/CorporateCompanyPage";

export const CorporatePortal = () => {
  return (
    <CorporatePortalLayout>
      <Switch>
        <Redirect exact from="/corporate-portal" to="/corporate-portal/news" />
        <Route
          path="/corporate-portal/news"
          render={({ match: { path } }) => (
            <CorporateNewsLayout>
              <Switch>
                <Route exact path={path} component={CorporateNewsPage} />
                <Route exact path={`${path}/:id`} component={CorporateDetailNewsPage} />
              </Switch>
            </CorporateNewsLayout>
          )}
        />
        <Route exact path="/corporate-portal/employees" component={CorporateEmployeesPage} />
        <Route exact path="/corporate-portal/documents" component={CorporateDocumentsPage} />
        <Route exact path="/corporate-portal/company" component={CorporateCompanyPage} />
      </Switch>
    </CorporatePortalLayout>
  );
};
