import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { observer } from "mobx-react";
import corporateEmployeesStore from "../stores/corporateEmployeesStore";
import React, { useMemo } from "react";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { dataOfDoctors } from "../utils/employees/generatedEmployeesData";
import { Avatar as MuiAvatar, Paper, Stack, useMediaQuery } from "@mui/material";
import { CorporateSubTitle } from "../styled/CorporateTypography.styled";
import { styled } from "@mui/material/styles";
import { scrollbars } from "../../components/chats/style-elements";
import { breakpoints, corporateTextPalette } from "../styled/common";
import { getFullNameWithInitials } from "../../domain/services/doctor";
import { colors } from "../../components/styleguide/colors";

const Container = styled(Paper)(
  () =>
    `
      box-sizing: border-box;
      border-top: 1px solid ${colors.concrete};
      
      .MuiTableContainer-root {
        ${scrollbars};
        &::-webkit-scrollbar {
          border-left: none;
        }
      }
    `
);

const Avatar = styled(MuiAvatar)`
  width: 38px;
  height: 38px;
  font-size: 0.9rem;
  text-transform: uppercase;
  ${breakpoints.desktop} {
    width: 30px;
    height: 30px;
    font-size: 0.8rem;
  }
  ${breakpoints.tablet} {
    width: 25px;
    height: 25px;
    font-size: 0.7rem;
  }
`;

export const CorporateMaterialTable = observer(({ tableData }) => {
  const isDownDesktop = useMediaQuery(breakpoints.desktop);
  const isDownTablet = useMediaQuery(breakpoints.tablet);

  const data = useMemo(() => {
    return tableData.map(item => {
      return {
        id: item.id,
        doctorPhoto: item.photo,
        fullName: item.fullName,
        lastName: item.contact_information.last_name,
        firstName: item.contact_information.first_name,
        middleName: item.contact_information.middle_name,
        subdivision: item.working_information.subdivision,
        typeMedicalStaff: item.working_information.type_medical_staff,
        position: item.working_information.position,
        specialization: item.working_information.specialization
      };
    });
  }, [tableData]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "fullName",
        header: "ФИО",
        size: isDownTablet ? 120 : isDownDesktop ? 150 : 280,
        Cell: ({ row }) => (
          <Stack direction="row" spacing={1} alignItems="center">
            <Avatar src={row.original.doctorPhoto}>
              {row.original.lastName.slice(0, 1) + row.original.firstName.slice(0, 1)}
            </Avatar>
            <CorporateSubTitle modifiers={"md"}>
              {isDownDesktop
                ? getFullNameWithInitials(row.original.fullName)
                : row.original.fullName}
            </CorporateSubTitle>
          </Stack>
        )
      },
      {
        accessorKey: "subdivision",
        header: "Подразделение",
        size: isDownTablet ? 180 : isDownDesktop ? 210 : 240,
        Cell: ({ row }) => (
          <CorporateSubTitle modifiers={"md"}>{row.original.subdivision}</CorporateSubTitle>
        )
      },
      {
        accessorKey: "typeMedicalStaff",
        header: "Тип мед персонала",
        size: isDownTablet ? 180 : isDownDesktop ? 210 : 240,
        Cell: ({ row }) => (
          <CorporateSubTitle modifiers={"md"}>{row.original.typeMedicalStaff}</CorporateSubTitle>
        )
      },
      {
        accessorKey: "position",
        header: "Должность",
        size: isDownTablet ? 180 : isDownDesktop ? 210 : 240,
        Cell: ({ row }) => (
          <CorporateSubTitle modifiers={"md"}>{row.original.position}</CorporateSubTitle>
        )
      },
      {
        accessorKey: "specialization",
        header: "Направление деятельности",
        size: isDownTablet ? 180 : isDownDesktop ? 210 : 240,
        Cell: ({ row }) => (
          <CorporateSubTitle modifiers={"md"}>{row.original.specialization}</CorporateSubTitle>
        )
      }
    ],
    [isDownDesktop, isDownTablet]
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableFullScreenToggle: false,
    enablePagination: false,
    enableColumnActions: false,
    enableHiding: false,
    enableDensityToggle: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableStickyHeader: true,
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        height: "100%"
      }
    },
    muiTableContainerProps: () => ({
      sx: {
        boxShadow: "none"
        // height: "100%"
      }
    }),
    muiTableHeadRowProps: () => ({
      sx: {
        ".Mui-TableHeadCell-Content-Wrapper": {
          fontSize: "14px",
          [`${breakpoints.desktop}`]: {
            fontSize: "12px"
          },
          [`${breakpoints.tablet}`]: {
            fontSize: "10px"
          }
        }
      }
    }),
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        ":hover span": {
          color: corporateTextPalette.pink
        }
      },
      onClick: () => corporateEmployeesStore.openProfileEmployee(row.original.id)
    }),
    muiTableBodyProps: {
      sx: {
        "& tr": {
          cursor: "pointer",
          "&:hover > td::after": {
            backgroundColor: "rgba(216, 216, 216, 0.15)"
          }
        }
      }
    },
    layoutMode: "grid",
    localization: {
      ...MRT_Localization_RU,
      noRecordsToDisplay: "Данные по сотрудникам не найдены"
    },
    isMultiSortEvent: () => true
  });

  return (
    <Container elevation={0} square>
      <MaterialReactTable table={table} />
    </Container>
  );
});
