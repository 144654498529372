// @flow
import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";
import parsePhoneNumber from 'libphonenumber-js'

import type { Patient } from "../../domain/entities/Patient";
import { getPatientFullName, getAgeByBirthDate, getMonthAgeByBirthDate } from "../../domain/services/patient";
import { getNounEndingByNumber } from "../../utils/getNounEndingByNumber";
import {HeadingH3, HeadingH4, HeadingH6, PNormal, PSmall, PXSmall} from "../styleguide/typography";
import PatientImg from "./user-placeholder.svg";
import PatientImgDMS from "./user-placeholder-dms.svg";
import { READABLE_DATE_FORMAT } from "../../domain/services/field";
import { colors } from "../styleguide/colors";
import {CommentsModal} from "../decision-support/CommentsModal";
import {ModalPortal} from "../modal/ModalPortal";
import {CommentsModalProvider} from "../decision-support/CommentsModalProvider";
import {ModalWithCloseButtonLayout} from "../modal/ModalWithCloseButtonLayout";
import {getPolicyDescription} from "../services/api/services";
import headerStore from "../../stores/headerStore";
import { SERVER_DATE_FORMAT } from "../../utils/dates";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Avatar = styled.div`
  width: 43px;
  height: 43px;
  margin-left: 17px;
  background: url(${props => props.isDMS ? PatientImgDMS : PatientImg}) no-repeat;
  background-size: cover;
  border-radius: 100%;

  flex-shrink: 0;
`;

const Name = styled(HeadingH6)`
  margin-bottom: 2px;
  cursor: pointer;
`;

const PatientDataText = styled(PSmall).attrs({
  modifiers: "1"
})`
  opacity: 0.6;
`;

const BadgeContainer = styled.div`
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    margin-top: 8px;
`;

const Badge = styled.span`
  display: inline-block;
  padding: 4px 8px;
  border: 1px solid ${props => props.color || colors.magenta};
  border-radius: 99em;
  color: ${props => props.color || colors.magenta};
  font-size: 11px;
`;

const ModalContainer = styled.div`
  max-height: 95vh;
  overflow: auto;
  width: 653px;
  padding: 30px 48px 48px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    width: auto;
    padding: 25px 30px 35px;
  }
  @media (max-width: 480px) {
    padding: 20px;
  }
`;

const PlaceholderPNormal = styled(PNormal)`
  color: ${colors.gray700};
`;

const PatientComment = styled(PNormal)`
  white-space: ${(props) => props.whiteSpace ? props.whiteSpace : 'pre-wrap'};
`;

const ModalSubtitle = styled(HeadingH4)`
  margin-bottom: 8px;
`;

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 40px;
`;

const AttachmentBottom = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${PXSmall} {
    opacity: 0.6;
  }
`;

const AttachmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const AttachmentItem = styled.div`
  ${props =>
    !props.disabled &&
    `
    cursor: pointer;
    
    &:hover ${PXSmall}, &:hover ${PNormal} {
      color: ${colors.pink};
    }
  `}
`;

const ContactsItem = styled.div`
  ${PXSmall} {
    opacity: 0.6;
  }
`;

export const AsidePatient = (props: { patient: Patient, badge?: string, checkup?: boolean }) => {
  const [policyDescriptions, setPolicyDescriptions] = useState([]);
  const age = getAgeByBirthDate(props.patient);
  const monthAge = age < 18 ? getMonthAgeByBirthDate(props.patient) : null;
  const birthdate = moment(props.patient.birthdate).format(READABLE_DATE_FORMAT);
  const currentDate = moment().format(SERVER_DATE_FORMAT);
  const isShowBadgeByData = (data) => data?.some((item) => currentDate <= moment(item.end_date).format(SERVER_DATE_FORMAT)) ?? false;

  const handleClickPolicy = policyDescription => {
    const win = window.open(
      "",
      "Title",
      "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=600"
    );
    win.document.body.innerHTML = policyDescription.description;
  };

  return (
      <CommentsModalProvider>
          {({isModalShow, showModal, hideModal}) => (
              <>
                <Container onClick={async () => {
                  setPolicyDescriptions(await getPolicyDescription(props.patient.id));
                  showModal();
                  headerStore.handlerOnCollapseDrawer(false);
                }}>
                  <div>
                    <Name>{getPatientFullName(props.patient)}</Name>
                    {age >= 0 ? (
                      <PatientDataText>{`${birthdate} (${age} ${getNounEndingByNumber(age, [
                        "год",
                        "года",
                        "лет"
                      ])}${monthAge !== null ? ` ${monthAge} ${getNounEndingByNumber(monthAge, [
                        "месяц",
                        "месяца",
                        "месяцев"
                      ])}` : ""})`}</PatientDataText>
                    ) : (
                      <PatientDataText>{birthdate}</PatientDataText>
                    )}
                    <BadgeContainer>
                      {isShowBadgeByData(props.patient?.policies) && <Badge color={colors.blue}>ДМС</Badge>}
                      {isShowBadgeByData(props.patient?.letters_guarantee) && <Badge>ГП</Badge>}
                      {props.badge ? <Badge>{props.badge}</Badge> : null}
                      {!!props.checkup && <Badge>Чекап</Badge>}
                    </BadgeContainer>
                  </div>
                  <Avatar isDMS={isShowBadgeByData(props.patient?.policies)}/>
                    <ModalPortal>
                      <CommentsModal isShow={isModalShow} hide={hideModal} handleClickOutside={hideModal}>
                        <ModalWithCloseButtonLayout handleClose={hideModal}>
                          <ModalContainer>
                            <HeadingH3>Информация о пациенте</HeadingH3>

                            <ModalContentWrapper>
                              <div>
                                <ModalSubtitle>Контакты</ModalSubtitle>

                                {!props.patient.phone_number && !props.patient.email && !props.patient.relative_phones?.length && (
                                    <PlaceholderPNormal modifiers="left">Нет контактов</PlaceholderPNormal>
                                )}

                                {(!!props.patient.phone_number || !!props.patient.email) && (
                                  <ContactsItem>
                                    <PNormal modifiers="left">
                                      {parsePhoneNumber(props.patient.phone_number, 'RU')?.formatInternational() || props.patient.phone_number}
                                      {!!props.patient.email && !!props.patient.phone_number && ' | '}
                                      {!!props.patient.email && props.patient.email}
                                    </PNormal>
                                    <PXSmall modifiers="left">
                                      пациент, {getPatientFullName(props.patient)}
                                    </PXSmall>
                                  </ContactsItem>
                                )}

                                {!!props.patient.relative_phones && props.patient.relative_phones.map((relative, idx) => (
                                  <ContactsItem key={idx}>
                                    <PNormal modifiers="left">
                                      {parsePhoneNumber(relative.phone_number, 'RU')?.formatInternational() || relative.phone_number}
                                    </PNormal>
                                    <PXSmall modifiers="left">
                                      {relative.role}, {relative.full_name}
                                    </PXSmall>
                                  </ContactsItem>
                                ))}
                              </div>
                              <div>
                                <ModalSubtitle>Прикрепления</ModalSubtitle>

                                {!!props.patient.policies && props.patient.policies.length ? (
                                  <AttachmentWrapper>
                                    {props.patient.policies.map((policy, index) => {
                                      const policyDescription = policyDescriptions.find(
                                        pd =>
                                          pd.org_name === policy.org_name &&
                                          pd.program_name === policy.program_name
                                      );

                                      return (
                                        <AttachmentItem
                                            key={index}
                                            onClick={() => handleClickPolicy(policyDescription)}
                                            disabled={!policyDescription}>
                                          <PNormal modifiers="left">
                                            {policy.number} {policy.program_name}
                                          </PNormal>
                                          <AttachmentBottom>
                                            <PXSmall modifiers="left">
                                              до {moment(policy.end_date).format("DD.MM.YYYY")}
                                            </PXSmall>
                                            <PXSmall modifiers="left">{policy.org_name}</PXSmall>
                                          </AttachmentBottom>
                                        </AttachmentItem>
                                      );
                                    })}
                                  </AttachmentWrapper>
                                ) : (
                                  <PlaceholderPNormal modifiers="left">Нет прикреплений</PlaceholderPNormal>
                                )}
                              </div>
                              <div>
                                <ModalSubtitle>Примечание</ModalSubtitle>
                                {props.patient.comment ? (
                                  <PatientComment modifiers="left">{props.patient.comment}</PatientComment>
                                ) : (
                                  <PlaceholderPNormal modifiers="left">Нет примечаний</PlaceholderPNormal>
                                )}
                              </div>
                              <div>
                                <ModalSubtitle>Комментарий по долгам</ModalSubtitle>
                                {props.patient.comment_dms ? (
                                  <PatientComment modifiers="left">{props.patient.comment_dms}</PatientComment>
                                ) : (
                                  <PlaceholderPNormal modifiers="left">
                                    Нет комментариев по долгам
                                  </PlaceholderPNormal>
                                )}
                              </div>
                            </ModalContentWrapper>
                          </ModalContainer>
                        </ModalWithCloseButtonLayout>
                      </CommentsModal>
                    </ModalPortal>
                </Container>
              </>
          )}
      </CommentsModalProvider>
  );
};
