import news1 from "../../../assets/image/corporate-portal/news1.jpg";
import news2 from "../../../assets/image/corporate-portal/news2.jpg";
import news3 from "../../../assets/image/corporate-portal/news3.jpg";
import news4 from "../../../assets/image/corporate-portal/news4.jpg";
import emptyNews from "../../../assets/image/corporate-portal/emptyNews.png";
import avatar1 from "../../../assets/image/corporate-portal/photosDoctor/photo1.png";
import avatar2 from "../../../assets/image/corporate-portal/photosDoctor/photo2.png";
import avatar3 from "../../../assets/image/corporate-portal/photosDoctor/photo3.png";
import avatar4 from "../../../assets/image/corporate-portal/photosDoctor/photo4.png";
import _ from "lodash";
import * as moment from "moment";
import { READABLE_DATE_FORMAT, READABLE_TIME_FORMAT_MOMENT } from "../../../utils/dates";

export const newsCategories = [
  { id: 1, name: "Все новости" },
  { id: 2, name: "Гинекология" },
  { id: 3, name: "Беременность" },
  { id: 4, name: "Клиника" },
  { id: 5, name: "Здоровье" },
  { id: 6, name: "Вредные привычки" },
  { id: 7, name: "Репродуктология" },
  { id: 8, name: "Воспитание детей" },
  { id: 9, name: "Профилактика" },
  { id: 10, name: "Аллергия" }
];

const names = [
  "История одной болезни",
  "Борьба за жизнь: медицина на передовой",
  "Чудеса современной медицины",
  "Врачи-герои нашего времени",
  "Путь к выздоровлению: медицинские истории успеха",
  "Тайны медицинской науки",
  "Медицина будущего: новые технологии и перспективы",
  "Медицинские открытия, изменившие мир",
  "В поисках лекарства от неизлечимого",
  "Жизнь в руках врачей: истории из практики",
  "Врачи-исследователи: как они совершают открытия",
  "Революционные методы лечения: от прошлого к будущему"
];

const userNames = [
  "Анна Смирнова",
  "Николай Файрузов",
  "Дмитрий Михайлов",
  "Олеся Кутузова",
];

const textComments = [
  "Поздравляю!",
  "Круто, так держать!",
  "Классная новость!",
  "Очень инетересно было почитать",
];

export const generatedNewsData = new Array(12).fill().map((value, index) => {
  // ToDo доработать на генерацию новостей под категории feel-6625
  const order = index + 1;
  const randomCategory = newsCategories[Math.floor(Math.random() * 9) + 1];
  const uniqueId = _.uniqueId();
  const randomDate = moment()
    .add(Math.floor(Math.random() * 365), "days")
    .format(READABLE_DATE_FORMAT);
  const randomTime = moment()
    .hour(Math.floor(Math.random() * 24))
    .minute(Math.floor(Math.random() * 60))
    .format(READABLE_TIME_FORMAT_MOMENT);
  const randomValueCounter = () => Math.floor(Math.random() * 100 * order);

  return {
    category: randomCategory.name,
    id: uniqueId,
    src:
      order % 2 === 0
        ? news1
        : order % 3 === 0
        ? news2
        : order % 4 === 0
        ? news3
        : order % 4 === 0
        ? news4
        : null,
    date: randomDate,
    time: randomTime,
    name: names[index],
    description:
      "Российские учёные разработали робота, который может проводить дезинфекцию помещений. Устройство оснащено системой навигации и датчиками для обнаружения патогенов. Робот способен передвигаться по заданному маршруту и автоматически определять зоны, требующие обработки. Разработка может найти применение в медицинских учреждениях, на предприятиях пищевой промышленности и в других местах, где требуется регулярная дезинфекция.",
    number_of_like: randomValueCounter(),
    is_news_is_liked: order % 3 === 0,
    number_of_view: randomValueCounter()
  };
});

export const generatedDetailedNewsData = new Array(12).fill().map((value, index) => {
  return ({
    id: generatedNewsData[index].id,
    template: `
      <img src="${generatedNewsData[index]?.src || emptyNews}" alt="News Image"/>
      <p>${generatedNewsData[index].description}</p>
      <h2>2 заголовок</h2>
      <p>Пара минут для настройки - и "Ультработ" выходит в бой с бактериями и вирусами. Работает по такому же принципу, как и кварцевание в больнице. Но для начала роботу задают маршрут.</p>
      <p>Студент Сколковского института науки и технологий Андрей Потапов отметил: "Карта отображается на дисплее, также её можно отобразить в ноутбуке при настройке. И во время того, когда он собрал карту, самостоятельно можем указывать точки, по которым он будет следовать на карте". </p>
      <p>Чтобы увеличить мощность излучения, разработчики использовали дальний ультрафиолетовый свет c длинной волны 254 нанометра и специальные отражатели.</p>
      <p>Собственный корреспондент CGTN-Русский Алиса Алексеева заявила: "Такие лампы убивают больше 95% бактерий и вирусов. Правда, когда они включены, человеку рядом лучше не находиться. А очки - для защиты от ожогов роговицы". </p>
      <p>Но если вдруг человек рядом, робот не навредит. Отключит лампы, если увидит объект в зоне поражения. При этом дезинфекция продолжится - с помощью других, безопасных для здоровья очистителей. "Ультработ" работает автономно и, в отличие от аналогов, умеет объезжать движущиеся препятствия и точно позиционироваться на больших расстояниях.</p>
      <p>Стажёр-исследователь лаборатории Сколковского института науки и технологий Екатерина Карманова сказала: "У нас на работе сверху установлены 3 3D-камеры, 2 из которых используются для объезда препятствий, и одна - для определения человека в зоне поражения ультрафиолетового излучения".</p>
      <p>Разрабатывать робота в Сколтехе решили сразу, как началась пандемия.</p>
      <p>"Мы посмотрели на существующие решения, которые есть для борьбы с пандемией, особенно робототехнические решения, и поняли, что их категорически недостаточно", - отметил аспирант лаборатории робототехники Сколковского института науки и технологий Павел Карпышев, - "Естественно, мы использовали часть наработок с наших предыдущих проектов, но в общем я бы сказал, что на разработку ПО и архитектуры ушло где-то 1,5 года". </p>
      <p>И теперь это самое развивающееся направление в разработке автономных роботов. Сейчас компаний, которые выпускают аналоги "Ультработа", уже больше 50. В Сколтехе ждут сертификации изобретения. </p>
      <p>"Существует огромный интерес со стороны больниц, со стороны ритейла, со стороны компаний, управляющих складами, и мы надеемся, что мы преодолеем все ограничения и сможем помочь стране в ситуации с ковидом, а также сделать удачный коммерческий продукт", - заявил Дмитрий Тетерюков, руководитель лаборатории робототехники Сколковского института науки и технологий.</p>
      <p>"Ультработ" уже успешно прошёл несколько тестовых испытаний с потенциальными заказчиками. В ближайшее время таких дезинфекторов можно будет встретить в самых разных заведениях. </p>
    `
  });
});

export const generatedNewsComments = new Array(4).fill().map((value, index) => {
  const uniqueId = _.uniqueId();
  const randomDate = moment()
    .add(Math.floor(Math.random() * 365), "days")
    .format(READABLE_DATE_FORMAT);
  const randomTime = moment()
    .hour(Math.floor(Math.random() * 24))
    .minute(Math.floor(Math.random() * 60))
    .format(READABLE_TIME_FORMAT_MOMENT);

  return ({
    id: uniqueId,
    sender: {
      avatar:  index === 0
        ? avatar1
        : index === 1
          ? avatar2
          : index === 2
            ? avatar3
            : index === 3
              ? avatar4
              : null,
      name: userNames[index]
    },
    date: randomDate,
    time: randomTime,
    text: textComments[index]
  });
});
