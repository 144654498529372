import React, { useEffect, useState, useMemo, useCallback, Fragment } from "react";
import { keyBy } from "lodash";
import useReactRouter from "use-react-router";
import moment from "moment";
import styled from "styled-components";
import * as R from "ramda";
import { useStore } from "effector-react";
import { rgba } from "polished";

import { formatFileDateToYearMonth } from "../../domain/services/uploadedFile";
import { ModalPortal } from "../modal/ModalPortal";
import { useFileEdit } from "../file-edit-modal/useFileEdit";
import { AnalyzesFormLayout } from "./AnalyzesFormLayout";
import { AnalyzesPreloader } from "./AnalyzesPreloader";
import { HeadingH2 } from "../styleguide/typography";
import { useFiles } from "./useFiles";
import { FileUploadButton } from "../file/FileUploadButton";
import { RoundedRectangleWithShadow } from "../styleguide/RoundedRectangleWithShadow";
import { FilesFieldset } from "../form/FilesFieldset";
import { TextView } from "../form/TextView";
import { ButtonRadioGroup } from "../form-elements/ButtonRadioGroup";
import { tokenStore } from "../../stores/auth";
import { colors } from "../styleguide/colors";
import { setVoximplantSupportData } from "../../utils/voximplantSupport";
import apiv2 from "../../apiv2";

export const TAB_TYPE = {
  ANALYSIS: "analysis",
  DOCUMENT: "document",
  DOCUMENT_FDOC: "documentFDOC"
};

const groupByProp = prop => array => {
  const byMonth = () => array => formatFileDateToYearMonth(R.view(R.lensProp(prop), array));
  return R.groupBy(byMonth())(array);
};

const AnalyzesContainer = styled(RoundedRectangleWithShadow)`
  width: 100%;
  height: fit-content;
  padding: 23px 32px 0 32px;
  @media (max-width: 768px) {
    padding: 20px;
  }
  @media (max-width: 480px) {
    padding: 15px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
  @media (max-width: 768px) {
    padding-bottom: 20px;
  }
  @media (max-width: 480px) {
    padding-bottom: 15px;
  }
`;

const MonthSectionName = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;

  & span {
    font-size: 17px;
  }
`;

const MonthSectionChildren = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 36px;
  border-top: 1px solid ${colors.gray150};
`;

const FieldContainer = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    min-width: fit-content;
  }
`;

const NoAnalyses = styled.p`
  color: ${rgba(colors.transparentGray, 1)};
  font-size: 22px;
  text-align: center;
  margin: 61px 0 48px;
`;

const getDate = value => {
  const date = moment(value).format("MMMM, YYYY");
  return date ? [date[0].toUpperCase(), date.slice(1)].join("") : "";
};

export const groupFilesByMonth = files => {
  const filesDates = groupByProp("date")(files);
  const dates = R.reverse(R.sortBy(date => date, R.uniq(R.keys(filesDates))));

  const getFilesByDate = date => filesDates[date] || [];

  const getSortedFiles = date =>
    R.sortWith([R.descend(R.prop("date")), R.ascend(R.prop("name"))], getFilesByDate(date));

  return R.map(date => {
    return {
      date: date,
      files: [...getSortedFiles(date)]
    };
  }, dates);
};

const tabs = [
  { type: TAB_TYPE.ANALYSIS, name: "Анализы" },
  { type: TAB_TYPE.DOCUMENT, name: "Документы" },
  { type: TAB_TYPE.DOCUMENT_FDOC, name: "Документы FDOC" }
];

const tabObjects = keyBy(tabs, "name");

export const AnalyzesPage = () => {
  const token = useStore(tokenStore);
  const {
    match: { params },
    history
  } = useReactRouter();
  const medicalFileId = parseInt(params.medical_file_id);
  const { uploads, analyzes, uploadsFDOC, isLoading, patient, patientName } = useFiles(medicalFileId);
  const { fileEditModal, editFile, lastEditedFile, lastRemovedFile } = useFileEdit();

  const [activeTab, setActiveTab] = useState(
    params.type ? tabs.find(tab => tab.type === params.type) : tabs[0]
  );
  const [listOfAnalysis, setListOfAnalysis] = useState([]);
  const [listOfDocuments, setListOfDocuments] = useState([]);
  const [listOfDocumentsFDOC, setListOfDocumentsFDOC] = useState([]);
  const setterByTab = {
    analysis: setListOfAnalysis,
    document: setListOfDocuments,
    documentFDOC: setListOfDocumentsFDOC
  };

  const handleChangePage = useCallback(
    value => {
      if (value) {
        const tab = tabs.find(tab => tab.name === value);
        setActiveTab(tab);
        history.push(`/appointment/analyzes/${medicalFileId}/${tabObjects[value].type}`);
      }
    },
    [history, medicalFileId]
  );

  const onAddFile = useCallback(
    async newFiles => {
      if (!token || !patient) {
        return;
      }
      const setFiles = setterByTab[activeTab.type];
      const uploadedFiles = await Promise.all(
        Object.values(newFiles).map(async file => {
          if (!(file instanceof File)) {
            return;
          }
          try {
            const result = await apiv2.files.postUploadedFile({
              uploadOptions: { file: file, original_name: file.name || "" },
              patient,
              type: activeTab.type
            });
            return result;
          } catch (e) {
            return null;
          }
        })
      );
      setFiles(prevUploads => [...uploadedFiles.filter(Boolean), ...prevUploads]);
    },
    [patient, token, activeTab]
  );

  useEffect(() => {
    if (uploads) {
      setListOfDocuments(uploads);
    }
    if (analyzes) {
      setListOfAnalysis(analyzes);
    }
    if (uploadsFDOC) {
      setListOfDocumentsFDOC(uploadsFDOC);
    }
  }, [uploads, analyzes, uploadsFDOC]);

  useEffect(() => {
    if (!lastEditedFile) {
      return;
    }
    const callback = ["analysis", "1c_analysis"].includes(lastEditedFile.type)
      ? setListOfAnalysis
      : setListOfDocuments;
    callback(files => files.map(file => (file.id === lastEditedFile.id ? lastEditedFile : file)));
  }, [lastEditedFile]);

  useEffect(() => {
    if (!lastRemovedFile) {
      return;
    }
    const callback = ["analysis", "1c_analysis"].includes(lastRemovedFile.type)
      ? setListOfAnalysis
      : setListOfDocuments;

    callback(files => files.filter(file => file.id !== lastRemovedFile.id));
  }, [lastRemovedFile]);

  useEffect(() => {
    const voximplantData = {
      "Patient ID": patient,
      "Patient name": patientName
    };
    if (patient && patientName) {
      setVoximplantSupportData(
        {
          client_email: voximplantData,
          ...window.VoxKitWidgetSettings.client_data
        },
        false,
        true
      );
    }

    return () => {
      setVoximplantSupportData(
        {
          client_email: {}
        },
        false,
        true
      );
    };
  }, [patient, patientName]);

  const groupedAnalyzes = useMemo(() => {
    return groupFilesByMonth(listOfAnalysis);
  }, [listOfAnalysis]);

  const groupedDocuments = useMemo(() => {
    return groupFilesByMonth(listOfDocuments);
  }, [listOfDocuments]);

  const groupedDocumentsFDOC = useMemo(() => {
    return groupFilesByMonth(listOfDocumentsFDOC);
  }, [listOfDocumentsFDOC]);

  const getDocumentsFields = () => {
    const config = {
      [TAB_TYPE.ANALYSIS]: {
        text: "анализа",
        files: groupedAnalyzes
      },
      [TAB_TYPE.DOCUMENT]: {
        text: "документа",
        files: groupedDocuments
      },
      [TAB_TYPE.DOCUMENT_FDOC]: {
        text: "документа FDOC",
        files: groupedDocumentsFDOC
      }
    };
    const files = config[activeTab.type]?.files || [];
    const text = config[activeTab.type]?.text || "";

    if (isLoading) {
      return <AnalyzesPreloader />;
    }
    if (!files.length) {
      return <NoAnalyses>{`У пациента нет ни одного ${text}`}</NoAnalyses>;
    }

    return files.map((month, index) => (
      <Fragment key={`${month}-${index}`}>
        <MonthSectionName>
          <TextView>{getDate(month.date)}</TextView>
        </MonthSectionName>
        <MonthSectionChildren>
          {month.files.map(file => (
            <FieldContainer key={file.date + file.id}>
              <FilesFieldset
                key={file.date}
                {...file}
                editable={activeTab.type !== TAB_TYPE.DOCUMENT_FDOC}
                onEdit={editFile}
              />
            </FieldContainer>
          ))}
        </MonthSectionChildren>
      </Fragment>
    ));
  };

  return (
    <Fragment>
      <AnalyzesFormLayout
        title={<HeadingH2>Анализы и документы</HeadingH2>}
        sections={
          <AnalyzesContainer>
            <HeaderContainer>
              <ButtonRadioGroup
                items={tabObjects}
                checked={activeTab.name}
                name="analyzes"
                shouldUncheck={false}
                onChange={handleChangePage}
              />
              <FileUploadButton onAddFile={onAddFile} />
            </HeaderContainer>
            {getDocumentsFields()}
          </AnalyzesContainer>
        }
      />
      <ModalPortal>{fileEditModal}</ModalPortal>
    </Fragment>
  );
};
