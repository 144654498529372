// @flow
import React, { useEffect } from "react";
import useReactRouter from "use-react-router";
import type { AppointmentWithDetails } from "../../domain/entities/Appointment";
import type { LayoutDetailed } from "../../domain/entities/Layout";
import type { UltrasoundScan } from "../../domain/entities/UltrasoundScan";
import { getPatientFullName } from "../../domain/services/patient";
import { useStore } from "effector-react";
import { tokenStore } from "../../stores/auth";
import { PNormal } from "../styleguide/typography";
import { formatAppointmentDateToDateTimeWithWords } from "../../domain/services/appointments";
import { getLastNameWithInitials } from "../../domain/services/doctor";
import { PrintButton } from "./PrintButton";
import { PrintButtonDropDown } from "./PrintButtonDropDown";
import type { Field, FieldSingle, FieldBodyMassIndex } from "../../domain/entities/Field";
import {
  fieldIsEmpty,
  getFieldSingleTextValue,
  isFieldWithoutLabel,
  isLabelNeededAtGroup,
  sectionIsEmpty,
  formatDateToReadableDateFormat
} from "../../domain/services/field";
import { Fieldset, Legend, Container } from "../form/Fieldset";
import { AppointmentFormLayout } from "./AppointmentFormLayout";
import styled, { css } from "styled-components";
import _ from "lodash";
import { TextView } from "../form/TextView";
import { AlwaysExpandedFormSection } from "../form/AlwaysExpandedFormSection";
import { FileGroup } from "../file/FileGroup";
import { UltrasoundScanFile } from "../file/UltrasoundScanFile";
import { TextareaView } from "../form/TextareaView";
import { EditButton } from "./EditButton";
import { CopyButton } from "./CopyButton";
import { getAppointment_id } from "../../domain/entities/Appointment";
import { PatientAppointmentsRefreshTrigger } from "../appointment-switch-page/PatientAppointmentsRefreshTrigger";
import { HistoryTable } from "../history-table/HistoryTable";
import { ReadablePrescriptionTable } from "./ReadablePrescriptionTable";
import { getPrescriptionFromSection } from "../../domain/services/prescription";
import { ButtonFormSection } from "../appointment-page/ButtonFormSection";
import { ScrollDetector } from "../scroll-detector/ScrollDetector";
import { ReadableAppointmentDiagnoses } from "../decision-support/ReadableAppointmentDiagnoses";
import { ReadonlyDecisionSupportFormSection } from "../decision-support/ReadonlyDecisionSupportFormSection";
import { withoutUnchecked, withoutUncheckedGroups } from "../../domain/services/checklist";
import { ReadableChecklistList } from "../decision-support/ReadableChecklistList";
import { ReadableGroupedChecklistList } from "../decision-support/ReadableGroupedChecklistList";
import { ReadableChecklistItem } from "../decision-support/ReadableChecklistItem";
import { ChecklistWithCommaSeparatedChildrenInParentheses } from "../decision-support/ChecklistWithCommaSeparatedChildrenInParentheses";
import { ReadableChecklistContentWithCommentFormatted } from "../decision-support/ReadableChecklistContentWithCommentFormatted";

import type { BodyMassIndex } from "../../domain/value-objects/BodyMassIndex";
import { getLayout_id } from "../../domain/entities/Layout";
import { Feedback } from "../feedback/Feedback";
import type { MedicalFile } from "../../domain/entities/MedicalFile";
import { LiveBirthProbability } from "./LiveBirthProbability";
import { ReadableServicesList } from "../services/ReadableServicesList";
import {getTableFromSection, Table} from '../table/Table';
import {ServicesSuggest} from "../services/ServicesSuggest";
import {RightSidebarPortal} from "../right-sidebar/RightSidebarPortal";
import {SidebarContainer} from "../sidebar/SidebarContainer";
import {
  openPrintDocumentsSidebar,
  PRINT_DOCUMENTS, rightSidebarState,
} from "../../stores/rightSidebar";
import {ScrollToTopButton} from "../buttons/ScrollToTopButton";
import {PrintDocumentsFixedLabel} from "../print-documents/PrintDocumentsFixedLabel";
import {applicationFeatureEnabled, FEATURE, useApplicationFeatureValue} from "../../utils/applicationFeatures";
import { InfoBlock } from "../common/InfoBlock";
import EditIcon from "./icons/edit.inline.svg";
import { DocumentsTree } from "../documents-tree/DocumentsTree";
import {PrimaryButton} from "../buttons/PrimaryButton";
import appointmentCreateStore from "../../stores/AppointmentCreateStore";
import popularLayoutsStore from '../../stores/popularLayoutsStore';
import {observer} from 'mobx-react';
import apiv2 from '../../apiv2';
import { AppointmentTitle } from "./AppointmentTitle";

const ButtonContainer = styled.div`
  margin-bottom: 30px;
  margin-top: 30px;
`;

const MetaInfoText = styled(PNormal)`
  opacity: 0.6;
  & + & {
    margin-left: 30px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 15px;
  }
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
  @media screen and (max-width: 480px) {
    font-size: 12px;
  }
`;

const MetaInfoContainer = styled.div`
  display: flex;
  gap: 30px;

  @media screen and (max-width: 1024px) {
    gap: 10px;
    justify-content: space-between;
    width: 100%;
  }
`;

const SectionsContainer = styled.div`
  > * + * {
    margin-top: 27px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const FieldsetContainer = styled.div`
  & + & {
    margin-top: 15px;
  }
`;

const FieldsetLegendCommon = styled(Legend)`
  max-width: 300px;
`;

const FieldsetLegendContainer = styled(Container)`
  min-height: 23px;
`;

const FieldsetServicesLegendContainer = styled(Container)`
  min-height: 43px;
`;

const ChildFieldContainer = styled.span`
  & + & {
    :before {
      content: ", ";
    }
  }

  ${p =>
    p.break &&
    css`
      & + & {
        :before {
          content: "";
          display: block;
        }
      }
    `}
`;

const ButtonTooltip = styled.div`
  width: 175px;
  font-size: 11px;
  line-height: 14px;
  color: #454545;
`;

const ReadableBodyMassIndexField = (props: { legend: string, value: ?number }) => {
  return (
    <FieldsetContainer>
      <Fieldset
        overrides={{
          Container: {
            component: FieldsetLegendContainer
          },
          Legend: {
            component: FieldsetLegendCommon
          }
        }}
        legend={props.legend}>
        <TextView>{(props.value && `${props.value}`) || "-"}</TextView>
      </Fieldset>
    </FieldsetContainer>
  );
};

const ReadableBodyMassIndex = (props: { field: FieldBodyMassIndex, value: BodyMassIndex }) => {
  const { field, value } = props;
  return (
    <>
      <ReadableBodyMassIndexField legend="Рост" value={value.height} />
      <ReadableBodyMassIndexField legend="Вес" value={value.weight} />
      <ReadableBodyMassIndexField legend={field.name} value={value.bmi} />
    </>
  );
};

export const ReadableAppointmentPage = observer((props: {
  appointment: AppointmentWithDetails,
  layout: LayoutDetailed,
  ultrasoundScans: ?Array<UltrasoundScan>,
  medicalFiles: Array<MedicalFile>
}) => {
  const { appointment, layout, ultrasoundScans } = props;
  const token = useStore(tokenStore);
  const questionnaireLayoutId = useApplicationFeatureValue(FEATURE.HC_QUESTIONNAIRE_LAYOUT_ID)

  const { history } = useReactRouter();

  const rightSidebar = useStore(rightSidebarState);

  const hasCorrectMedicalFiles = props.medicalFiles.length > 0;

  const fieldValuesMap = _.keyBy(appointment.layout_field_values, item => item.field);

  useEffect(() => {
    popularLayoutsStore.initialize();
  }, []);

  const metaInfo =
    (appointment && (
      <React.Fragment>
        <MetaInfoContainer>
          <MetaInfoText>{formatAppointmentDateToDateTimeWithWords(appointment)}</MetaInfoText>
          {appointment.medical_file ? (
            <MetaInfoText>
              АК №{appointment.medical_file.number} {appointment.medical_file.organization.name || ""}
            </MetaInfoText>
          ) : null}
          <MetaInfoText>{getLastNameWithInitials(appointment.doctor)}</MetaInfoText>
        </MetaInfoContainer>
      </React.Fragment>
    )) ||
    null;

  const onEditClick = async () => {
    try {
      await apiv2.appointments.reopenAppointment(getAppointment_id(appointment));
      PatientAppointmentsRefreshTrigger();
    } catch (e) {
      console.log(e);
    }
  };

  const onDuplicateClick = () => {
    appointmentCreateStore.changeCopyAppointment(appointment);
    history.push(`/appointment/new/${appointment.medical_file.id}`);
  };

  const menuItems = [
    { to: `/appointment/${appointment.id}/print/both`, title: "Обе версии" },
    { to: `/appointment/${appointment.id}/print/doctor`, title: "Только версию для врача" },
    { to: `/appointment/${appointment.id}/print/patient`, title: "Только версию для пациента" },
    { to: `/appointment/${appointment.id}/print/doctor?needEdit=true`, title: "Редактировать версию для врача" },
    { to: `/appointment/${appointment.id}/print/patient?needEdit=true`, title: "Редактировать версию для пациента" }
  ]

  const toolButtons = (
    <ButtonsContainer>
      {window.QUEST_MODE && appointment.date_finished && appointment.can_be_reopened && (
        <EditButton onClick={onEditClick} /* disabled={appointment.folder?.is_finished} */ />
      )}
      {!window.QUEST_MODE && (
        <>
          {!appointment.is_cancelled ? (
            <>
              {appointment.date_finished && appointment.can_be_reopened && (
                <EditButton onClick={onEditClick} /* disabled={appointment.folder?.is_finished} */ />
              )}
              {hasCorrectMedicalFiles ? (
                <CopyButton onClick={onDuplicateClick} />
              ) : (
                <CopyButton
                  tooltip={
                    <ButtonTooltip>Нет подходящей медкарты для дублирования приёма</ButtonTooltip>
                  }
                  disabled={true}
                />
              )}
            </>
          ) : null}
          <PrintButtonDropDown menuItems={menuItems} />
        </>
      )}
    </ButtonsContainer>
  );

  useEffect(() => {
    localStorage.setItem("checkedSections", "{}");
  }, []);

  const getWidgetSingle = (field: FieldSingle) => {
    if (
      field.field_type === "text" ||
      field.field_type === "date" ||
      field.field_type === "checkbox" ||
      field.field_type === "radiobutton" ||
      field.field_type === "number"
    ) {
      return <TextView>{getFieldSingleTextValue(field, fieldValuesMap[field.id]) || "—"}</TextView>;
    } else if (field.field_type === "textarea") {
      return (
        <TextareaView inline>
          {getFieldSingleTextValue(field, fieldValuesMap[field.id]) || "—"}
        </TextareaView>
      );
    } else if (field.field_type === "file") {
      return <FileGroup files={fieldValuesMap[field.id].files} />;
    } else if (field.field_type === "prescription") {
      return (
        fieldValuesMap[field.id].value instanceof Array && (
          <ReadablePrescriptionTable value={fieldValuesMap[field.id].value} />
        )
      );
    } else if (field.field_type === "formula") {
      return <TextView>{getFieldSingleTextValue(field, fieldValuesMap[field.id]) || "—"}</TextView>;
    } else if (field.field_type === "body_mass_index") {
      const fieldValue = fieldValuesMap[field.id].value;
      if (
        typeof fieldValue === "object" &&
        (fieldValue !== null &&
          (fieldValue.height || fieldValue.height === 0) &&
          (fieldValue.weight || fieldValue.weight === 0)) &&
        typeof fieldValue !== "string" &&
        typeof fieldValue !== "number" &&
        !(fieldValue instanceof Array)
      ) {
        return <ReadableBodyMassIndex field={field} value={fieldValue} />;
      } else return <></>;
    } else {
      (field.field_type: empty); // check that all field_type is considered
      return <div style={{ height: "50px" }}>Haven&quot;t widget for type {field.field_type}</div>;
    }
  };

  const getWidget = (field: Field) => {
    if (field.field_type === "group") {
      return (
        <PNormal modifiers={"left"}>
          {field.children.map(child => {
            if (fieldIsEmpty(child, fieldValuesMap)) {
              return null;
            }
            return (
              <ChildFieldContainer key={child.id} break={child.field_type === "textarea"}>
                {isLabelNeededAtGroup(child) && (
                  <React.Fragment>{child.name}:&nbsp;</React.Fragment>
                )}
                {getWidgetSingle(child)}
              </ChildFieldContainer>
            );
          })}
        </PNormal>
      );
    } else {
      return getWidgetSingle(field);
    }
  };
  const sections =
    (layout && (
      <SectionsContainer>
        { !appointment.date_finished && !appointment.can_be_reopened && (
          <InfoBlock text="Врач еще не закончил заполнение этого приема" icon={<EditIcon />} />
        )}
        {layout.sections.map(section => {
          const showLiveBirthProbability =
            section.for_live_birth_probability &&
            (appointment.eko_chance || appointment.eko_do_chance || appointment.vmi_chance);

          if (showLiveBirthProbability) {
            return (
              // $FlowFixMe
              <AlwaysExpandedFormSection
                {...section}
                showPrintCheckmark={true}
                printCheckmarkChecked={section.for_customer}>
                <LiveBirthProbability />
              </AlwaysExpandedFormSection>
            );
          }

          if (
            (!section.display_history || !appointment.is_latest) &&
            sectionIsEmpty(section, fieldValuesMap)
          ) {
            return null;
          }
          if (section.display_history && appointment.is_latest) {
            return (
              <ButtonFormSection
                key={section.id}
                name={`${section.name} (история)`}
                id={section.id.toString()}
                showPrintCheckmark={true}
                printCheckmarkChecked={section.for_customer}
                button={
                  <PrintButton to={`/appointment/${appointment.id}/${section.id}/print/history`} />
                }>
                <ScrollDetector>
                  <HistoryTable
                    withFixedColumn
                    section_id={section.id}
                    appointment_id={appointment.id}
                  />
                </ScrollDetector>
              </ButtonFormSection>
            );
          }
          const prescription = getPrescriptionFromSection(section);
          if (prescription && fieldValuesMap[prescription.id].value instanceof Array) {
            const printButton = (
              <PrintButton
                to={`/appointment/${appointment.id}/${section.id}/print/prescriptions`}
              />
            );
            return (
              <ButtonFormSection
                name="Таблица назначения препаратов"
                button={printButton}
                id={section.id.toString()}
                showPrintCheckmark={true}
                printCheckmarkChecked={section.for_customer}>
                <ReadablePrescriptionTable value={fieldValuesMap[prescription.id].value} />
              </ButtonFormSection>
            );
          }
          const table = getTableFromSection(section);
          if (table) {
            const printButton = (
              <PrintButton
                to={`/appointment/${appointment.id}/${section.id}/print/table`}
              />
            );
            return (
              <Table
                editable={false}
                button={printButton}
                appointment={appointment}
                table={table}
                fieldValue={fieldValuesMap[table.id]}
              />
            );
          }
          return (
            // $FlowFixMe
            <AlwaysExpandedFormSection
              {...section}
              key={section.id}
              id={section.id.toString()}
              showPrintCheckmark={true}
              printCheckmarkChecked={section.for_customer}>
              {section.fields.map(field => {
                if (fieldIsEmpty(field, fieldValuesMap)) {
                  return null;
                }
                const widget = getWidget(field);
                const isWithoutLabel = isFieldWithoutLabel(field);
                if (field.field_type === "body_mass_index") {
                  return widget;
                }
                return (
                  <FieldsetContainer key={field.id}>
                    {isWithoutLabel ? (
                      widget
                    ) : (
                      <Fieldset
                        overrides={{
                          Container: {
                            component: FieldsetLegendContainer
                          },
                          Legend: {
                            component: FieldsetLegendCommon,
                            props: {
                              cursorPointer: layout.id === questionnaireLayoutId && field.possible_values.length
                            }
                          }
                        }}
                        tooltipLegendText={layout.id === questionnaireLayoutId && field.possible_values.join(', ')}
                        legend={`${field.name}:`}>
                        {widget}
                      </Fieldset>
                    )}
                  </FieldsetContainer>
                );
              })}
            </AlwaysExpandedFormSection>
          );
        })}
        {appointment.primary_diagnoses &&
          appointment.secondary_diagnoses &&
          (appointment.primary_diagnoses.length > 0 ||
            appointment.secondary_diagnoses.length > 0) && (
            <ReadonlyDecisionSupportFormSection
              name="Диагноз по МКБ"
              showPrintCheckmark={true}
              printCheckmarkChecked={true}
              id={"diagnosis"}>
              <ReadableAppointmentDiagnoses appointment={appointment} />
            </ReadonlyDecisionSupportFormSection>
          )}

        {appointment.examination_checklist &&
          withoutUncheckedGroups(appointment.examination_checklist.items).length > 0 && (
            <ReadonlyDecisionSupportFormSection
              name="Обследование"
              showPrintCheckmark={true}
              printCheckmarkChecked={true}
              id={"examination"}>
              <ReadableGroupedChecklistList
                items={withoutUncheckedGroups(appointment.examination_checklist.items)}
              />
            </ReadonlyDecisionSupportFormSection>
          )}

        {appointment.medication_checklist &&
          withoutUnchecked(appointment.medication_checklist.items).length > 0 && (
            <ReadonlyDecisionSupportFormSection
              name="Медикаментозное лечение"
              showPrintCheckmark={true}
              printCheckmarkChecked={true}
              id={"medication"}>
              <ReadableChecklistList>
                {withoutUnchecked(appointment.medication_checklist.items).map(checklist => (
                  <ReadableChecklistItem key={checklist.guid}>
                    <ChecklistWithCommaSeparatedChildrenInParentheses checklist={checklist} />
                  </ReadableChecklistItem>
                ))}
              </ReadableChecklistList>
            </ReadonlyDecisionSupportFormSection>
          )}

        {appointment.recommendation_checklist &&
          withoutUnchecked(appointment.recommendation_checklist.items).length > 0 && (
            <ReadonlyDecisionSupportFormSection
              name="Рекомендации"
              showPrintCheckmark={true}
              printCheckmarkChecked={true}
              id={"recommendation"}>
              <ReadableChecklistList>
                {withoutUnchecked(appointment.recommendation_checklist.items).map(item => (
                  <ReadableChecklistItem key={item.guid}>
                    <ReadableChecklistContentWithCommentFormatted checklist={item} />
                  </ReadableChecklistItem>
                ))}
              </ReadableChecklistList>
            </ReadonlyDecisionSupportFormSection>
          )}

          { !!appointment.prescribed_services?.length &&
              <ButtonFormSection
                  name={"Направления"}
                  id={"prescribedServices"}
                  showPrintCheckmark={true}
                  printCheckmarkChecked={false}
                  button={null}
                  key={appointment.id + 'isPrescribed'}
              >
                  <ServicesSuggest
                      disabled={true}
                      displayTeeth={layout.display_teeth}
                      appointment={appointment}
                      isPrescribed={true}
                  />
              </ButtonFormSection>
          }
        {!window.QUEST_MODE &&
          <AlwaysExpandedFormSection
            name="Услуги"
            showPrintCheckmark={true}
            printCheckmarkChecked={true}
            id={"services"}>
            {appointment.next_visit_date && (
              <FieldsetContainer>
                <Fieldset
                  overrides={{
                    Container: {
                      component: FieldsetServicesLegendContainer
                    },
                    Legend: {
                      component: FieldsetLegendCommon
                    }
                  }}
                  legend={"Дата следующего приема:"}>
                  <TextareaView>
                    {formatDateToReadableDateFormat(appointment.next_visit_date)}
                  </TextareaView>
                </Fieldset>
              </FieldsetContainer>
            )}
            <FieldsetContainer>
              <Fieldset
                overrides={{
                  Container: {
                    component: FieldsetServicesLegendContainer
                  },
                  Legend: {
                    component: FieldsetLegendCommon
                  }
                }}
                legend={"Перечень оказанных услуг и проведенных обследований:"}>
                <ReadableServicesList services={appointment.services} />
              </Fieldset>
            </FieldsetContainer>
            {appointment.services_description && (
              <FieldsetContainer>
                <Fieldset
                  overrides={{
                    Container: {
                      component: FieldsetServicesLegendContainer
                    },
                    Legend: {
                      component: FieldsetLegendCommon
                    }
                  }}
                  legend={"Комментарий:"}>
                  <TextareaView>{appointment.services_description}</TextareaView>
                </Fieldset>
              </FieldsetContainer>
            )}
          </AlwaysExpandedFormSection>
        }

        {ultrasoundScans && ultrasoundScans.length > 0 && (
          <AlwaysExpandedFormSection name="Материалы УЗИ">
            <FieldsetContainer>
              <Fieldset
                overrides={{
                  Container: {
                    component: FieldsetServicesLegendContainer
                  },
                  Legend: {
                    component: FieldsetLegendCommon
                  }
                }}
                legend={"Изображения УЗИ:"}>
                <FileGroup>
                  {ultrasoundScans.map((scan, index) => (
                    <UltrasoundScanFile
                      key={index}
                      patientName={getPatientFullName(appointment.medical_file.patient)}
                      file={scan}
                    />
                  ))}
                </FileGroup>
              </Fieldset>
            </FieldsetContainer>
          </AlwaysExpandedFormSection>
        )}
      </SectionsContainer>
    )) ||
    null;

  if (!token) {
    return null;
  }

  return (
    <>
      <Feedback
        appointment={appointment}
        appointment_id={getAppointment_id(appointment)}
        layout_id={getLayout_id(layout)}
        layout={layout}
        fieldValuesMap={fieldValuesMap}
      />
      <AppointmentFormLayout
        title={appointment && <AppointmentTitle title={layout.name} layoutId={layout.id} appointmentId={appointment.id} /> || null}
        metaInfo={metaInfo}
        toolButtons={toolButtons}
        sections={sections}
      />
      {window.QUEST_MODE && <ButtonContainer><PrimaryButton onClick={() => history.push("/quest/thanks")}>Все правильно</PrimaryButton></ButtonContainer>}
      { !window.QUEST_MODE && applicationFeatureEnabled(FEATURE.DOCUMENT_SIDEBAR) &&
        <PrintDocumentsFixedLabel onClick={openPrintDocumentsSidebar} />
      }
      <RightSidebarPortal>
        <SidebarContainer closeOnClickOutside={rightSidebar === PRINT_DOCUMENTS} isShown={!!rightSidebar}>
          {rightSidebar === PRINT_DOCUMENTS && <DocumentsTree appointment={appointment} />}
        </SidebarContainer>
      </RightSidebarPortal>
      <ScrollToTopButton />
    </>
  );
});
