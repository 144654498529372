import { makeObservable, observable, action } from "mobx";
import {
  filtersToParams,
  getMeta,
  paramsMeta,
  metaToParams,
  sortingToParams, stringifyAllParams
} from "../utils/tableUtils";
import _ from "lodash";


export const filtersAfterCheck = (filters) => {
  const newFilters = Object.keys(filters).reduce((acc, key) => {
    const checkNotArray = !filters[key] && filters[key] !== false && filters[key] !== 0;
    const checkArray = Array.isArray(filters[key]) && (!filters[key].length || (filters[key].length === 1 && !filters[key][0]))
    if (checkNotArray || checkArray) {
      return acc;
    }
    acc[key] = filters[key];
    return acc;
  }, {})
  return newFilters;
}

class DynamicTableStore {
  dataTable = [];
  metaTable = {};
  filtersTable = {};
  sortingTable = [];
  valuesParamsTable = [];
  globalFiltersTable = {}
  oldParams = {}
  annotate_mtm_params = null
  fetchFunction = null;
  isInitialized = false;
  columnPinning = { left: [], right: [] };
  additionalParamsValues = [];
  additionalFilterValues = {};
  isLoading = false;
  transformData = null;
  isEnableSavingPresets = true;

  constructor(fetchDataFunction, additionalParamsValues = [], transformData) {
    this.fetchFunction = fetchDataFunction;
    this.additionalParamsValues = additionalParamsValues;
    this.transformData = transformData;

    makeObservable(this, {
      dataTable: observable,
      metaTable: observable,
      filtersTable: observable,
      sortingTable: observable,
      valuesParamsTable: observable,
      annotate_mtm_params: observable,
      globalFiltersTable: observable,
      isLoading: observable,
      additionalFilterValues: observable,
      oldParams: observable,
      isEnableSavingPresets: observable,
      fetchFunction: action,
      setColumnPinning: action,
      setFiltersTable: action,
      setValuesParamsTable: action,
      setAdditionalFilterValues: action,
      setSortingTable: action,
      setGlobalFiltersTable: action,
      setAnnotateMtmParams: action,
      setMetaTable: action,
      fetchDataTable: action,
      generateSearch: action,
      changeOldParams: action,
      enableSavingPresets: action
    });
  }

  enableSavingPresets(flag) {
    this.isEnableSavingPresets = flag;
  }

  setAdditionalFilterValues(filters) {
    this.additionalFilterValues = filters;
  }

  setColumnPinning(columnPinning) {
    if (columnPinning?.left.length || columnPinning?.right.length) {
      this.columnPinning = columnPinning;
    }
    if (this.isEnableSavingPresets) {
      history.pushState(null, null, `${location.pathname}${this.generateSearch()}`);
    }
  }

  setFiltersTable(filtersTable, isNeedFetch = true) {
    this.filtersTable = filtersTable;

    if (isNeedFetch) {
      this.fetchDataTable();
    }
  }

  setAnnotateMtmParams(annotate_mtm_params) {
    this.annotate_mtm_params = annotate_mtm_params;
  }

  setValuesParamsTable(valuesParamsTable, isNeedFetch = true) {
    this.valuesParamsTable = _.concat(valuesParamsTable, this.additionalParamsValues);

    if (isNeedFetch) {
      this.fetchDataTable();
    }
  }

  setSortingTable(sortingTable, isNeedFetch = true) {
    this.sortingTable = sortingTable || [];

    if (isNeedFetch) {
      this.fetchDataTable();
    }
  }

  setGlobalFiltersTable(globalFiltersTable, isNeedFetch = true) {
    if (!_.isEqual({ ...globalFiltersTable, ...this.additionalFilterValues }, this.globalFiltersTable)) {
      this.globalFiltersTable = { ...globalFiltersTable, ...this.additionalFilterValues };

      if (isNeedFetch) {
        this.fetchDataTable();
      }
    }
  }

  setMetaTable(metaTable, isNeedFetch = true, isInitializedParams, setMeta) {
    const pagination = {...metaTable};
    pagination.pageIndex = parseInt((metaTable.pageIndex > -1 && isInitializedParams ? metaTable.pageIndex : this.metaTable.pageIndex) || 0);
    pagination.pageSize = parseInt(metaTable.pageSize || this.metaTable.pageSize || 10);
    pagination.total_items = parseInt(metaTable.total_items || metaTable.total_items === 0 ? metaTable.total_items : (this.metaTable.total_items  || 0));
    if (this.metaTable.pageSize && this.metaTable.pageSize !== metaTable.pageSize && isInitializedParams) {
      pagination.pageIndex = 0;
    }

    if (setMeta) {
      setMeta(pagination);
    }
    if (!_.isEqual(this.metaTable, pagination)) {
      this.metaTable = pagination;
      if (isNeedFetch) {
        this.fetchDataTable();
      }
    }
  }

  generateSearch(meta) {
    return `?${stringifyAllParams(
      {
        filter: filtersToParams(filtersAfterCheck(this.filtersTable), 'filters'),
        globalFilters: filtersToParams(filtersAfterCheck(this.globalFiltersTable), 'globalFilters'),
        sorting: sortingToParams(this.sortingTable),
        meta: metaToParams(meta || this.metaTable),
        columnPinning: (this.columnPinning && (this.columnPinning.left.length || this.columnPinning.right.length)) ? filtersToParams(this.columnPinning, 'columnPinning') : undefined,
      })}`;
  }

  async fetchDataTable(isIgnoreCheck) {
    try {
      if (this.valuesParamsTable.length) {
        const params = {
          filter_params: {...filtersAfterCheck(this.filtersTable), ...filtersAfterCheck(this.globalFiltersTable) },
          values_params: this.valuesParamsTable,
          order_by_params: this.sortingTable,
          meta: null,
        };
        if (this.annotate_mtm_params) {
          params.annotate_mtm_params = this.annotate_mtm_params;
        }
        const isChangeParams = !_.isEqual({...this.oldParams, meta: null}, params)
        params.meta = paramsMeta( isChangeParams && this.isInitialized ? {...this.metaTable, pageIndex: 0} : this.metaTable);
        if (!!isIgnoreCheck || !_.isEqual(this.oldParams, params)) {
          this.oldParams = params;
          this.isLoading = true;
          const response = await this.fetchFunction(params);
          this.dataTable = this.transformData ? this.transformData(response.data) : response.data;
          this.setMetaTable(getMeta(response.meta), false, true);
          if (this.isEnableSavingPresets) {
            history.pushState(null,null,
              `${location.pathname}${this.generateSearch(getMeta(response.meta))}`
            )
          }
          this.isLoading = false;
          this.isInitialized = true;
        }
      }
    } catch(e) {
      if (e.toString() !== 'AbortError: The user aborted a request.') {
        this.isLoading = false;
      }
      console.error(e);
      return false;
    }
  }

  changeOldParams(value) {
    this.oldParams = value
  }
}

export default DynamicTableStore;
