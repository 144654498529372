// @flow
import React from "react";
import styled from "styled-components";
import { colors } from "../styleguide/colors";

const Container = styled.li`
  display: flex;
  align-items: center;
  padding: 0 19px;
  min-height: 40px;
  box-sizing: border-box;
  opacity: 0.8;
  cursor: pointer;

  :hover,
  :focus {
    background-color: ${colors.gray100};
  }
`;

const OptionText = styled.span`
  font-family: inherit;
  font-size: 13px;
  line-height: 1.54;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

  ${Container}:hover & {
    color: ${colors.magenta};
  }

  ${Container}:focus & {
    color: ${colors.magenta};
  }
`;

export const Option = (props: { text: string, iconPlace?: React$Node, onClick: () => mixed }) => {
  const { text, iconPlace, ...otherProps } = props;
  return (
    <Container tabIndex="0" aria-label={text} role="menuitemradio" {...otherProps}>
      {iconPlace}
      <OptionText title={text}>{text}</OptionText>
    </Container>
  );
};
