import React from "react";
import { observer } from "mobx-react";
import { Box, CardContent } from "@mui/material";
import { styled } from "@mui/material/styles";

import { CorporateCard } from "./CorporateCard";
import { CorporateLikeCounter } from "./activity/CorporateLikeCounter";
import { CorporateViewCounter } from "./activity/CorporateViewCounter";
import { CorporateSubTitle, CorporateTitle } from "../styled/CorporateTypography.styled";
import { CorporateWrapper } from "../styled/CorporateWrapper.styled";
import { breakpoints } from "../styled/common";
import corporateNewsStore from "../stores/corporateNewsStore";

const CardTitleStyled = styled(CardContent)(
  () =>
    `
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 0;
      ${breakpoints.tablet} {
        gap: 5px;
      }
    `
);

const CardTitleInfoContainer = styled(Box)(
  () =>
    `
      display: flex;
      gap: 12px;
      flex-wrap: wrap;
      ${breakpoints.tablet} {
        gap: 8px;
      }
    `
);

const DetailedNewsContent = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 0;
    
  img {
    max-width: 100%;
  }
    
  h2 {
	  font-size: 24px;
	  font-weight: 500;
	  line-height: 130%;
      
    @media screen and (max-width: 1200px) {
      font-size: 22px;
    }
	  @media screen and (max-width: 640px) {
	    font-size: 20px;
	  }
  }
    
  p {
	  line-height: 24px;
  }
`;

const CardActionsContainer = styled(Box)(
  () =>
    `
      display: flex;
      justify-content: space-between;
    `
);

export const CorporateDetailNewsCard = observer(props => {
  const {
    news: { id, isNewsLiked, numberOfLike, ...newsData },
    detailedNews: { template }
  } = props;

  const handleChangeLikeCounter = () => {
    corporateNewsStore.changeLikeCounter({ id, isNewsLiked });
  };

  return (
    <CorporateWrapper>
      <CorporateCard>
        <CardTitleStyled>
          <CardTitleInfoContainer>
            <CorporateSubTitle modifiers={["md", "pink"]}>
              {newsData.category}
            </CorporateSubTitle>
            <CorporateSubTitle modifiers={["md", "gray"]}>
              {newsData.date} {newsData.time}
            </CorporateSubTitle>
          </CardTitleInfoContainer>
          <CorporateTitle>{newsData.name}</CorporateTitle>
        </CardTitleStyled>
        <DetailedNewsContent dangerouslySetInnerHTML={{__html: template}}/>
        <CardActionsContainer>
          <CorporateLikeCounter
            value={numberOfLike}
            active={isNewsLiked}
            onClick={handleChangeLikeCounter}
          />
          <CorporateViewCounter value={newsData.numberOfView} />
        </CardActionsContainer>
      </CorporateCard>
    </CorporateWrapper>
  );
});
