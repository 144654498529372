import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class Auth {
	apiService;
	constructor() {
		this.apiService = new ApiService('/api/users/auth', null, null, API_URL);
	}

	sendSms(phone) {
		return this.apiService.post('send_sms', {}, {phone});
	}

	login({phone, code}) {
		return this.apiService.post('login', {}, {role: 'doctor', phone, sms_code: code});
	}

	refreshToken(refreshToken) {
		return this.apiService.post('refresh', {}, {}, {refresh_token: refreshToken});
	}

	getCurrentUser() {
		return this.apiService.get('current_user');
	}

	editCurrentUser(data) {
		return this.apiService.patch('current_user', {}, {doctor: data});
	}

	editCurrentUserDoctor(data) {
		return this.apiService.patch('current_user/doctor', {}, data, {}, false);
	}

}

export default new Auth();
