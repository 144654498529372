// @flow
import React from "react";
import { useConfirm } from "../alert-warning/useConfirm";
import { ModalPortal } from "../modal/ModalPortal";
import { clearToasts } from "../toast/toastStore";
import { logout } from "../../stores/auth";
import { AlertWarningPrimaryButton, AlertWarningWhiteButton } from "../buttons/AlertButton";
import { AlertWarning } from "../alert-warning/AlertWarning";
import { IconButton } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { ButtonCaps, HeadingH3 } from "../styleguide/typography";
import { Tab as ExitTab } from "../navigation-tabs/NavigationTabs";
import { IconContainerStyled } from "../icon-containter-styles/IconContainer.styled";
import { styled } from "@mui/material/styles";

const IconButtonStyled = styled(IconButton)(({ theme }) => `
  grid-area: exit;
  margin-left: 10px;
  :hover, :active {
    color: ${theme.palette.primary.main};
    ${IconContainerStyled} {
      .MuiSvgIcon-root { 
        stroke: rgb(234 214 217);
      }
    }
  }
`
);

export const Logout = ({ isCustomExitTab }) => {
  const exitConfirm = useConfirm();

  const handleOnExit = async () => {
    if (!exitConfirm.requestConfirm) {
      return;
    }
    try {
      const confirmResult = await exitConfirm.requestConfirm();
      if (!confirmResult) {
        return;
      }
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage("logout");
      }
      logout();
      clearToasts();
    } catch (e) {
      console.log(e);
    }
  };

  const exitAlertWarning = (
    <AlertWarning
      isNeedConfirm={exitConfirm.isNeedConfirm}
      sendConfirm={exitConfirm.sendConfirm}
      heading={<HeadingH3 modifiers="center">Вы точно хотите выйти</HeadingH3>}
      cancelButton={onClick => (
        <AlertWarningWhiteButton onClick={onClick}>Отменить</AlertWarningWhiteButton>
      )}
      confirmButton={onClick => (
        <AlertWarningPrimaryButton onClick={onClick}>Выйти</AlertWarningPrimaryButton>
      )}
    />
  );

  return (
    <>
      {isCustomExitTab ? (
        <ExitTab onClick={handleOnExit}>
          <IconContainerStyled marginRight="8px">
            <LogoutIcon />
          </IconContainerStyled>
          <ButtonCaps modifiers={["left", "uppercase"]}>Выйти</ButtonCaps>
        </ExitTab>
      ) : (
        <IconButtonStyled aria-label="exit" color="black" onClick={handleOnExit}>
          <IconContainerStyled>
            <LogoutIcon />
          </IconContainerStyled>
        </IconButtonStyled>
      )}
      <ModalPortal>{exitAlertWarning}</ModalPortal>
    </>
  );
};
