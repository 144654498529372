// @flow
import { makeObservable, action, observable } from "mobx";
import dayjs from "dayjs";
import DynamicTableStore from "./dynamicTableStore";
import apiv2 from "../apiv2";
import { READABLE_DATE_FORMAT, READABLE_DATE_TIME_FORMAT, SERVER_DATE_TIME_FORMAT } from "../utils/dates";
import { getPatientLastNameWithInitials } from "../domain/services/patient";
import { FEATURE, featureValue } from "../utils/applicationFeatures";
import { trimLongTextValueField } from "../utils/tableUtils";
import moment from "moment";
import { Badge, Chip } from "@mui/material";
import { currentUser } from "./auth";
import authStore from "./authStore";
import { getFullNameWithInitials, getLastNameWithInitials } from "../domain/services/doctor";
import organizationsStore from "./organizationsStore";

const transformCommentData = (comment) => {
  const fullName = comment.author.doctor
    ? getPatientLastNameWithInitials(comment.author.doctor)
    : comment.author.username;
  const placeholderAvatar =
    !comment.author.doctor.last_name || !comment.author.doctor.first_name
      ? ""
      : `${comment.author.doctor?.last_name?.slice(0, 1)}${comment.author.doctor?.first_name?.slice(0, 1)}`;

  return {
    id: comment.id,
    text: comment.comment.split("\n"),
    avatar: {
      src: comment.author.photo,
      placeholder: placeholderAvatar
    },
    fullName,
    date: dayjs(comment.created_at).format(READABLE_DATE_TIME_FORMAT)
  };
};

export const TICKET_STATUSES = {
  created: "Создан",
  clarify: "На уточнении",
  inprogress: "В работе",
  testing: "На проверке",
  done: "Готов"
};

export const TICKET_STATUSES_DMS = {
  created: "Новая заявка",
  inprogress: "В работе",
  waitingInsurance: "Ожидает ответа СК",
  waitingGuaranteeLetter: "Ожидаем ГП",
  done: "Выполненная"
};

export const TICKET_STATUSES_DMS_COLOR = {
  created: "default",
  inprogress: "warning",
  waitingInsurance: "warning",
  waitingGuaranteeLetter: "warning",
  done: "success"
};

export const TICKET_PRIORITIES = {
  urgent: "Срочный",
  high: "Высокий",
  medium: "Средний",
  low: "Низкий"
};

export const AREAS_FIELD = {
  app: "Мобильное приложение пациента",
  doctor_app: "Мобильное приложение врача",
  telemed: "Телемед (видеоконсультации и чаты)",
  asya: "Ася",
  site: "Сайт Fomin-Clinic",
  mis: "МИС (1C)",
  ['Legal lab']: "Legal Lab",
  new_feature: "Предложение по улучшению",
  other: "Другое",
};

const BASIC_OPTIONS_TABLE = {
  initialState: {
    showColumnFilters: true,
    columnVisibility: {
      updated_at: false,
      author_text: false,
      author__username: false,
      plus_authors: false
    }
  },
  actions: ["edit"]
};

const OPTIONS_BY_KEY = {
  status: TICKET_STATUSES,
  statusDMS: TICKET_STATUSES_DMS,
  priority: TICKET_PRIORITIES
};

export const getTicketOptionsByAccessorKey = (accessorKey) => {
  return Object.entries(OPTIONS_BY_KEY[accessorKey]).map(([value, label]) => ({
    value,
    label
  }));
}
export const TICKET_SEGMENTS = {
  COMMON: {
    name: "common",
    additionalFilters: () => {},
    hiddenColumns: [],
    disabledFieldsForCreation: [],
    disabledFieldsForEditing: [],
    hiddenFieldForCreation: [],
    hiddenFieldForEditing: [],
    doctorAssignee: () => {
      const TICKET_DOCTOR_ASSIGNEE_COMMON = featureValue(FEATURE.TICKET_DOCTOR_ASSIGNEE_COMMON) || '';
      return TICKET_DOCTOR_ASSIGNEE_COMMON.split(',').map((item) => parseInt(item))
    },
    optionsTable: BASIC_OPTIONS_TABLE,
    defaultData: () => {}
  },
  LEGAL_LAB: {
    name: "legal-lab",
    additionalFilters: () => ({ area: "Legal lab" }),
    hiddenColumns: ["area", "plus_authors", "author_text", "author__username"],
    disabledFieldsForCreation: [],
    disabledFieldsForEditing: [],
    hiddenFieldForCreation: ["area", "plus_authors", "author_text", "author__username"],
    hiddenFieldForEditing: ["area", "plus_authors", "author_text", "author__username"],
    optionsTable: BASIC_OPTIONS_TABLE,
    doctorAssignee: () => {
      const TICKET_DOCTOR_ASSIGNEE_COMMON = featureValue(FEATURE.TICKET_DOCTOR_ASSIGNEE_COMMON) || '';
      return TICKET_DOCTOR_ASSIGNEE_COMMON.split(',').map((item) => parseInt(item))
    },
    defaultData: () => ({ area: "Legal lab" })
  },
  PROFILE: {
    name: "profile",
    // ToDo бек не умеет принимать {value} || {value}
    additionalFilters: userID => ({ author: userID }),
    hiddenColumns: ["author_text", "author__username"],
    disabledFieldsForCreation: [],
    disabledFieldsForEditing: [],
    hiddenFieldForCreation: ["author_text", "author__username"],
    hiddenFieldForEditing: ["author_text", "author__username"],
    doctorAssignee: () => {
      const TICKET_DOCTOR_ASSIGNEE_COMMON = featureValue(FEATURE.TICKET_DOCTOR_ASSIGNEE_COMMON) || '';
      return TICKET_DOCTOR_ASSIGNEE_COMMON.split(',').map((item) => parseInt(item))
    },
    optionsTable: BASIC_OPTIONS_TABLE,
    defaultData: () => {}
  },
  MEDICAL_ERROR: {
    name: "medical-error",
    additionalFilters: () => ({ area: "Врачебная ошибка" }),
    hiddenColumns: ["area", "plus_authors", "author_text", "author__username"],
    disabledFieldsForCreation: ['description'],
    disabledFieldsForEditing: ['description'],
    hiddenFieldForCreation: ["area", "plus_authors", "status", "theme", "author_text", "author__username"],
    hiddenFieldForEditing: ["area", "plus_authors", "theme", "author_text", "author__username"],
    optionsTable: BASIC_OPTIONS_TABLE,
    doctorAssignee: () => {
      const TICKET_DOCTOR_ASSIGNEE_MEDICAL_ERROR = featureValue(FEATURE.TICKET_DOCTOR_ASSIGNEE_MEDICAL_ERROR) || '';
      return TICKET_DOCTOR_ASSIGNEE_MEDICAL_ERROR.split(',').map((item) => parseInt(item))
    },
    defaultData: () => ({ area: "Врачебная ошибка", status: 'created', theme: "Врачебная ошибка" })
  },
  MANAGER_DMS: {
    name: "manager-dms",
    annotateMtmParams: {
      "appointment__medical_file__patient__policies": "appointment__medical_file__patient__policies__org_name"
    },
    additionalFilters: () => ({ area: "manager_dms", appointment__medical_file__organization__in: authStore.user.doctor.organizations }),
    optionsTable: BASIC_OPTIONS_TABLE,
    disabledFieldsForCreation: [],
    disabledFieldsForEditing: [],
    hiddenFieldForCreation: [],
    hiddenFieldForEditing: [],
    additionalEditValues: [
      'doctor_fullname',
      'patient_fullname',
      'appointment__medical_file__patient__birthdate',
      'appointment__medical_file__patient__policies__org_name',
      'appointment__medical_file__patient__policies__number',
      'appointment__medical_file__patient__policies__start_date',
      'appointment__medical_file__patient__policies__end_date',
      'created_at',
      'appointment__layout__name',
      'appointment__primary_diagnoses__diagnosis__name',
      'appointment__primary_diagnoses__comment',
      'appointment__id',
      'files__file',
      'files__name',
      'files__id',
    ],
    annotate_mtm_edit_params: {
      'appointment__primary_diagnoses__diagnosis__name': 'appointment__primary_diagnoses__diagnosis__name',
      'appointment__primary_diagnoses__comment': 'appointment__primary_diagnoses__comment',
      'files': [
        'files__file',
        'files__name',
        'files__id',
      ],
      'appointment__medical_file__patient__policies': [
        'appointment__medical_file__patient__policies__org_name',
        'appointment__medical_file__patient__policies__number',
        'appointment__medical_file__patient__policies__start_date',
        'appointment__medical_file__patient__policies__end_date'
      ],
    },
    defaultEditData: () => ({ fullname_manager_dms: getLastNameWithInitials(authStore.user.doctor), approval_period_dms: 30, area: "manager_dms" }),
    defaultData: () => ({ area: "manager_dms", status: 'created', theme: "Мэнеджер ДМС"}),
    patchFields: [
      'id',
      'fullname_manager_dms',
      'fullname_curator_insurance_dms',
      'approval_date_dms',
      'approval_period_dms',
      'description',
      'letter_guarantee_number',
      'status',
      'area',
    ],
    handlerWatcherOnChangeField: (updatedData, reset, changedKey) => {
      if (changedKey !== "fullname_curator_insurance_dms" && changedKey !== "letter_guarantee_number") {
        return {};
      }
      /** Обработка, если пользователь успеет внести изменения в разрез debounceTime */
      if (updatedData.fullname_curator_insurance_dms.length && updatedData.letter_guarantee_number.length) {
        /** Сбросить значение для поля, которое устанавливалось изначально */
        reset({ ...updatedData, [changedKey]: "" });
        /** Заблокировать данное поле */
        return {
          disabledFields: {
            'fullname_curator_insurance_dms': changedKey === "fullname_curator_insurance_dms",
            'letter_guarantee_number': changedKey === "letter_guarantee_number"
          }
        }
      }
      return {
        disabledFields: {
          'fullname_curator_insurance_dms': changedKey === "fullname_curator_insurance_dms" ? false : !!updatedData[changedKey].length,
          'letter_guarantee_number': changedKey === "letter_guarantee_number" ? false : !!updatedData[changedKey].length
        }
      }
    },
    editSchema: [
      {
        type: "text",
        name: "area",
        label: "",
        hiddenField: true,
        disabled: true
      },
      {
        type: "text",
        name: "fullname_manager_dms",
        label: "ФИО автора менеджера",
        disabled: true
      },
      {
        type: "text",
        name: "fullname_curator_insurance_dms",
        label: "ФИО куратора от СК",
      },
      {
        type: "date",
        typePicker: 'dateTime',
        name: "approval_date_dms",
        label: "Дата и время согласования",
      },
      {
        type: "number",
        name: "approval_period_dms",
        label: "Срок согласования",
      },
      {
        type: "textarea",
        name: "description",
        label: "Комментарий"
      },
      {
        type: "text",
        name: "letter_guarantee_number",
        label: "Номер гарантийного письма",
      },
      {
        type: "file",
        name: "new_files",
        currentFiles: 'files',
        multiple: true,
        deleteMethod: async (value) => {
          await apiv2.ticket.deleteTicketFiles(value.id);
        },
        placeholder: 'Выберите файл'
      },
      {
        type: "select",
        name: "status",
        label: "Статус",
        options: getTicketOptionsByAccessorKey("statusDMS")
      },
    ],
    tableColumns: () => [
      {
        accessorKey: "appointment__medical_file__patient__policies__org_name", //normal accessorKey
        header: "Название страховой компании",
        grow: false,
        size: 400,
        filterFn: "icontains",
        Cell: ({ row }) => {
          if (!row.original.appointment__medical_file__patient__policies__org_name) {
            return '';
          }

          let orgNames = '';
          row.original.appointment__medical_file__patient__policies__org_name.forEach((item, index) => {
            orgNames += index === 0 ? item : `, ${item}`;
          })

          return orgNames;
        }
      },
      {
        accessorKey: "status",
        additionalFields: ['updated_at_status'],
        header: "Статус",
        filterFn: "in",
        filterVariant: "multi-select",
        filterSelectOptions: getTicketOptionsByAccessorKey("statusDMS"),
        Cell: ({ row }) => {
          const getColor = () => {
            if ((row.original.status === 'created' || row.original.status === 'inprogress') && (row.original.updated_at_status ? moment().diff(moment(row.original.updated_at_status), 'days') : moment().diff(moment(row.original.created_at), 'days'))) {
              return 'error';
            } else {
              return TICKET_STATUSES_DMS_COLOR[row.original.status]
            }
          }
          return (
            <Chip
              label={TICKET_STATUSES_DMS[row.original.status]}
              variant="outlined"
              color={getColor()}>
            </Chip>
          )
        },
        size: 300
      },
      {
        accessorKey: 'appointment__medical_file__organization__name',
        header: 'Организация',
        filterFn: 'in',
        filterVariant: 'multi-select',
        filterSelectOptions: organizationsStore.organizations.filter((item) => authStore.user.doctor.organizations.includes(item.id)).map((item) => ({value: item.id, label: item.name})),
        size: 300,
      },
      {
        accessorKey: "doctor_fullname", //normal accessorKey
        header: "ФИО врача",
        grow: false,
        size: 300,
        filterFn: "icontains",
      },
      {
        accessorKey: "patient_fullname", //normal accessorKey
        header: "ФИО пациента",
        grow: false,
        size: 300,
        filterFn: "icontains",
      },
      {
        accessorKey: "created_at",
        header: "Дата создания заявки",
        size: 250,
        enableColumnFilter: false,
        Cell: ({ cell }) => cell.getValue() ? moment(cell.getValue()).format(READABLE_DATE_FORMAT) : ''
      },
      {
        accessorKey: "id",
        header: "Идентификатор",
        size: 200,
        filterFn: 'icontains',
        enableHiding: false,
      },
    ]
  }
};

export const TICKET_SEGMENTS_URL = {
  "legal-lab": TICKET_SEGMENTS.LEGAL_LAB,
  "common": TICKET_SEGMENTS.COMMON,
  "profile": TICKET_SEGMENTS.PROFILE,
  "medical-error": TICKET_SEGMENTS.MEDICAL_ERROR,
  "manager-dms": TICKET_SEGMENTS.MANAGER_DMS,
};

class TicketStore extends DynamicTableStore {
  static getSegmentUrl() {
    const splitPathName = location.pathname.split("/");
    let segment = TICKET_SEGMENTS.COMMON.name;
    if (location.pathname.includes("/profile/")) {
      segment = TICKET_SEGMENTS.PROFILE.name;
    } else if (location.pathname.includes("/segment/")) {
      segment = splitPathName[splitPathName.indexOf("segment") + 1];
    }
    return TICKET_SEGMENTS_URL[segment];
  }
  ticketID = null;
  ticket = null;
  segment = TICKET_SEGMENTS.COMMON.name;
  feedbackData = {};
  comments = [];
  DMSPrescribedServices = [];

  constructor() {
    const segment = TicketStore.getSegmentUrl().name;
    super(
      apiv2.ticket.getListTickets.bind({
        ...apiv2.ticket,
        presetFilterParams:
          segment !== TICKET_SEGMENTS.MEDICAL_ERROR.name && segment !== TICKET_SEGMENTS.MANAGER_DMS.name
            ? { area__in: Object.keys(AREAS_FIELD) }
            : null
      })
    );
    this.setSegment(segment);

    makeObservable(this, {
      setTicketID: action,
      ticketID: observable,
      getTicket: action,
      setTicket: action,
      ticket: observable,
      setFeedbackData: action,
      feedbackData: observable,
      setComments: action,
      updateComments: action,
      comments: observable,
      DMSPrescribedServices: observable
    });
  }

  setTicketID(id: number) {
    this.ticket = null;
    this.ticketID = id;
  }

  setTicket(ticket) {
    if (ticket && ticket.area === "Врачебная ошибка") {
      ticket.author__username = "";
    }
    this.ticket = ticket;
  }

  setSegment(segment) {
    this.segment = segment;
  }

  async getTicket(valuesParams) {
    try {
      let newValuesParams = [...valuesParams.filter((item) => item !== 'new_files'), "id"];
      let annotate_mtm_params = null;
      if (TICKET_SEGMENTS_URL[this.segment].additionalEditValues && TICKET_SEGMENTS_URL[this.segment].additionalEditValues.length) {
        newValuesParams = [
          ...newValuesParams,
          ...TICKET_SEGMENTS_URL[this.segment].additionalEditValues,
        ];
      }
      if (TICKET_SEGMENTS_URL[this.segment].annotate_mtm_edit_params) {
        annotate_mtm_params = TICKET_SEGMENTS_URL[this.segment].annotate_mtm_edit_params
      }
      const params = {
        filter_params: { id: this.ticketID },
        values_params: newValuesParams,
        annotate_mtm_params: annotate_mtm_params,
      };
      const response = await apiv2.ticket.getListTickets(params);
      if (TICKET_SEGMENTS_URL[this.segment].defaultEditData) {
        const defaultData = TICKET_SEGMENTS_URL[this.segment].defaultEditData();
        Object.keys(defaultData).forEach((key) => {
          if (!response.data[0][key]) {
            response.data[0][key] = defaultData[key];
          }
        })
      }
      if (response.data[0]['files__file'] && response.data[0]['files__file'].length) {
        response.data[0].files = [];
        response.data[0]['files__file'].forEach((item, index) => {
          response.data[0].files.push({
            name: response.data[0]['files__name'][index],
            file: item,
            id: response.data[0]['files__id'][index]
          })
        })
      }
      this.setTicket(response.data[0]);
      await this.getCommentsByTicket();
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async postEditedTicket(data) {
    data.plus_authors_ids = data.plus_authors;
    delete data.plus_authors;
    delete data.created_at;
    delete data.updated_at;
    if (data.new_files) {
      for (const file of data.new_files) {
        let formData = new FormData();
        formData.append("name", file.name);
        formData.append("file", file);
        await apiv2.ticket.postCreateTicketFile(this.ticketID, formData);
      }
      delete data.new_files;
    }
    if (this.DMSPrescribedServices.length) {
      this.DMSPrescribedServices.forEach((item) => {
        const approval_date_dms = data.status === "done" && data.approval_date_dms ? moment(data.approval_date_dms).format(SERVER_DATE_TIME_FORMAT) : undefined;
        const approval_end_date_dms = data.status === "done" && data.approval_period_dms ? moment(data.approval_date_dms).add(data.approval_period_dms, 'days').format(SERVER_DATE_TIME_FORMAT) : undefined;
        apiv2.appointments.postAppointmentPrescribedService(data.appointment__id, {
          service_id: item.service.id,
          dms_status: item.dms_status,
          approval_date_dms,
          approval_end_date_dms,
        });
      });
    }
    if (TICKET_SEGMENTS_URL[this.segment].patchFields) {
      data = Object.keys(data).reduce((acc, key) => {
        if (TICKET_SEGMENTS_URL[this.segment].patchFields.includes(key)) {
          acc[key] = data[key];
        }
        return acc;
      }, {});
    }
    try {
      await apiv2.ticket.postEditedTicket([data]);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async postCreateTicket(data) {
    try {
      await apiv2.ticket.postCreateTicket(data);
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  setFeedbackData(data) {
    this.feedbackData = data;
  }

  resetFeedbackData() {
    this.feedbackData = {};
  }

  async sendCommentByTicket(comment) {
    try {
      const response = await apiv2.ticket.sendComment({ ticketId: this.ticketID, comment });
      this.updateComments(response);
    } catch (e) {
      console.error(e);
    }
  }

  async getCommentsByTicket() {
    if (!this.ticketID) {
      return;
    }
    try {
      const response = await apiv2.ticket.getComments(this.ticketID);
      this.setComments(response.reverse());
    } catch (e) {
      console.error(e);
    }
  }

  setComments(comments) {
    this.comments = comments.map(comment => transformCommentData(comment));
  }

  updateComments(comment) {
    this.comments.push(transformCommentData(comment));
  }

  clearComments() {
    this.comments = [];
  }

  setDMSPrescribedServices(data) {
    this.DMSPrescribedServices = data;
  }

  changeDMSPrescribedServices(prescribedService, dms_status) {
    this.setDMSPrescribedServices(
      this.DMSPrescribedServices.map((item) => {
        if (item.id === prescribedService.id) {
          item.dms_status = dms_status;
        }
        return item;
      })
    );
  }
}

export default new TicketStore();
