import React from "react";
import { Controller } from "react-hook-form";
import { MuiFileInput } from "mui-file-input";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import { Link } from "@mui/material";

const FileBlock = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const FieldBlock = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 6px;
`;

export const FormInputFile = (props) => {
  const [file, setFile] = React.useState(null)
  const [valueData, setValueData] = React.useState(null)
  const [isChange, setIsChange] = React.useState(false)

  const handleChange = (newFile, onChange) => {
    setFile(newFile);
    setIsChange(true);
    onChange(newFile)
  }

  const deleteFile = (value) => {
    setIsChange(true);
    setValueData(props.multiple ? valueData.filter((item) => item.id !== value.id) : null);
    props.deleteMethod(value);
  }

  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({
        field: { onChange, value },
        fieldState: { error },
      }) => {
        if (!isChange && props.currentFiles) {
          setValueData(props.allData[props.currentFiles]);
        }
        const newProps = {...props};
        delete newProps.deleteMethod;
        delete newProps.currentFiles;
        delete newProps.allData;
        return (
          <FieldBlock>
            <MuiFileInput
              value={file}
              onChange={(event) => handleChange(event, onChange)}
              fullWidth
              helperText={error ? error.message : props.subInfo ? props.subInfo : null}
              error={!!error}
              inputProps={
                { readOnly: props.readOnly, }
              }
              clearIconButtonProps={{
                title: "Remove",
                children: <CloseIcon fontSize="small" />
              }}
              variant="outlined"
              {...newProps}
            />
            {!!valueData && (
              <>
                {newProps.multiple ? (
                  <>
                    {valueData.map((val) => (
                      <FileBlock key={val.file}>
                        <Link fontSize={12} target={"_blank"} rel={"nofollow noreferrer"} href={val.file}>{val.name}</Link>
                        <CloseIcon onClick={() => deleteFile(val)} cursor={'pointer'} fontSize={'12'} color={'primary'}></CloseIcon>
                      </FileBlock>
                    ))}
                  </>
                ) : (
                  <FileBlock key={valueData.file}>
                    <Link fontSize={12} target={"_blank"} rel={"nofollow noreferrer"} href={valueData.file}>{valueData.name}</Link>
                    <CloseIcon onClick={() => deleteFile(valueData)} cursor={'pointer'} fontSize={'12'} color={'primary'}></CloseIcon>
                  </FileBlock>
                )}
              </>
            )}
          </FieldBlock>
        )
      }}
    />
  );
};
