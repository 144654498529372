// @flow
import React, { Fragment, useLayoutEffect, useState } from "react";
import { observer } from "mobx-react";
import corporateEmployeesStore, {
  ACCORDION_NESTING_LEVELS
} from "../stores/corporateEmployeesStore";
import {
  CorporateBirthdaysWidget,
  CorporateFeedBackWidget,
  CorporateVoteWidget
} from "../modules/widgets";
import { CorporateContent } from "../components/CorporateContent";
import { BackdropLoader, BlockLoader } from "../../components/loader/Loader";
import { CorporateAccordion } from "../components/CorporateAccordion";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { CorporateSubTitle, CorporateTitle } from "../styled/CorporateTypography.styled";
import { CorporateProfileEmployee } from "../components/CorporateProfileEmployee";
import { CorporateMaterialTable } from "../components/CorporateMaterialTable";
import { CorporateWrapper } from "../styled/CorporateWrapper.styled";
import { breakpoints } from "../styled/common";
import { CorporateControlPanel } from "../components/CorporateControlPanel";

const ContentContainer = styled(Box)`
  margin-top: 24px;
  ${breakpoints.desktop} {
    margin-top: 16px;
  }
  ${breakpoints.sm} {
    margin-top: 10px;
  }
`;

const CorporateAccordionContainer = styled(ContentContainer)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  > * {
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.04);
  }
`;

const CorporateStructureWrapper = styled(CorporateWrapper)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const CorporateEmployeesTable = observer(({ data, expanded = false }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [tableData, setTableData] = useState([]);

  useLayoutEffect(() => {
    if (!expanded) {
      setTableData([]);
      setIsInitialized(false);
    }
  }, [expanded]);

  useLayoutEffect(() => {
    setTableData([]);
    setIsInitialized(false);
  }, [corporateEmployeesStore.searchValue]);

  useLayoutEffect(() => {
    if (!expanded || tableData.length || isInitialized) {
      return;
    }

    (async () => {
      const response = await corporateEmployeesStore.getEmployeesData(data.breadcrumbs);
      // ToDo для тестовой среды, удалить после интеграции
      setTimeout(() => {
        setTableData(response);
        setIsInitialized(true);
      }, 1000);
    })();
  }, [expanded, tableData]);

  if (!expanded) {
    return null;
  }

  return !isInitialized ? <BlockLoader /> : <CorporateMaterialTable tableData={tableData} />;
});

export const CorporateEmployeesPage = observer(() => {
  const sideBarContent = [
    {
      name: "birthdays",
      content: <CorporateBirthdaysWidget />
    },
    {
      name: "feedBack",
      content: <CorporateFeedBackWidget />
    },
    {
      name: "vote",
      content: <CorporateVoteWidget />
    }
  ];

  const maxVisibleElementsByLevel = {
    [ACCORDION_NESTING_LEVELS.ORGANIZATIONS]: 10,
    [ACCORDION_NESTING_LEVELS.SPECIALIZATIONS]: 10
  };

  const handleOnOpeningAccordion = ({ data, setCurrentLevelExpanded }) => {
    if (!corporateEmployeesStore.foundedEmployees) {
      return;
    }

    let isExpanded = false;

    if (data.breadcrumbs.city) {
      isExpanded = corporateEmployeesStore.foundedEmployees.some(item => {
        return item.cities.includes(data.breadcrumbs.city);
      });
    }
    if (data.breadcrumbs.organization) {
      isExpanded = corporateEmployeesStore.foundedEmployees.some(item => {
        return (
          item.cities.includes(data.breadcrumbs.city) &&
          item.organizations.includes(data.breadcrumbs.organization)
        );
      });
    }
    if (data.breadcrumbs.specialization) {
      isExpanded = corporateEmployeesStore.foundedEmployees.some(item => {
        return (
          item.cities.includes(data.breadcrumbs.city) &&
          item.organizations.includes(data.breadcrumbs.organization) &&
          item.specializations.includes(data.breadcrumbs.specialization)
        );
      });
    }
    setCurrentLevelExpanded(isExpanded);
  };

  return (
    <Fragment>
      <CorporateContent rightSideBarContent={sideBarContent}>
        <BackdropLoader isOpen={!corporateEmployeesStore.isInitialized} />
        <CorporateControlPanel
          loading={!corporateEmployeesStore.isInitialized}
          tabs={corporateEmployeesStore.tabs}
          indexSelectedTab={corporateEmployeesStore.selectedTab.index}
          handleOnChangeSelectedTab={corporateEmployeesStore.changeSelectedTab}
          searchField={{
            label: "Поиск сотрудника",
            value: corporateEmployeesStore.searchValue,
            onChange: corporateEmployeesStore.changeSearchValue
          }}
        />
        {corporateEmployeesStore.selectedTab.index === 0 ? (
          <CorporateAccordionContainer>
            {corporateEmployeesStore.isInitialized &&
            !corporateEmployeesStore.accordionData.length ? (
              <CorporateWrapper>
                <CorporateSubTitle modifiers={["gray"]}>Сотрудники не найдены</CorporateSubTitle>
              </CorporateWrapper>
            ) : (
              <CorporateAccordion
                data={corporateEmployeesStore.accordionData}
                currentLevel={ACCORDION_NESTING_LEVELS.CITIES}
                maxVisibleElementsByLevel={maxVisibleElementsByLevel}
                renderContent={props => <CorporateEmployeesTable {...props} />}
                triggerForOpeningAccordions={corporateEmployeesStore.foundedEmployees}
                triggerForClosingAccordions={corporateEmployeesStore.searchValue}
                handleOnOpeningAccordion={handleOnOpeningAccordion}
                searchedData={corporateEmployeesStore.foundedEmployees}
              />
            )}
          </CorporateAccordionContainer>
        ) : (
          <ContentContainer>
            <CorporateStructureWrapper>
              <CorporateTitle modifiers={"md"}>Структура компании</CorporateTitle>
              <CorporateSubTitle>Разработка в дальнейших итерациях</CorporateSubTitle>
            </CorporateStructureWrapper>
          </ContentContainer>
        )}
      </CorporateContent>
      <CorporateProfileEmployee />
    </Fragment>
  );
});
