// @flow
import * as React from "react";
import styled, { css } from "styled-components";
import type { ScheduleReserved } from "../../domain/entities/Schedule";
import { PList } from "../styleguide/typography";
import { colors } from "../styleguide/colors";
import arrowIcon from "./icons/arrow-right.svg";
import arrowIconColor from "./icons/arrow-right-color.svg";
import {
  formatScheduleTimeForDisplay,
  isSameOrAfterDate,
  isSameOrAfterDateAndTime
} from "../../domain/services/schedule";
import { useStore } from "effector-react";
import {
  changeDataDeleteAppointment,
  dataStore
} from "../schedule-page/scheduleStore";
import { DeleteButtonIcon } from "../buttons/DeleteButtonIcon";
import { isChatSchedule } from './types';
import Tooltip from "@mui/material/Tooltip";
import * as moment from "moment";
import { READABLE_TIME_FORMAT_MOMENT, SERVER_DATE_FORMAT } from "../../utils/dates";
import { Box } from "@mui/material";

import chat from './icons/chat.svg';

const DeleteScheduleButtonContainer = styled.div`
  display: none;
`;

const Container = styled.div`
  height: 66px;
  padding: 0 24px;
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  align-items: center;
  transition: all 0.15s ease-in-out;

  position: relative;

  ${PList} {
    transition: all 0.15s ease-in-out;
  }

  ${props => props.isDisabled && "pointer-events: none;"};

  :hover {
    ${PList} {
      color: ${colors.magenta};
    }
    ${DeleteScheduleButtonContainer} {
      display: flex;
    }
  }
  @media (max-width: 768px) {
    height: auto;
    padding: 10px 20px;
  }
  @media (max-width: 480px) {
    padding: 10px 15px;
  }
`;

const TimeContainer = styled.div`
  opacity: 0.7;
  display: flex;
  align-items: center;
`;

const Indicator = styled.div`
  width: 5px;
  height: 5px;
  background: ${colors.magenta};
  border-radius: 50%;
`;

const Info = styled.div`
  display: grid;
  grid-template-columns: 71px 1fr;
  @media (max-width: 768px) {
    grid-template-columns: max-content 1fr;
    grid-gap: 10px;
  }
`;

const FullName = styled(PList)`
  font-size: 16px;
  transition: all 0.15s ease-in-out;
`;

const Arrow = styled.div`
  width: 20px;
  height: 20px;

  background: url(${arrowIcon});
  background-size: contain;

  opacity: 0.7;

  transition: all 0.15s ease-in-out;

  ${Container}:hover & {
    background-image: url(${arrowIconColor});
  }
`;

const ScheduleWithoutTimeCell = styled(PList)`
  opacity: 0.5;
`;

const OnlineIndicator = styled.div`
  width: 4px;
  height: 61px;
  background-color: ${colors.magenta};
  opacity: ${props => (props.online ? 1 : 0)};
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
`;

const Badge = styled.span`
  padding: 4px 8px;
  border: 1px solid ${props => props.color || colors.magenta};
  border-radius: 99em;
  color: ${props => props.color || colors.magenta};
  margin-left: 8px;
  font-size: 11px;
  line-height: 11px;
  vertical-align: middle;
`;

const Description = styled.div`
  @media (max-width: 768px) {
    max-width: calc(100% - 100px);
  }
`

const CommentPList = styled(PList)`
  ${() =>
    document.body &&
    "WebkitLineClamp" in document.body.style &&
    "WebkitBoxOrient" in document.body.style
      ? css`
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        `
      : css`
          white-space: nowrap;
        `}
  width: max-content;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 640px;
  @media (max-width: 768px) {
    max-width: none;
    width: 100%;
  }
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconImage = styled.img`
  height: 20px;
  margin-right: 4px;
  opacity: 0.6;
`;

export const ScheduleHour = (props: { item: ScheduleReserved, onClick?: () => mixed }) => {
  const scheduleStore = useStore(dataStore);
  let isDisabled = props.item.type === "home_visit";
  const currentDate = moment().format(SERVER_DATE_FORMAT);
  if (!isDisabled) {
    if (scheduleStore.isEditSchedule) {
      isDisabled = props.item.time
        ? !isSameOrAfterDateAndTime(props.item.date, props.item.time)
        : !isSameOrAfterDate(props.item.date);
    } else {
      isDisabled = !props.item.medical_file;
    }
  }

  const timeByTimeZoneUser = props.item.datetime
    ? moment(props.item.datetime).format(READABLE_TIME_FORMAT_MOMENT)
    : "";
  const timeByTimeZoneClinic = formatScheduleTimeForDisplay(props.item.time);
  const isDifferentTimeZones = timeByTimeZoneClinic && timeByTimeZoneClinic !== timeByTimeZoneUser;
  const isShowBadgeByData = (data) => data?.some((item) => currentDate <= moment(item.end_date).format(SERVER_DATE_FORMAT)) ?? false;

  return (
    <Container role="button" onClick={props.onClick} isDisabled={isDisabled}>
      <OnlineIndicator online={false} />
      <Info>
        <TimeContainer>
          {timeByTimeZoneUser && timeByTimeZoneClinic ? (
            <Tooltip title={isDifferentTimeZones && `По часовому поясу филиала - ${timeByTimeZoneClinic}`}>
              <Box sx={{ display: "flex", gap: "2px" }}>
                <PList modifiers="disabled">{timeByTimeZoneUser}</PList>
                {isDifferentTimeZones && <Indicator />}
              </Box>
            </Tooltip>
          ) : (
            <ScheduleWithoutTimeCell modifiers="disabled">—:—</ScheduleWithoutTimeCell>
          )}
        </TimeContainer>
          <Description>
            <FullName>
              <NameWrapper>
                {
                  isChatSchedule(props.item.type) && (
                    <IconImage src={chat} alt="Chat icon"/>
                  )
                }
                {props.item.patient_full_name}{" "}
                {isShowBadgeByData(props.item?.medical_file?.patient?.policies) && <Badge color={colors.blue}>ДМС</Badge>}
                {isShowBadgeByData(props.item?.medical_file?.patient?.letters_guarantee) && <Badge>ГП</Badge>}
                {props.item.medical_file?.badge ? (
                  <Badge>{props.item.medical_file.badge}</Badge>
                ) : null}
              </NameWrapper>
            </FullName>
            {props.item.comment &&
              <Tooltip enterDelay={500} title={<span>{props.item.comment}</span>}>
                <CommentPList>{props.item.comment}</CommentPList>
              </Tooltip>
            }
          </Description>
      </Info>
      {scheduleStore.isEditSchedule && (process.env.APP_FLAVOR === "telemed") && (
        <DeleteScheduleButtonContainer>
          <DeleteButtonIcon onClick={() => changeDataDeleteAppointment(props.item)} />
        </DeleteScheduleButtonContainer>
      )}
      {props.item.medical_file && !scheduleStore.isEditSchedule && <Arrow />}
    </Container>
  );
};
