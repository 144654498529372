import React, { useContext, useMemo } from "react";
import styled from "styled-components";
import { keyBy, get, orderBy } from "lodash";
import useReactRouter from "use-react-router";
import moment from "moment";
import {
  isFieldWithoutLabel,
  isLabelNeededAtGroup,
  READABLE_DATE_FORMAT
} from "../../domain/services/field";
import { Fieldset, Legend, Container, LegendText } from "../form/Fieldset";
import { SummaryFormLayout } from "./SummaryFormLayout";
import { RoundedRectangleWithShadow } from "../styleguide/RoundedRectangleWithShadow";
import { colors } from "../styleguide/colors";
import { TextView } from "../form/TextView";
import { SummaryFormSection } from "./SummaryFormSection";
import { FileGroup } from "../file/FileGroup";
import { TextareaView } from "../form/TextareaView";
import { HeadingH4, PNormal } from "../styleguide/typography";
import { SummaryButton } from "./SummaryButton";
import { FolderPageContext } from "./FolderPageContext";
import { SettingsButton } from "./SettingsButton";
import { SummaryCells } from "./SummaryCells";
import { LinkView } from "../form/LinkView";
import { useSummaryContext } from './SummaryContext';
import { Checkmark } from '../buttons/Checkmark';

const SectionsContainer = styled(RoundedRectangleWithShadow)`
  width: 100%;
  background-color: ${colors.white};
  padding: 24px 32px;
  @media (max-width: 768px) {
    padding: 20px 25px;
  }
  @media (max-width: 480px) {
    padding: 15px 20px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    gap: 10px;
    flex-wrap: wrap;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 10px;
    @media (max-width: 768px) {  
      margin-left: 0;
    }
  }
  @media (max-width: 768px) {
    gap: 10px;
    flex-wrap: wrap;
  }
`;

const FieldsetContainer = styled.div`
  margin-top: 8px;
`;

const FieldsetLegendCommon = styled(Legend)`
  max-width: 300px;
`;

const FieldsetLegendContainer = styled(Container)`
  min-height: 23px;
`;

const FileGroupContainer = styled.div`
  min-width: unset;
`;

const FieldsetLegendText = styled(LegendText)`
  color: #3c3c3c;
`;

const ChildFieldContainer = styled.span`
  & + & {
    :before {
      content: ", ";
    }
  }
`;

const calculateHalfFieldsLength = fields => {
  return Math.ceil(fields.length / 2);
};

const customSummary = {
  oocytes: "ооцитов",
  vmi: "ВМИ"
};

export const Summary = props => {
  const { history } = useReactRouter();
  const { folder } = useContext(FolderPageContext);
  const { summary: checkedSummary, addSummary, removeSummary } = useSummaryContext();
  const { summary, primary, notNeedAddMinHeight } = props;

  const title = summary ? <HeadingH4>{summary.name}</HeadingH4> : null;

  console.log(summary?.name, summary?.id);

  const handleAnalyzesClick = () => {
    history.push(`/appointment/folder/${summary?.id}/analyzes`);
  };

  const handleAgreementsClick = () => {
    history.push(`/appointment/folder/${summary?.id}/agreements`);
  };

  const handleSettingsButtonClick = () => {
    history.push(`/appointment/folder/${summary?.id}/questionnaire`);
  };

  const handleEmbryosClick = () => {
    history.push(`/appointment/folder/${summary?.id}/embryos/${folder.medical_file}`);
  };

  const handleOocytesClick = () => {
    history.push(`/appointment/folder/${summary?.id}/oocytes/${folder.medical_file}`);
  };

  const toolButtons = (
    <ButtonsContainer>
      <Checkmark checked={checkedSummary.includes(summary?.id)} onClick={(value) => {
        if (summary) {
          value
            ? addSummary(summary.id)
            : removeSummary(summary.id);
        }
      }} tooltip="Отметить для печати"/>
      {
        summary?.name.includes(customSummary.vmi) ? (
          null
        ) : summary?.name.includes(customSummary.oocytes) ? (
        <SummaryButton onClick={handleOocytesClick} quantity={summary?.embryos_count}>
          Ооциты
        </SummaryButton>
        ) : (
          <SummaryButton onClick={handleEmbryosClick} quantity={summary?.embryos_count}>
            Эмбрионы
          </SummaryButton>
        )
      }
      {!summary.is_finished && primary && (
        <>
          <SummaryButton onClick={handleAnalyzesClick} quantity={folder?.patient_examinations || 0}>
            Анализы
          </SummaryButton>
          <SummaryButton onClick={handleAgreementsClick} quantity={folder?.patient_agreements || 0}>
            Согласия
          </SummaryButton>
          <SettingsButton onClick={handleSettingsButtonClick} />
        </>
      )}
    </ButtonsContainer>
  );

  const getFieldSingleTextValue = field => {
    if (field.value === null || field.value === undefined) return "-";
    if (
      field.field_type === "text" ||
      field.field_type === "textarea" ||
      field.field_type === "radiobutton" ||
      field.field_type === "select" ||
      field.field_type === "checkbox"
    ) {
      return field.value;
    } else if (field.field_type === "number") {
      return field.value.toString();
    } else if (field.field_type === "date") {
      if (typeof field.value !== "string") {
        return "Некорректная дата";
      }

      try {
        return moment(field.value).format(READABLE_DATE_FORMAT);
      } catch (e) {
        console.warn(e);
      }
    } else if (field.field_type === "multiselect") {
      if (Array.isArray(field.value)) return field.value.join(", ");
      return field.value || "-";
    } else if (field.field_type === "file") {
      return String(field.template_file);
    }
  };

  const getWidgetSingle = field => {
    if (
      field.field_type === "text" ||
      field.field_type === "date" ||
      field.field_type === "checkbox" ||
      field.field_type === "radiobutton" ||
      field.field_type === "number" ||
      field.field_type === "select" ||
      field.field_type === "multiselect"
    ) {
      return <TextView>{getFieldSingleTextValue(field) || "—"}</TextView>;
    } else if (field.field_type === "textarea") {
      return <TextareaView>{getFieldSingleTextValue(field) || "—"}</TextareaView>;
    } else if (field.field_type === "file") {
      return (
        <FileGroup
          files={field.template_file ? [field.template_file] : []}
          overrides={{
            GroupContainer: { component: FileGroupContainer }
          }}
        />
      );
    } else if (field.field_type === "partner") {
      if (!field.value) {
        return "-";
      }

      if (field.value.medical_file_id) {
        return (
          <LinkView to={`/appointment/new/${field.value.medical_file_id}/`}>
            {field.value.value}
          </LinkView>
        );
      } else {
        return <TextView>{field.value.value || "-"}</TextView>;
      }
    } else {
      return <div style={{ height: "50px" }}>Haven&quot;t widget for type {field.field_type}</div>;
    }
  };

  const getWidget = field => {
    if (field.field_type === "group") {
      return (
        <PNormal modifiers={"left"}>
          {field.children
            ? field.children.map(child => {
                return (
                  <ChildFieldContainer key={child.id}>
                    {isLabelNeededAtGroup(child) && (
                      <React.Fragment>{child.name}:&nbsp;</React.Fragment>
                    )}
                    {getWidgetSingle(child)}
                  </ChildFieldContainer>
                );
              })
            : "-"}
        </PNormal>
      );
    } else {
      return getWidgetSingle(field);
    }
  };

  const cells = useMemo(() => {
    const cellSection = summary.summary_sections.find(c => c.compact);
    if (!cellSection) return null;

    const fields = summary.summary_field_values
      .filter(f => f.summary_field?.summary_section?.id === cellSection?.id && f.value !== null)
      .map(f => ({ name: f.summary_field.name, value: f.value }));
    if (!fields.length) return null;

    return <SummaryCells cells={fields} />;
  }, [summary]);

  const sections = useMemo(() => {
    const sectionsById = orderBy(summary.summary_field_values, "summary_field.position").reduce(
      (result, field) => {
        const sectionId = field.summary_field?.summary_section?.id;
        if (!sectionId) return result;

        const fullField = { ...field, ...field.summary_field };

        /**
         * If there's no specified section or value of a field is empty then skip
         */
        if (!get(result, sectionId, false) || field.value === null || field.value === "") {
          return result;
        }

        if (!get(result[sectionId], "fields", false)) {
          result[sectionId].fields = [fullField];
        } else {
          result[sectionId].fields.push(fullField);
        }

        return result;
      },
      keyBy(summary.summary_sections.filter(s => !s.compact), "id")
    );

    return summary.summary_sections.filter(s => {
      const section = sectionsById[s.id];
      if (!section) return false;
      return section.fields && section.fields.length > 0;
    });
  }, [summary.summary_sections, summary.summary_field_values]);

  const renderedSections = summary ? (
    <SectionsContainer>
      <HeaderContainer>
        {title}
        {toolButtons}
      </HeaderContainer>
      {cells}
      {sections.map(section => {
        return (
          <SummaryFormSection
            {...section}
            key={section.id}
            id={section.id.toString()}
            showPrintCheckmark={false}
            printCheckmarkChecked={false}
            halfFieldsLength={calculateHalfFieldsLength(section.fields)}>
            {section.fields.map(field => {
              if (field.value) {
                const widget = getWidget(field);
                const isWithoutLabel = isFieldWithoutLabel(field);
                return (
                  <FieldsetContainer key={field.id}>
                    {!isWithoutLabel ? (
                      <Fieldset
                        overrides={{
                          Container: {
                            component: FieldsetLegendContainer
                          },
                          Legend: {
                            component: FieldsetLegendCommon
                          },
                          LegendText: {
                            component: FieldsetLegendText
                          }
                        }}
                        legend={`${field.name}:`}>
                        {widget}
                      </Fieldset>
                    ) : (
                      widget
                    )}
                  </FieldsetContainer>
                );
              } else {
                return null;
              }
            })}
          </SummaryFormSection>
        );
      })}
    </SectionsContainer>
  ) : null;

  return <SummaryFormLayout notNeedAddMinHeight={notNeedAddMinHeight} metaInfo="" sections={renderedSections} />;
};
