// @flow
import * as React from "react";

import { getNounEndingByNumber } from "../../utils/getNounEndingByNumber";
import { HeadingH2 } from "../styleguide/typography";
import {
  Avatar,
  AvatarPlaceholder,
  Container as GreetingContainer,
  InnerContainer,
  Text,
  TextContainer,
  ContainerWrapper
} from "./greetingCommon";
import { ButtonWithIcon } from "../buttons/ButtonWithIcon";
import styled from "styled-components";
import { useStore } from "effector-react";
import { dataStore, changeViewSchedule } from "../schedule-page/scheduleStore";
import { observer } from "mobx-react";
import { FEATURE, useApplicationFeatureEnabled } from "../../utils/applicationFeatures";

const Container = styled(GreetingContainer)`
  align-items: flex-end;
  justify-content: space-between;
`;

export const GreetingSchedule = observer((props: {|
  firstName: string,
  lastName?: string,
  avatar: ?string,
  numberOfPatients: ?number,
  page?: string
|}) => {
  const { firstName, lastName, numberOfPatients, page } = props;
  const scheduleStore = useStore(dataStore);
  const canEditSchedule = useApplicationFeatureEnabled(FEATURE.EDIT_SCHEDULE);
  const canBlockChat = useApplicationFeatureEnabled(FEATURE.CAN_BLOCK_CHAT_FOR_A_DAY);

  return (
    <Container>
      <ContainerWrapper>
        {props.avatar ? (
          <Avatar avatar={props.avatar} />
        ) : (
          <AvatarPlaceholder>
            {(lastName ? lastName.slice(0, 1) : "") + firstName.slice(0, 1)}
          </AvatarPlaceholder>
        )}
        <InnerContainer>
          <HeadingH2>Мое расписание</HeadingH2>
          <TextContainer>
            <Text as="span">
              Сегодня {page === "hospital" ? "в стационаре" : "у вас"}{" "}
            </Text>
            {numberOfPatients ? (
              <Text as="span" modifiers={["bold", "color"]}>
                {numberOfPatients}{" "}
                {getNounEndingByNumber(numberOfPatients, ["пациент", "пациента", "пациентов"])}
              </Text>
            ) : (
              <Text as="span">нет пациентов</Text>
            )}
          </TextContainer>
        </InnerContainer>
      </ContainerWrapper>
      {(canEditSchedule || canBlockChat || process.env.APP_FLAVOR === "telemed") && page !== "hospital" && (
        <ButtonWithIcon onClick={() => changeViewSchedule(!scheduleStore.isEditSchedule)}
                        overridesDefaultButton={{
                          Icon: {
                            props: {
                              modifiers: scheduleStore.isEditSchedule ? "check" : "edit",
                            }
                          }
                        }}>
          { scheduleStore.isEditSchedule ? "Готово" : "Изменить расписание" }
        </ButtonWithIcon>
      )}
    </Container>
  );
});
