//@flow
import React from "react";
import styled from "styled-components";
import { createEvent, createStore } from "effector";
import { useStore } from "effector-react";
import { getNounEndingByNumber } from "../../utils/getNounEndingByNumber";
import { useToast } from "../toast/useToast";
import { parseMilliseconds } from "../../utils/parseMilliseconds";
import { useApiRequest } from '../../apiv2/useApiRequest';
import apiv2 from '../../apiv2';

const ToastContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  line-height: 18px;
`;

const feedbackToastStore = createStore(false);
const setFeedbackShown = createEvent("setFeedbackShown");
feedbackToastStore.on(setFeedbackShown, (state, payload) => payload);

export const useFeedbackCountMessage = () => {
  const toastShowed = useStore(feedbackToastStore);
  let { data: feedbackCount } = useApiRequest(async () => {
    return await apiv2.feedback.getFeedbacksCount();
  });

  const { showToast } = useToast();

  if (!feedbackCount || feedbackCount.need_callback === 0) {
    return null;
  }

  if (!toastShowed) {
    setFeedbackShown(true);
    showToast(
      <ToastContainer>
        {feedbackCount.need_callback}{" "}
        {getNounEndingByNumber(feedbackCount.need_callback, ["пациент", "пациента", "пациентов"])}{" "}
        {getNounEndingByNumber(feedbackCount.need_callback, ["ожидает", "ожидают", "ожидают"])}{" "}
        вашего звонка
      </ToastContainer>,
      {
        duration: parseMilliseconds("60m")
      }
    );
  }
};
