import styled from "styled-components";
import { withModifiers } from "with-modifiers";
import { breakpoints, corporateTextPalette } from "./common";

const colorsModifiers = {
  white: () => `color: ${corporateTextPalette.white};`,
  black: () => `color: ${corporateTextPalette.black};`,
  gray: () => `color: ${corporateTextPalette.gray};`,
  lightGray: () => `color: ${corporateTextPalette.lightGray};`,
  pink: () => `color: ${corporateTextPalette.pink};`,
  red: () => `color: ${corporateTextPalette.red};`,
  green: () => `color: ${corporateTextPalette.green};`
};

export const Typography = styled.span`
  font-family: Graphik;
  color: ${corporateTextPalette.black};
`;

export const CorporateTitle = withModifiers({
  ...colorsModifiers,
  normalWeight: () => `
    font-weight: 400;
  `,
  highWeight: () => `
    font-weight: 700;
  `,
  xs: () => `
    font-size: 12px;
    ${breakpoints.desktop} {
      font-size: 10px;
    }
    ${breakpoints.tablet} {
      font-size: 8px;
    }
  `,
  sm: () => `
    font-size: 18px;
    ${breakpoints.desktop} {
      font-size: 16px;
    }
    ${breakpoints.tablet} {
      font-size: 14px;
    }
  `,
  md: () => `
    font-size: 20px;
    ${breakpoints.desktop} {
      font-size: 18px;
    }
    ${breakpoints.tablet} {
      font-size: 16px;
    }
  `,
  lg: () => `
    font-size: 22px;
    ${breakpoints.desktop} {
      font-size: 20px;
    }
    ${breakpoints.tablet} {
      font-size: 18px;
    }
  `
})(
  modifier => styled(Typography)`
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
    ${breakpoints.desktop} {
      font-size: 22px;
    }
    ${breakpoints.tablet} {
      font-size: 20px;
    }

    ${modifier};
  `
);

export const CorporateSubTitle = withModifiers({
  ...colorsModifiers,
  xs: () => `
    ${breakpoints.all} {
      font-size: 10px;
    }
  `,
  sm: () => `
    ${breakpoints.all} {
      font-size: 12px;
    }
  `,
  md: () => `
    font-size: 14px;
    ${breakpoints.desktop} {
      font-size: 12px;
    }
    ${breakpoints.tablet} {
      font-size: 10px;
    }
  `
})(
  modifier => styled(Typography)`
    font-size: 16px;
    line-height: 130%;
    ${breakpoints.desktop} {
      font-size: 14px;
    }
    ${breakpoints.tablet} {
      font-size: 12px;
    }

    ${modifier};
  `
);
