// @flow
import React, { Fragment } from "react";
import { Divider, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import { styled } from "@mui/material/styles";
import FolderIcon from "../../assets/icons/corporate-portal/fileManager/folderIcon.inline.svg";
import PdfIcon from "../../assets/icons/corporate-portal/fileManager/pdfIcon.inline.svg";
import DocIcon from "../../assets/icons/corporate-portal/fileManager/docIcon.inline.svg";
import TxtIcon from "../../assets/icons/corporate-portal/fileManager/txtIcon.inline.svg";
import VideoIcon from "../../assets/icons/corporate-portal/fileManager/videoIcon.inline.svg";
import ImageIcon from "../../assets/icons/corporate-portal/fileManager/imageIcon.inline.svg";
import UnknownFormatIcon from "../../assets/icons/corporate-portal/fileManager/unknownFormatIcon.inline.svg";

// ToDo После создании сторы, можнно вынести
export const FILE_TYPES = {
  FOLDER: "folder",
  PDF: "pdf",
  DOC: "doc",
  TXT: "txt",
  VIDEO: "video",
  IMAGE: "image",
  UNKNOWN_FORMAT: "unknown_format"
};

const ICON_BY_TYPE = {
  [FILE_TYPES.FOLDER]: <FolderIcon />,
  [FILE_TYPES.PDF]: <PdfIcon />,
  [FILE_TYPES.DOC]: <DocIcon />,
  [FILE_TYPES.TXT]: <TxtIcon />,
  [FILE_TYPES.VIDEO]: <VideoIcon />,
  [FILE_TYPES.IMAGE]: <ImageIcon />,
  [FILE_TYPES.UNKNOWN_FORMAT]: <UnknownFormatIcon />
};

const FileManagerContainer = styled(List)`
  .MuiButtonBase-root {
    padding: 12px 16px;
  }
  .MuiListItemIcon-root {
    min-width: auto;
    padding-right: 8px;
  }
`;

export const CorporateFileManager = props => {
  if (!props.files.length) {
    return null;
  }

  const handleOnClickFile = (type, fileId) => () => {
    if (type === FILE_TYPES.FOLDER) {
      console.log("Логика на переход в другую папку", fileId);
    } else {
      console.log(`Логика на скачивание файла с типом ${type}`, fileId);
    }
  };

  return (
    <FileManagerContainer>
      {props.files.map(({ id, type = FILE_TYPES.UNKNOWN_FORMAT, name = "Файл" }, index) => {
        return (
          <Fragment key={id}>
            {!index && <Divider />}
            <ListItem disablePadding>
              <ListItemButton onClick={handleOnClickFile(type, id)}>
                <ListItemIcon>{ICON_BY_TYPE[type]}</ListItemIcon>
                <ListItemText primary={name} />
              </ListItemButton>
            </ListItem>
            <Divider />
          </Fragment>
        );
      })}
    </FileManagerContainer>
  );
};
