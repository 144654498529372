// @flow
import React from "react";
import { CorporateWrapper } from "../styled/CorporateWrapper.styled";
import { Box } from "@mui/material";
import { CorporateContent } from "../components/CorporateContent";
import { CorporateSubTitle, CorporateTitle } from "../styled/CorporateTypography.styled";
import { CorporateFileManager, FILE_TYPES } from "../components/CorporateFileManager";

const files = [
  { id: 1, type: FILE_TYPES.FOLDER, name: "Секретная папка" },
  { id: 2, type: FILE_TYPES.PDF, name: "Файл с pdf-форматом" },
  { id: 3, type: FILE_TYPES.DOC, name: "Файл с doc-форматом" },
  { id: 4, type: FILE_TYPES.TXT, name: "Файл с txt-форматом" },
  { id: 5, type: FILE_TYPES.VIDEO, name: "Файл с video-форматом" },
  { id: 6, type: FILE_TYPES.IMAGE, name: "Файл с image-форматом" },
  { id: 7, type: FILE_TYPES.UNKNOWN_FORMAT, name: "Файл с неизвестным форматом" }
];

export const CorporateDocumentsPage = () => {
  return (
    <CorporateContent>
      <CorporateWrapper>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px"
          }}>
          <CorporateTitle modifiers={"md"}>Документы</CorporateTitle>
          <CorporateSubTitle>Контент для страницы "Документы"</CorporateSubTitle>
          <CorporateFileManager files={files} />
        </Box>
      </CorporateWrapper>
    </CorporateContent>
  );
};
