// @flow
import { createEffect, createEvent, createStore, forward, type Store, combine } from "effector";
import type { AppointmentWithDetails, TAppointment_id } from "../domain/entities/Appointment";
import type { Checklist, TChecklistGuid } from "../domain/entities/Checklist";
import type { Diagnosis, TDiagnosis_id } from "../domain/entities/Diagnosis";
import type { TChecklistDestination } from "../domain/value-objects/TShortcuts";
import { tokenStore } from "./auth";
import { getDiagnosis_id } from "../domain/entities/Diagnosis";
import type { Conclusion } from "../domain/entities/Conclusion";
import * as R from "ramda";
import { transformToHandler } from "../utils/transformToHandler";
import { concatRight } from "../utils/concatRight";
import { getAppointment_id } from "../domain/entities/Appointment";
import { reorder } from "../utils/reorder";
import type { TypeOfConclusion } from "../domain/entities/Appointment";
import type { TConclusionStore } from "../domain/entities/Conclusions";
import type {
  ConclusionGuide,
  ConclusionGuideField,
  ConclusionGuideItem
} from "../domain/value-objects/ConclusionGuide";
import type { Examination } from "../domain/entities/Examination";
import _ from "lodash";
import lodashContrib from 'lodash-contrib';
import { appointmentStore, setAppointment } from "./appointment";
import moment from "moment";
import { READABLE_DATE_FORMAT } from "../utils/dates";
import apiv2 from '../apiv2';

export const processExaminationDateRange = (
  item: Examination,
  appointment: AppointmentWithDetails
) => {
  if (item.period) {
    const startDate = moment(appointment.date_created).isBefore(new Date(), "d")
      ? moment()
      : moment(appointment.date_created);

    return [
      startDate.format(READABLE_DATE_FORMAT),
      startDate.add(item.period && item.period - 1, "d").format(READABLE_DATE_FORMAT)
    ];
  }

  return null;
};

let debouncedSaveDecisionValues: Function = null;

export const setSaveDecisionValues = (appointment: AppointmentWithDetails) => {
  debouncedSaveDecisionValues = _.debounce(async () => {
    const token = tokenStore.getState();
    const appointment_id = getAppointment_id(appointment);

    if (!token || !appointment_id) {
      return;
    }

    try {
      const primaryDiagnosesValue =
        primaryDiagnoses.getState()[appointment_id] || appointment.primary_diagnoses || [];
      const secondaryDiagnosesValue =
        secondaryDiagnoses.getState()[appointment_id] || appointment.secondary_diagnoses || [];
      const examinationsChecklists =
        appointmentExaminationsStore.getState()[appointment_id] ||
        (appointment.examination_checklist && appointment.examination_checklist.items) ||
        [];
      const medicationChecklists =
        appointmentMedicationStore.getState()[appointment_id] ||
        (appointment.medication_checklist && appointment.medication_checklist.items) ||
        [];
      const recommendationChecklists =
        appointmentRecommendationsStore.getState()[appointment_id] ||
        (appointment.recommendation_checklist && appointment.recommendation_checklist.items) ||
        [];
      const typeOfConclusion = conclusionTypeStore.getState() || null;
      const newAppointment = await apiv2.appointments.postSetConclusion({
        appointment_id,
        type_of_ppr: typeOfConclusion,
        primary_diagnoses: primaryDiagnosesValue,
        secondary_diagnoses: secondaryDiagnosesValue,
        examination_checklist: { items: (examinationsChecklists: any) },
        medication_checklist: { items: (medicationChecklists: any) },
        recommendation_checklist: { items: (recommendationChecklists: any) }
      });
      setAppointment(newAppointment);
    } catch (e) {
      console.log(e);
    }
  }, 500);
};

export const primaryDiagnoses = createStore<{ [TAppointment_id]: ?Array<Diagnosis> }>({});

const addNewPrimaryDiagnosisAttached = createEvent<{
  appointment: AppointmentWithDetails,
  diagnosis: Diagnosis
}>();

const removePrimaryDiagnosisAttached = createEvent<{
  appointment: AppointmentWithDetails,
  diagnosisId: TDiagnosis_id
}>();

export const setConclusionType = createEvent<*>("setConclusionType");

const filterPrimaryDiagnosisAdding = appointment => diagnosis =>
  R.compose(
    R.not,
    R.includes(getDiagnosis_id(diagnosis)),
    R.map(getDiagnosis_id),
    R.defaultTo([]),
    R.defaultTo(appointment.primary_diagnoses),
    R.prop((getAppointment_id(appointment): any))
  )(primaryDiagnoses.getState());

export const addNewPrimaryDiagnosis = (appointment: AppointmentWithDetails) => {
  const event = createEvent<Diagnosis>();
  forward({
    from: event
      .filter({
        fn: filterPrimaryDiagnosisAdding(appointment)
      })
      .map(payload => ({ appointment, diagnosis: payload })),
    to: addNewPrimaryDiagnosisAttached
  });
  return event;
};

export const removePrimaryDiagnosis = (appointment: AppointmentWithDetails) => {
  const event = createEvent<TDiagnosis_id>();
  forward({
    from: event.map(payload => ({ appointment, diagnosisId: payload })),
    to: removePrimaryDiagnosisAttached
  });
  return event;
};

export const isPrimaryDiagnosesEmpty = () =>
  lodashContrib.pipeline(primaryDiagnoses, store => {
    const storeState = store.getState();
    const entries = Object.entries(storeState);

    if (entries.length <= 0) return true;

    return entries.reduce((state, [, entryValue]) => {
      // $FlowFixMe
      return state && entryValue.length <= 0;
    }, true);
  });
  // primaryDiagnoses.thru<boolean>(store => {
  //   const storeState = store.getState();
  //   const entries = Object.entries(storeState);
  //
  //   if (entries.length <= 0) return true;
  //
  //   return entries.reduce((state, [, entryValue]) => {
  //     // $FlowFixMe
  //     return state && entryValue.length <= 0;
  //   }, true);
  // });

export const fetchConclusion = createEffect<
  { diagnosis: Diagnosis, appointment: AppointmentWithDetails },
  ?(Conclusion[]),
  *
>({
  handler: async ({ diagnosis, appointment }) => {
    const token = tokenStore.getState();

    if (!token) {
      return;
    }

    return await apiv2.appointments.getConclusion({
      diagnosis: getDiagnosis_id(diagnosis),
      organization_id: appointment.medical_file.organization.id
    }).then(result =>
      result.map(item => {
        if (item.examination_checklist && item.examination_checklist.items.length) {
          item.examination_checklist.items = item.examination_checklist.items.map(examination => {
            examination.items = examination.items.map(child => {
              if (!child.count) {
                child.count = 1;
              }
              return child;
            });
            return examination;
          });
        }
        return { ...item, appointmentId: appointment.id };
      })
    );
  }
});

type UpdateAppointmentConclusionsResult = {
  appointmentId: TAppointment_id,
  conclusionType: TypeOfConclusion,
  conclusions: {
    diagnosisId: TDiagnosis_id,
    conclusion: Array<Conclusion>
  }[],
  examination_checklist?: { items: Array<Checklist> },
  medication_checklist?: { items: Array<Checklist> },
  recommendation_checklist?: { items: Array<Checklist> }
};

export const updateAppointmentConclusions = createEffect<
  AppointmentWithDetails,
  ?UpdateAppointmentConclusionsResult,
  *
>({
  handler: async appointment => {
    const token = tokenStore.getState();
    if (!token) {
      return;
    }
    const result: UpdateAppointmentConclusionsResult = {
      appointmentId: getAppointment_id(appointment),
      conclusionType: null,
      conclusions: []
    };

    // setConclusionAppointment(appointment.type_of_ppr);

    if (appointment.primary_diagnoses) {
      for (let diagnosis of appointment.primary_diagnoses) {
        const diagnosisId = getDiagnosis_id(diagnosis);
        const conclusion = await apiv2.appointments.getConclusion({
          diagnosis: diagnosisId,
          organization_id: appointment.medical_file.organization.id
        });
        if (!!conclusion && conclusion.every(item => !!item.medication_checklist)) {
          result.conclusions.push({
            diagnosisId,
            conclusion
          });
        }
      }
    }
    if (appointment.examination_checklist) {
      result.examination_checklist = appointment.examination_checklist;
    }
    if (appointment.medication_checklist) {
      result.medication_checklist = appointment.medication_checklist;
    }
    if (appointment.recommendation_checklist) {
      result.recommendation_checklist = appointment.recommendation_checklist;
    }

    result.conclusionType = appointment.type_of_ppr;

    return result;
  }
});

forward({
  from: addNewPrimaryDiagnosisAttached,
  to: fetchConclusion
});

type TConclusionReceived = {
  +result: Array<Conclusion> | any,
  +params: any
};

const conclusionReceived = fetchConclusion.done
  .map(({ result, ...rest }: TConclusionReceived) => ({
    ...rest,
    result: result.map(({ examination_checklist, ...rest }) => ({
      ...rest,
      examination_checklist: {
        ...examination_checklist,
        // $FlowFixMe
        items: examination_checklist.items?.map(({ items, ...rest }) => ({
          ...rest,
          items: items.map(item => ({
            ...item,
            examination_id: item.id,
            dateRange: null
          }))
        }))
      }
    }))
  }))
  .filter({
    fn: ({ result }: TConclusionReceived) => {
      return !!result && !!result.every(item => item.medication_checklist);
    }
  });

const concatChecklistWithConclusion = field => payload => {
  if (_.isEmpty(payload.result)) {
    return;
  }

  const type = conclusionTypeStore.getState();
  const index =
    payload.result.length === 1
      ? 0
      : payload.result.findIndex(item => item.conclusion_type === (!type ? "ADULT" : type));

  return R.over(
    R.lensProp("" + getAppointment_id(payload.params.appointment)),
    R.compose(
      concatRight(R.view(R.lensPath(["result", index, field, "items"]), payload)),
      R.defaultTo([]),
      R.defaultTo(R.view(R.lensPath(["params", "appointment", field, "items"]), payload))
    )
  );
};

const rejectingByDiagnosesId = diagnosisId =>
  R.reject(
    R.compose(
      R.includes(diagnosisId),
      R.propOr([], "diagnoses")
    )
  );

const removeItemsFromChecklist = field => payload =>
  R.over(
    R.lensProp("" + getAppointment_id(payload.appointment)),
    R.compose(
      rejectingByDiagnosesId(payload.diagnosisId),
      R.defaultTo([]),
      R.defaultTo(R.view(R.lensPath(["params", "appointment", field, "items"]), payload))
    )
  );

const removeOnlyChildItemsFromChecklist = field => payload =>
  R.over(
    R.lensProp("" + getAppointment_id(payload.appointment)),
    R.compose(
      R.map(R.over(R.lensProp("items"), rejectingByDiagnosesId(payload.diagnosisId))),
      R.reject(item =>
        R.compose(
          R.and(
            R.compose(
              R.includes(payload.diagnosisId),
              R.propOr([], "diagnoses")
            )(item)
          ),
          R.not,
          R.any(
            R.compose(
              R.not,
              R.includes(payload.diagnosisId),
              R.propOr([], "diagnoses")
            )
          ),
          R.propOr([], "items")
        )(item)
      ),
      R.defaultTo([]),
      R.defaultTo(R.view(R.lensPath(["params", "appointment", field, "items"]), payload))
    )
  );

const transformItemChecklist = payload =>
  R.over(R.lensProp("" + payload.appointmentId), (groups = payload.default) => {
    const item = R.compose(
      R.view(R.lensPath(["items", payload.from.index])),
      R.find(R.propEq("guid", payload.from.groupGuid))
    )(groups);

    if (payload.from.groupGuid === payload.to.groupGuid) {
      return R.map(group => {
        if (group.guid === payload.from.groupGuid) {
          return R.over(R.lensProp("items"), reorder(payload.from.index, payload.to.index), group);
        }
        return group;
      }, groups);
    }

    return R.compose(
      R.map(group => {
        if (group.guid === payload.from.groupGuid) {
          return R.over(R.lensProp("items"), R.remove(payload.from.index, 1), group);
        }
        return group;
      }),
      R.map(group => {
        if (group.guid === payload.to.groupGuid) {
          return R.over(
            R.lensProp("items"),
            R.compose(
              R.insert(payload.to.index, item),
              R.defaultTo([])
            ),
            group
          );
        }
        return group;
      })
    )(groups);
  });

export const secondaryDiagnoses = createStore<{ [TAppointment_id]: ?Array<Diagnosis> }>({});

export const isSecondaryDiagnosesEmpty = () =>
  lodashContrib.pipeline(secondaryDiagnoses, store => {
    const storeState = store.getState();
    const entries = Object.entries(storeState);

    if (entries.length <= 0) return true;

    return entries.reduce((state, [, entryValue]) => {
      // $FlowFixMe
      return state && entryValue.length <= 0;
    }, true);
  });
  // secondaryDiagnoses.thru<boolean>(store => {
  //   const storeState = store.getState();
  //   const entries = Object.entries(storeState);
  //
  //   if (entries.length <= 0) return true;
  //
  //   return entries.reduce((state, [, entryValue]) => {
  //     // $FlowFixMe
  //     return state && entryValue.length <= 0;
  //   }, true);
  // });

export const dragExaminations = createEvent<{
  appointmentId: TAppointment_id,
  default: ?Array<Checklist>,
  from: { groupGuid: TChecklistGuid, index: number },
  to: { groupGuid: TChecklistGuid, index: number }
}>();

type ChecklistToAppointmentMap = {
  [TAppointment_id]: ?Array<Checklist>
};

export const setAppointmentExaminations = createEvent("setAppoitmentExaminations");

export const appointmentExaminationsStore: Store<ChecklistToAppointmentMap> = createStore({})
  .on(setAppointmentExaminations, (state, payload) => ({...state, ...payload}))
  .on(conclusionReceived, (state, payload) => {
    const checklist = transformToHandler(concatChecklistWithConclusion("examination_checklist"))(
      state,
      payload
    );

    return checklist;
  })
  .on(updateAppointmentConclusions.done, (state: ChecklistToAppointmentMap, { result }) => {
    if (result && result.examination_checklist && result.examination_checklist.items) {
      // $FlowFixMe
      return { ...state, [result.appointmentId]: result.examination_checklist.items };
    }
    return { ...state };
  })
  .on(
    removePrimaryDiagnosisAttached,
    transformToHandler(removeOnlyChildItemsFromChecklist("examination_checklist"))
  )
  .on(dragExaminations, transformToHandler(transformItemChecklist))
  .on(setConclusionType, (state, { type, appointment }) => {
    const appointmentId = getAppointment_id(appointment);
    const diagnosis = conclusionsStore.getState();
    const needConc = diagnosis.reduce((value, item) => {
      if (
        item.conclusion.length === 1 &&
        item.conclusion[0].examination_checklist &&
        item.conclusion[0].examination_checklist.items
      ) {
        // $FlowFixMe
        return [...value, ...item.conclusion[0].examination_checklist?.items];
      } else {
        const needTypeEl = item.conclusion.find(el => el.conclusion_type === type);
        if (needTypeEl?.examination_checklist?.items) {
          // $FlowFixMe
          return [...value, ...needTypeEl.examination_checklist.items];
        }
        return;
      }
    }, []);

    // $FlowFixMe
    return { ...state, [appointmentId]: needConc };
  });

export const setAppointmentMedication = createEvent("setAppointmentMedication");

export const appointmentMedicationStore: Store<ChecklistToAppointmentMap> = createStore({})
  .on(setAppointmentMedication, (state, payload) => ({...state, ...payload}))
  .on(conclusionReceived, transformToHandler(concatChecklistWithConclusion("medication_checklist")))
  .on(updateAppointmentConclusions.done, (state: ChecklistToAppointmentMap, { result }) => {
    if (result && result.medication_checklist && result.medication_checklist.items) {
      state[result.appointmentId] = result.medication_checklist.items;
    }
    return { ...state };
  })
  .on(
    removePrimaryDiagnosisAttached,
    transformToHandler(removeItemsFromChecklist("medication_checklist"))
  )
  .on(setConclusionType, (state, { type, appointment }) => {
    const appointmentId = getAppointment_id(appointment);
    const diagnosis = conclusionsStore.getState();
    const needConc = diagnosis.reduce((value, item) => {
      if (
        item.conclusion.length === 1 &&
        item.conclusion[0].medication_checklist &&
        item.conclusion[0].medication_checklist.items
      ) {
        // $FlowFixMe
        return [...value, ...item.conclusion[0].medication_checklist?.items];
      } else {
        const needTypeEl = item.conclusion.find(el => el.conclusion_type === type);
        if (needTypeEl?.medication_checklist?.items) {
          // $FlowFixMe
          return [...value, ...needTypeEl.medication_checklist.items];
        }
        return;
      }
    }, []);

    // $FlowFixMe
    return { ...state, [appointmentId]: needConc };
  });

export const setAppointmentRecommendations = createEvent("setAppointmentRecommendations");

export const appointmentRecommendationsStore: Store<ChecklistToAppointmentMap> = createStore({})
  .on(setAppointmentRecommendations, (state, payload) => ({...state, ...payload}))
  .on(
    conclusionReceived,
    transformToHandler(concatChecklistWithConclusion("recommendation_checklist"))
  )
  .on(updateAppointmentConclusions.done, (state: ChecklistToAppointmentMap, { result }) => {
    if (result && result.recommendation_checklist && result.recommendation_checklist.items) {
      state[result.appointmentId] = result.recommendation_checklist.items;
    }
    return { ...state };
  })
  .on(
    removePrimaryDiagnosisAttached,
    transformToHandler(removeItemsFromChecklist("recommendation_checklist"))
  )
  .on(setConclusionType, (state, { type, appointment }) => {
    const appointmentId = getAppointment_id(appointment);
    const diagnosis = conclusionsStore.getState();

    const needConc = diagnosis.reduce((value, item) => {
      if (
        item.conclusion.length === 1 &&
        item.conclusion[0].recommendation_checklist &&
        item.conclusion[0].recommendation_checklist.items
      ) {
        // $FlowFixMe
        return [...value, ...item.conclusion[0].recommendation_checklist?.items];
      } else {
        const needTypeEl = item.conclusion.find(el => el.conclusion_type === type);
        if (needTypeEl?.recommendation_checklist?.items) {
          // $FlowFixMe
          return [...value, ...needTypeEl.recommendation_checklist.items];
        }
        return;
      }
    }, []);

    // $FlowFixMe
    return { ...state, [appointmentId]: needConc };
  });

export const decisionTypeStores = {
  recommendation_checklist: appointmentRecommendationsStore,
  medication_checklist: appointmentMedicationStore,
  examination_checklist: appointmentExaminationsStore
};

export const isNotEmptyCheckedCurrentChecklist = (
  appointment_id: TAppointment_id,
  decisionType: TChecklistDestination
) => {
  const store = decisionTypeStores[decisionType].getState();
  return store[appointment_id]
    ? store[appointment_id].some(item => item.is_checked || item.items?.some(el => el.is_checked))
    : false;
};

type ConclusionGuideToDiagnosisMap = { [TDiagnosis_id]: ?ConclusionGuide };

export const diagnosisConclusionGuideStore = createStore<ConclusionGuideToDiagnosisMap>({});
diagnosisConclusionGuideStore
  .on(
    conclusionReceived,
    (store: ConclusionGuideToDiagnosisMap, { params, result }: TConclusionReceived) => {
      const type = conclusionTypeStore.getState();

      if (_.isEmpty(result)) {
        return R.set(R.lensProp("" + params.diagnosis.id), null, store);
      }

      const resultByType =
        result.length === 1
          ? result[0]
          : result.find(item => item.conclusion_type === (!type ? "ADULT" : type));
      return R.set(R.lensProp("" + params.diagnosis.id), resultByType && resultByType.guide, store);
    }
  )
  .on(
    updateAppointmentConclusions.done,
    (store, { result }: TUpdateAppointmentConclusionsResult) => {
      if (result.conclusions) {
        for (let conclusionItem of result.conclusions) {
          if (conclusionItem.conclusion.length === 1) {
            store[conclusionItem.diagnosisId] = conclusionItem.conclusion[0]?.guide;
          } else {
            store[conclusionItem.diagnosisId] =
              conclusionItem.conclusion.find(el => el.conclusion_type === result.conclusionType)
                ?.guide || null;
          }
        }
      }
      return { ...store };
    }
  )
  .on(removePrimaryDiagnosisAttached, (store: ConclusionGuideToDiagnosisMap, payload) => {
    // $FlowFixMe
    if ([payload.diagnosisId] in store) {
      delete store[payload.diagnosisId];
    }
    return { ...store };
  })
  .on(setConclusionType, (store, { type }) => {
    const conclusions: TConclusionStore = conclusionsStore.getState();

    return conclusions.reduce((acc, conclusionItem) => {
      if (conclusionItem.conclusion.length === 1) {
        return { ...acc, [conclusionItem.diagnosisId]: conclusionItem.conclusion[0]?.guide };
      } else {
        // $FlowFixMe
        const needEl = conclusionItem.conclusion.find(el => el.conclusion_type === type)?.guide;
        return { ...acc, [conclusionItem.diagnosisId]: needEl };
      }
    }, {});
  });

export const createConclusionGuideItemsStore = (
  diagnosisIds: Array<TDiagnosis_id>,
  type: ConclusionGuideField
) =>
  diagnosisConclusionGuideStore.map<{ [TDiagnosis_id]: ConclusionGuideItem }>(
    R.compose(
      R.map(R.prop(type)),
      // $FlowFixMe
      R.pick(diagnosisIds)
    )
  );

export const conclusionTypeStore = createStore<string | null>(null);

export const setConclusionAppointment = createEvent<*>("setConclusionAppointment");

export const setConclusionsByType = createEvent<*>("setConclusionsByType");

export const handleDiagnosisUpdated = createEvent<*>("handleDiagnosisUpdated");

type TUpdateAppointmentConclusionsResult = {
  +result: UpdateAppointmentConclusionsResult | any
};

conclusionTypeStore
  .on(setConclusionType, (store, { type }) => {
    return type;
  })
  .on(handleDiagnosisUpdated, (store, payload) => {
    if (payload?.some(item => item.conclusion?.length > 1)) {
      if (store === null || store === "") {
        return "ADULT";
      }
    } else {
      return null;
    }
  })
  .on(updateAppointmentConclusions.done, (store, payload: TUpdateAppointmentConclusionsResult) => {
    return payload.result.conclusionType;
  });

export const conclusionsStore = createStore<TConclusionStore>([]);

conclusionsStore
  .on(updateAppointmentConclusions.done, (store, payload: TUpdateAppointmentConclusionsResult) => {
    if (payload.result.conclusions.length > 0) {
      // $FlowFixMe
      return [...payload.result.conclusions];
    }

    return [];
  })
  .on(conclusionReceived, (store, payload) => {
    // $FlowFixMe
    if (payload.result.length > 0) {
      // $FlowFixMe
      return [...store, { diagnosisId: payload.params.diagnosis.id, conclusion: payload.result }];
    }

    return [...store];
  })
  .on(removePrimaryDiagnosisAttached, (store, payload) => {
    return store.filter(item => item.diagnosisId !== payload.diagnosisId);
  });

conclusionsStore.watch(store => handleDiagnosisUpdated(store));

combine(
  primaryDiagnoses,
  secondaryDiagnoses,
  appointmentExaminationsStore,
  appointmentMedicationStore,
  conclusionTypeStore,
  appointmentRecommendationsStore,
  diagnosisConclusionGuideStore,
  () => {
    if (debouncedSaveDecisionValues) {
      debouncedSaveDecisionValues();
    }
  }
);
