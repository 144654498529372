import React, {useCallback, useRef, useState, useMemo, useImperativeHandle, forwardRef, useEffect} from "react";
import _ from "lodash";
import { useAsyncFn, useClickAway } from "react-use";
import { arrayToEntriesById } from "../../utils/arrays";

import {
  getServices,
  addAppointmentService,
  removeAppointmentService,
  removeAppointmentPrescribedService, addAppointmentPrescribedService
} from "./api/services";

import { ServicesSuggestPreloader } from "./ServicesSuggestPreloader";

import * as S from "./styled/ServicesSuggest.styled";
import _debounce from "lodash/debounce";
import { ServicePriceInput } from "../appointment-page/ServicePriceInput";
import {DateRange} from "../decision-support/DateRange";
import {PointHintExamination} from "../decision-support/PointHintExamination";
import {AddCommentButton} from "../decision-support/AddCommentButton";
import {ModalPortal} from "../modal/ModalPortal";
import {CommentsModal} from "../decision-support/CommentsModal";
import {CommentForm} from "../decision-support/CommentForm";
import {CommentsModalProvider} from "../decision-support/CommentsModalProvider";
import styled from "styled-components";
import {colors} from "../styleguide/colors";
import {
  applicationFeatureEnabled,
  FEATURE,
  featureEnabledForOrganization,
  useApplicationFeatureEnabled
} from "../../utils/applicationFeatures";
import {getChecklistGuid} from "../../domain/entities/Checklist";
import {CheckboxLabel} from "../decision-support/CheckboxLabel";
import {getLastNameWithInitials} from "../../domain/services/doctor";
import {getAppointmentDateOrTime, getAppointmentDateTime} from "../../domain/services/appointments";
import {getNounEndingByNumber} from "../../utils/getNounEndingByNumber";
import {PList} from "../styleguide/typography";
import serviceStore from "../../stores/serviceStore";
import {observer} from "mobx-react";
import {Checkbox} from "../appointment-page/Checkbox";
import {SearchInputWrapper} from "./styled/ServicesSuggest.styled";
import {
  TeethPicker,
  teethIDsToString,
  teethIDsToValue,
  teethValueToArray
} from '../teeth-picker/TeethPicker';
import { useAutoFocusRef } from "../../utils/useAutoFocusRef";
import RcTooltip from "rc-tooltip";


const Ellipse = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 50%;
  margin: 0 8px;
  flex-shrink: 0;
  background: ${colors.darkGray};
`;

const SuggestTextButton = styled.span`
  color: #979797;
  cursor: pointer;
  flex-shrink: 0;
  
  &:hover {
    color: ${colors.magenta};
  }
  
  &:active {
    color: ${colors.magenta};
  }
`;

const SuggestDisplayTeeth = styled.span`
  font-size: 13px;
  color: #979797;
  flex-shrink: 0;
`;

const MissedExamination = observer(() => {
  if (!serviceStore.missedExamination?.length) {
    return null
  }
  return (
      <S.AllPriceBlock style={{fontWeight: 'normal'}}>
        <p style={{fontSize: 12}}>
          <RcTooltip animation="zoom" placement={"right"} overlay={<span>если соответствующая услуга внесена, то, пожалуйста, сообщите об этом в поддержку, и Асю обучат этому соответствию</span>}>
            <span style={{cursor: 'default'}}>(?) </span>
          </RcTooltip>
          Выбрано в разделе "Обследования", но Ася не смогла найти соответстие в разделе "Направления":
          <PList style={{color: colors.darkGray}}>
            {serviceStore.missedExamination.join('; ')}
          </PList>
        </p>
      </S.AllPriceBlock>
  );
})


export const ServicesSuggest = observer(forwardRef(({ appointment, disabled, onChange, isPrescribed, isPrevPrescribedLastDay, isPrevPrescribedPrevDays, addToServices, removeFromServices, canChooseFromAll, displayTeeth }, ref) => {
  const organizationId = appointment.medical_file.organization.id;

  serviceStore.setOrganizationId(organizationId);

  const suggestListRef = useRef();
  const [showInput, setShowInput] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeSuggestItemIndex, setActiveSuggestItemIndex] = useState(0);
  const [searchedServices, setSearchedServices] = useState(null);
  const [onlyFavorite, setOnlyFavorite] = useState(true);
  const canEditPrevServices = useApplicationFeatureEnabled(FEATURE.EDIT_PREV_SERVICES);

  let defaultSelectedServices = appointment.services;
  if (isPrevPrescribedLastDay) {
    disabled = !canEditPrevServices;
    let sortedPrevPrescribedServices = _.orderBy(appointment.prev_prescribed_services || [], ['appointment_date', 'appointment_id', 'created_at'], ['desc', 'desc', 'desc']);
    if (sortedPrevPrescribedServices[0]) {
      sortedPrevPrescribedServices = sortedPrevPrescribedServices.filter((s) => s.appointment_date === sortedPrevPrescribedServices[0].appointment_date);
    }
    defaultSelectedServices = sortedPrevPrescribedServices.map(s => ({...s, date_range: s.date_range?.value || null }))
  } else if (isPrevPrescribedPrevDays) {
    disabled = !canEditPrevServices;
    let sortedPrevPrescribedServices = _.orderBy(appointment.prev_prescribed_services || [], ['appointment_date', 'appointment_id', 'created_at'], ['desc', 'desc', 'desc']);
    if (sortedPrevPrescribedServices[0]) {
      sortedPrevPrescribedServices = sortedPrevPrescribedServices.filter((s) => s.appointment_date !== sortedPrevPrescribedServices[0].appointment_date);
    }
    defaultSelectedServices = sortedPrevPrescribedServices.map(s => ({...s, date_range: s.date_range?.value || null }))
  } else if (isPrescribed) {
    defaultSelectedServices = appointment.prescribed_services?.map(s => ({...s, date_range: s.date_range?.value || null }))
  }
  const [selectedServices, setSelectedServices] = useState(defaultSelectedServices);

  useEffect(() => {
      if (isPrescribed) {
          serviceStore.setPrescribedServices(selectedServices);
      }
  }, []);

  const $input = useAutoFocusRef(null, { showInput, selectedServices });
  const $inputContainer = useRef();

  const [{ loading: searchedServicesLoading }, loadServices] = useAsyncFn(
    async value => {

      if (!onlyFavorite || isPrescribed && value.length <3) {
        setSearchedServices([]);
        return;
      }
      let services = await getServices({ organizationId, searchQuery: value, favorites: onlyFavorite && !isPrescribed, forPrescribedSection: isPrescribed});

      if (!onlyFavorite || isPrescribed) {
        // для избранных услуг в Оказанных услугах эта сортировка не нужна, потомучто сортировка по частоте использования - на сервере
        services = serviceStore.methodSortServices(services, value);
      }
      setSearchedServices(services);
    },
    [organizationId, onlyFavorite]
  );

  useEffect(() => {
    if (isPrescribed) {
      const selectedServicesIds = selectedServices.map(s => s.id);
      const storeServicesIds = serviceStore.prescribedServices.map(s => s.id);

      if (!_.isEqual(storeServicesIds, selectedServicesIds)) {
        for (let id of selectedServicesIds) {
          if (!storeServicesIds.includes(id)) {
            handleRemoveService(id);
          } else {
            handleSelectService(serviceStore.prescribedServices.find(s => s.id === id));
          }
        }
        for (let id of storeServicesIds) {
          if (!selectedServicesIds.includes(id)) {
            handleSelectService(serviceStore.prescribedServices.find(s => s.id === id));
          }
        }
      }
    }
  }, [serviceStore.prescribedServices]);

  useEffect(() => {
    if (showInput) {
      handleShowInput();
    }
  }, [onlyFavorite]);

  const selectedServicesEntries = useMemo(() => arrayToEntriesById(selectedServices), [
    selectedServices
  ]);

  const filteredSearchedServices = useMemo(
    () => searchedServices && searchedServices.filter(({ id }) => !selectedServicesEntries[id]),
    [searchedServices, selectedServicesEntries]
  );

  const handleShowInput = useCallback(() => {
    setShowInput(true);

    if (!searchedServices) {
      clearStateSuggest();
      loadServices(searchQuery);
    }
  }, [loadServices, searchedServices]);

  // const [handleDebouncedInputChange] = useDebouncedCallback(value => {
  //   setSearchedServices(null);
  //   loadServices(value);
  // }, 500);

  const handleDebouncedInputChange = value => {
    clearStateSuggest();
    loadServices(value);
  }

  const handleSelectService = useCallback(
    service => {
      setSelectedServices(prev => {
        let services;
        service.count = service.count || 1;
        if (prev.some((item) => item.id === service.id)) {
          services = prev.map((item) => item.id === service.id ? service : item);
        } else {
          services = [...prev, service];
        }

        onChange?.(services);
        if (isPrescribed) {
          serviceStore.setPrescribedServices(services);
        }
        return services;
      });

      if (isPrescribed) {
        addAppointmentPrescribedService({
          appointmentId: appointment.id,
          serviceId: service.id,
          count: parseInt(service.count || 1),
          comment: service.comment,
          date_range: service.date_range,
          teeth: service.teeth
        });
      } else {
        addAppointmentService({
          appointmentId: appointment.id,
          serviceId: service.id,
          comment: service.comment,
          count: parseInt(service.count || 1),
          teeth: service.teeth
        });
      }

      setTimeout(() => {
        if (!filteredSearchedServices) {
          // такое случается когда filteredSearchedServices вызывается в компоненте предке, через ref
          return;
        }
        if (filteredSearchedServices.length - 1 < 1) {
          setActiveSuggestItemIndex(0);
        } else if (activeSuggestItemIndex === filteredSearchedServices.length - 1) {
          setActiveSuggestItemIndex(filteredSearchedServices.length - 2); // 2 так как выбранный элемент еще не удален из списка
        } else {
          const findIndexService = filteredSearchedServices.findIndex((item) => item.id === service.id);
          if (findIndexService < activeSuggestItemIndex) {
            setActiveSuggestItemIndex(activeSuggestItemIndex - 1);
          }
        }
      }, 0);
    },
    [appointment, onChange, setActiveSuggestItemIndex, filteredSearchedServices, activeSuggestItemIndex]
  );

  const handleRemoveService = useCallback(
    id => {
      setSelectedServices(prev => {
        const services = prev.filter(item => item.id !== id);

        onChange?.(services);
        if (isPrescribed) {
          serviceStore.setPrescribedServices(services);
        }

        return services;
      });
      if (isPrescribed) {
        removeAppointmentPrescribedService({appointmentId: appointment.id, serviceId: id});
      } else {
        removeAppointmentService({appointmentId: appointment.id, serviceId: id});
      }
    },
    [appointment, onChange]
  );



  useImperativeHandle(ref, () => ({
    async handleSelectService(service) {
      const services = await getServices({ organizationId, id: service.id, favorites: false });
      if (services[0]) {
        handleSelectService(services[0]);
      }
    },
    handleRemoveService(id) {
      handleRemoveService(id)
    },
    setDateRange(ids, value) {
      ids.forEach((id) => {
        const service = selectedServices.find(s => s.id === id);
        if (!service) {
          return;
        }
        service.date_range = value;
        handleSelectService(service);
      })
    },
    setComment(ids, value) {
      ids.forEach((id) => {
        const service = selectedServices.find(s => s.id === id);
        if (!service) {
          return;
        }
        service.comment = value;
        handleSelectService(service);
      })
    },
  }));

  const handleChangeSearchQuery = value => {
    clearStateSuggest(true);
    setSearchQuery(value);
  };

  const handleKeyDown = useCallback(
    e => {
      if (!filteredSearchedServices || filteredSearchedServices.length < 1 ) {
        return false;
      }
      switch (e.key) {
        case 'Enter':
          handleSelectService(filteredSearchedServices[activeSuggestItemIndex]);
          break;
        case 'ArrowDown':
          setActiveSuggestItemIndex(!!filteredSearchedServices[activeSuggestItemIndex + 1] ? activeSuggestItemIndex + 1 : 0)
          setTimeout(() => {
            const activeItemEl = suggestListRef.current.getElementsByClassName('active')[0];
            if (suggestListRef.current.offsetHeight + suggestListRef.current.scrollTop - activeItemEl.offsetTop < activeItemEl.offsetHeight) {
              suggestListRef.current.scrollTo(0, activeItemEl.offsetTop - suggestListRef.current.offsetHeight + activeItemEl.offsetHeight);
            }
            if (suggestListRef.current.scrollTop > activeItemEl.offsetTop) {
              suggestListRef.current.scrollTo(0, suggestListRef.current.scrollTop - (suggestListRef.current.scrollTop - activeItemEl.offsetTop));
            }
          })
          e.preventDefault();
          break;
        case 'ArrowUp':
          setActiveSuggestItemIndex(!!filteredSearchedServices[activeSuggestItemIndex - 1] ? activeSuggestItemIndex - 1 : filteredSearchedServices.length - 1)
          setTimeout(() => {
            const activeItemEl = suggestListRef.current.getElementsByClassName('active')[0];
            if (suggestListRef.current.scrollTop > activeItemEl.offsetTop) {
              suggestListRef.current.scrollTo(0, suggestListRef.current.scrollTop - (suggestListRef.current.scrollTop - activeItemEl.offsetTop));
            }
            if (suggestListRef.current.offsetHeight + suggestListRef.current.scrollTop - activeItemEl.offsetTop < activeItemEl.offsetHeight) {
              suggestListRef.current.scrollTo(0, activeItemEl.offsetTop - suggestListRef.current.offsetHeight + activeItemEl.offsetHeight);
            }
          })
          e.preventDefault();
          break;
      }
    },
    [appointment, filteredSearchedServices, activeSuggestItemIndex, suggestListRef]
  );

  useClickAway($inputContainer, () => {
    if (showInput) {
      $input.current.value = "";
      setSearchQuery("");
      clearStateSuggest(true);
      setShowInput(false);
    }
  });

  const clearStateSuggest = (needClearScroll) => {
    setSearchedServices(null);
    setActiveSuggestItemIndex(0);
    if (needClearScroll) {
      suggestListRef.current.scrollTo(0, 0);
    }
  }

  const selectedServicesAllPrice = () => {
    return selectedServices.reduce((acc, service) => {
      const count = service.count || 1;
      acc += service.price * count;
      return acc;
    }, 0);
  }

  let lastRenderAppointmentId = null;

  return (
    <S.Container>
      {selectedServices.map((service) => {
        let dmsCheckData = '';
        if (appointment.medical_file.patient.policies?.length) {
          if (service.dms_type === 'ENABLED') {
            dmsCheckData = 'по ДМС'
          } else if (service.dms_type === 'DISABLED') {
            dmsCheckData = 'оплата пациентом'
          } else {
            dmsCheckData = 'требуется согласование'
          }
        }
        let needAppointmentItem = false;
        let checkAppointmentItem = false;
        if (isPrevPrescribedPrevDays || isPrevPrescribedLastDay) {
          if (lastRenderAppointmentId !== service.appointment_id) {
            needAppointmentItem = true;
            checkAppointmentItem = selectedServices.filter(s => service.appointment_id === s.appointment_id).every(s => !!s.completed_appointment);
            lastRenderAppointmentId = service.appointment_id;
          }
        }

        return [
              ...(!needAppointmentItem ? [] : [
                  <S.ServiceItem
                      isAppointment
                      key={'appointment_'+service.appointment_id}
                      disabled={(disabled && !isPrevPrescribedPrevDays && !isPrevPrescribedLastDay) || showInput}
                      onClick={() => {
                        selectedServices.filter(s => service.appointment_id === s.appointment_id).forEach(s => {
                          const newValue = checkAppointmentItem ? null : appointment.id;
                          if (s.completed_appointment === newValue) {
                            return;
                          }
                          s.completed_appointment = newValue;
                          if (s.completed_appointment) {
                            addToServices(s);
                          } else {
                            removeFromServices(s.id);
                          }
                          addAppointmentPrescribedService({
                            appointmentId: s.appointment_id,
                            serviceId: s.id,
                            count: parseInt(s.count || 1),
                            comment: s.comment,
                            completed_appointment: s.completed_appointment,
                            date_range: s.date_range,
                          });
                        });
                        setSelectedServices([...selectedServices]);
                      }}
                  >
                    { (isPrevPrescribedLastDay || isPrevPrescribedPrevDays) &&
                        <CheckboxLabel
                            style={{margin: '0 10px 0 0'}}
                            modifiers={(!checkAppointmentItem && "disabled") || undefined}
                        />
                    }
                    <div style={{position: 'relative', flex: 1}}>
                      <S.ServiceRow>
                        <S.FlexBlockService>
                          {getLastNameWithInitials(service.appointment_doctor)} | {service.appointment_layout_name} | {getAppointmentDateTime({date: service.appointment_date, time: service.appointment_time})}
                        </S.FlexBlockService>
                      </S.ServiceRow>
                    </div>
                  </S.ServiceItem>
              ]),

              <S.ServiceItem
                  key={service.appointment_id + '_' + service.id}
                  disabled={(disabled && !isPrevPrescribedPrevDays && !isPrevPrescribedLastDay) || showInput}
                  onClick={() => {
                    if (!isPrevPrescribedLastDay && !isPrevPrescribedPrevDays) {
                      return;
                    }
                    service.completed_appointment = service.completed_appointment ? null : appointment.id;
                    if (service.completed_appointment) {
                      addToServices(service);
                    } else {
                      removeFromServices(service.id);
                    }
                    addAppointmentPrescribedService({
                      appointmentId: service.appointment_id,
                      serviceId: service.id,
                      count: parseInt(service.count || 1),
                      comment: service.comment,
                      completed_appointment: service.completed_appointment,
                      date_range: service.date_range,
                    });
                    setSelectedServices([...selectedServices]);
                  }}
              >
                { (isPrevPrescribedLastDay || isPrevPrescribedPrevDays) &&
                  <CheckboxLabel
                      style={{margin: '2px 10px 0 0'}}
                      modifiers={(!service.completed_appointment && "disabled") || undefined}
                  />
                }
                <div style={{position: 'relative', flex: 1, width: '100%'}}>
                  <S.ServiceRow>
                    <S.FlexBlockService>
                      <S.ServiceCode empty={!service.kdf_code}>
                        {service.kdf_code?.trim() || "————"}
                      </S.ServiceCode>
                      <S.ServiceName>{service.name} <small>{serviceStore.categoriesText(service)}</small></S.ServiceName>
                    </S.FlexBlockService>
                    <S.FlexBlockService>
                      <ServicePriceInput
                          readonly={disabled}
                          isActive
                          count={service.count}
                          handleChange={_debounce((value) => {
                            service.count = value;
                            handleSelectService(service)
                          })}
                          price={service.price}
                      />
                      { !isPrevPrescribedPrevDays && !isPrevPrescribedLastDay &&
                        <S.Remove onClick={() => handleRemoveService(service.id)}/>
                      }
                    </S.FlexBlockService>
                  </S.ServiceRow>
                  <S.ServiceRow isJustifyLeft isInline>
                    {isPrescribed &&
                        <>
                          {(!disabled || service.date_range) &&
                              <DateRange
                                  isLeftContainer
                                  value={service.date_range}
                                  onSetDateRange={value => {
                                    service.date_range = value;
                                    handleSelectService(service);
                                  }}
                                  onResetDateRange={() => {
                                    service.date_range = null;
                                    handleSelectService(service);
                                  }}
                                  hideIcon
                                  containerNotPadding
                                  defaultTitle="Добавить дату"
                                  modifiers="averageSize"
                                  color="darkGray"
                              />
                          }
                          {(!disabled || (service.date_range && (service.comment || displayTeeth))) &&
                              <Ellipse/>
                          }
                        </>
                    }
                    {displayTeeth && (
                      <>
                        {disabled ? (
                          <SuggestDisplayTeeth>{teethIDsToString(teethValueToArray(service.teeth), true)}</SuggestDisplayTeeth>
                        ) : (
                          <TeethPicker selectedTeeths={teethValueToArray(service.teeth)}
                                       handleComponent={<SuggestTextButton>{teethIDsToString(teethValueToArray(service.teeth))}</SuggestTextButton>}

                                       onChange={(value) => {
                                         const newValue = teethIDsToValue(value);

                                         if (service.teeth !== newValue) {
                                           service.teeth = newValue;
                                           handleSelectService(service);
                                         }
                                       }}
                          />
                        )}
                        {applicationFeatureEnabled(FEATURE.COMMENT_IN_SERVICES) && (!disabled || service.comment) &&
                          <Ellipse/>
                        }
                      </>
                    )}
                    {applicationFeatureEnabled(FEATURE.COMMENT_IN_SERVICES) && (!disabled || service.comment) &&
                        <CommentsModalProvider>
                          {({isModalShow, showModal, hideModal}) => (
                              <>
                                {service.comment ? (
                                    <PointHintExamination style={{ pointerEvents: disabled ? 'none' : 'all'}} onClick={showModal}>
                                      {service.comment}
                                    </PointHintExamination>
                                ) : (
                                    <AddCommentButton
                                        modifiers="averageSize"
                                        color="darkGray"
                                        containerNotPadding
                                        hideIcon
                                        onClick={showModal}
                                    />
                                )}
                                <ModalPortal>
                                  <CommentsModal isShow={isModalShow} hide={hideModal}>
                                    <CommentForm
                                        defaultValue={service.comment || ""}
                                        onClose={hideModal}
                                        onSubmit={value => {
                                          service.comment = value;
                                          handleSelectService(service);
                                          hideModal();
                                        }}
                                        onDelete={() => {
                                          service.comment = null;
                                          handleSelectService(service);
                                          hideModal();
                                        }}
                                        isAutofocus
                                    />
                                  </CommentsModal>
                                </ModalPortal>
                              </>
                          )}
                        </CommentsModalProvider>
                    }
                    {dmsCheckData &&
                        <>
                          {(!disabled || (service.date_range || service.comment)) &&
                              <Ellipse/>
                          }
                          { dmsCheckData }
                        </>
                    }
                  </S.ServiceRow>
                </div>
              </S.ServiceItem>,
        ];
      })}
      <S.Add disabled={disabled} onClick={handleShowInput} hide={showInput}>
        {disabled ? (
          disabled
        ) : (
          <>
            <S.Plus />
            Добавить услугу
          </>
        )}
      </S.Add>
      <S.InputContainer hide={!showInput} ref={$inputContainer}>
        <S.SearchInputWrapper>
          <S.Input
            placeholder="Название или код услуги"
            ref={$input}
            autoFocus
            onKeyDown={handleKeyDown}
            onChange={e => {
              handleChangeSearchQuery(e.target.value);
              handleDebouncedInputChange(e.target.value);
            }}
          />
          { !isPrevPrescribedPrevDays && !isPrevPrescribedLastDay && !isPrescribed && featureEnabledForOrganization(FEATURE.PRESCRIBED_SERVICES, organizationId) &&
              canChooseFromAll &&
            <Checkbox style={{minWidth: 210, marginLeft: 10}} text="Показывать только избранные" checked={onlyFavorite} onChange={() => {
              clearStateSuggest(true);
              setOnlyFavorite(!onlyFavorite);
            }}/>
          }
        </S.SearchInputWrapper>
        <S.SuggestList ref={suggestListRef} empty={filteredSearchedServices && filteredSearchedServices.length === 0}>
          {(searchedServicesLoading || filteredSearchedServices === null) && (
            <ServicesSuggestPreloader />
          )}
          {filteredSearchedServices && filteredSearchedServices.length < 500 &&
            filteredSearchedServices.map((item, index) => (
              <S.SuggestItem
                  className={index === activeSuggestItemIndex && 'active'}
                  modifiers={index === activeSuggestItemIndex && 'active'}
                  key={item.id}
                  onClick={() => handleSelectService(item)}>
                <S.ServiceCode empty={!item.kdf_code}>{item.kdf_code?.trim() || "————"}</S.ServiceCode>
                <S.ServiceName>{item.name} <small>{serviceStore.categoriesText(item)}</small></S.ServiceName>
              </S.SuggestItem>
            ))}

          {filteredSearchedServices && filteredSearchedServices.length === 0 && (
            <S.EmptyMessage>
              {isPrescribed ?
                  (searchQuery.length > 4
                      ? "Мы не нашли услугу с таким названием или кодом."
                      : "Начните вводить название услуги или её код")
                  :
                  (searchQuery
                ? "Мы не нашли услугу с таким названием или кодом. Возможно, ее нет в услугах закрепленных за вами в МИС(1с). Обратитесь к администратору или в техподдержку для добавления услуги."
                : "Здесь появятся услуги закрепленные за вами в МИС(1с). Обратитесь к администратору или в техподдержку для добавления услуг.")}
            </S.EmptyMessage>
          )}
        </S.SuggestList>
      </S.InputContainer>
      {selectedServicesAllPrice() > 0 && (
          <S.AllPriceBlock>
            <p>Итого: <PList style={{display: 'inline', color: colors.darkGray}}>({selectedServices.length} {getNounEndingByNumber(selectedServices.length, ["услуга", "услуги", "услуг"])})</PList></p>
            <p>{selectedServicesAllPrice().toLocaleString()}₽</p>
          </S.AllPriceBlock>
      )}
      { isPrescribed && appointment.medical_file.organization.id !== 30 &&
          <>
            <p style={{fontSize: 12}}>
              Мы напоминаем пациенту о необходимости прохождения обследований в течение 30 дней. Если рекомендованные сроки сдачи отличаются, выберите соответствующую дату в календаре.
            </p>
            <MissedExamination />
          </>
      }
    </S.Container>
  );
}));
