// @flow
import React, { useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { useToast } from "../toast/useToast";
import styled from "styled-components";
import favoritesAppointmentStore from "../../stores/favoritesAppointmentStore";
import { trimLongTextValueField } from "../../utils/tableUtils";
import { ProfileLayout } from "../layouts/ProfileLayout";
import { getLastNameWithInitials } from "../../domain/services/doctor";
import { getPatientLastNameWithInitials } from "../../domain/services/patient";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import { DropDownMenu } from "../drop-down-menu/DropDownMenu";
import {
  InsertDriveFileOutlined as AppointmentIcon,
  FileCopyOutlined as AppointmentCopyIcon,
  FavoriteBorderOutlined as DeleteFavoritesAppointmentIcon
} from "@mui/icons-material";
import { BlockLoader } from "../loader/Loader";

const Container = styled.div`
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  display: block;
  position: relative;
  padding: 40px 15px 70px;
  height: ${props => props?.height || "100%"};
  .MuiTableContainer-root {
    ${scrollbars}
  }
`;

export const FavoritesAppointmentPage = observer(() => {
  const toastManager = useToast();
  const tableRef = useRef(null);
  const [tableHeight, setTableHeight] = useState(0);

  const columns = useMemo(
    () => [
      {
        accessorKey: "date",
        header: "Дата",
        size: 100,
        filterFn: "contains",
        accessorFn: originalRow => originalRow.date
      },
      {
        accessorKey: "time",
        header: "Время",
        size: 100,
        filterFn: "contains",
        accessorFn: originalRow => originalRow.time
      },
      {
        accessorKey: "doctor",
        header: "Врач",
        size: 150,
        filterFn: "contains",
        accessorFn: originalRow => getLastNameWithInitials(originalRow.doctor),
        Cell: ({ row }) => (
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Avatar
              sx={{ width: 25, height: 25, fontSize: ".7rem", textTransform: "uppercase" }}
              src={row.original.doctor.photo}>
              {row.original.doctor?.last_name?.slice(0, 1) +
                row.original.doctor?.first_name?.slice(0, 1)}
            </Avatar>
            <Typography fontSize="14px">{getLastNameWithInitials(row.original.doctor)}</Typography>
          </Stack>
        )
      },
      {
        accessorKey: "patient",
        header: "Пациент",
        size: 150,
        filterFn: "contains",
        accessorFn: originalRow => getPatientLastNameWithInitials(originalRow.patient),
        Cell: ({ row }) => (
          <Typography fontSize="14px">
            {getPatientLastNameWithInitials(row.original.patient)}
          </Typography>
        )
      },
      {
        accessorKey: "sample",
        header: "Шаблон",
        size: 250,
        Cell: ({ row }) =>
          trimLongTextValueField({ value: row.original.sample, numberOfCharacters: 32 })
      },
      {
        accessorKey: "organization",
        header: "Организация",
        size: 150
      }
    ],
    [favoritesAppointmentStore.appointmentList]
  );

  const data = useMemo(() => {
    return favoritesAppointmentStore.appointmentList;
  }, [favoritesAppointmentStore.appointmentList]);

  const table = useMaterialReactTable({
    columns,
    data,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    enableGlobalFilter: false,
    enableSorting: false,
    enableRowActions: true,
    displayColumnDefOptions: {
      "mrt-row-actions": {
        size: 85
      }
    },
    layoutMode: "grid",
    initialState: {
      density: "compact"
    },
    muiTableContainerProps: ({ table }) => ({
      sx: {
        height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`
      }
    }),
    muiTablePaperProps: {
      sx: {
        height: "100%"
      }
    },
    renderRowActions: ({ row }) => {
      const path = `${window.location.origin}/appointment/${row.original.id}`;
      const menuItems = [
        {
          name: "Перейти в прием",
          icon: <AppointmentIcon />,
          handleOnClick: () => window.open(path, "_blank")
        },
        {
          name: "Скопировать ссылку на прием",
          icon: <AppointmentCopyIcon />,
          handleOnClick: () => {
            try {
              navigator.clipboard.writeText(path);
              toastManager.showToast(
                <div>Ссылка на прием успешно скопирована в буфер обмена</div>,
                {
                  duration: 5000
                }
              );
            } catch (e) {
              toastManager.showToast(<div>Произошла ошибка при копировании ссылки на прием</div>, {
                duration: 5000
              });
            }
          }
        },
        { name: "divider" },
        {
          name: "Убрать из избранных приёмов",
          icon: <DeleteFavoritesAppointmentIcon />,
          handleOnClick: () => favoritesAppointmentStore.deleteFavoriteAppointment(row.original.id)
        }
      ];

      return <DropDownMenu menuItems={menuItems} view="item" variant="horizontal" />;
    },
    localization: MRT_Localization_RU,
    isMultiSortEvent: () => true
  });

  const handleChangeTableHeightOnResize = () => {
    setTableHeight(window.innerHeight - tableRef?.current?.getBoundingClientRect().top);
  };

  useEffect(() => {
    window.addEventListener("resize", handleChangeTableHeightOnResize);
    return () => window.removeEventListener("resize", handleChangeTableHeightOnResize);
  }, [window.innerWidth, window.innerHeight]);

  useEffect(() => {
    if (!favoritesAppointmentStore.isInitialized) {
      return;
    }
    handleChangeTableHeightOnResize();
  }, [favoritesAppointmentStore.isInitialized]);

  return (
    <ProfileLayout activeIndexMenu={5}>
      {!favoritesAppointmentStore.isInitialized ? (
        <BlockLoader transparent />
      ) : (
        <Container ref={tableRef} height={`${tableHeight}px`}>
          <MaterialReactTable table={table} />
        </Container>
      )}
    </ProfileLayout>
  );
});
