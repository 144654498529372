import React, {useEffect, useState} from 'react';
import styled, { css } from 'styled-components';
import RcTooltip from 'rc-tooltip';

import { colors } from '../styleguide/colors';

import CheckboxInlineIcon from '../../assets/icons/checkbox.inline.svg';

const CheckmarkContainer = styled.div`
  pointer-events: all;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  
  --checkbox-color: #DCDBDB;
  --checkbox-fill-color: transparent;
    
  &:hover {
    --checkbox-color: ${colors.magenta};
  }
    
  ${props => props.checked && css`
    --checkbox-color: ${colors.magenta};
    --checkbox-fill-color: ${colors.magenta};
  `}
`;


export const Checkmark = props => {
  const [checked, setChecked] = useState(props.checked || false);

  const onClick = e => {
    e.stopPropagation();
    setChecked(!checked);
    props.onClick?.(!checked);
  };

  useEffect(() => {
    if (checked !== props.checked) {
      setChecked(props.checked);
    }
  }, [props.checked]);

  return (
    <CheckmarkContainer checked={checked} disabled={props.disabled}>
      <RcTooltip animation="zoom" placement={"bottom"} overlay={<span>{ props.tooltip }</span>}>
        <CheckboxInlineIcon onClick={onClick}/>
      </RcTooltip>
    </CheckmarkContainer>
  );
};
