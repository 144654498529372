import _ from 'lodash';
import styled from 'styled-components';
import {logout as eff_logout, tokenStore as eff_tokenStore} from '../stores/auth';
import { useToast } from '../components/toast/useToast';
import React from 'react';
import {DefaultToastBody} from '../components/toast/toast';
import connectionStability from '../stores/connectionStability';
import {captureExceptionToSentry} from '../utils/captureToSentry';

const ToastBody = styled(DefaultToastBody)`
  width: 320px;
`;

const RESPONSE_CACHE = {};
const GET_CACHE = {};
const toastManager = useToast();

export const clearAllCache = () => {
  for (let key in RESPONSE_CACHE) {
    delete RESPONSE_CACHE[key];
  }
  for (let key in GET_CACHE) {
    delete GET_CACHE[key];
  }
};

class ApiService {
  constructor(urlPrefix, requestPrepare, responseCallback, host) {
    this.requestPrepare = requestPrepare;
    this.responseCallback = responseCallback;
    this.urlPrefix = urlPrefix;
    this.host = host || window.location.origin;
    this.abortControllers = {};
  }

  abortAll(reason) {
    Object.values(this.abortControllers).forEach(controller => {
      controller.abort(reason);
    });
  }

  async getFullUrlAndParams(method, url, params, body, headers, contentType, host, withoutLastSlash){
    let urlPrefix = this.urlPrefix;
    if (this.requestPrepare) {
      const newParams = await this.requestPrepare(method, url, params, body, headers);
      // eslint-disable-next-line no-param-reassign
      method = newParams.method;
      // eslint-disable-next-line no-param-reassign,no-param-reassign
      url = newParams.url;
      // eslint-disable-next-line no-param-reassign
      params = newParams.params;
      // eslint-disable-next-line no-param-reassign
      body = newParams.body;
      // eslint-disable-next-line no-param-reassign
      headers = newParams.headers || {};
    }

    let urlString = `${urlPrefix}/${url ? url + "/" : ""}`
    if (withoutLastSlash) {
      urlString = `${urlPrefix}/${url}`
    }

    const fullUrl = new URL(urlString, host || this.host);
    // Формируем параметры fetch
    const requestParams = {
      method,
      headers: {
        'APP-TYPE': 'doctor', // TODO: Кастомный заголовок для отсеивания ботов, в будущем должен зависеть от площадки
        ...headers
      }
      // credentials: 'include',
      // mode: 'cors',
    };
    // Для возможности отключить поле content-type. Pixel не обрабатывает запросы с content-type.
    if (contentType) {
      requestParams.headers["Content-Type"] = "application/json; charset=utf-8";
      requestParams.headers["Accept"] =
        "application/json, application/xml, text/plain, text/html, *.*";
    }
    // Для возможности отключить поле content-type. Pixel не обрабатывает запросы с content-type.
    if (eff_tokenStore?.getState()) {
      requestParams.headers["Authorization"] = `JWT ${eff_tokenStore.getState()}`;
    }
    // Для методов, предполагающих тело запроса добавляем тело запроса
    if (body && ["POST", "PUT", "PATCH", "DELETE"].includes(method)) {
      if (body instanceof FormData) {
        requestParams.body = body;
      } else {
        requestParams.body = JSON.stringify(body);
      }
    }
    if (params) {
      fullUrl.search = new URLSearchParams(_.pickBy(params, _.identity));
    }
    return [fullUrl, requestParams];
  }

  async request(method, url, params, body, headers, contentType, host, cache, checkCache, abortPrevious, abortReason, withoutLastSlash) {
    const requestUrl = this.urlPrefix + url;
    const [fullUrl, requestParams] = await this.getFullUrlAndParams(method, url, params, body, headers, contentType, host, withoutLastSlash);
    if (this.abortControllers[requestUrl] && abortPrevious) {
      this.abortControllers[requestUrl].abort(abortReason);
    }
    this.abortControllers[requestUrl] = new AbortController();

    const fullUrlString = fullUrl.toString();

    if (cache && RESPONSE_CACHE[fullUrlString]) {
      return _.cloneDeep(RESPONSE_CACHE[fullUrlString]);
    }
    if (checkCache) {
      return false;
    }

    const makeRequest = async () => {
      let response;
      let json;

      const handleError = async (error) => {
        if (connectionStability.isFetchError(error)) {
          console.error(error);
          captureExceptionToSentry(error);
          await connectionStability.waitConnection();
          return await makeRequest();
        }
        throw error;
        // Raven.captureMessage('Fetch error', {
        //   level: 'error',
        //   extra: {
        //     error,
        //     fullUrl,
        //     requestParams,
        //   },
        // });
      };

      try {
        response = await fetch(fullUrl, {
          signal: this.abortControllers[requestUrl].signal,
          ...requestParams
        });
        json = await response.json();
      } catch(error) {
        if (error.message === "Unexpected end of JSON input" && response.ok) {
          return [response, ""];
        }
        if (error.message === "The string did not match the expected pattern." && response.ok) {
          return [response, ""];
        }
        [response, json] = await handleError(error);
      }

      return [response, json];
    };

    let [response, result] = await makeRequest();

    delete this.abortControllers[requestUrl];

    if (this.responseCallback) {
      const res = await this.responseCallback(
        { status: response.status, result },
        method,
        url,
        params,
        body,
        headers
      );
      if (res || res === null) {
        return res;
      }
    }

    // Обрабатываем ошибки со статусом 40х, 50х
    if (response.status >= 400) {
        const error = new Error(response.statusText);
      error.code = response.status;
      error.data = response;
      error.result = result;
      const isLoggedOut = !(eff_tokenStore && eff_tokenStore.getState());

      if (error.code === 401 && !isLoggedOut) {
        toastManager.showToast(
          <div>
            Время сессии истекло.<br/>
            Войдите в свой аккаунт еще раз.
          </div>,
          {
            container: {
              component: ToastBody
            },
            duration: 10000
          }
        );
        eff_logout();
      }
      if (error.code === 500) {
        toastManager.showToast(
          <div>
            Неизвестная ошибка сервера
          </div>,
          {
            container: {
              component: ToastBody
            },
            duration: 10000
          }
        );
      }
      // if (error.code === 400) {
      //   toastManager.showToast(
      //     <div>
      //       {error.details}
      //     </div>,
      //     {
      //       container: {
      //         component: ToastBody
      //       },
      //       duration: 10000
      //     }
      //   );
      // }

      throw error;
    }

    if (cache) {
      RESPONSE_CACHE[fullUrlString] = _.cloneDeep(result);
    }

    return result;
  }

  get(url, params = {}, headers = {}, contentType = true, host = "", cache = false, abortPrevious = false, abortReason = "", withoutLastSlash = false) {
    return this.getFullUrlAndParams(null, url, params, {}, headers, contentType, host, withoutLastSlash).then(async ([fullUrl]) => {
      const fullUrlString = fullUrl.toString();
      if (cache && GET_CACHE[fullUrlString]) {
        // Необходимо делать cloneDeep значения, так как Promise возвращает одну и ту же ссылку на объект.
        return GET_CACHE[fullUrlString].then(result => _.cloneDeep(result));
      }

      const request = this.request("GET", url, params, {}, headers, contentType, host, cache, false, abortPrevious, abortReason, withoutLastSlash);

      if (cache) {
        GET_CACHE[fullUrlString] = request;
      }

      return request;
    });
  }

  checkCache(url, params = {}, headers = {}, contentType = true, host = "") {
    return this.request("GET", url, params, {}, headers, contentType, host, true, true);
  }

  post(url, params, body, headers = {}, contentType = true, host = "", abortPrevious = false, abortReason = "", withoutLastSlash = false) {
    return this.request("POST", url, params, body, headers, contentType, host, false, false, abortPrevious, abortReason, withoutLastSlash);
  }

  patch(url, params, body, headers = {}, contentType = true, host = "", abortPrevious = false, abortReason = "") {
    return this.request("PATCH", url, params, body, headers, contentType, host, false, false, abortPrevious, abortReason);
  }

  put(url, params, body, headers = {}, contentType, abortPrevious = false, abortReason = "") {
    return this.request("PUT", url, params, body, headers, contentType, "", false, false, abortPrevious, abortReason);
  }

  delete(url, params, body, headers = {}, abortPrevious = false, abortReason = "") {
    return this.request("DELETE", url, params, body, headers, true, "", false, false, abortPrevious, abortReason);
  }

  async clearCacheForRequest(url, params, headers, contentType, host) {
    const [fullUrl] = await this.getFullUrlAndParams(null, url, params, {}, headers, contentType, host);
    const fullUrlString = fullUrl.toString();
    if (RESPONSE_CACHE[fullUrlString]) {
      delete RESPONSE_CACHE[fullUrlString];
    }
    if (GET_CACHE[fullUrlString]) {
      delete GET_CACHE[fullUrlString];
    }
  }
}

export default ApiService;
