import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class Feedback {
  apiService;
  constructor() {
    this.apiService = new ApiService('/api/feedback', null, null, API_URL);
  }

  getFeedback(id) {
    return this.apiService.get(`feedbacks/${id}`);
  }

  getFeedbackFields(id) {
    return this.apiService.get(`layouts/${id}/fields`);
  }

  getFeedbackResponse(id) {
    return this.apiService.get(`feedbacks/${id}/response`);
  }

  getFeedbackComplete({ id, doctors_comment }) {
    return this.apiService.post(`feedbacks/${id}/complete`, {}, { doctors_comment });
  }

  getFeedbacks(params) {
    if (!params.order) {
      delete params.order;
    }
    return this.apiService.get('feedbacks', params);
  }

  postFeedbacks({ layout_id, appointment_id, notification_date }) {
    return this.apiService.post('feedbacks', {}, { layout_id, appointment_id, notification_date });
  }

  getFeedbacksCount() {
    return this.apiService.get('feedbacks/count');
  }

  postRefreshToken(token) {
    return this.apiService.post('refresh-token', {}, { token });
  }

  logout() {
    return this.apiService.post('logout');
  }
}

export default new Feedback();
