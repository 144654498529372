import React, { useMemo } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Accordion,
  AccordionSummary,
  Avatar,
  Box,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import { MRT_Table, useMaterialReactTable } from "material-react-table";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { trimLongTextValueField } from "../../../utils/tableUtils";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";

const MainInformationContainer = styled(Box)(
  () => `
    display: flex;
    justify-content: space-between;
  `
);

const MuiAccordion = styled(Accordion)`
  &::before {
    display: none;
  }
  .MuiAccordionSummary-content {
    font-size: 16px;
    font-weight: 500;
  }
`;

const AccordionDetailsStyled = styled(AccordionDetails)`
    white-space: pre-wrap;
`

const CloseButton = ({ onClose }) => {
  return (
    <IconButton
      aria-label="close"
      onClick={onClose}
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
        color: theme => theme.palette.grey[500]
      }}>
      <CloseIcon />
    </IconButton>
  );
};

export const InformationAboutDoctor = ({ doctor, isShow, onClose, informationIcons }) => {
  const specializations = doctor.specializations.filter(specialization => !!specialization) || [];
  const organizations = doctor.allOrganizations.filter(organization => !!organization) || [];

  const columns = useMemo(
    () => [
      {
        accessorKey: "kdfСode",
        header: "КДФ код",
        size: 100
      },
      {
        accessorKey: "ministryOfHealthCode",
        header: "Код минздрава",
        size: 100
      },
      {
        accessorKey: "name",
        header: "Наименование услуги",
        Cell: ({ row }) =>
          trimLongTextValueField({ value: row.original.name, numberOfCharacters: 50 })
      },
      {
        accessorKey: "price",
        header: "Цена",
        size: 100,
        Cell: ({ row }) => `${row.original.price?.toLocaleString() || "0"}₽`
      }
    ],
    []
  );

  const table = useMaterialReactTable({
    columns: columns,
    data: doctor.favoritesServices,
    enableFullScreenToggle: false,
    enablePagination: false,
    enableColumnActions: false,
    enableHiding: false,
    enableColumnFilters: false,
    enableGlobalFilter: false,
    enableDensityToggle: false,
    muiTableHeadRowProps: {
      sx: {
        boxShadow: "none"
      }
    },
    initialState: {
      density: "compact"
    },
    localization: MRT_Localization_RU,
    isMultiSortEvent: () => true
  });

  return (
    <Dialog open={isShow} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle sx={{ m: 0, p: 2 }}>Информация о враче</DialogTitle>
      <CloseButton onClose={onClose} />
      <DialogContent>
        <Stack spacing={2}>
          <MainInformationContainer>
            <Stack spacing={0.5}>
              <Typography variant="h5" fontWeight={500}>
                {doctor.fullname_doctor}
              </Typography>
              {!!informationIcons && informationIcons}
              {!!specializations.length && (
                <Typography>
                  <span style={{ fontWeight: "500" }}>Специализации: </span>
                  {specializations.join(", ")}
                </Typography>
              )}
              {!!organizations.length && (
                <Typography>
                  <span style={{ fontWeight: "500" }}>Организации: </span>
                  {organizations.join(", ")}
                </Typography>
              )}
            </Stack>
            <Avatar
              alt="avatar"
              src={doctor.photo}
              sx={{ width: 100, height: 100, textTransform: "uppercase" }}>
              {doctor.last_name?.slice(0, 1) + doctor.first_name?.slice(0, 1)}
            </Avatar>
          </MainInformationContainer>

          {!!doctor.description && (
            <MuiAccordion disableGutters slotProps={{ transition: { unmountOnExit: true } }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>О враче</AccordionSummary>
              <AccordionDetailsStyled>{doctor.description}</AccordionDetailsStyled>
            </MuiAccordion>
          )}

          {!!doctor.comment && (
            <MuiAccordion disableGutters slotProps={{ transition: { unmountOnExit: true } }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>Комментарий</AccordionSummary>
              <AccordionDetailsStyled>{doctor.comment}</AccordionDetailsStyled>
            </MuiAccordion>
          )}

          <MRT_Table table={table} />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
