import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { CurrentLevel } from "./CurrentLevel";
import { BonusSchemaAllLevelsModal } from "./BonusSchemaAllLevelsModal";
import { MyAppointmentsMonth } from "./MyAppointmentsMonth";
import { FlexBlock } from "./bonusSchema.styled";
import { RunRate } from "./RunRate";
import { BonusSchemaSalary } from "./BonusSchemaSalary";
import { BonusSchemaRateCard } from "./BonusSchemaRateCard";
import styled from "styled-components";
import { BonusSchemaChartPatients } from "./BonusSchemaChartPatients";
import bonusSchemaStore from "../../../stores/bonusSchemaStore";
import { colors } from "../../styleguide/colors";
import { ProfileLayout } from "../../layouts/ProfileLayout";
import {BonusSchemaWelcomeMessageModal} from "./BonusSchemaWelcomeMessageModal";

const FlexBlockStretch = styled(FlexBlock)`
    align-items: stretch;
    
    @media (max-width: 1024px) {
        flex-direction: column;
    }
`;

const BonusSchemaContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    box-sizing: border-box;
    padding-bottom: 70px;
    * {
        box-sizing: border-box;
    }
    position: relative;
`;

const TitleBold = styled.div`
    font-weight: bold;
    font-size: 22px;
    color: ${colors.black};

    @media (max-width: 768px) {
        font-size: 18px;
    }
`;

export const BonusSchema = observer(() => {
  useEffect(() => {
    (async () => {
      await bonusSchemaStore.getBonusSchemaData();
    })();
  }, []);

  return (
    <ProfileLayout activeIndexMenu={2}>
      <Container>
        <BonusSchemaWelcomeMessageModal/>
        <TitleBold>
          Мой прогресс
        </TitleBold>
        <BonusSchemaContainer className='bonus-schema-container'>
          {!!bonusSchemaStore.bonusSchemaData && (
            <>
              <FlexBlockStretch>
                {/*<CurrentLevel />*/}
                <MyAppointmentsMonth />
              </FlexBlockStretch>
              <BonusSchemaChartPatients />
              <RunRate />
              <BonusSchemaRateCard />
              {/*<BonusSchemaSalary />*/}
              <BonusSchemaAllLevelsModal />
            </>
          )}
        </BonusSchemaContainer>
      </Container>
    </ProfileLayout>
  )
});