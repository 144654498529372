// @flow
import React, { useEffect } from "react";
import styled from "styled-components";
import { GroupTitle } from "./GroupTitle";
import { AddPointButton } from "./AddPointButton";
import { AddPointContainer, AddPointText, NewPointInputContainer } from "./AddPointContainer";
import { GroupContainer } from "./GroupContainer";
import { PointTemplate } from "./PointTemplate";
import { PointHint } from "./PointHint";
import { DiagnosisLabel } from "./DiagnosisLabel";
import { HeadingH3, PNormal } from "../styleguide/typography";
import { PointsContainer } from "./PointsContainer";
import { AddingHOC } from "./AddingHOC";
import { NewDiagnosisInput } from "./NewDiagnosisInput";
import { SelectContainer } from "./SelectContainer";
import { ListContainer } from "../select/ListContainer";
import { Option } from "../select/Option";
import { DiagnosesSuggestProvider } from "./DiagnosesSuggestProvider";
import {
  addNewPrimaryDiagnosis,
  primaryDiagnoses,
  removePrimaryDiagnosis,
  secondaryDiagnoses
} from "../../stores/decisionSupportStores";
import type { AppointmentWithDetails } from "../../domain/entities/Appointment";
import { getAppointment_id } from "../../domain/entities/Appointment";
import type { Diagnosis } from "../../domain/entities/Diagnosis";
import { useDiagnoses } from "./useDiagnoses";
import { getDiagnosis_id } from "../../domain/entities/Diagnosis";
import { useList } from "effector-react";
import { PointButtons } from "./PointButtons";
import { AutosuggestRelativeContainer } from "./AutosuggestRelativeContainer";
import { AutosuggestAbsoluteContainer } from "./AutosuggestAbsoluteContainer";
import { forward } from "effector";
import { useConfirm } from "../alert-warning/useConfirm";
import { AlertWarning } from "../alert-warning/AlertWarning";
import { AlertWarningPrimaryButton, AlertWarningWhiteButton } from "../buttons/AlertButton";
import { ModalPortal } from "../modal/ModalPortal";
import { OpenTreeButton } from "../diagnoses-tree/OpenTreeButton";
import { openPrimaryDiagnosesTree, openSecondaryDiagnosesTree } from "../../stores/diagnosesTree";

const DiagnosisAutosuggestAbsoluteContainer = styled(AutosuggestAbsoluteContainer)`
  left: 15px;
  max-width: calc(100% - 15px);
`;

const DiagnosisAutosuggetIconContainer = styled.div`
  flex-shrink: 0;
  margin-right: 11px;
`;

const DiagnosisAdding = (props: { addDiagnosis: (diagnosis: Diagnosis) => mixed }) => {
  const { addDiagnosis } = props;
  return (
    <AddingHOC>
      {({ value, onChange, isAdding, setAdding }) => (
        <>
          {isAdding && (
            <NewPointInputContainer>
              <AutosuggestRelativeContainer>
                <DiagnosesSuggestProvider value={value}>
                  {diagnoses => (
                    <>
                      <NewDiagnosisInput
                        value={value}
                        onChange={onChange}
                        onBlur={() => setTimeout(() => setAdding(false), 1000)}
                        onEnter={() => {
                          if (diagnoses.length > 0) {
                            addDiagnosis(diagnoses[0]);
                          }
                          setTimeout(() => setAdding(false), 100);
                        }}
                      />
                      {diagnoses.length > 0 && (
                        <DiagnosisAutosuggestAbsoluteContainer>
                          <SelectContainer>
                            <ListContainer role="menu">
                              {diagnoses.map(diagnosis => (
                                <Option
                                  onClick={() => {
                                    addDiagnosis(diagnosis);
                                    setAdding(false);
                                  }}
                                  key={diagnosis.id}
                                  iconPlace={
                                    diagnosis.icd_code && (
                                      <DiagnosisAutosuggetIconContainer>
                                        <DiagnosisLabel title={diagnosis.icd_code} />
                                      </DiagnosisAutosuggetIconContainer>
                                    )
                                  }
                                  text={diagnosis.name}
                                />
                              ))}
                            </ListContainer>
                          </SelectContainer>
                        </DiagnosisAutosuggestAbsoluteContainer>
                      )}
                    </>
                  )}
                </DiagnosesSuggestProvider>
              </AutosuggestRelativeContainer>
            </NewPointInputContainer>
          )}
          {!isAdding && (
            <AddPointContainer withHover withText onClick={() => setAdding(true)}>
              <AddPointButton />
              <AddPointText>Добавить диагноз</AddPointText>
            </AddPointContainer>
          )}
        </>
      )}
    </AddingHOC>
  );
};

const Point = props => {
  const { diagnosis, setComment, removeComment, remove } = props;
  const removeConfirm = useConfirm();

  const handleRemove = async () => {
    if (!removeConfirm.requestConfirm) {
      return;
    }
    try {
      const confirmResult = await removeConfirm.requestConfirm();
      if (!confirmResult) {
        return;
      }
      remove(getDiagnosis_id(diagnosis));
    } catch (e) {
      console.log(e);
    }
  };

  const removeWarning = (
    <AlertWarning
      isNeedConfirm={removeConfirm.isNeedConfirm}
      sendConfirm={removeConfirm.sendConfirm}
      heading={<HeadingH3 modifiers="center">Вы точно хотите удалить диагноз?</HeadingH3>}
      cancelButton={onClick => (
        <AlertWarningWhiteButton onClick={onClick}>Отмена</AlertWarningWhiteButton>
      )}
      confirmButton={onClick => (
        <AlertWarningPrimaryButton onClick={onClick}>Удалить</AlertWarningPrimaryButton>
      )}
    />
  );

  return (
    <>
      <ModalPortal>{removeWarning}</ModalPortal>
      <PointTemplate
        icon={diagnosis.icd_code && <DiagnosisLabel title={diagnosis.icd_code} />}
        buttons={
          <PointButtons
            comment={diagnosis.comment}
            setComment={value => setComment({ value, id: getDiagnosis_id(diagnosis) })}
            removeComment={() => removeComment(getDiagnosis_id(diagnosis))}
            remove={handleRemove}
          />
        }
        title={<PNormal modifiers="left">{diagnosis.name}</PNormal>}
        hint={diagnosis.comment && <PointHint>{diagnosis.comment}</PointHint>}
      />
    </>
  );
};

const AppointmentDiagnosesList = props => {
  const {
    store,
    appointment_id,
    defaultValues,
    addNewAppointmentDiagnosis,
    removeAppointmentDiagnosis
  } = props;
  const {
    store: listStore,
    addNewDiagnosis,
    setDiagnosisComment,
    removeDiagnosisComment,
    removeDiagnosis
  } = useDiagnoses(store, appointment_id, defaultValues);

  React.useEffect(() => {
    if (!addNewAppointmentDiagnosis) {
      return;
    }
    forward({
      from: addNewDiagnosis,
      to: addNewAppointmentDiagnosis
    });
  }, [addNewDiagnosis]);

  React.useEffect(() => {
    if (!removeAppointmentDiagnosis) {
      return;
    }
    forward({
      from: removeDiagnosis,
      to: removeAppointmentDiagnosis
    });
  }, [removeDiagnosis]);

  const list = useList(listStore, diagnosis => (
    <Point
      key={diagnosis.id}
      diagnosis={diagnosis}
      remove={removeDiagnosis}
      setComment={setDiagnosisComment}
      removeComment={removeDiagnosisComment}
    />
  ));

  return (
    <>
      <PointsContainer>{list}</PointsContainer>
      <DiagnosisAdding addDiagnosis={addNewDiagnosis} />
    </>
  );
};

export const AppointmentDiagnoses = (props: { appointment: AppointmentWithDetails, isRequired: boolean , isError: boolean, callbackOnChangeDiagnoses: () => void }) => {
  const { appointment } = props;
  const appointment_id = getAppointment_id(appointment);

  useEffect(() => {
    props.callbackOnChangeDiagnoses(!!appointment.primary_diagnoses.length);
  }, [appointment.primary_diagnoses]);

  const addNewPrimaryDiagnosisConnected = React.useMemo(() => addNewPrimaryDiagnosis(appointment), [
    appointment_id
  ]);
  const removePrimaryDiagnosisConnected = React.useMemo(() => removePrimaryDiagnosis(appointment), [
    appointment_id
  ]);

  return (
    <>
      <GroupContainer>
        <GroupTitle title="Основной диагноз:" isAlreadyOpened isRequired={props.isRequired} isError={props.isError}>
          <OpenTreeButton onClick={openPrimaryDiagnosesTree} />
        </GroupTitle>
        <AppointmentDiagnosesList
          appointment_id={appointment_id}
          store={primaryDiagnoses}
          addNewAppointmentDiagnosis={addNewPrimaryDiagnosisConnected}
          removeAppointmentDiagnosis={removePrimaryDiagnosisConnected}
          defaultValues={appointment.primary_diagnoses}
        />
      </GroupContainer>
      <GroupContainer>
        <GroupTitle title="Сопутствующий диагноз:" isAlreadyOpened>
          <OpenTreeButton onClick={openSecondaryDiagnosesTree} />
        </GroupTitle>
        <AppointmentDiagnosesList
          appointment_id={appointment_id}
          store={secondaryDiagnoses}
          addNewAppointmentDiagnosis={null}
          removeAppointmentDiagnosis={null}
          defaultValues={appointment.secondary_diagnoses}
        />
      </GroupContainer>
    </>
  );
};
