// @flow
import type { LoginObject, TokenObject } from "../domain/value-objects/auth";
import type { User } from "../domain/entities/User";
import type { ScheduleGetParams } from "../domain/value-objects/schedule";
import type { ScheduleInsertParams } from "../domain/value-objects/scheduleInsert";
import type {
  AppointmentsGetParams,
  PatientAppointmentsGetParams
} from "../domain/value-objects/appointments";
import type { UploadsPatchParams, UploadedFileOptions } from "../domain/value-objects/uploadedFile";
import type { DoctorPermissionParams } from "../domain/value-objects/doctorPermissions";
import type {
  AppointmentCreationOptions,
  AppointmentCopyOptions,
  FiledValuesForSave
} from "../domain/value-objects/appointment";
import type {
  ShortcutCreationOptions,
  ShortcutOrderOptions,
} from "../domain/value-objects/TShortcuts";
import type { TAppointment_id, TypeOfConclusion } from "../domain/entities/Appointment";
import type { Diagnosis, TDiagnosis_id } from "../domain/entities/Diagnosis";
import type { Checklist } from "../domain/entities/Checklist";

import * as R from "ramda";
import type { TFeedbacksParams } from "../domain/value-objects/feedback";
import type { TFeedback_id } from "../domain/entities/Feedback";
import type { TFeedbackLayout_id } from "../domain/entities/FeedbackLayout";

export const EMS_API_URL = (process.env.EMS_API_URL || location.origin) + "/api";

export const COMMON_FETCH_OPTIONS = {
  headers: {
    Accept: "application/json, application/xml, text/plain, text/html, *.*",
    "Content-Type": "application/json; charset=utf-8"
  }
};

const transformParams = R.compose(
  R.toString,
  (params: any) => new URLSearchParams(params),
  R.reject(R.isNil)
);

export const postObtainToken = async (options: LoginObject): Promise<TokenObject> => {
  const response = await fetch(`${EMS_API_URL}/auth/obtain-token/`, {
    ...COMMON_FETCH_OPTIONS,
    method: "post",
    body: JSON.stringify(options)
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return await response.json();
};

export const postRefreshToken = async (options: TokenObject): Promise<TokenObject> => {
  const response = await fetch(`${EMS_API_URL}/auth/refresh-token/`, {
    ...COMMON_FETCH_OPTIONS,
    method: "post",
    body: JSON.stringify(options)
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return await response.json();
};

export const postLogout = async (options: TokenObject) => {
  const response = await fetch(`${EMS_API_URL}/auth/logout/`, {
    ...COMMON_FETCH_OPTIONS,
    method: "post",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${options.token}`
    }
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
};

export const getCurrentUser = async (options: TokenObject): Promise<User> => {
  const response = await fetch(`${EMS_API_URL}/auth/current-user/`, {
    ...COMMON_FETCH_OPTIONS,
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${options.token}`
    }
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return await response.json();
};

export const getSchedule = (options: { ...$Exact<TokenObject>, ...$Exact<ScheduleGetParams> }) => {
  const { token, ...params } = options;
  const url = new URL(`${EMS_API_URL}/schedule/`);

  url.search = new URLSearchParams((params: any)).toString();
  return [
    url,
    {
      ...COMMON_FETCH_OPTIONS,
      headers: {
        ...COMMON_FETCH_OPTIONS.headers,
        Authorization: `JWT ${token}`
      }
    }
  ];
};

export const fetchSchedule = (
  options: $Diff<
    {| ...$Exact<TokenObject>, ...$Exact<ScheduleGetParams> |},
    { limit: any, offset: any }
  >
) => async (params: { limit: number, offset: number }) => {
  const { limit, offset } = params;
  const response = await fetch(...getSchedule({ ...options, offset, limit }));
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return await response.json();
};

export const postScheduleInserts = async (options: {
  ...$Exact<TokenObject>,
  scheduleValues: ScheduleInsertParams
}) => {
  const { token, scheduleValues } = options;

  const response = await fetch(`${EMS_API_URL}/schedule-inserts/`, {
    ...COMMON_FETCH_OPTIONS,
    method: "post",
    body: JSON.stringify(scheduleValues),
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return await response.json();
};

export const getFeedbacks = (options: { ...$Exact<TokenObject>, ...$Exact<TFeedbacksParams> }) => {
  const { token, ...params } = options;
  const url = new URL(`${EMS_API_URL}/feedback/feedbacks/`);

  url.search = new URLSearchParams((params: any)).toString();
  return [
    url,
    {
      ...COMMON_FETCH_OPTIONS,
      headers: {
        ...COMMON_FETCH_OPTIONS.headers,
        Authorization: `JWT ${token}`
      }
    }
  ];
};

export const fetchFeedbacks = (
  options: $Diff<
    {| ...$Exact<TokenObject>, ...$Exact<TFeedbacksParams> |},
    { limit: any, offset: any }
  >
) => async (params: { limit: number, offset: number }) => {
  const { limit, offset } = params;
  if (!options.order) {
    delete options.order;
  }
  const response = await fetch(...getFeedbacks({ ...options, offset, limit }));
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return await response.json();
};

export const postFeedbacks = async (options: {
  ...$Exact<TokenObject>,
  layout_id: number,
  appointment_id: number,
  notification_date: string
}) => {
  const { layout_id, appointment_id, notification_date } = options;
  const response = await fetch(`${EMS_API_URL}/feedback/feedbacks/`, {
    ...COMMON_FETCH_OPTIONS,
    method: "post",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${options.token}`
    },
    body: JSON.stringify({
      layout_id,
      appointment_id,
      notification_date
    })
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
};

export const getFeedbacksCount = (options: { ...$Exact<TokenObject> }) => {
  const { token } = options;
  const url = new URL(`${EMS_API_URL}/feedback/feedbacks/count/`);

  return [
    url,
    {
      ...COMMON_FETCH_OPTIONS,
      headers: {
        ...COMMON_FETCH_OPTIONS.headers,
        Authorization: `JWT ${token}`
      }
    }
  ];
};

export const fetchFeedback = async (options: { ...$Exact<TokenObject>, id: TFeedback_id }) => {
  const { token, id } = options;

  const response = await fetch(`${EMS_API_URL}/feedback/feedbacks/${id}/`, {
    ...COMMON_FETCH_OPTIONS,
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });

  if (!response.ok) {
    throw Error(response.statusText);
  }
  return await response.json();
};

export const fetchFeedbackFields = async (options: {
  ...$Exact<TokenObject>,
  id: TFeedbackLayout_id
}) => {
  const { token, id } = options;

  const response = await fetch(`${EMS_API_URL}/feedback/layouts/${id}/fields/`, {
    ...COMMON_FETCH_OPTIONS,
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });

  if (!response.ok) {
    throw Error(response.statusText);
  }
  return await response.json();
};

export const fetchFeedbackResponse = async (options: {
  ...$Exact<TokenObject>,
  id: TFeedback_id
}) => {
  const { token, id } = options;

  const response = await fetch(`${EMS_API_URL}/feedback/feedbacks/${id}/response/`, {
    ...COMMON_FETCH_OPTIONS,
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });

  if (!response.ok) {
    throw Error(response.statusText);
  }
  return await response.json();
};

export const fetchFeedbackComplete = async (options: {
  ...$Exact<TokenObject>,
  id: TFeedback_id,
  doctors_comment?: string
}) => {
  const { token, id, doctors_comment } = options;

  const response = await fetch(`${EMS_API_URL}/feedback/feedbacks/${id}/complete/`, {
    ...COMMON_FETCH_OPTIONS,
    method: "post",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    },
    body: JSON.stringify({
      doctors_comment
    })
  });

  if (!response.ok) {
    throw Error(response.statusText);
  }
  return true;
};

export const getLayoutGroups = (options: { ...$Exact<TokenObject> }) => {
  const { token } = options;

  return [
    `${EMS_API_URL}/layout-groups/`,
    {
      ...COMMON_FETCH_OPTIONS,
      headers: {
        ...COMMON_FETCH_OPTIONS.headers,
        Authorization: `JWT ${token}`
      }
    }
  ];
};

export const getLayouts = (options: { ...$Exact<TokenObject>, group: ?string }) => {
  const { token, group } = options;

  return [
    `${EMS_API_URL}/layouts/?group=${group || ""}`,
    {
      ...COMMON_FETCH_OPTIONS,
      headers: {
        ...COMMON_FETCH_OPTIONS.headers,
        Authorization: `JWT ${token}`
      }
    }
  ];
};

export const getPopularLayouts = (options: { ...$Exact<TokenObject> }) => {
  const { token } = options;

  return [
    `${EMS_API_URL}/layouts/popular/`,
    {
      ...COMMON_FETCH_OPTIONS,
      headers: {
        ...COMMON_FETCH_OPTIONS.headers,
        Authorization: `JWT ${token}`
      }
    }
  ];
};

export const postAppointments = async (options: {
  ...$Exact<TokenObject>,
  creationOptions: AppointmentCreationOptions
}) => {
  const { token, creationOptions } = options;

  const response = await fetch(`${EMS_API_URL}/appointments/`, {
    ...COMMON_FETCH_OPTIONS,
    method: "post",
    body: JSON.stringify(creationOptions),
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return await response.json();
};

const cachedAppointmentsRequests = new Map();
let cachedClearTimeout = null;

const getCachedRequest = (url: string) => {
  if (cachedAppointmentsRequests.has(url)) {
    return cachedAppointmentsRequests.get(url);
  }
  return false;
};

const saveRequestToHash = (url: string, data: any) => {
  if (data) {
    cachedAppointmentsRequests.set(url, data);
    clearTimeout(cachedClearTimeout);
    cachedClearTimeout = setTimeout(() => {
      cachedAppointmentsRequests.clear();
    }, 1000 * 60);
  }
};

const makeCachedRequest = async (url, options) => {
  const cachedRequest = getCachedRequest(url.href);
  if (cachedRequest) {
    return cachedRequest;
  }
  const response = await fetch(url, options);
  if (!response.ok) {
    throw Error(response.statusText);
  }
  const result = await response.json();
  saveRequestToHash(url.href, result);
  return result;
};

export const getPatientAppointments = (options: {|
  ...$Exact<TokenObject>,
  ...$Exact<PatientAppointmentsGetParams>
|}) => {
  const { token, ...params } = options;
  const url = new URL(`${EMS_API_URL}/appointments/`);

  url.search = new URLSearchParams((params: any)).toString();

  return [
    url,
    {
      ...COMMON_FETCH_OPTIONS,
      headers: {
        ...COMMON_FETCH_OPTIONS.headers,
        Authorization: `JWT ${token}`
      }
    }
  ];
};

export const fetchPatientAppointments = (
  options: $Diff<
    {| ...$Exact<TokenObject>, ...$Exact<PatientAppointmentsGetParams> |},
    { limit: any, offset: any }
  >
) => async (params: { limit?: number, offset?: number }) => {
  const { limit, offset } = params;
  const [url, appointmentOptions] = getPatientAppointments({ ...options, offset, limit });
  return await makeCachedRequest(url, appointmentOptions);
};

export const getAppointments = (options: {|
  ...$Exact<TokenObject>,
  ...$Exact<AppointmentsGetParams>
|}) => {
  const { token, ...params } = options;
  const url = new URL(`${EMS_API_URL}/appointments/search/`);

  url.search = new URLSearchParams((params: any)).toString();
  return [
    url,
    {
      ...COMMON_FETCH_OPTIONS,
      headers: {
        ...COMMON_FETCH_OPTIONS.headers,
        Authorization: `JWT ${token}`
      }
    }
  ];
};

export const fetchAppointments = (
  options: $Diff<
    {| ...$Exact<TokenObject>, ...$Exact<AppointmentsGetParams> |},
    { limit: any, offset: any }
  >
) => async (params: { limit?: number, offset?: number }) => {
  const { limit, offset } = params;
  const [url, appointmentOptions] = getAppointments({ ...options, offset, limit });
  return await makeCachedRequest(url, appointmentOptions);
};

export const getAppointment = (options: { ...$Exact<TokenObject>, id: number }) => {
  const { token, id } = options;

  return [
    `${EMS_API_URL}/appointments/${id}/`,
    {
      ...COMMON_FETCH_OPTIONS,
      headers: {
        ...COMMON_FETCH_OPTIONS.headers,
        Authorization: `JWT ${token}`
      }
    }
  ];
};

export const getSectionHistory = (options: {
  ...$Exact<TokenObject>,
  id: number,
  section_id: number
}) => {
  const { token, id, section_id } = options;

  return [
    `${EMS_API_URL}/appointments/${id}/section-history/${section_id}/`,
    {
      ...COMMON_FETCH_OPTIONS,
      headers: {
        ...COMMON_FETCH_OPTIONS.headers,
        Authorization: `JWT ${token}`
      }
    }
  ];
};

export const getLayout = (options: { ...$Exact<TokenObject>, id: number }) => {
  const { token, id } = options;

  return [
    `${EMS_API_URL}/layouts/${id}/`,
    {
      ...COMMON_FETCH_OPTIONS,
      headers: {
        ...COMMON_FETCH_OPTIONS.headers,
        Authorization: `JWT ${token}`
      }
    }
  ];
};

export const postAppointmentsSetValues = async (options: {
  ...$Exact<TokenObject>,
  appointment_id: TAppointment_id,
  fieldValues: FiledValuesForSave
}) => {
  const { token, appointment_id, fieldValues } = options;

  const response = await fetch(`${EMS_API_URL}/appointments/${appointment_id}/set-values/`, {
    ...COMMON_FETCH_OPTIONS,
    method: "post",
    body: JSON.stringify(fieldValues),
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }

  return await response.json();
};

export const postAppointmentsFinish = async (options: {
  ...$Exact<TokenObject>,
  appointment_id: TAppointment_id,
  services_description: string,
  next_visit_date?: string
}) => {
  const { token, appointment_id, services_description, next_visit_date } = options;

  const response = await fetch(`${EMS_API_URL}/appointments/${appointment_id}/finish/`, {
    ...COMMON_FETCH_OPTIONS,
    method: "post",
    body: JSON.stringify(
      next_visit_date ? { services_description, next_visit_date } : { services_description }
    ),
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return true;
};

export const postAnalytics = async (options: {
  ...$Exact<TokenObject>,
  appointment: TAppointment_id,
  time_spent: number,
  scrolling_count: number,
  mouse_clicks_count: number,
  button_link_clicks_count: number,
  mouse_movement: number,
  key_presses_count: number,
  doctor: number
}) => {
  const { token, ...other } = options;

  const response = await fetch(`${EMS_API_URL}/protocol_filling_analytics/`, {
    ...COMMON_FETCH_OPTIONS,
    method: "post",
    body: JSON.stringify(other),
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return true;
};

export const postSetConclusion = async (options: {
  ...$Exact<TokenObject>,
  appointment_id: TAppointment_id,
  primary_diagnoses?: Array<Diagnosis>,
  secondary_diagnoses?: Array<Diagnosis>,
  examination_checklist?: { items: Array<Checklist> },
  medication_checklist?: { items: Array<Checklist> },
  recommendation_checklist?: { items: Array<Checklist> },
  type_of_ppr?: TypeOfConclusion
}) => {
  const { token, appointment_id, ...other } = options;

  const response = await fetch(`${EMS_API_URL}/appointments/${appointment_id}/set-conclusion/`, {
    ...COMMON_FETCH_OPTIONS,
    method: "post",
    body: JSON.stringify(other),
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return await response.json();
};

export const postUploadedFile = async (options: {
  ...$Exact<TokenObject>,
  uploadOptions: UploadedFileOptions,
  patient?: number,
  type: "analysis" | "document"
}) => {
  const { token, patient, uploadOptions, type } = options;

  let formData = new FormData();

  formData.append("file", uploadOptions.file);
  formData.append("original_name", uploadOptions.original_name || "");
  formData.append("type", type || "analysis");

  const response = await fetch(
    patient ? `${EMS_API_URL}/patients/${patient}/uploads/` : `${EMS_API_URL}/uploads/`,
    {
      ...COMMON_FETCH_OPTIONS,
      method: "post",
      body: formData,
      headers: {
        Authorization: `JWT ${token}`
      }
    }
  );
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return await response.json();
};

export const getOrganizations = (options: { ...$Exact<TokenObject> }) => {
  const { token } = options;

  return [
    `${EMS_API_URL}/organizations/`,
    {
      ...COMMON_FETCH_OPTIONS,
      headers: {
        ...COMMON_FETCH_OPTIONS.headers,
        Authorization: `JWT ${token}`
      }
    }
  ];
};

export const getOrganization = (options: { ...$Exact<TokenObject>, id: number }) => {
  const { token, id } = options;

  return [
    `${EMS_API_URL}/organizations/${id}/`,
    {
      ...COMMON_FETCH_OPTIONS,
      headers: {
        ...COMMON_FETCH_OPTIONS.headers,
        Authorization: `JWT ${token}`
      }
    }
  ];
};

export const deleteAppointment = async (options: {
  ...$Exact<TokenObject>,
  appointment_id: TAppointment_id
}) => {
  const { token, appointment_id } = options;

  const response = await fetch(`${EMS_API_URL}/appointments/${appointment_id}/`, {
    ...COMMON_FETCH_OPTIONS,
    method: "delete",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return true;
};

export const reopenAppointment = async (options: {
  ...$Exact<TokenObject>,
  appointment_id: TAppointment_id
}) => {
  const { token, appointment_id } = options;

  const response = await fetch(`${EMS_API_URL}/appointments/${appointment_id}/reopen/`, {
    ...COMMON_FETCH_OPTIONS,
    method: "post",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return true;
};

export const getDrugs = (options: { ...$Exact<TokenObject>, q?: string }) => {
  const { token, q } = options;
  const url = new URL(`${EMS_API_URL}/drugs/`);

  url.search = new URLSearchParams(q ? { q } : {}).toString();

  return [
    url,
    {
      ...COMMON_FETCH_OPTIONS,
      headers: {
        ...COMMON_FETCH_OPTIONS.headers,
        Authorization: `JWT ${token}`
      }
    }
  ];
};

export const postDrugs = async (options: { ...$Exact<TokenObject>, name: string }) => {
  const { token, name } = options;

  const response = await fetch(`${EMS_API_URL}/drugs/`, {
    ...COMMON_FETCH_OPTIONS,
    method: "post",
    body: JSON.stringify({ name }),
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return await response.json();
};

export const getUploads = (options: {
  ...$Exact<TokenObject>,
  patient: number,
  limit?: number
}) => {
  const { token, patient, ...params } = options;
  const url = new URL(`${EMS_API_URL}/patients/${patient}/uploads/`);

  url.search = new URLSearchParams((params: any)).toString();
  return [
    url,
    {
      ...COMMON_FETCH_OPTIONS,
      headers: {
        ...COMMON_FETCH_OPTIONS.headers,
        Authorization: `JWT ${token}`
      }
    }
  ];
};

export const patchUploads = async (options: {
  ...$Exact<TokenObject>,
  ...$Exact<UploadsPatchParams>
}) => {
  const { token, id, ...params } = options;

  const response = await fetch(`${EMS_API_URL}/uploads/${id}/`, {
    ...COMMON_FETCH_OPTIONS,
    method: "PATCH",
    body: JSON.stringify({ ...params }),
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return await response.json();
};

export const patchAnalyse = async (options: {
  ...$Exact<TokenObject>,
  ...$Exact<UploadsPatchParams>
}) => {
  const { token, id, ...params } = options;

  const response = await fetch(`${EMS_API_URL}/analyses/${id}/`, {
    ...COMMON_FETCH_OPTIONS,
    method: "PATCH",
    body: JSON.stringify({ ...params }),
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return await response.json();
};

export const patchDoctorPermission = async (options: {
  ...$Exact<TokenObject>,
  ...$Exact<DoctorPermissionParams>
}) => {
  const { token, doctor_id, ...params } = options;

  const response = await fetch(`${EMS_API_URL}/doctor/${doctor_id}/add_permission/`, {
    ...COMMON_FETCH_OPTIONS,
    method: "PATCH",
    body: JSON.stringify({ ...params }),
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return await response;
};

export const getScheduleUpdatesUrl = () => {
  const url = new URL(`${EMS_API_URL}/ws/schedule-updates/`);

  url.protocol = "wss:";
  return url.toString();
};


export const getShortcuts = async (options: {
  ...$Exact<TokenObject>,
  layout_id: TLayout_id
}): Promise<Array<TShortcuts>> => {
  const response = await fetch(`${EMS_API_URL}/layouts/${options.layout_id}/shortcuts/`, {
    ...COMMON_FETCH_OPTIONS,
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${options.token}`
    }
  });

  if (!response.ok) {
    throw Error(response.statusText);
  }
  return await response.json();
};

export const postShortcut = async (options: {
  ...$Exact<TokenObject>,
  creationOptions: ShortcutCreationOptions
}) => {
  const { token, creationOptions } = options;
  const response = await fetch(`${EMS_API_URL}/shortcuts/`, {
    ...COMMON_FETCH_OPTIONS,
    method: "post",
    body: JSON.stringify(creationOptions),
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
};

export const putShortcut = async (options: {
  ...$Exact<TokenObject>,
  shortcut_id: number,
  creationOptions: ShortcutCreationOptions
}) => {
  const { token, shortcut_id, creationOptions } = options;

  const response = await fetch(`${EMS_API_URL}/shortcuts/${shortcut_id}/`, {
    ...COMMON_FETCH_OPTIONS,
    method: "PATCH",
    body: JSON.stringify(creationOptions),
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
};

export const postShortcutOrder = async (options: {
  ...$Exact<TokenObject>,
  shortcutsOrderOptions: ShortcutOrderOptions
}) => {
  const { token, shortcutsOrderOptions } = options;
  const response = await fetch(`${EMS_API_URL}/shortcuts/set-order/`, {
    ...COMMON_FETCH_OPTIONS,
    method: "post",
    body: JSON.stringify(shortcutsOrderOptions),
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
};

export const deleteShortcut = async (options: { ...$Exact<TokenObject>, shortcut_id: number }) => {
  const { token, shortcut_id } = options;

  const response = await fetch(`${EMS_API_URL}/shortcuts/${shortcut_id}/`, {
    ...COMMON_FETCH_OPTIONS,
    method: "delete",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return true;
};

export const getDiagnoses = (options: {
  ...$Exact<TokenObject>,
  parent?: ?TDiagnosis_id,
  search?: string
}) => {
  const { token, ...params } = options;
  const url = new URL(`${EMS_API_URL}/diagnoses/`);

  url.search = transformParams(params);
  return [
    url,
    {
      ...COMMON_FETCH_OPTIONS,
      headers: {
        ...COMMON_FETCH_OPTIONS.headers,
        Authorization: `JWT ${token}`
      }
    }
  ];
};

export const getDiagnosisParents = (options: { ...$Exact<TokenObject>, id: TDiagnosis_id }) => {
  const { token, id } = options;
  const url = new URL(`${EMS_API_URL}/diagnoses/${id}/parents/`);

  return [
    url,
    {
      ...COMMON_FETCH_OPTIONS,
      headers: {
        ...COMMON_FETCH_OPTIONS.headers,
        Authorization: `JWT ${token}`
      }
    }
  ];
};

export const getExaminations = (options: { ...$Exact<TokenObject>, search?: string }) => {
  const { token, ...params } = options;
  const url = new URL(`${EMS_API_URL}/decision-support/examinations/`);

  url.search = new URLSearchParams((params: any)).toString();
  return [
    url,
    {
      ...COMMON_FETCH_OPTIONS,
      headers: {
        ...COMMON_FETCH_OPTIONS.headers,
        Authorization: `JWT ${token}`
      }
    }
  ];
};

export const postProposeExaminations = async (options: {
  ...$Exact<TokenObject>,
  name: string
}) => {
  const { token, name } = options;

  const response = await fetch(`${EMS_API_URL}/decision-support/examinations/propose/`, {
    ...COMMON_FETCH_OPTIONS,
    method: "post",
    body: JSON.stringify({ name }),
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return true;
};

export const getMedicines = (options: { ...$Exact<TokenObject>, search?: string }) => {
  const { token, ...params } = options;
  const url = new URL(`${EMS_API_URL}/decision-support/medicines/`);

  url.search = new URLSearchParams((params: any)).toString();
  return [
    url,
    {
      ...COMMON_FETCH_OPTIONS,
      headers: {
        ...COMMON_FETCH_OPTIONS.headers,
        Authorization: `JWT ${token}`
      }
    }
  ];
};

export const postProposeMedicines = async (options: { ...$Exact<TokenObject>, name: string }) => {
  const { token, name } = options;

  const response = await fetch(`${EMS_API_URL}/decision-support/medicines/propose/`, {
    ...COMMON_FETCH_OPTIONS,
    method: "post",
    body: JSON.stringify({ name }),
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return true;
};

export const getConclusion = (options: { ...$Exact<TokenObject>, diagnosis: TDiagnosis_id }) => {
  const { token, ...params } = options;
  const url = new URL(`${EMS_API_URL}/decision-support/conclusion/`);

  url.search = new URLSearchParams((params: any)).toString();
  return [
    url,
    {
      ...COMMON_FETCH_OPTIONS,
      headers: {
        ...COMMON_FETCH_OPTIONS.headers,
        Authorization: `JWT ${token}`
      }
    }
  ];
};

export const getUltrasoundScans = (options: { ...$Exact<TokenObject>, appointment: number }) => {
  const { token, ...params } = options;
  const url = new URL(`${EMS_API_URL}/ultrasound/`);

  url.search = new URLSearchParams((params: any)).toString();
  return [
    url,
    {
      ...COMMON_FETCH_OPTIONS,
      headers: {
        ...COMMON_FETCH_OPTIONS.headers,
        Authorization: `JWT ${token}`
      }
    }
  ];
};

export const getFullSchedule = (options: {
  ...$Exact<TokenObject>,
  ...$Exact<ScheduleGetParams>,
  doctor: string,
  speciality: number,
  favorites_only: boolean
}) => {
  const { token, ...params } = options;
  const url = new URL(`${EMS_API_URL}/full-schedule/`);

  url.search = new URLSearchParams((params: any)).toString();

  return [
    url,
    {
      ...COMMON_FETCH_OPTIONS,
      headers: {
        ...COMMON_FETCH_OPTIONS.headers,
        Authorization: `JWT ${token}`
      }
    }
  ];
};

export const getPatientEmbryosCount = (options: { ...$Exact<TokenObject>, patientId: number }) => {
  const { token, patientId } = options;
  const url = new URL(`${EMS_API_URL}/embryos_count/?patient_id=${patientId}`);
  return [
    url,
    {
      ...COMMON_FETCH_OPTIONS,
      headers: {
        ...COMMON_FETCH_OPTIONS.headers,
        Authorization: `JWT ${token}`
      }
    }
  ];
};

export const fetchFullSchedule = (options: {
  ...$Exact<TokenObject>,
  ...$Exact<ScheduleGetParams>,
  doctor?: string,
  speciality?: number,
  favorites_only?: boolean
}) => async (params: { doctor?: string, speciality?: number, favorites_only?: boolean }) => {
  // const { doctor, speciality, favorites_only } = params;
  const response = await fetch(...getFullSchedule({ ...options, ...params }));
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return await response.json();
};

export const getFolders = (options: {
  ...$Exact<TokenObject>,
  layout_id: string,
  patient_id: number
}) => {
  const { token, layout_id, patient_id } = options;
  const url = new URL(`${EMS_API_URL}/folders/`);
  if (layout_id) url.searchParams.append("layout_id", layout_id);
  if (patient_id) url.searchParams.append("patient_id", patient_id.toString());
  return [
    url,
    {
      ...COMMON_FETCH_OPTIONS,
      headers: {
        ...COMMON_FETCH_OPTIONS.headers,
        Authorization: `JWT ${token}`
      }
    }
  ];
};

export const getQuestionnaire = (options: { ...$Exact<TokenObject>, id: number }) => {
  const { token, id } = options;
  const url = new URL(`${EMS_API_URL}/questionnaire/${id}/`);

  return [
    url,
    {
      ...COMMON_FETCH_OPTIONS,
      headers: {
        ...COMMON_FETCH_OPTIONS.headers,
        Authorization: `JWT ${token}`
      }
    }
  ];
};

export const updateQuestionnaireValue = async (options: {
  ...$Exact<TokenObject>,
  id: number,
  payload: any
}) => {
  const { token, id, payload } = options;
  const response = await fetch(`${EMS_API_URL}/questionnaire_values/${id}/`, {
    method: "PATCH",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    },
    body: JSON.stringify(payload)
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return await response.json();
};

export const finishQuestionnaire = async (options: { ...$Exact<TokenObject>, id: number }) => {
  const { token, id } = options;
  const response = await fetch(`${EMS_API_URL}/questionnaire/${id}/finish/`, {
    method: "POST",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return true;
};

export const getFolderAnalyses = async (options: { ...$Exact<TokenObject>, id: number }) => {
  const { token, id } = options;
  const response = await fetch(`${EMS_API_URL}/folders/${id}/patient_examinations/`, {
    method: "GET",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return await response.json();
};

export const getFolderAgreement = async (options: {
  ...$Exact<TokenObject>,
  folderId: number,
  agreementId: number
}) => {
  const { token, folderId, agreementId } = options;
  const response = await fetch(
    `${EMS_API_URL}/folders/${folderId}/patient_agreements/${agreementId}/print/`,
    {
      method: "GET",
      headers: {
        ...COMMON_FETCH_OPTIONS.headers,
        Authorization: `JWT ${token}`
      }
    }
  );
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return await response.json();
};

export const getFolderAgreements = async (options: { ...$Exact<TokenObject>, id: number }) => {
  const { token, id } = options;
  const response = await fetch(`${EMS_API_URL}/folders/${id}/patient_agreements/`, {
    method: "GET",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return await response.json();
};

export const getFolderPrintDetails = (options: {
  ...$Exact<TokenObject>,
  id: string,
  concreteFolder?: string
}) => {
  const { token, id, concreteFolder } = options;
  const url = new URL(`${EMS_API_URL}/folders/${id}/full_summary/print/`);
  if (concreteFolder) url.searchParams.append("only_folder_id", concreteFolder);

  return [
    url,
    {
      ...COMMON_FETCH_OPTIONS,
      headers: {
        ...COMMON_FETCH_OPTIONS.headers,
        Authorization: `JWT ${token}`
      }
    }
  ];
};

export const updateFolderAnalyze = async (options: {
  ...$Exact<TokenObject>,
  id: number,
  folderId: number,
  payload: any
}) => {
  const { token, folderId, id, payload } = options;
  const response = await fetch(`${EMS_API_URL}/folders/${folderId}/patient_examinations/${id}/`, {
    method: "PATCH",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    },
    body: JSON.stringify(payload)
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return await response.json();
};

export const updateFolderAgreement = async (options: {
  ...$Exact<TokenObject>,
  id: number,
  folderId: number,
  payload: any
}) => {
  const { token, folderId, id, payload } = options;
  const response = await fetch(`${EMS_API_URL}/folders/${folderId}/patient_agreements/${id}/`, {
    method: "PUT",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    },
    body: JSON.stringify(payload)
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return await response.json();
};

export const moveAppointmentFolder = async (options: {
  ...$Exact<TokenObject>,
  id: number,
  folderId: ?number,
  folderTypeId: ?number,
  appointmentId: number
}) => {
  const { token, folderId, folderTypeId, appointmentId } = options;
  const data = {};
  if (folderId) data.folder_id = folderId;
  if (folderTypeId) data.f_layout_id = folderTypeId;
  const response = await fetch(`${EMS_API_URL}/appointments/${appointmentId}/folders/`, {
    method: "PATCH",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    },
    body: JSON.stringify(data)
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return true;
};

export const removeAppointmentFromFolder = async (options: {
  ...$Exact<TokenObject>,
  id: number,
  folderId: number,
  appointmentId: number
}) => {
  const { token, folderId, appointmentId } = options;
  const response = await fetch(`${EMS_API_URL}/appointments/${appointmentId}/folders/`, {
    method: "DELETE",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    },
    body: JSON.stringify({ folder_id: folderId })
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return true;
};

export const getFolderSummary = async (options: { ...$Exact<TokenObject>, id: number }) => {
  const { token, id } = options;
  const response = await fetch(`${EMS_API_URL}/folders/${id}/full_summary/`, {
    method: "GET",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return await response.json();
};

export const getHospitalAdmissions = async (options: { ...$Exact<TokenObject> }) => {
  const { token } = options;
  const response = await fetch(`${EMS_API_URL}/hospital_admission/`, {
    method: "GET",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return await response.json();
};

export const patchHospitalAdmissions = async (options: {
  ...$Exact<TokenObject>,
  payload: any
}) => {
  const { token, payload } = options;
  const response = await fetch(`${EMS_API_URL}/hospital_admission/${payload.id}/`, {
    method: "PATCH",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    },
    body: JSON.stringify(payload)
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return await response.json();
};

// $FlowFixMe
export const getAppointmentsOutcomes = async ({ token, payload: { query, filter, eventType } }) => {
  const url = new URL(`${EMS_API_URL}/appointments/outcomes/`);

  if (query) {
    url.searchParams.append("patient_name", query);
  }

  if (filter === "all") {
    url.searchParams.append("all", "true");
  }

  if (eventType) {
    url.searchParams.append("layout_folder_id", eventType);
  }

  const response = await fetch(url, {
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });

  if (!response.ok) {
    throw Error(response.statusText);
  }

  return await response.json();
};

// $FlowFixMe
export const patchAppointmentsOutcomesFieldValue = async ({ token, payload: { value, id } }) => {
  const url = new URL(`${EMS_API_URL}/field_values/${id}/`);

  try {
    const response = await fetch(url, {
      method: "PATCH",
      headers: {
        ...COMMON_FETCH_OPTIONS.headers,
        Authorization: `JWT ${token}`
      },
      body: JSON.stringify({ value })
    });

    if (!response.ok) {
      throw Error(response.statusText);
    }

    return await response.json();
  } catch (err) {
    throw Error("Нет подключения");
  }
};

// $FlowFixMe
export const postAppointmentsOutcomesFieldValue = async ({
  token,
  payload: { appointmentId, fieldId, value }
}) => {
  const url = new URL(`${EMS_API_URL}/field_values/`);

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        ...COMMON_FETCH_OPTIONS.headers,
        Authorization: `JWT ${token}`
      },
      body: JSON.stringify({ value, appointment_id: appointmentId, copy_id: fieldId })
    });

    if (!response.ok) {
      throw Error(response.statusText);
    }

    return await response.json();
  } catch (err) {
    throw Error("Нет подключения");
  }
};

// $FlowFixMe
export const getEmbryoMedicalFiles = async ({ token, payload: { gender, embryoId } }) => {
  const url = new URL(`${EMS_API_URL}/embryos/${embryoId}/medical-files/`);

  if (gender) {
    url.searchParams.append("gender", gender);
  }

  const response = await fetch(url, {
    method: "GET",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });

  if (!response.ok) {
    throw Error(response.statusText);
  }

  return await response.json();
};

// $FlowFixMe
export const patchQuestionnairePartner = async ({ token, payload: { id, partnerId } }) => {
  const url = new URL(`${EMS_API_URL}/questionnaire/${id}/`);

  const response = await fetch(url, {
    method: "PATCH",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    },
    body: JSON.stringify({ partner_id: partnerId })
  });

  if (!response.ok) {
    throw Error(response.statusText);
  }

  return await response.json();
};

// $FlowFixMe
export const getEmbryosCountByFolderId = async ({ token, payload: { folderId } }) => {
  const url = new URL(`${EMS_API_URL}/folders/${folderId}/embryos_count/`);

  const response = await fetch(url, {
    method: "GET",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });

  if (!response.ok) {
    throw Error(response.statusText);
  }

  return await response.json();
};

// $FlowFixMe
export const getEmbryosByFolderId = async ({ token, payload: { folderId, viewType } }) => {
  const url = new URL(`${EMS_API_URL}/folders/${folderId}/embryos/`);

  if (viewType) {
    url.searchParams.append("view_type", viewType);
  }

  const response = await fetch(url, {
    method: "GET",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });

  if (!response.ok) {
    throw Error(response.statusText);
  }

  return await response.json();
};

// $FlowFixMe
export const getEmbryosManipulations = async ({ token, payload: { folderId } }) => {
  const url = new URL(`${EMS_API_URL}/folders/${folderId}/manipulations/`);

  const response = await fetch(url, {
    method: "GET",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });

  if (!response.ok) {
    throw Error(response.statusText);
  }

  return await response.json();
};

// $FlowFixMe
export const getEmbryologists = async ({ token }) => {
  const url = new URL(`${EMS_API_URL}/doctors/embryologists/`);

  const response = await fetch(url, {
    method: "GET",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });

  if (!response.ok) {
    throw Error(response.statusText);
  }

  return await response.json();
};

// $FlowFixMe
export const patchEmbryosManipulations = async ({
  token,
  payload: { id, folderId, time, doctorId, date, clear }
}) => {
  const url = new URL(`${EMS_API_URL}/folders/${folderId}/manipulations/${id}/`);

  const response = await fetch(url, {
    method: "PATCH",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    },
    body: JSON.stringify({ time, doctor_id: doctorId, date, clear })
  });

  if (!response.ok) {
    throw Error(response.statusText);
  }

  return await response.json();
};

// $FlowFixMe
export const removeAnalyse = async ({ token, ...params }) => {
  const url = new URL(`${EMS_API_URL}/analyses/${params.id}/`);

  const response = await fetch(url, {
    method: "DELETE",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });

  if (!response.ok) {
    throw Error(response.statusText);
  }

  return await response.json();
};

// $FlowFixMe
export const removeUploads = async ({ token, ...params }) => {
  const url = new URL(`${EMS_API_URL}/uploads/${params.id}/`);

  const response = await fetch(url, {
    method: "DELETE",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });

  if (!response.ok) {
    throw Error(response.statusText);
  }
};

// $FlowFixMe
export const getSummaryPrint = async ({ token, ...params }) => {
  const searchParams = new URLSearchParams();

  if (params.summary) {
    params.summary.forEach(summaryId => {
      searchParams.append("summary", summaryId);
    });
  }

  const url = new URL(`${EMS_API_URL}/patient/${params.patient_id}/print_epicrises/${searchParams ? `?${searchParams}` : ''}`);

  const response = await fetch(url, {
    method: "GET",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });

  if (!response.ok) {
    throw Error(response.statusText);
  }

  return await response.json();
};

// $FlowFixMe
export const getAppointmentAISlices = async ({ token, ...params }) => {
  const url = new URL(`${EMS_API_URL}/ai/appointment/ai_status/${params.appointmentId}`);

  const response = await fetch(url, {
    method: "GET",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });

  if (!response.ok) {
    throw Error(response.statusText);
  }

  return await response.json();
};

// $FlowFixMe
export const getPatientReminders = async ({ token }) => {
  const url = new URL(`${EMS_API_URL}/notifications/`);

  const response = await fetch(url, {
    method: "GET",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });

  if (!response.ok) {
    throw Error(response.statusText);
  }

  return await response.json();
};

// $FlowFixMe
export const getOrganizationById = async ({ token, id }) => {
  const response = await fetch(`${EMS_API_URL}/organizations/${id}/`, {
    method: "GET",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });

  if (!response.ok) {
    throw Error(response.statusText);
  }

  return await response.json();
};

// $FlowFixMe
export const getPatientRemindersUrl = ({ token }) => {
  const url = new URL(`${EMS_API_URL}/ws/notifications/`);
  url.searchParams.append("token", token);
  url.protocol = "wss:";

  return url.toString();
};

// $FlowFixMe
export const setPatientReminderRead = async ({ token, id }) => {
  const url = new URL(`${EMS_API_URL}/notifications/${id}/read/`);

  const response = await fetch(url, {
    method: "PATCH",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });

  if (!response.ok) {
    throw Error(response.statusText);
  }

  return await response.json();
};

// $FlowFixMe
export const getEmbryosPrintDocumentData = async ({ token, folderId, patientId, type }) => {
  const url = new URL(`${EMS_API_URL}/patients/${patientId}/embryos_print/`);

  url.searchParams.append("type", type);
  folderId && url.searchParams.append("folderId", folderId);

  const response = await fetch(url, {
    method: "GET",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    }
  });

  if (!response.ok) {
    throw Error(response.statusText);
  }

  return await response.json();
};

// $FlowFixMe
export const createPatientReminder = async ({ token, payload }) => {
  const url = new URL(`${EMS_API_URL}/notifications/`);

  const response = await fetch(url, {
    method: "POST",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      Authorization: `JWT ${token}`
    },
    body: JSON.stringify({
      appointment_id: payload.appointmentId,
      date_send: payload.date,
      text: payload.text
    })
  });

  if (!response.ok) {
    throw Error(response.statusText);
  }

  return await response.json();
};

export const getFeaturesConfig = async () => {
  const url = new URL(`${(process.env.EMS_API_URL || location.origin)}/mobile-api/configs/`);

  const response = await fetch(url, {
    method: "GET",
    headers: {
      ...COMMON_FETCH_OPTIONS.headers
    }
  });

  if (!response.ok) {
    throw Error(response.statusText);
  }

  return await response.json();
};
