// @flow
import React, { useEffect, useMemo } from "react";
import reviewStatisticsStore from "../../stores/reviewStatisticsStore";
import { MaterialTable } from "../common/MaterialTable";
import { useForm } from "react-hook-form";
import { FormInputDate } from "../common/dynamic-form/fields/FormInputDate";
import { Grid, Paper } from "@mui/material";
import moment from "moment";
import Button from "@mui/material/Button";
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { READABLE_DATE_FORMAT, SERVER_DATE_TIME_FORMAT } from "../../utils/dates";
import { trimLongTextValueField } from "../../utils/tableUtils";
import { WidgetsComponent } from "../common/widgets-components-common/WidgetsComponent";
import styled from "styled-components";
import useReactRouter from "use-react-router";
import { FormInputCheckbox } from "../common/dynamic-form/fields/FormInputCheckbox";
import patientFirstAvatar from "../../assets/image/patient-first-avatar.png"
import { observer } from "mobx-react";
import {
  GET_FIELDS_FOR_WIDGETS,
  GET_GENERATE_WIDGETS, GET_OPTIONS_FOR_WIDGET,
  REVIEW_KEYS
} from "../common/widgets-components-common/constants";
import organizationsStore from "../../stores/organizationsStore";
import { VoxInformationPatient } from "../vox-information-patient/VoxInformationPatient";

const Container = styled.div`
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
    position: relative;
`;

const PatientFirstAvatar = styled.img`
    position: absolute;
    right: 10px;
    top: 5px;
    width: 75px;
    height: 75px;
    @media(max-width: 900px) {
      height: 65px;
      width: 65px;
    }
`;

export const ReviewStatisticsPage = observer(() => {
  const date_end = moment().set({ hour: 23, minute: 59, second: 59 }).format(SERVER_DATE_TIME_FORMAT);
  const date_start = moment().set({ hour: 0, minute: 0, second: 0 }).set('month', moment().get('month') - 1).format(SERVER_DATE_TIME_FORMAT);
  const [changedColumnFilters, setChangedColumnFilters] = React.useState([]);

  const { history } = useReactRouter();

  const defaultValuesDates = {
    date_start,
    date_end
  }

  const methods = useForm({
    defaultValues: defaultValuesDates,
  });
  const { handleSubmit, control, setValue } = methods;

  const afterInitializeParams = (callback) => {
    reviewStatisticsStore.setAnnotateMtmParams({
      "tags": "tags__name"
    })
    if (!reviewStatisticsStore.globalFiltersTable.date__lte){
      reviewStatisticsStore.setGlobalFiltersTable({
        date__gte: date_start,
        date__lte: date_end,
      }, false)
    } else {
      setValue('date_start', reviewStatisticsStore.globalFiltersTable.date__gte)
      setValue('date_end', reviewStatisticsStore.globalFiltersTable.date__lte)
      setValue('patient_reviews__review_found', reviewStatisticsStore.globalFiltersTable.patient_reviews__review_found)
    }
    callback();
  }

  const onSubmit = (data) => {
    const filters = {
      date__gte: data.date_start,
      date__lte: data.date_end,
    };

    if (data.patient_reviews__review_found) {
      filters.patient_reviews__review_found = data.patient_reviews__review_found ? 'not sure' : '';
    }

    reviewStatisticsStore.setGlobalFiltersTable(filters);
  }

  useEffect(() => {
    if (!reviewStatisticsStore.changedFilterParams) {
      return;
    }
    setValue('date_start', reviewStatisticsStore.changedFilterParams.date.date__gte);
    setValue('date_end', reviewStatisticsStore.changedFilterParams.date.date__lte);
    setChangedColumnFilters(reviewStatisticsStore.changedFilterParams.columns);
  }, [reviewStatisticsStore.changedFilterParams]);

  const additionalFilters = (
    <Paper style={{
      padding: '20px'
    }}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid alignItems={"center"} container spacing={2}>
          <Grid item xs={12} md={3} sm={6}>
            <FormInputDate typePicker="dateTime" label="Дата от"  name="date_start" control={control} />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <FormInputDate typePicker="dateTime" label="до " name="date_end" control={control} />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <FormInputCheckbox label="Нужна модерация" name="patient_reviews__review_found" control={control} />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Button type={"submit"} size={"large"} variant="contained" color={"success"}>
              Применить
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );

  const handleChangeTab = (event, newValue) => {
    reviewStatisticsStore.changeActiveTab(newValue);
  };

  useEffect(() => {
    (async () => {
      await organizationsStore.getOrganizations();
      await reviewStatisticsStore.getReviewTag();
    })()
  }, []);


  const columns = useMemo(
    () => [
      {
        accessorKey: 'patient_reviews__review_found', //normal accessorKey
        header: 'Связь с ПЛ',
        filterVariant: 'multi-select',
        filterSelectOptions: [
          {
            value: 'yes',
            label: 'Да'
          },
          {
            value: 'no',
            label: 'Нет'
          },
          {
            value: 'not sure',
            label: 'Не уверен'
          },
        ],
        filterFn: 'in',
        Cell: ({ cell, row }) => {
          if (cell.getValue() !== 'not sure') {
            return cell.getValue() === 'yes' ? 'Да' : 'Нет';
          } else {
            return (
              <Button onClick={() => history.push(`/review-statistics/${row.original.id}`)} variant="contained" color={"error"}> Модерировать </Button>
            )
          }
        }
      },
      {
        accessorKey: 'id', //normal accessorKey
        header: 'Идентификатор отзыва',
        size: 270,
        enableHiding: false,
        filterFn: 'icontains',
      },
      {
        accessorKey: 'patient_reviews__id', //normal accessorKey
        header: 'Идентификатор отзыва пациента',
        filterFn: 'icontains',
        size: 350,
      },
      {
        accessorKey: 'date',
        header: 'Дата отправления',
        enableColumnFilter: false,
        size: 250,
        Cell: ({ cell }) =>
          moment(cell.getValue()).format(READABLE_DATE_FORMAT),
      },
      {
        accessorKey: 'patient_name',
        additionalFields: ['fullname'],
        filterFn: 'icontains',
        size: 350,
        header: 'Пациент',
        Cell: ({ row }) => row.original.fullname?.trim() || row.original.patient_name
      },
      {
        accessorKey: 'patient_reviews__patient__phone_number',
        filterFn: 'icontains',
        size: 350,
        header: 'Телефон пациента',
      },
      {
        accessorKey: 'patient_reviews__schedule__medical_file__number',
        filterFn: 'icontains',
        header: 'Номер медицинской карты',
        size: 300,
      },
      {
        accessorKey: 'map_name',
        header: 'Где оставили отзыв',
        filterFn: 'in',
        size: 450,
        filterVariant: 'multi-select',
        filterSelectOptions: [...reviewStatisticsStore.mapNamesOptions, {value: 'app_internal', label: 'APP Внутренний сервис'}],
        Cell: ({ row }) => reviewStatisticsStore.mapNamesOptionsTableName[row.original.map_name]
      },
      {
        accessorKey: 'organization',
        additionalFields: ['organization__name'],
        header: 'Организация',
        filterFn: 'in',
        filterVariant: 'multi-select',
        filterSelectOptions: organizationsStore.organizations.map((item) => ({value: item.id, label: item.name})),
        Cell: ({ row }) => row.original.organization__name,
        size: 300,
      },
      {
        accessorKey: 'rate',
        header: 'Оценка',
        filterFn: 'in',
        filterVariant: 'multi-select',
        size: 265,
        filterSelectOptions: [
          {
            value: '1',
            label: '1'
          },
          {
            value: '2',
            label: '2'
          },
          {
            value: '3',
            label: '3'
          },
          {
            value: '4',
            label: '4'
          },
          {
            value: '5',
            label: '5'
          },
        ],
      },
      {
        accessorKey: 'patient_reviews__doctor_rate',
        header: 'Оценка по доктору',
        filterFn: 'in',
        filterVariant: 'multi-select',
        size: 300,
        filterSelectOptions: [
          {
            value: '1',
            label: '1'
          },
          {
            value: '2',
            label: '2'
          },
          {
            value: '3',
            label: '3'
          },
          {
            value: '4',
            label: '4'
          },
          {
            value: '5',
            label: '5'
          },
        ],
        Cell: ({ row }) => row.original.patient_reviews__doctor_rate ? row.original.patient_reviews__doctor_rate : '',
      },
      {
        accessorKey: 'patient_reviews__administrators_rate',
        header: 'Оценка по администратору',
        filterFn: 'in',
        filterVariant: 'multi-select',
        size: 300,
        filterSelectOptions: [
          {
            value: '1',
            label: '1'
          },
          {
            value: '2',
            label: '2'
          },
          {
            value: '3',
            label: '3'
          },
          {
            value: '4',
            label: '4'
          },
          {
            value: '5',
            label: '5'
          },
        ],
        Cell: ({ row }) => row.original.patient_reviews__administrators_rate ? row.original.patient_reviews__administrators_rate : '',
      },
      {
        accessorKey: 'patient_reviews__middle_medical_staff_rate',
        header: 'Оценка по среднему персоналу',
        filterFn: 'in',
        filterVariant: 'multi-select',
        size: 330,
        filterSelectOptions: [
          {
            value: '1',
            label: '1'
          },
          {
            value: '2',
            label: '2'
          },
          {
            value: '3',
            label: '3'
          },
          {
            value: '4',
            label: '4'
          },
          {
            value: '5',
            label: '5'
          },
        ],
        Cell: ({ row }) => row.original.patient_reviews__middle_medical_staff_rate ? row.original.patient_reviews__middle_medical_staff_rate : '',
      },
      {
        accessorKey: 'patient_reviews__service_rate',
        header: 'Оценка по сервису',
        filterFn: 'in',
        filterVariant: 'multi-select',
        size: 300,
        filterSelectOptions: [
          {
            value: '1',
            label: '1'
          },
          {
            value: '2',
            label: '2'
          },
          {
            value: '3',
            label: '3'
          },
          {
            value: '4',
            label: '4'
          },
          {
            value: '5',
            label: '5'
          },
        ],
        Cell: ({ row }) => row.original.patient_reviews__service_rate ? row.original.patient_reviews__service_rate : '',
      },
      {
        accessorKey: 'patient_reviews__is_need_callback',
        header: 'Нужен обратный звонок?',
        filterVariant: 'select',
        filterSelectOptions: [
          {
            value: true,
            label: 'Да'
          },
          {
            value: false,
            label: 'Нет'
          },
        ],
        size: 300,
        filterFn: '',
        Cell: ({ cell }) =>
          cell.getValue() === true ? 'Да' : 'Нет',
      },
      {
        accessorKey: 'review_text',
        header: 'Отзыв',
        filterFn: 'icontains',
        Cell: ({ row }) => trimLongTextValueField({ value: row.original.review_text })
      },
      {
        accessorKey: 'status',
        header: 'Статус',
        filterFn: 'in',
        filterSelectOptions: reviewStatisticsStore.statusOptionsFields,
        filterVariant: 'multi-select',
        Cell: ({ cell }) =>
          reviewStatisticsStore.statusOptionsFields.find((options) => options.value === cell.getValue())?.label,
      },
      {
        accessorKey: 'patient_reviews__is_set_loyalty_coins',
        header: 'Начислены баллы лояльности?',
        filterVariant: 'select',
        filterSelectOptions: [
          {
            value: true,
            label: 'Да'
          },
          {
            value: false,
            label: 'Нет'
          },
        ],
        size: 350,
        filterFn: '',
        Cell: ({ cell }) => {
          return cell.getValue() === true ? "Да" : "Нет";
        },
      },
      {
        accessorKey: 'tags__name',
        header: 'Тэг',
        Cell: ({ row }) => row.original.tags__name?.join(', '),
        filterFn: 'in',
        filterVariant: 'multi-select',
        filterSelectOptions: reviewStatisticsStore.reviewTags.map((item) => ({value: item.name, label: item.name})),
      },
      {
        accessorKey: 'appointment_type',
        header: 'Тип приема',
        filterFn: 'in',
        filterSelectOptions: reviewStatisticsStore.appointmentTypeOptionsFields,
        filterVariant: 'multi-select',
        Cell: ({ cell }) =>
          reviewStatisticsStore.appointmentTypeOptionsFields.find((options) => options.value === cell.getValue())?.label || '',
      },
      {
        accessorKey: 'comment',
        header: 'Коммент',
        filterFn: 'icontains',
      },
    ],
    [organizationsStore.organizations, reviewStatisticsStore.reviewTags],
  );

  return (
    <Container>
      <PatientFirstAvatar src={patientFirstAvatar} />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={reviewStatisticsStore.activeTab} onChange={handleChangeTab} aria-label="basic tabs example">
          <Tab label="Таблица" value={'table'} />
          <Tab label="Графики" value={'charts'} />
          <Tab label="Информация о пациенте" value={'patientInformation'} />
        </Tabs>
      </Box>
      <div style={{display: reviewStatisticsStore.activeTab !== 'table' ? 'none' : 'block'}}>
        <MaterialTable disableHeightResizing={true} initialState={{showColumnFilters: true, columnVisibility: {patient_reviews__id: false, patient_reviews__schedule__medical_file__number: false}}} additionalFilters={additionalFilters} afterInitializeParams={afterInitializeParams} actions={['edit']} store={reviewStatisticsStore} columns={columns} changedColumnFilters={changedColumnFilters}/>
      </div>
      <div style={{display: reviewStatisticsStore.activeTab !== 'charts' ? 'none' : 'block'}}>
        <WidgetsComponent
          dashboardLabel="Графики по отзывам"
          fieldsWidgets={GET_FIELDS_FOR_WIDGETS(REVIEW_KEYS)}
          generateWidgets={GET_GENERATE_WIDGETS(REVIEW_KEYS)}
          optionsField={GET_OPTIONS_FOR_WIDGET(REVIEW_KEYS)}
          nameLocalStorage="ReviewWidgetComponents"
        />
      </div>
      <div style={{display: reviewStatisticsStore.activeTab !== 'patientInformation' ? 'none' : 'block'}}>
        <VoxInformationPatient />
      </div>
    </Container>
  );
});
