import { makeAutoObservable } from "mobx";
import apiv2 from "../../src/apiv2";
import React from "react";
import { useToast } from "../components/toast/useToast";

const toastManager = useToast();

const checkRequestForSuccess = (response, callback) => {
  if (response.mailing_id) {
    if (callback) {
      callback();
    }
    toastManager.showToast(
      <div>Сообщение пациенту отправилось успешно</div>,
      {
        duration: 10000
      }
    );
  } else {
    toastManager.showToast(
      <div>Произошла ошибка при отправке сообщения</div>,
      {
        duration: 10000
      }
    );
  }
};

export const TEMPLATES = {
  /** Отмена приема */
  MESSAGE_INITIATION_CANCEL: "message_initiation_cancel",
  /** Перенос приема */
  MESSAGE_INITIATION_TRANSFER: "message_initiation_transfer",
  /** Открытие расписания (лист ожидания) */
  MESSAGE_INITIATION_OPENING_SCHEDULE: "message_initiation_opening_schedule",
  /** Освободившийся слот (лист ожидания) */
  MESSAGE_INITIATION_VACANT_SLOT: "message_initiation_vacant_slot",
  /** Подготовка к процедурам */
  MESSAGE_INITIATION_PREPARATION_PROCEDURES: "message_initiation_preparation_procedures",
  /** Динамическое сообщение */
  MESSAGE_INITIATION_DYNAMIC_TEXT: "message_initiation_dynamic_text",
  /** Обратная связь */
  T_FEEDBACK_NEW: "t_feedback_new",
};

export const TEMPLATE_TEXTS = {
  [TEMPLATES.MESSAGE_INITIATION_CANCEL]:
    "Приветствуем вас 👋🏻\nЭто служба заботы сети «Клиника Фомина» \n\nК сожалению, мы вынуждены сообщить вам об отмене вашего приема 😔\nМы приносим извинения за возможные неудобства и будем рады предложить вам альтернативные даты и время для визита 🙌🏻\n\nПожалуйста, свяжитесь с нами,чтобы перенести ваш прием на удобное для вас время.",
  [TEMPLATES.MESSAGE_INITIATION_TRANSFER]:
    "Приветствуем вас 👋🏻\nЭто служба заботы сети «Клиника Фомина»\n\nМы хотели бы уведомить вас о необходимости переноса вашего приема 😔\n\nПожалуйста, свяжитесь с нами для выбора другого времени. \nБлагодарим за ваше понимание и терпение ❤️",
  [TEMPLATES.MESSAGE_INITIATION_OPENING_SCHEDULE]:
    "Приветствуем вас 👋🏻\nЭто служба заботы сети «Клиника Фомина»\n\nРады сообщить, что у нас открылось расписание для приема у специалиста 😍\nМы увидели, что вы в списке ожидания, и приглашаем записаться на прием в удобное для вас время!\n\nПожалуйста, свяжитесь с нами, чтобы выбрать подходящую дату и время. \nМы будем рады видеть вас в нашей клинике ❤️",
  [TEMPLATES.MESSAGE_INITIATION_VACANT_SLOT]:
    "Приветствуем вас 👋🏻\nЭто служба заботы сети «Клиника Фомина»\n\nРады сообщить, что у нас освободился слот для приема у специалиста 😍\nМы увидели, что вы в списке ожидания, и приглашаем записаться на прием! \n\nПожалуйста, свяжитесь с нами, чтобы выбрать подходящую дату и время. \nМы будем рады видеть вас в нашей клинике ❤️",
  [TEMPLATES.MESSAGE_INITIATION_PREPARATION_PROCEDURES]:
    "Приветствуем вас 👋🏻\nЭто служба заботы сети «Клиника Фомина». \n\nМы рады помочь вам и расскажем, как правильно подготовиться к процедуре.\n\nПознакомиться с подготовкой вы можете здесь: {{link}}\n\nЕсли возникнут вопросы, всегда рады помочь ❤️",
  [TEMPLATES.MESSAGE_INITIATION_DYNAMIC_TEXT]:
    "Приветствуем вас 👋🏻\nЭто служба заботы сети «Клиника Фомина». \n\n{{message}}",
  [TEMPLATES.T_FEEDBACK_NEW]:
    "{{last_name}} {{first_name}}, здравствуйте 🙌🏻\nБлагодарим вас за то, что выбрали нашу клинику!\nЧтобы стать еще лучше для наших пациентов, мы хотим узнать ваше мнение о нашем сервисе и услугах ❤️\nБудем благодарны, если вы оставите отзыв: {{link}}\n\nВ благодарность за ваше мнение мы пополним вашу программу лояльности бонусными баллами в размере 500 рублей 😍"
};

class VoxInformationPatientStore {
  communications = [];
  medicalFile = null;
  scheduleFuture = [];
  phone_number = null;
  dynamicText = '';
  linkToInstructions = '';
  feedbackData = {};

  constructor() {
    makeAutoObservable(this);
  }

  setPhoneNumber(value) {
    this.phone_number = value;
  }

  setDynamicText(value) {
    this.dynamicText = value;
  }

  setLinkToInstructions(value) {
    this.linkToInstructions = value;
  }

  setFeedbackData(key, value) {
    this.feedbackData[key] = value;
  }

  async fetchCommunications() {
    try {
      const response = await apiv2.integrations.getCommunication(this.phone_number);
      this.communications = response.map((item) => {
        item.templateWithVariables = item.communication_mailing.template || '';
        Object.keys(item.message_data).forEach((key) => {
          if (item.templateWithVariables.includes(`{{${key}}}`)) {
            item.templateWithVariables = item.templateWithVariables.replaceAll(`{{${key}}}`, item.message_data[key]);
          }
        })
        return item;
      });
    } catch (e) {
      console.log(e);
    }
  }

  async fetchScheduleFuture() {
    try {
      this.scheduleFuture = await apiv2.integrations.getScheduleFuture(this.phone_number);
    } catch (e) {
      console.log(e);
    }
  }

  async fetchMedicalFiles() {
    try {
      const response = await apiv2.integrations.getMedicalFiles(this.phone_number);
      if (!response.length) {
        return;
      }
      this.medicalFile = response.reduce((acc, item, index) => {
        if (index === 0) {
          acc.patient = item.patient;
          acc.organizations = [];
          acc.badge = [];
          acc.policies = [];
        }
        if (!acc.organizations.includes(item.organization.name)) {
          acc.organizations.push(item.organization.name);
        }

        if (!acc.badge.includes(item.badge) && item.badge) {
          acc.badge.push(item.badge);
        }

        if (item.policies?.length) {
          acc.policies.push(item.policies);
        }

        return acc;
      }, {});
    } catch (e) {
      console.log(e);
    }
  }

  async initialize() {
    if (!this.phone_number) {
      console.log('Нету параметра phone_number');
      return;
    }
    await this.fetchCommunications();
    await this.fetchMedicalFiles();
    await this.fetchScheduleFuture();
  }

  async sendMessageInitiationCancel() {
    const response = await apiv2.communication.sendDynamicMessage({
      phone_number: this.phone_number, template: TEMPLATES.MESSAGE_INITIATION_CANCEL
    });
    checkRequestForSuccess(response);
  }

  async sendMessageInitiationTransfer() {
    const response = await apiv2.communication.sendDynamicMessage({
      phone_number: this.phone_number, template: TEMPLATES.MESSAGE_INITIATION_TRANSFER
    });
    checkRequestForSuccess(response);
  }

  async sendMessageInitiationOpeningSchedule() {
    const response = await apiv2.communication.sendDynamicMessage({
      phone_number: this.phone_number, template: TEMPLATES.MESSAGE_INITIATION_OPENING_SCHEDULE
    });
    checkRequestForSuccess(response);
  }

  async sendMessageInitiationVacantSlot() {
    const response = await apiv2.communication.sendDynamicMessage({
      phone_number: this.phone_number, template: TEMPLATES.MESSAGE_INITIATION_VACANT_SLOT
    });
    checkRequestForSuccess(response);
  }
  async sendMessageInitiationPreparationProcedures() {
    const response = await apiv2.communication.sendDynamicMessage({
      messageData: { link: this.linkToInstructions }, phone_number: this.phone_number, template: TEMPLATES.MESSAGE_INITIATION_PREPARATION_PROCEDURES
    });
    checkRequestForSuccess(response, () => this.linkToInstructions = '');
  }

  async sendDynamicMessagePatient() {
    const response = await apiv2.communication.sendDynamicMessage({
      messageData: { message: this.dynamicText }, phone_number: this.phone_number, template: TEMPLATES.MESSAGE_INITIATION_DYNAMIC_TEXT
    });
    checkRequestForSuccess(response, () => this.dynamicText = '');
  }

  async sendFeedbackNew() {
    const response = await apiv2.communication.sendDynamicMessage({
      messageData: {
        last_name: this.feedbackData?.last_name || "",
        first_name: this.feedbackData?.first_name || "",
        link: this.feedbackData?.link || ""
      },
      phone_number: this.phone_number,
      template: TEMPLATES.T_FEEDBACK_NEW
    });
    checkRequestForSuccess(response, () => this.feedbackData = {});
  }
}

export default new VoxInformationPatientStore();
