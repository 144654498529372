// @flow
import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { CorporateContent } from "../components/CorporateContent";
import {
  CorporateBirthdaysWidget,
  CorporateFeedBackWidget,
  CorporateVoteWidget
} from "../modules/widgets";
import { BackdropLoader } from "../../components/loader/Loader";
import corporateCompanyStore from "../stores/corporateCompanyStore";
import { CorporateControlPanel } from "../components/CorporateControlPanel";
import { CorporateWrapper } from "../styled/CorporateWrapper.styled";
import { styled } from "@mui/material/styles";
import { breakpoints } from "../styled/common";

const ContentContainer = styled(CorporateWrapper)`
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
  gap: 24px;

  img {
    flex: 1;
    min-width: 30%;
    max-width: 35%;
    border-radius: 4px;
  }
  h2 {
    display: inline-block;
    width: 100%;
    font-size: 24px;
    font-weight: 500;
  }
  p {
    line-height: 24px;
  }

  ${breakpoints.desktop} {
    margin-top: 24px;
    gap: 16px;
    img {
      min-width: 45%;
      max-width: 50%;
    }
    h2 {
      font-size: 22px;
    }
  }
  ${breakpoints.sm} {
    margin-top: 10px;
    gap: 10px;
    img {
      min-width: auto;
      max-width: 100%;
    }
    h2 {
      font-size: 20px;
    }
  }
`;

export const CorporateCompanyPage = observer(() => {
  const sideBarContent = [
    {
      name: "birthdays",
      content: <CorporateBirthdaysWidget />
    },
    {
      name: "feedBack",
      content: <CorporateFeedBackWidget />
    },
    {
      name: "vote",
      content: <CorporateVoteWidget />
    }
  ];

  useEffect(() => {
    corporateCompanyStore.getCompanyInfo();
  }, []);

  return (
    <CorporateContent rightSideBarContent={sideBarContent}>
      <BackdropLoader isOpen={!corporateCompanyStore.isInitialized} />
      <CorporateControlPanel
        loading={!corporateCompanyStore.isInitialized}
        tabs={corporateCompanyStore.tabs}
        indexSelectedTab={corporateCompanyStore.selectedTab.index}
        handleOnChangeSelectedTab={corporateCompanyStore.changeSelectedTab}
      />
      {!!corporateCompanyStore.companyInfoByTab && (
        <ContentContainer
          dangerouslySetInnerHTML={{ __html: corporateCompanyStore.companyInfoByTab }}
        />
      )}
    </CorporateContent>
  );
});
