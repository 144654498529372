// @flow
import styled from "styled-components";
import plusImg from "./icons/plus.svg";

export const AddPointButton = styled.button`
  width: 14px;
  height: 14px;
  background: url(${plusImg}) no-repeat center / contain;
  border: none;
  opacity: 0.3;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
`;
