import React from "react";
import { observer } from "mobx-react";
import { CorporateBirthdaysWidget, CorporateFeedBackWidget } from "../../modules/widgets";
import { BackdropLoader } from "../../../components/loader/Loader";
import corporateNewsStore from "../../stores/corporateNewsStore";
import { Stack } from "@mui/material";
import { CorporateContent } from "../CorporateContent";
import { CorporateControlPanel } from "../CorporateControlPanel";

export const CorporateNewsLayout = observer(({ children }) => {
  const sideBarContent = [
    {
      name: "birthdays",
      content: <CorporateBirthdaysWidget />
    },
    {
      name: "feedBack",
      content: <CorporateFeedBackWidget />
    }
  ];

  return (
    <CorporateContent rightSideBarContent={sideBarContent}>
      <BackdropLoader isOpen={!corporateNewsStore.isInitialized} />
      <Stack spacing={3}>
        <CorporateControlPanel
          loading={!corporateNewsStore.isInitialized}
          tabs={corporateNewsStore.categories}
          indexSelectedTab={corporateNewsStore.selectedCategory.index}
          handleOnChangeSelectedTab={corporateNewsStore.changeSelectedCategory}
          backButton={{
            isShow: !!corporateNewsStore.news,
            text: "Все новости",
            path: "/corporate-portal/news"
          }}
          searchField={{
            label: "Поиск новости",
            value: corporateNewsStore.searchValue,
            onChange: corporateNewsStore.changeSearchValue
          }}
        />
        {children}
      </Stack>
    </CorporateContent>
  );
});
