import React from "react";
import apiv2 from '../apiv2';
import DynamicTableStore from "./dynamicTableStore";
import { action, makeObservable, observable } from "mobx";
import _ from "lodash";
import { organizationsColor } from "../utils/organizationsColor";
import { useToast } from "../components/toast/useToast";

const toastManager = useToast();

export const INTERVAL_FORMAT = {
  day: 'DD.MM.YYYY',
  week: 'DD.MM.YYYY',
  month: 'MMMM',
  year: 'YYYY'
}

export const transformTableData = (data, organization_ids, valueName, organizations) => {
  const returnData = [];
  if (Array.isArray(data)) {
    const organizationsData = _.groupBy(data, 'organization_id');
    Object.keys(organizationsData).forEach((organizationId) => {
      returnData.push(
        {
          organization: organizations.find((organization) => parseInt(organization.id) === parseInt(organizationId))?.name,
          count: organizationsData[organizationId].reduce((acc, item) => {
            acc += item[valueName];
            return acc;
          }, 0)
        }
      )
    })
  }
  return returnData;
};

export const transformTableDataPercentage = (data, organization_ids, valueName, organizations) => {
  const returnData = [];
  if (Array.isArray(data)) {
    const organizationsData = _.groupBy(data, 'organization_id');
    Object.keys(organizationsData).forEach((organizationId) => {
      returnData.push(
        {
          organization: organizations.find((organization) => parseInt(organization.id) === parseInt(organizationId))?.name,
          count: (organizationsData[organizationId].reduce((acc, item) => {
            acc += item[valueName];
            return acc;
          }, 0) / organizationsData[organizationId].length).toFixed(0)
        }
      )
    })
  }
  return returnData;
};

export const transformDiagramData = ({data, interval, name, valueName = 'count', isCollapseData}) => {
  const groupedDataDate = _.groupBy(_.sortBy(data, 'date_trunc'), 'date_trunc');
  const groupedDataOrganizations = _.groupBy(_.sortBy(data, 'organization_id'), 'organization_id');
  const groupedDataDateOrganizations = Object.keys(groupedDataDate).reduce((acc, key) => {
    acc[key] = _.groupBy(_.sortBy(groupedDataDate[key], 'organization_id'), 'organization_id');
    return acc;
  }, {})
  const labels = Object.keys(groupedDataDate);
  const organizationsData = Object.keys(groupedDataOrganizations).reduce((acc, key) => {
    acc[key] = {
      data: [],
      label: '',
      organization_id: '',
    };
    return acc;
  }, {});
  labels.forEach((labelDate) => {
    Object.keys(groupedDataOrganizations).forEach((organizationId) => {
      const dataOrganizations = groupedDataDateOrganizations[labelDate][organizationId];
      if (!organizationsData[organizationId].label && dataOrganizations) {
        organizationsData[organizationId].label = dataOrganizations[0].name;
        if (name) {
          organizationsData[organizationId].label += ` - ${name}`;
        }
        organizationsData[organizationId].organization_id = dataOrganizations[0].organization_id;
      }
      organizationsData[organizationId].data.push(dataOrganizations ? dataOrganizations.reduce((acc, item) => {
        acc += item[valueName];
        return acc;
      }, 0) : 0);
    })
  })

  const datasets = Object.keys(organizationsData).reduce((acc, key) => {
    const data = organizationsData[key];
    if (isCollapseData) {
      if (!acc.length) {
        acc.push({
          label: data.label,
          data: data.data || [],
          nameField: name,
          color: organizationsColor[key],
          highlightScope: {
            highlighted: 'series',
            faded: 'global',
          },
          organization_id: data.organization_id
        })
      } else {
        acc[0].label += ', ' + data.label;
        data.data.forEach((count, index) => {
          acc[0].data[index] = acc[0].data[index] + count;
        })
      }
    } else {
      acc.push({
        label: data.label,
        data: data.data || [],
        nameField: name,
        color: organizationsColor[key],
        highlightScope: {
          highlighted: 'series',
          faded: 'global',
        },
        organization_id: data.organization_id
      })
    }
    return acc;
  }, []);
  return { labels, datasets };
}

export const transformDiagramDataPercentage = ({data, interval, name, valueName = 'count', isCollapseData}) => {
  const groupedDataDate = _.groupBy(_.sortBy(data, 'date_trunc'), 'date_trunc');
  const groupedDataOrganizations = _.groupBy(_.sortBy(data, 'organization_id'), 'organization_id');
  const groupedDataDateOrganizations = Object.keys(groupedDataDate).reduce((acc, key) => {
    acc[key] = _.groupBy(_.sortBy(groupedDataDate[key], 'organization_id'), 'organization_id');
    return acc;
  }, {})
  const labels = Object.keys(groupedDataDate);
  const organizationsData = Object.keys(groupedDataOrganizations).reduce((acc, key) => {
    acc[key] = {
      data: [],
      label: '',
      organization_id: '',
    };
    return acc;
  }, {});
  labels.forEach((labelDate) => {
    Object.keys(groupedDataOrganizations).forEach((organizationId) => {
      const dataOrganizations = groupedDataDateOrganizations[labelDate][organizationId];
      if (!organizationsData[organizationId].label && dataOrganizations) {
        organizationsData[organizationId].label = dataOrganizations[0].name;
        if (name) {
          organizationsData[organizationId].label += ` - ${name}`;
        }
        organizationsData[organizationId].organization_id = dataOrganizations[0].organization_id;
      }
      organizationsData[organizationId].data.push(dataOrganizations ? dataOrganizations.reduce((acc, item) => {
        acc += item[valueName];
        return acc;
      }, 0) : null);
    })
  })

  const datasets = Object.keys(organizationsData).reduce((acc, key) => {
    const data = organizationsData[key];
    if (isCollapseData) {
      if (!acc.length) {
        acc.push({
          label: data.label,
          data: data.data || [],
          nameField: name,
          color: organizationsColor[key],
          highlightScope: {
            highlighted: 'series',
            faded: 'global',
          },
          organization_id: data.organization_id
        })
      } else {
        acc[0].label += ', ' + data.label;
        data.data.forEach((count, index) => {
          acc[0].data[index] = count === null ? acc[0].data[index] : (acc[0].data[index] + count) / 2;
        })
      }
    } else {
      acc.push({
        label: data.label,
        data: data.data || [],
        nameField: name,
        color: organizationsColor[key],
        highlightScope: {
          highlighted: 'series',
          faded: 'global',
        },
        organization_id: data.organization_id
      })
    }
    return acc;
  }, []);
  return {labels, datasets};
}

class ReviewStatisticsStore extends DynamicTableStore {
  appointmentType = {
    labels: [],
    datasets: [],
  };
  setRate = [];
  redirectYandex = [];
  rate = {
    labels: [],
    datasets: [],
  };
  urlOpen = [];
  urlSend = [];
  reviewId = null;
  patientId = null;
  loyaltyProgram = {
    isInstalledPoints: false,
    isRegisteredInProgram: false,
    numberOfPoints: 0
  };
  review = null;
  reviewTags = [];
  mapNamesOptionsTableName = {
    internal: 'Внутренний сервис',
    yandex: 'Яндекс',
    '2gis': '2ГИС',
    'about_doctors': 'Продокторов',
    app_internal: 'APP Внутренний сервис',
    google: 'Google',
  }
  mapNamesOptions = [
    {value: 'internal', label: 'Внутренний сервис'},
    {value: 'yandex', label: 'Яндекс'},
    {value: '2gis', label: '2ГИС'},
    {value: 'about_doctors', label: 'Продокторов'},
  ];
  appointmentTypeOptionsFields = [
    {
      label: 'Первичный',
      value: 'first'
    },
    {
      label: 'Вторичный',
      value: 'second'
    },
  ];
  statusOptionsFields = [
    {
      label: 'Не обработан',
      value: 'unprocessed'
    },
    {
      label: 'В работе',
      value: 'in_progress'
    },
    {
      label: 'Обработан',
      value: 'processed'
    },
    {
      label: 'Потерян',
      value: 'lost'
    }
  ];

  constructor() {
    super(apiv2.reviewStatistics.getListReviews.bind(apiv2.reviewStatistics));
    this.changedFilterParams = null;
    this.activeTab = "table";

    makeObservable(this, {
      reviewId: observable,
      review: observable,
      reviewTags: observable,
      appointmentTypeOptionsFields: observable,
      mapNamesOptionsTableName: observable,
      mapNamesOptions: observable,
      statusOptionsFields: observable,
      setReviewId: action,
      getReview: action,
      postReviews: action,
      getReviewTag: action,
      appointmentType: observable,
      rate: observable,
      patientId: observable,
      loyaltyProgram: observable,
      setChangedFilterParams: action,
      changedFilterParams: observable,
      changeActiveTab: action,
      activeTab: observable,
    })
  }

  setReviewId(reviewId) {
    this.reviewId = reviewId;
  }

  setChangedFilterParams(params) {
    this.changedFilterParams = params;
    this.setGlobalFiltersTable({ ...params.date }, false);
    this.changeActiveTab("table");
  }

  changeActiveTab(tab) {
    this.activeTab = tab;
  }

  async getReview(valuesParams) {
    try {

      const response = await apiv2.reviewStatistics.getListReviews({
        filter_params: {id: this.reviewId},
        values_params: valuesParams,
        annotate_mtm_params: { tags: "tags__id" }
      });
      if (response.data[0].patient_reviews__review_found === 'not sure') {
        const responseModerate = await apiv2.reviewStatistics.getListReviews({
          filter_params: {id: this.reviewId},
          values_params: ['fullname', 'patient_reviews__date_is_set_rate', 'patient_reviews__organization__name', 'patient_reviews__id', 'patient_reviews__rate', 'patient_reviews__review_text', 'patient_name', 'date', 'organization__name', 'rate', 'review_text', 'patient_reviews__review_found'],
        });
        this.review = responseModerate.data[0];
        return;
      }
      this.review = response.data[0];
      this.patientId = response.data[0]?.patient_reviews__patient || null;
      if (this.patientId) {
        this.loyaltyProgram.isInstalledPoints = response.data[0]?.patient_reviews__is_set_loyalty_coins || false;
        await this.getNumberOfLoyaltyPoints();
      }
    } catch(e) {
      console.error(e);
      return false;
    }
  }
  async postReviews(data) {
    data.tags = data.tags__id;
    delete data.tags__id;
    data.patient_reviews__administrators_rate = parseInt(data.patient_reviews__administrators_rate);
    data.patient_reviews__doctor_rate = parseInt(data.patient_reviews__doctor_rate);
    data.patient_reviews__middle_medical_staff_rate = parseInt(data.patient_reviews__middle_medical_staff_rate);
    data.patient_reviews__service_rate = parseInt(data.patient_reviews__service_rate);
    try {
      await apiv2.reviewStatistics.postReviews([data]);
    } catch(e) {
      console.error(e);
      return false;
    }
  }

  async getReviewTag() {
    if (!this.reviewTags.length) {
      try {
        this.reviewTags = await apiv2.reviewStatistics.getReviewTags();
        return this.reviewTags;
      } catch(e) {
        console.error(e);
        return false;
      }
    } else {
      return this.reviewTags;
    }
  }

  async reviewModeration(data) {
    try {
      await apiv2.reviewStatistics.reviewModeration(data);
    } catch(e) {
      console.error(e);
      return false;
    }
  }

  async getSetRateDiagram(filters) {
    try {
      return await apiv2.reviewStatistics.getSetRateDiagram(filters);
    } catch(e) {
      console.error(e);
      return false;
    }
  }

  async getRedirectYandexDiagram(filters) {
    try {
      return await apiv2.reviewStatistics.getRedirectYandexDiagram(filters);
    } catch(e) {
      console.error(e);
      return false;
    }
  }

  async getHaveReviewTextDiagram(filters) {
    try {
      return await apiv2.reviewStatistics.getHaveReviewTextDiagram(filters);
    } catch(e) {
      console.error(e);
      return false;
    }
  }

  async getRateDiagram(filters) {
    try {
      return await apiv2.reviewStatistics.getRateDiagram(filters);
    } catch(e) {
      console.error(e);
      return false;
    }
  }

  async getInternalRateDiagram(filters) {
    try {
      return await apiv2.reviewStatistics.getInternalRateDiagram(filters);
    } catch(e) {
      console.error(e);
      return false;
    }
  }

  async getRateDiagramBySource(filters) {
    try {
      return await apiv2.reviewStatistics.getRateDiagramBySource(filters);
    } catch(e) {
      console.error(e);
      return false;
    }
  }

  async getRegistrationLoyaltyDiagram(filters) {
    try {
      return await apiv2.reviewStatistics.getRegistrationLoyaltyDiagram(filters);
    } catch(e) {
      console.error(e);
      return false;
    }
  }

  async getTagsDiagram(filters) {
    try {
      return await apiv2.reviewStatistics.getTagsDiagram(filters);
    } catch(e) {
      console.error(e);
      return false;
    }
  }

  async getNpsDiagram(filters) {
    try {
      return await apiv2.reviewStatistics.getNpsDiagram(filters);
    } catch(e) {
      console.error(e);
      return false;
    }
  }

  async getCesDiagram(filters) {
    try {
      return await apiv2.reviewStatistics.getCesDiagram(filters);
    } catch(e) {
      console.error(e);
      return false;
    }
  }

  async getUrlOpenDiagram(filters) {
    try {
      return await apiv2.reviewStatistics.getUrlOpenDiagram(filters);
    } catch(e) {
      console.error(e);
      return false;
    }
  }

  async getUrlSendDiagram(filters) {
    try {
      return await apiv2.reviewStatistics.getUrlSendDiagram(filters);
    } catch(e) {
      console.error(e);
      return false;
    }
  }

  async getStepsDiagram(filters) {
    try {
      const urlSend = await this.getUrlSendDiagram(filters);
      const urlOpen = await this.getUrlOpenDiagram(filters);
      const setRate = await this.getSetRateDiagram(filters);
      const redirectYandex = await this.getRedirectYandexDiagram(filters);
      const haveReview = await this.getHaveReviewTextDiagram(filters);

      return {
        urlSend,
        urlOpen,
        setRate,
        redirectYandex,
        haveReview,
      }

    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async getCsatDiagram(filters) {
    try {
      return apiv2.reviewStatistics.getCsatDiagram(filters);
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async getRprDiagram(filters) {
    try {
      return await apiv2.reviewStatistics.getRprDiagram(filters);
    } catch(e) {
      console.error(e);
      return false;
    }
  }

  async getTotalReviewsDiagram(filters) {
    try {
      return await apiv2.reviewStatistics.getTotalReviewsDiagram(filters);
    } catch(e) {
      console.error(e);
      return false;
    }
  }

  async getAppointmentTypeDiagram(filters) {
    try {
      return await apiv2.reviewStatistics.getAppointmentTypeDiagram(filters);
    } catch(e) {
      console.error(e);
      return false;
    }
  }

  async getNumberOfLoyaltyPoints() {
    try {
      const response = await apiv2.reviewStatistics.getNumberOfLoyaltyPointsByPatientId(this.patientId);
      this.loyaltyProgram = {
        ...this.loyaltyProgram,
        isRegisteredInProgram: true,
        numberOfPoints: response.balance
      }
    } catch (e) {
      return console.error(e);
    }
  }

  async addLoyaltyPoint() {
    try {
      await apiv2.reviewStatistics.postAddLoyaltyPoint(this.patientId, this.reviewId);
      this.loyaltyProgram = { ...this.loyaltyProgram, isInstalledPoints: true }
      toastManager.showToast(
        <div>Баллы лояльности будут начислены в интервале от 10 до 20 минут</div>,
        {
          duration: 10000
        }
      );
    } catch (event) {
      toastManager.showToast(
        <div>
          {event.result.error === "Loyalty card not found"
            ? 'Для данного пациента не зарегистрирована карта лояльности'
            : 'Неизвестная ошибка сервера'
          }
        </div>,
        {
          duration: 10000
        }
      );
      console.error(event);
      return false;
    }
  }

  async getRatingByReviews(filters) {
    try {
      return await apiv2.reviewStatistics.getRatingByReviews(filters);
    } catch(e) {
      console.error(e);
      return false;
    }
  }
}

export default new ReviewStatisticsStore();
