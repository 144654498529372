import React, { useLayoutEffect } from "react";
import { observer } from "mobx-react";
import useReactRouter from "use-react-router";

import corporateNewsStore from "../stores/corporateNewsStore";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { breakpoints } from "../styled/common";
import { CorporateDetailNewsCard } from "../components/CorporateDetailNewsCard";
import { CorporateComments } from "../components/CorporateComments";

const DetailNewsContainer = styled(Box)`
  &.MuiBox-root {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex-grow: 1;
    margin-top: 24px;
    ${breakpoints.desktop} {
      margin-top: 16px;
    }
    ${breakpoints.sm} {
      margin-top: 10px;
    }
  }
`;

export const CorporateDetailNewsPage = observer(() => {
  const {
    match: { params }
  } = useReactRouter();

  useLayoutEffect(() => {
    corporateNewsStore.setNews(params.id);
    corporateNewsStore.fetchNewsById(params.id);
    corporateNewsStore.fetchCommentsByNewsId(params.id);
  }, [params.id]);

  return (
    <DetailNewsContainer>
      {
        corporateNewsStore.news && corporateNewsStore.detailedNews && (
          <CorporateDetailNewsCard news={corporateNewsStore.news} detailedNews={corporateNewsStore.detailedNews} />
        )
      }
      <CorporateComments/>
    </DetailNewsContainer>
  );
});