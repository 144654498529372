import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Autocomplete, createFilterOptions } from "@mui/material";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { useDebounce } from "use-debounce";
import _ from "lodash";

const filter = createFilterOptions();

export const FormInputSelectFreeSolo = props => {
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTextDebounced] = useDebounce(inputValue, 700);
  const valueName = props.valueName || "value";
  const labelName = props.labelName || "label";
  const fieldParams = { ...props };
  delete fieldParams.minSearchLength;
  delete fieldParams.needUpdateSearchValue;
  delete fieldParams.getOptions;
  delete fieldParams.getOptionLabel;
  delete fieldParams.getSearchOptions;

  useEffect(() => {
    if (props.control._formValues[props.name] && props.getOptions && props.needUpdateSearchValue) {
      const options = props.getOptions();
      setOptions(options);
      if (options.length) {
        setInputValue(options[0][labelName]);
      } else {
        setOptions("");
      }
    }
  }, [props.control._formValues]);

  useEffect(() => {
    if (!props.getSearchOptions || !open) {
      return;
    }
    if (inputValue.length <= (props.minSearchLength || 0)) {
      setOptions([]);
      return;
    }
    (async () => {
      setLoading(true);
      const list = await props.getSearchOptions(inputValue);
      setOptions(list);
      setLoading(false);
    })();
  }, [searchTextDebounced, open]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const getRenderOption = option => {
    if (props.getOptionLabel && !option[labelName].startsWith("+")) {
      return props.getOptionLabel(option);
    } else {
      return option[labelName];
    }
  };

  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field: { value, onChange, ...fieldProps } }) => {
        return (
          <Autocomplete
            {...fieldProps}
            {...fieldParams}
            inputValue={inputValue || ""}
            options={options}
            freeSolo
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            loading={loading}
            onChange={(event, data) => {
              if (typeof data === "string") {
                const foundOptions = options.find((option) => option[labelName].toLowerCase() === data.toLowerCase());
                if (foundOptions) {
                  return onChange(foundOptions[valueName]);
                }
              }
              onChange(typeof data === "string" ? data : data?.[valueName] || "");
            }}
            onInputChange={(event, value, reason) => {
              setInputValue(value);
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;
              const isExisting = options.some(option => inputValue === option[labelName]);
              if (!!inputValue.length && !isExisting) {
                filtered.push({
                  [valueName]: inputValue,
                  [labelName]: `+ ${inputValue}`
                });
              }
              return filtered;
            }}
            getOptionLabel={option => {
              if (typeof option === "string") {
                return option;
              }
              if (!option[labelName]) {
                return "";
              }
              if (option[labelName].startsWith("+")) {
                return option[labelName].replace("+ ", "");
              }
              return option[labelName];
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={`${props.key}_${_.uniqueId()}`}>
                  {getRenderOption(option)}
                </li>
              );
            }}
            loadingText="Поиск пациентов..."
            renderInput={params => {
              return (
                <TextField
                  {...params}
                  label={props[labelName]}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              );
            }}
          />
        );
      }}
    />
  );
};
