// @flow
import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import useReactRouter from "use-react-router";
import { DynamicForm } from "../common/dynamic-form/DynamicForm";
import surveyStore from "../../stores/surveyStore";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider, IconButton, Paper, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import styled from "styled-components";
import { colors } from "../styleguide/colors";
import Button from "@mui/material/Button";
import ThumbUpIcon from '@mui/icons-material/ThumbUpOutlined';
import { AbsoluteLoader } from "../loader/Loader";
import { MarkdownBlock } from "../markdown/Markdown";

const SurveyPageBlock = styled.div`
    box-sizing: border-box;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    gap: 20px;
    @media (max-width: 768px) {
        gap: 15px;
        padding: 25px;
    }
    .MuiFormLabel-root {
        top: 0;
        &:not(.Mui-focused, [data-shrink = "true"]) {
            top: 4px;
            font-size: .9em;
            transform: translate(14px, 9px) scale(1);
        }
    }

    .MuiInputBase-input:not(.MuiAutocomplete-input, .MuiSelect-select) {
        height: 46px;
        font-size: .9em;
        padding: 0 14px;
    }
    .MuiSelect-select {
        padding: 10px 14px;
        font-size: .9em;
    }
    .MuiFormControlLabel-root {
        .MuiTypography-root {
            font-size: .9rem;
        }
    }
    .MuiInputAdornment-root .MuiSvgIcon-root {
        width: .8em;
        height: .8em;
    }

    .MuiAutocomplete-root {
        .MuiInputBase-root {
            min-height: 46px;
            padding: 5px 65px 5px 5px;

            .MuiInputBase-input {
                font-size: .9em;
                padding: 0 0 0 5px;
            }
        }

        .MuiChip-root {
            height: 20px;
            font-size: .8rem;

            .MuiSvgIcon-root {
                width: .65em;
                height: .65em;
            }
        }
    }
    
    .form-input-chips, .form-input-control-textarea {
        width: 100%;
        .MuiFormLabel-root {
            top: 0;
            &:not(.Mui-focused, [data-shrink = "true"]) {
                top: -12px;
                font-size: .9em;
                transform: translate(14px, 9px) scale(1);
            }
        }
    }
    
    .form-input-control-textarea-max {
        width: 100%;
    }
    
    .form-input-textarea {
        .MuiInputBase-root {
            padding: 11.5px 14px;
        }
    }
`;

const ContainerSurvey = styled(Paper)`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;
    padding: 24px;
    height: max-content;
    min-height: 200px;
    width: 100%;
    max-width: 800px;
    overflow-y: auto;
    background: #FFFFFF;
    border-radius: 20px !important;
    
    @media (max-width: 768px) {
        padding: 15px;
    }
    ${props => props.alignment === "center" 
      ? `
        align-items: center;
        justify-content: center;
        .MuiTypography-root {
          text-align: center;
        }
        .MuiSvgIcon-root {
          font-size: 3.5rem;
        }
      `
      : ``
    }
`;

const TopBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const TitleSurvey = styled.p`
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 8px 16px;
    background: white;
    border-radius: 20px;
    font-size: 16px;
    line-height: 22px;
`;

const HeaderBlock = styled.div`
    display: flex;
    justify-content: center;
`;

const StepsBlock = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    line-height: 23px;
    color: #7D7D7D;
`;

const HeaderSurveyBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
`;

const StepSurveyBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  form .MuiGrid-root .form-input-chips, form .MuiGrid-root .form-input-control-textarea {
    gap: 5px;
    .MuiFormLabel-root {
      transform: translate(0, 9px) scale(1);
      color: ${colors.black}
    }
  }

  @media (max-width: 768px) {
      gap: 16px;
  }
`;

const TitleStepSurvey = styled.div`
    font-size: 22px;
    line-height: 25px;
    font-weight: bold;
    font-style: normal;
    color: #262626;
    font-family: "Graphik LCG";

    @media (max-width: 768px) {
        font-weight: 500;
    }
`;

const DescriptionStepSurvey = styled.div`
    font-size: 18px;
    font-style: normal;
    line-height: 23px;
`;

export const SurveyPage = observer(() => {
  const {
    match: { params }
  } = useReactRouter();

  useEffect(() => {
    (async () => {
      await surveyStore.initialize(params.id);
      surveyStore.fieldsValidation();
    })();
  }, []);

  const changeHandler = (data, reset, changedKey, step) => {
    surveyStore.setCurrentStepValues({ currentStepValues: data, reset, changedKey, step });
  }

  const onClose = () => {
    surveyStore.changeShowModalInformationSurvey(false);
  }

  const defaultData = useMemo(() => {
    return surveyStore.currentStepValues;
  }, [surveyStore.currentStepValues]);

  const finishStep = () => {
    document.getElementsByClassName('survey-container')[0]?.scrollTo(0, 0)
    surveyStore.finishStep();
  }

  return surveyStore.isInitialized && Object.keys(surveyStore.currentFieldsStep).length ? (
    <>
      <SurveyPageBlock>
        <HeaderBlock>
          <TitleSurvey>
            <span>{surveyStore.surveyData.survey.name}</span>
            {surveyStore.surveyData.survey.description && (
              <Tooltip title={`Информация об опросе`}>
                <HelpOutlineIcon
                  cursor={"pointer"}
                  color={"primary"}
                  fontSize={"small"}
                  onClick={() => surveyStore.changeShowModalInformationSurvey(true)}
                />
              </Tooltip>
            )}
          </TitleSurvey>
        </HeaderBlock>
        <ContainerSurvey className={'survey-container'} alignment={surveyStore.surveyCompleted ? "center" : ""}>
          {surveyStore.surveyCompleted ? (
            <>
              <ThumbUpIcon />
              <Typography sx={{ fontSize: "22px", fontWeight: "500" }}>{surveyStore.surveyData.survey.final_text_after_survey || 'Спасибо за Ваше время и предоставленные ответы' }</Typography>
            </>
          ) : (
            <>
              <TopBlock>
                <>
                  {!surveyStore.surveyData.survey.hide_step_count && surveyStore.surveyData.survey.step_by_step && (
                    <HeaderSurveyBlock>
                      <StepsBlock>
                        Шаг {surveyStore.currentStepNumber} из{" "}
                        {surveyStore.surveyData.survey.steps.length}
                      </StepsBlock>
                    </HeaderSurveyBlock>
                  )}
                  {surveyStore.currentStepData.map(step => {
                    return (
                      <StepSurveyBlock key={step.id}>
                        {!step.hide_name && (
                          <>
                            {!!step.name && <TitleStepSurvey>{step.name}</TitleStepSurvey>}
                            {!!step.description && (
                              <DescriptionStepSurvey>
                                <MarkdownBlock data={step.description} />
                              </DescriptionStepSurvey>
                            )}
                          </>
                        )}
                        {!!defaultData[step.id] && surveyStore.currentFieldsStep[step.id].length > 0 && (
                          <DynamicForm
                            spacingForm={3.5}
                            textButton="Сохранить"
                            changeHandler={(...props) => changeHandler(...props, step.id)}
                            data={defaultData[step.id]}
                            debounceTime={250}
                            fields={surveyStore.currentFieldsStep[step.id]}
                            validateSchema={surveyStore.validateSchema[step.id]}
                            hiddenButtonCancel={true}
                            hiddenButtonSave={true}
                            hiddenButtonBack={true}
                          />
                        )}
                        {surveyStore.currentStepData.length > 1 && <Divider />}
                      </StepSurveyBlock>
                    );
                  })}
                </>
              </TopBlock>
              <Button
                type={"submit"}
                size={"large"}
                disabled={surveyStore.isActionBlocker}
                onClick={() => finishStep()}
                variant="contained"
                color={"success"}>
                Далее
              </Button>
            </>
        )}
        </ContainerSurvey>
      </SurveyPageBlock>
      <Dialog
        open={surveyStore.showModalInformationSurvey}
        onClose={onClose}
        fullWidth
        maxWidth="xs">
        <DialogTitle sx={{ m: 0, p: 2 }}>Информация об опросе</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}>
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <MarkdownBlock data={surveyStore.surveyData.survey.description} />
        </DialogContent>
      </Dialog>
    </>
  ) : (
    <AbsoluteLoader />
  );
});
