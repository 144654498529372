// @flow
import * as React from "react";
import { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { PSmall } from "../styleguide/typography";
import o from "react-overrides";
import type { ExtractOverridesProps } from "react-overrides";
import { RequiredField } from "./FieldGroupItemFieldSet";
import RcTooltip from "rc-tooltip";

export const Container = styled.fieldset`
  display: flex;
  justify-content: flex-start;
  gap: 4px;
  position: relative;

  @media screen and (max-width: 768px) {
    margin-top: 20px;
    flex-direction: column;
  }
  @media screen and (max-width: 480px) {
    margin-top: 10px;
  }
`;

const FieldsContainer = styled.div`
  width: 100%;
`;

export const Legend = styled.div`
  position: relative;
  top: 0;
  left: 0;
  max-width: 250px;
  width: 100%;
  opacity: 0.6;
  display: flex;
  cursor: ${props => props.cursorPointer ? 'pointer' : 'auto'};

  @media screen and (max-width: 576px) {
    max-width: 100%;
  }
`;

export const LegendText = (props: any) => <PSmall {...props} modifiers="1" />;

const overrides = {
  Container,
  Legend,
  LegendText
};

const useMinHeight = () => {
  const observableEl = useRef(null);
  const [minHeight, setMinHeight] = useState(0);
  useEffect(() => {
    if (!observableEl.current) {
      return;
    }
    setMinHeight(observableEl.current.getBoundingClientRect().height);
  });
  return [observableEl, minHeight];
};

export const Fieldset = (props: {
  legend: React.Node,
  tooltipLegendText?: string,
  children: React.Node,
  required: boolean,
  overrides?: ExtractOverridesProps<typeof overrides>
}) => {
  const [legendEl, minHeight] = useMinHeight();

  return (
    <Container {...o} style={{ minHeight: `${minHeight}px` }}>
      {props.tooltipLegendText ? (
        <RcTooltip animation="zoom" placement={"bottomLeft"} overlay={<span>{props.tooltipLegendText}</span>}>
          <Legend cursorPointer={!!props.tooltipLegendText} {...o} ref={(legendEl: any)}>
            {props.required && (<RequiredField>*</RequiredField>)}
            <LegendText {...o}>{props.legend}</LegendText>
          </Legend>
        </RcTooltip>
      ) : (
        <Legend {...o} ref={(legendEl: any)}>
          {props.required && (<RequiredField>*</RequiredField>)}
          <LegendText {...o}>{props.legend}</LegendText>
        </Legend>
      )}
      <FieldsContainer>
        {props.children}
      </FieldsContainer>
    </Container>
  );
};
