import { makeAutoObservable } from "mobx";
import { newsCategories, generatedNewsData, generatedDetailedNewsData, generatedNewsComments } from "../utils/news/generatedNewsData";
import emptyNews from "../../assets/image/corporate-portal/emptyNews.png";
import Binder from "../../utils/binder";

class CorporateNewsStore {
  /** Табы */
  isInitialized = false;
  isCommentsLoading = false;
  categories = [];
  selectedCategory = {
    index: 0,
    info: null
  };
  /** Поиск новостей по полю ввода */
  searchValue = "";
  /** Контент */
  dataNews = [];
  // ToDo для тестовой среды, удалить после интеграции
  allDataNews = [];
  // ToDo При реализации fell-6619 заменить
  news = null;
  detailedNews = null;
  newsComments = [];

  constructor() {
    this.getCategories();
    this.setAllCategories();

    Binder.bind(this, CorporateNewsStore);
    makeAutoObservable(this);
  }

  setAllCategories() {
    this.allDataNews = generatedNewsData.map(item => {
      return {
        ...item,
        media: {
          src: item?.src || emptyNews,
          alt: item.category
        },
        numberOfLike: item.number_of_like,
        isNewsLiked: item.is_news_is_liked,
        numberOfView: item.number_of_view
      };
    });
  }

  setNewsComments(comments) {
    this.newsComments = comments;
  }

  clearNewsComments() {
    this.newsComments = [];
  }

  setInitialized(flag) {
    this.isInitialized = flag;
  }

  setIsCommentsLoading(flag) {
    this.isCommentsLoading = flag;
  }

  setDataNews(data) {
    this.dataNews = data;
  }

  setNews(id) {
    this.news = this.allDataNews.find(news => news.id === id);
  }

  setDetailedNews(news) {
    this.detailedNews = news;
  }

  removeNews() {
    this.news = null;
  }

  clearDetailedNews() {
    this.detailedNews = null;
  }

  getCategories() {
    const response = newsCategories;
    setTimeout(() => {
      this.categories = response;
      this.changeSelectedCategory(0);
      // ToDo для тестовой среды, удалить после интеграции
    }, 500);
  }

  getNewsByCategory({ category = "", name = "" }) {
    this.setInitialized(false);

    setTimeout(() => {
      const response = this.allDataNews;
      let filteredDataNews;
      if (category) {
        if (category === "Все новости") {
          filteredDataNews = response;
        } else {
          filteredDataNews = response.filter(
            news => news.category.toLowerCase() === category.toLowerCase()
          );
        }
      }
      if (name) {
        filteredDataNews = response.filter(news =>
          news.name.toLowerCase().includes(name.toLowerCase())
        );
      }

      this.setDataNews(filteredDataNews);
      this.setInitialized(true);
      // ToDo для тестовой среды, удалить после интеграции
    }, 500);
  }

  changeSearchValue(value) {
    if (this.searchValue === value) {
      return;
    }

    this.searchValue = value;
    if (this.selectedCategory.index !== 0) {
      this.changeSelectedCategory(0);
    }
    if (this.searchValue) {
      this.getNewsByCategory({ name: this.searchValue });
      console.log("current search value: ", this.searchValue);
      // ToDo -> getNews
    } else {
      this.getNewsByCategory({ category: "Все новости" });
      console.log("current search value: ", this.searchValue);
      // ToDo -> getNews
    }
  }

  changeSelectedCategory(key) {
    if (key !== 0 && this.searchValue.length) {
      this.searchValue = "";
    }
    this.selectedCategory = {
      index: key,
      info: this.categories[key]
    };
    this.getNewsByCategory({ category: this.categories[key].name });
  }

  changeLikeCounter({ id, isNewsLiked }) {
    const foundNewsById = this.dataNews.find(news => news.id === id);
    if (!foundNewsById) {
      return;
    }

    // ToDo -> post like|dislike news
    if (isNewsLiked) {
      foundNewsById.numberOfLike--;
      foundNewsById.isNewsLiked = false;
    } else {
      foundNewsById.numberOfLike++;
      foundNewsById.isNewsLiked = true;
    }
  }

  fetchNewsById(newsId) {
    this.setInitialized(false);

    setTimeout(() => {
      const response = generatedDetailedNewsData.find(news => news.id === newsId);
      if (response) {
        this.setDetailedNews(response);
      }
      this.setInitialized(true);
      // ToDo для тестовой среды, удалить после интеграции
    }, 500);
  }

  fetchCommentsByNewsId(newsId) {
    this.setIsCommentsLoading(true);

    setTimeout(() => {
      const response = generatedNewsComments;
      if (response && response.length > 0) {
        this.setNewsComments(response);
      }
      this.setIsCommentsLoading(false);
      // ToDo для тестовой среды, удалить после интеграции
    }, 500);
  }
}

export default new CorporateNewsStore();
