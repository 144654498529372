import { makeAutoObservable } from "mobx";
import Binder from "../../utils/binder";
import { clinicsOfOrganizations, dataOfDoctors } from "../utils/employees/generatedEmployeesData";
import { ACCORDION_CONTENT_TYPES } from "../components/CorporateAccordion";

const tabs = [
  { id: "employees", name: "Сотрудники" },
  { id: "structure", name: "Структура компании" }
];

export const ACCORDION_NESTING_LEVELS = {
  CITIES: "cities",
  ORGANIZATIONS: "organizations",
  SPECIALIZATIONS: "specializations"
};

class CorporateEmployeesStore {
  /** Табы */
  isInitialized = false;
  tabs = [];
  selectedTab = {
    index: 0,
    info: null
  };
  /** Аккордион */
  accordionData = [];
  /** Поиск сотрудников по полю ввода */
  searchValue = "";
  foundedEmployees = null;
  /** Контент для таба "Сотрудники" */
  allEmployeesData = dataOfDoctors;
  /** Контент для таба "Структура компании" */
  companyStructureData = [];
  /** Профиль сотрудника */
  isShowProfileEmployee = false;
  profileEmployeeData = null;

  constructor() {
    this.setTabs();

    Binder.bind(this, CorporateEmployeesStore);
    makeAutoObservable(this);
  }

  setInitialized(flag) {
    this.isInitialized = flag;
  }

  setTabs() {
    this.tabs = tabs;
    this.changeSelectedTab(0);
  }

  openProfileEmployee(profileId) {
    this.profileEmployeeData = dataOfDoctors.find(doctor => doctor.id === profileId);
    this.isShowProfileEmployee = true;
  }

  closeProfileEmployee() {
    this.isShowProfileEmployee = false;

    /** Для корректной отработки анимации */
    setTimeout(() => {
      this.profileEmployeeData = null;
    }, 200);
  }

  changeSelectedTab(key) {
    if (key !== 0 && this.searchValue.length) {
      this.searchValue = "";
    }
    this.selectedTab = {
      index: key,
      info: this.tabs[key]
    };

    if (this.tabs[key].id === "employees") {
      this.getAccordionData();
    }

    if (this.tabs[key].id === "structure") {
      this.getCompanyStructureData();
    }
  }

  getAccordionData() {
    if (this.accordionData.length) {
      return;
    }

    const convertedData = data => {
      if (data.hasOwnProperty("organizations")) {
        return {
          level: ACCORDION_NESTING_LEVELS.ORGANIZATIONS,
          type: ACCORDION_CONTENT_TYPES.ACCORDION,
          name: data.city,
          breadcrumbs: { city: data.city },
          details: data.organizations.map(item =>
            convertedData({
              clinic: item.clinic,
              specializations: item.specializations,
              breadcrumbs: {
                city: data.city
              }
            })
          )
        };
      }
      if (data.hasOwnProperty("specializations")) {
        return {
          level: ACCORDION_NESTING_LEVELS.SPECIALIZATIONS,
          type: ACCORDION_CONTENT_TYPES.ACCORDION,
          name: data.clinic,
          breadcrumbs: {
            ...data.breadcrumbs,
            organization: data.clinic
          },
          details: data.specializations.map(item =>
            convertedData({
              specialization: item,
              breadcrumbs: {
                ...data.breadcrumbs,
                organization: data.clinic
              }
            })
          )
        };
      }
      if (data.hasOwnProperty("specialization")) {
        return {
          breadcrumbs: { ...data.breadcrumbs, specialization: data.specialization },
          type: ACCORDION_CONTENT_TYPES.CONTENT,
          name: data.specialization
        };
      }
    };

    this.setInitialized(false);
    const response = clinicsOfOrganizations;

    // ToDo для тестовой среды, удалить после интеграции
    setTimeout(() => {
      this.accordionData = response.map(item => convertedData(item));

      this.setInitialized(true);
    }, 500);
  }

  async getEmployeesData(breadcrumbs) {
    // ToDo для тестовой среды, удалить после интеграции
    const response = this.allEmployeesData;

    return response.filter(employee => {
      if (
        employee.working_information.cities.includes(breadcrumbs.city) &&
        employee.working_information.organizations.includes(breadcrumbs.organization) &&
        employee.working_information.specializations.includes(breadcrumbs.specialization) &&
        employee.fullName.toLowerCase().includes(this.searchValue.toLowerCase())
      ) {
        return employee;
      }
    });
  }

  getCompanyStructureData() {
    if (this.companyStructureData.length) {
      return;
    }

    this.setInitialized(false);
    const response = [];

    // ToDo для тестовой среды, удалить после интеграции
    setTimeout(() => {
      this.companyStructureData = [{}];

      this.setInitialized(true);
    }, 500);
  }

  changeSearchValue(value) {
    this.foundedEmployees = null;
    if (this.searchValue === value) {
      return;
    }

    this.searchValue = value;

    if (!this.searchValue.length) {
      return;
    }

    // ToDo для тестовой среды, удалить после интеграции
    const filteredDataEmployee = this.allEmployeesData.filter(employee =>
      employee.fullName.toLowerCase().includes(value.toLowerCase())
    );

    if (!filteredDataEmployee.length) {
      return;
    }

    this.foundedEmployees = filteredDataEmployee.map(employee => {
      return {
        cities: employee.working_information.cities,
        organizations: employee.working_information.organizations,
        specializations: employee.working_information.specializations
      };
    });
  }
}

export default new CorporateEmployeesStore();
