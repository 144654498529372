import React from "react";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { FormLabel, Typography, FormControl } from "@mui/material";

export const FormInputTextarea = (props) => {
  const newProps = {...props};
  delete newProps.subInfo;
  delete newProps.description;

  return (
    <>
      <FormControl className={!!props.description ? 'form-input-control-textarea' : 'form-input-control-textarea-max'} error={!!props.control._formState.errors[props.name]} size={"small"} variant={"outlined"}>
        {!!props.description && <FormLabel component="legend" className="not-transform">{props.description} {props.required && "*"}</FormLabel>}
        <Controller
          name={props.name}
          control={props.control}
          render={({
            field: { onChange, value },
            fieldState: { error },
          }) => {
            if (value === null) {
              onChange({target: {value: ''}})
            }
            return (
              <TextField
                className={'form-input-textarea'}
                helperText={error ? error.message : props.subInfo ? props.subInfo : null}
                error={!!error}
                onChange={onChange}
                value={value || ''}
                fullWidth
                variant="outlined"
                multiline
                maxRows={5}
                inputProps={
                  { readOnly: props.readOnly, }
                }
                {...newProps}

              />
            )
          }}
        />
      </FormControl>
        {/*{!!props.subInfo && <Typography variant="caption">{props.subInfo}</Typography>}*/}
    </>
  );
};
