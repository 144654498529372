// @flow
import { useStore } from "effector-react";

import { tokenStore } from "../../stores/auth";
import type { UploadedFile } from "../../domain/entities/UploadedFile";
import { getPatientFullName } from "../../domain/services/patient";
import {useApiRequest} from '../../apiv2/useApiRequest';
import apiv2 from '../../apiv2';
import { TAB_TYPE } from "./AnalyzesPage";

export const useFiles = (
  medical_file_id: number,
  limit?: number
): {
  uploads: ?Array<UploadedFile>,
  analyzes: ?Array<UploadedFile>,
  patient: ?number,
  isLoading: ?boolean,
  patientName: ?string
} => {
  const token = useStore(tokenStore);

  const medicalFileResult = useApiRequest(async () => {
    if (medical_file_id) {
      return await apiv2.medicalFiles.getById(medical_file_id);
    }
  }, [ token, medical_file_id ]);

  const patientId = parseInt(medicalFileResult.data && medicalFileResult.data.patient.id);
  const patientName =
    medicalFileResult.data &&
    medicalFileResult.data.patient &&
    getPatientFullName(medicalFileResult.data.patient);

  const uploadsResult = useApiRequest(async () => {
    if (patientId && medicalFileResult.data && medicalFileResult.data.patient.id) {
      return await apiv2.uploads.getUploads({
        patient: patientId,
        limit: limit,
        type: TAB_TYPE.DOCUMENT
      });
    }
  }, [medicalFileResult.data, medicalFileResult.data && medicalFileResult.data.patient.id]);

  const analyzesResult = useApiRequest(async () => {
    if (patientId && medicalFileResult.data && medicalFileResult.data.patient.id) {
      return await apiv2.uploads.getUploads({
        patient: patientId,
        limit: limit,
        type: TAB_TYPE.ANALYSIS
      });
    }
  }, [medicalFileResult.data, medicalFileResult.data && medicalFileResult.data.patient.id]);

  const uploadsFDOCResult = useApiRequest(async () => {
    if (patientId && medicalFileResult.data && medicalFileResult.data.patient.id) {
      return await apiv2.uploads.getUploads({
        patient: patientId,
        limit: limit,
        type: TAB_TYPE.DOCUMENT_FDOC
      });
    }
  }, [medicalFileResult.data, medicalFileResult.data && medicalFileResult.data.patient.id]);

  return {
    isLoading: medicalFileResult.isLoading || uploadsResult.isLoading || analyzesResult.isLoading || uploadsFDOCResult.isLoading,
    uploads: uploadsResult.data,
    analyzes: analyzesResult.data,
    uploadsFDOC: uploadsFDOCResult.data,
    patient: patientId,
    patientName
  };
};
