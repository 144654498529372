import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class Survey {
  apiService;
  presetFilterParams;
  constructor() {
    this.apiService = new ApiService("/api/integration", null, null, API_URL);
  }

  getSurveyById({id}) {
    return this.apiService.get(`dynamic/medicalfile/retrieve/${id}`, {}, {}, true, '', true);
  }

  getSurveyList(id) {
    return this.apiService.get(`survey/list`, { survey_id: id });
  }

  createSurvey(data) {
    return this.apiService.post(`survey`, {}, data);
  }

  setValueSurvey(surveyId, data) {
    return this.apiService.post(`survey/${surveyId}/set-value`, {}, data);
  }

  finishStep(surveyId, data) {
    return this.apiService.post(`survey/${surveyId}/finish`, {}, data);
  }
}

export default new Survey();
