// @flow
import * as React from "react";
import styled, {css} from "styled-components";
import { rgba } from "polished";

import { colors } from "../styleguide/colors";
import { BaseStyledInput } from "../form-elements/BaseStyledInput";
import { DefaultInput } from "../form-elements/DefaultInput";
import { Textarea, StyledTextarea } from "../form-elements/Textarea";
import { FieldsetVertical } from "../form/FieldsetVertical";
import { PrimaryButton, PrimaryButtonContainer, PrimaryButtonText } from "../buttons/PrimaryButton";
import { DefaultButton, Container as DefaultButtonContainer } from "../buttons/DefaultButton";
import type {
  TChecklistShortcutArray,
  TChecklistDestination
} from "../../domain/value-objects/TShortcuts";
import TrashIcon from "./icons/trash.svg";
import { ChecklistWithCommaSeparatedChildren } from "../decision-support/ChecklistWithCommaSeparatedChildren";
import { SuggestItem } from '../services/styled/ServicesSuggest.styled';
import * as S from '../services/styled/ServicesSuggest.styled';
import serviceStore from '../../stores/serviceStore';
import { ServicePriceInput } from '../appointment-page/ServicePriceInput';
import { teethIDsToString, teethValueToArray } from '../teeth-picker/TeethPicker';
import { PointHintExamination } from '../decision-support/PointHintExamination';
import { Container as FieldsetContainer } from '../form/Fieldset';

const Form = styled.form`
  width: 100%;
  padding: 19px 24px;
  box-sizing: border-box;
`;

const Buttons = styled.div`
  display: flex;
  margin-top: 32px;
  & > * {
    margin-right: 15px;
  }

  ${PrimaryButtonText} {
    font-size: 13px;
  }
`;

const ShortcutStyledInput = styled(BaseStyledInput)`
  width: 100%;
`;

const ShortcutInputContainer = styled.div`
    width: 100%;
`;

const ShortcutStyledTextarea = styled(StyledTextarea)`
  width: 100%;
  min-height: 185px;
`;

export const ShortcutsPrimaryButtonContainer = styled(PrimaryButtonContainer)`
  width: 170px;
  height: 50px;
`;

const ClearButtonIcon = styled.div`
  width: 24px;
  height: 24px;

  background: url(${TrashIcon});
`;

const ClearButtonIconContainer = styled.div`
  margin-right: 6px;
`;

const ShortcutsButtonContainer = styled(DefaultButtonContainer)`
  width: auto;
  height: 50px;
  padding: 0 14px;
  border: none;
`;

const ButtonText = styled.span`
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1.1;
  color: ${rgba(colors.black, 0.5)};
`;

const ListContainer = styled.ul`
  font-size: 15px;
  line-height: 23px;
  color: ${colors.black};

  li {
    :not(:last-child) {
      margin-bottom: 24px;
    }
  }
`;

const InnerListContainer = styled.ul`
  margin-top: 8px;
  font-size: 15px;
  line-height: 23px;
  color: ${colors.black};

  li {
    position: relative;
    padding-left: 32px;

    :not(:last-child) {
      margin-bottom: 9px;
    }

    &::before {
      content: " ";
      position: absolute;
      top: calc(50% - 3px);
      left: 9px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: ${colors.gray200};
    }
  }
`;

const ChecklistComment = styled.p`
  margin-top: 8px;
  font-size: 15px;
  line-height: 23px;
  color: ${colors.gray700};
`;

const ChecklistFieldsetContainer = styled.div`
  margin-top: -9px;
`;

const PrescribedServicesFieldsetContainer = styled.div`
  margin-top: -9px;
    
  li:not(:last-child) {
      padding-bottom: 8px;
      border-bottom: 1px solid #e4e4e4;
      margin-bottom: 16px;
  }
    
  ${S.ServiceCode} {
    margin: 0;      
  }
    
  ${FieldsetContainer} {
    flex-direction: column;
  }
`;

const ChecklistLine = styled.div`
  width: calc(100% + 48px);
  height: 1px;
  margin-bottom: 23px;
  margin-left: -24px;
  background-color: ${colors.gray200};
`;

const Ellipse = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 50%;
  margin: 0 8px;
  flex-shrink: 0;
  background: ${colors.darkGray};
`;

const Measure = styled.span`
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  z-index: 1;
`;

const SuggestDisplayTeeth = styled.span`
  font-size: 13px;
  color: #979797;
  flex-shrink: 0;
`;

const DateRangeValue = styled.span`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.3px;
  color: #979797;
  white-space: nowrap;
`;

export const ShortcutsDefaultInputField = (props: {
  legend: string,
  value: string,
  handleChange: string => mixed,
  error?: [string, boolean],
  disabled?: boolean
}) => {
  const { legend, ...inputProps } = props;
  return (
    <FieldsetVertical legend={legend}>
      <DefaultInput
        {...inputProps}
        overrides={{
          Input: {
            component: ShortcutStyledInput
          },
          Container: {
            component: ShortcutInputContainer
          }
      }}
      />
    </FieldsetVertical>
  );
};

export const ShortcutsChecklistField = (props: {
  checklist: TChecklistShortcutArray,
  legend: string,
  fieldName: TChecklistDestination
}) => {
  const { checklist, legend, fieldName } = props;
  return (
    <ChecklistFieldsetContainer>
      <ChecklistLine />
      <FieldsetVertical legend={legend}>
        <ListContainer>
          {checklist.map(item => (
            <li key={item.guid}>
              {fieldName === "medication_checklist" ? (
                <>
                  <ChecklistWithCommaSeparatedChildren checklist={item} />
                  <ChecklistComment>{item.comment}</ChecklistComment>
                </>
              ) : (
                <>
                  {item.name}
                  <ChecklistComment>{item.comment}</ChecklistComment>
                  {item.items && (
                    <InnerListContainer>
                      {item.items.map(el => (
                        <li key={el.guid}>
                          {el.name}
                          <ChecklistComment>{el.comment}</ChecklistComment>
                        </li>
                      ))}
                    </InnerListContainer>
                  )}
                </>
              )}
            </li>
          ))}
        </ListContainer>
      </FieldsetVertical>
    </ChecklistFieldsetContainer>
  );
};

export const ShortcutsPrescribedServicesField = (props) => {
  const { services, legend, fieldName } = props;
  return (
    <PrescribedServicesFieldsetContainer>
      <ChecklistLine />
      <FieldsetVertical legend={legend}>
        <ListContainer>
          {services.map(item => (
            <li key={item.id}>
              <div style={{position: 'relative', flex: 1, width: '100%'}}>
                <S.ServiceRow>
                  <S.FlexBlockService>
                    <S.ServiceCode empty={!item.kdf_code}>
                      {item.kdf_code?.trim() || "————"}
                    </S.ServiceCode>
                    <S.ServiceName>{item.name} <small>{serviceStore.categoriesText(item)}</small></S.ServiceName>
                  </S.FlexBlockService>
                </S.ServiceRow>
                <S.ServiceRow style={{
                  marginTop: 8
                }}>
                  <span>
                    {item.count}&nbsp;<Measure>шт.</Measure>
                  </span>
                  <ServicePriceInput
                    readonly={true}
                    count={item.count}
                    price={item.price}
                  />
                </S.ServiceRow>
                <S.ServiceRow isJustifyLeft isInline>
                  <>
                    {(item.date_range) &&
                      <DateRangeValue>
                        {item.date_range.join('-')}
                      </DateRangeValue>
                    }
                    {(item.date_range && (item.comment || item.teeth)) &&
                      <Ellipse/>
                    }
                  </>
                  {item.teeth && (
                    <>
                      <SuggestDisplayTeeth>{teethIDsToString(teethValueToArray(item.teeth), true)}</SuggestDisplayTeeth>
                      {(item.comment) &&
                        <Ellipse/>
                      }
                    </>
                  )}
                  {item.comment &&
                    <PointHintExamination style={{pointerEvents: 'none'}}>
                      {item.comment}
                    </PointHintExamination>
                  }
                </S.ServiceRow>
              </div>
            </li>
          ))}
        </ListContainer>
      </FieldsetVertical>
    </PrescribedServicesFieldsetContainer>
  );
};

export const ShortcutsTextareaField = (props: {
  legend: string,
  value: string,
  handleChange?: string => mixed
}) => {
  const {legend, ...textareaProps} = props;
  return (
    <FieldsetVertical legend={legend}>
      <Textarea
        {...textareaProps}
        overrides={{
          StyledTextarea: {
            component: ShortcutStyledTextarea
          }
        }}
      />
    </FieldsetVertical>
  );
};

export const ShortcutsClearButton = (props: {
  disabled?: boolean,
  onClick?: () => mixed,
  children: ?React.Node
}) => (
  <DefaultButton
    {...props}
    overrides={{
      Container: {
        component: ShortcutsButtonContainer
      },
      Icon: {
        component: ClearButtonIcon
      },
      IconContainer: {
        component: ClearButtonIconContainer
      },
      Text: {
        component: ButtonText
      }
    }}
  />
);

export const ShortcutsButton = (props: {
  disabled?: boolean,
  onClick?: () => mixed,
  children: ?React.Node
}) => (
  <DefaultButton
    {...props}
    overrides={{
      Container: {
        component: ShortcutsButtonContainer
      },
      Text: {
        component: ButtonText
      }
    }}
  />
);

export const ShortcutsPrimaryButton = (props: {
  disabled?: boolean,
  onClick?: () => mixed,
  children: ?React.Node
}) => <PrimaryButton {...props} primaryButtonContainer={ShortcutsPrimaryButtonContainer}/>;

export const ShortcutsFormLayout = (props: { fields: React.Node, buttons: React.Node }) => {
  return (
    <Form>
      {props.fields}
      <Buttons>{props.buttons}</Buttons>
    </Form>
  );
};
