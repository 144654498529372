// @flow
import React, { useState } from "react";
import { Box, Card, CardActionArea, CardHeader, Collapse, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { KeyboardArrowDown as ExpandedIcon } from "@mui/icons-material";
import { CorporateTitle } from "../styled/CorporateTypography.styled";
import { breakpoints } from "../styled/common";

export const Container = styled(Card)(
  () => `
    width: 100%;
    background: transparent;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    .MuiCardHeader-root {
      padding: 0;
      margin-bottom: 16px;
      ${breakpoints.desktop} {
        margin-bottom: 10px;
      }
      .MuiCardHeader-title {
        font-size: 20px;
        font-weight: 500;
        ${breakpoints.desktop} {
          font-size: 18px;
        }
        ${breakpoints.tablet} {
          font-size: 16px;
        }
      }
    }
  `
);

const CollapseTitle = styled(CardActionArea)(
  ({ theme, expand, title }) => `
    .MuiSvgIcon-root {
      ${expand === "false" ? "transform: rotate(0deg)" : "transform: rotate(180deg)"};
      transition: ${theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })};
    }
    display: flex;
    justify-content: ${!title ? "flex-end" : "space-between"};
    padding: 20px;
    border-radius: ${expand === "false" ? "20px" : "20px 20px 0 0"};
    clip-path: border-box;
    
    ${theme.breakpoints.down("lg")} {
      padding: 15px;
    }
    ${theme.breakpoints.down("sm")} {
      padding: 10px;
    }
  `
);

const CollapseContent = styled(Box)(
  ({ theme }) => `
    padding: 20px;
    ${theme.breakpoints.down("lg")} {
      padding: 15px;
    }
    ${theme.breakpoints.down("sm")} {
      padding: 10px;
    }
  `
);

export const CorporateCard = ({
  title = "",
  enableCollapseContent = false,
  showDivider = false,
  children
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => setExpanded(!expanded);

  return (
    <Container>
      {enableCollapseContent ? (
        <CollapseTitle onClick={handleExpandClick} expand={`${expanded}`} title={title}>
          {!!title && <CorporateTitle modifiers={"md"}>{title}</CorporateTitle>}
          <ExpandedIcon />
        </CollapseTitle>
      ) : (
        !!title && <CardHeader title={title} />
      )}
      {showDivider && <Divider />}
      {enableCollapseContent ? (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CollapseContent>{children}</CollapseContent>
        </Collapse>
      ) : (
        children
      )}
    </Container>
  );
};
