import ApiService from "../apiService";
import { getAppointmentPrescribedService } from "../../components/services/api/services";

const API_URL = process.env.EMS_API_URL || location.origin;
class Appointments {
  apiService;
  constructor() {
    this.apiService = new ApiService('/api', null, null, API_URL);
  }

  createAppointment(creationOptions) {
    return this.apiService.post(`appointments`, {}, creationOptions);
  }

  reopenAppointment(appointmentId) {
    return this.apiService.post(`appointments/${appointmentId}/reopen`);
  }

  getAppointmentById(appointmentId) {
    return this.apiService.get(`appointments/${appointmentId}`);
  }

  sendAppointmentPDF(appointmentId, pdfData) {
    return this.apiService.post(`appointments/${appointmentId}/send`, {}, {
      appointment_id: appointmentId,
      appointment_pdf: pdfData
    });
  }

  getAppointment(appointmentId) {
    return this.apiService.get(`appointments/${appointmentId}`);
  }

  getAppointments(params) {
    return this.apiService.get(`appointments/search`, params);
  }

  getAppointmentsByPatientId(patientId, groupingFolders) {
    return this.apiService.get(`appointments`, { patient_id: patientId, grouping_folders: groupingFolders.toString() });
  }

  getAppointmentLayoutById(id) {
    return this.apiService.get(`layouts/${id}`, {}, {}, true, '', true);
  }

  deleteAppointment(appointmentId) {
    return this.apiService.delete(`appointments/${appointmentId}`);
  }

  getSectionHistory({ id, section_id }) {
    return this.apiService.get(`appointments/${id}/section-history/${section_id}`);
  }

  getLayout(id) {
    return this.apiService.get(`layouts/${id}`);
  }

  getLayoutGroups() {
    return this.apiService.get(`layout-groups`, {}, {}, true, '', true);
  }

  getLayoutsByGroupID(groupId) {
    return this.apiService.get(`layouts`, { group: groupId }, {}, true, '', true);
  }

  getPopularLayouts() {
    return this.apiService.get(`popular-layouts`);
  }

  addPopularLayout(layoutId) {
    return this.apiService.post(`popular-layouts`, {}, {
      layout_id: layoutId
    });
  }

  deletePopularLayout(layoutId) {
    return this.apiService.delete(`popular-layouts/${layoutId}`);
  }

  getUltrasoundScans(appointmentId) {
    return this.apiService.get(`ultrasound`, {
      appointment: appointmentId
    });
  }

  getShortcuts(layoutId) {
    return this.apiService.get(`layouts/${layoutId}/shortcuts`);
  }

  getAppointmentAISlices(appointmentId) {
    return this.apiService.get(`ai/appointment/ai_status/${appointmentId}`, {}, {}, true, "", false, false, "", true);
  }

  getAppointmentsOutcomes({ query, filter, eventType }) {
    const params = {};

    if (query) {
      params.patient_name = query;
    }

    if (filter === "all") {
      params.all = "true";
    }

    if (eventType) {
      params.layout_folder_id = eventType;
    }

    return this.apiService.get(`appointments/outcomes`, params);
  }

  patchAppointmentsOutcomesFieldValue(id, value) {
    return this.apiService.patch(`field_values/${id}`, {}, {value});
  }

  postAppointmentsOutcomesFieldValue({ appointmentId, fieldId, value }) {
    return this.apiService.post(`field_values`, {}, { value, appointment_id: appointmentId, copy_id: fieldId });
  }

  getFolders({ layoutId, patientId }) {
    return this.apiService.get(`folders`, {
      layout_id: layoutId,
      patient_id: patientId
    });
  }

  getFolder(id) {
    return this.apiService.get(`folders/${id}`);
  }

  moveAppointmentFolder(params) {
    const { folderId, folderTypeId, appointmentId } = params;
    const data = {};
    if (folderId) data.folder_id = folderId;
    if (folderTypeId) data.f_layout_id = folderTypeId;

    return this.apiService.patch(`appointments/${appointmentId}/folders`, {}, data);
  }

  removeAppointmentFromFolder(folderId, appointmentId) {
    return this.apiService.delete(`appointments/${appointmentId}/folders`, {}, { folder_id: folderId });
  }

  getFolderSummary(folderId) {
    return this.apiService.get(`folders/${folderId}/full_summary`);
  }

  getSummaryPrint(params) {
    const searchParams = new URLSearchParams();

    if (params.summary) {
      params.summary.forEach(summaryId => {
        searchParams.append("summary", summaryId);
      });
    }

    return this.apiService.get(`patient/${params.patient_id}/print_epicrises${searchParams ? `/?${searchParams}` : ''}`);
  }

  getFolderAnalyses(folderId) {
    return this.apiService.get(`folders/${folderId}/patient_examinations`);
  }

  updateFolderAnalyze(folderId, id, payload) {
    return this.apiService.patch(`folders/${folderId}/patient_examinations/${id}`, {}, payload);
  }

  getFolderAgreement(folderId, agreementId) {
    return this.apiService.get(`folders/${folderId}/patient_agreements/${agreementId}/print`);
  }

  getFolderAgreements(folderId) {
    return this.apiService.get(`folders/${folderId}/patient_agreements`);
  }

  updateFolderAgreement(folderId, id, payload) {
    return this.apiService.put(`folders/${folderId}/patient_agreements/${id}`, {}, payload, {}, true);
  }

  getFolderPrintDetails(params) {
    const { id, concreteFolder } = params;
    let newParams = {};
    if (concreteFolder) {
      newParams.only_folder_id = concreteFolder;
    }
    return this.apiService.get(`folders/${id}/full_summary/print`, newParams);
  }

  getAppointmentList({filter_params, meta = {}, values_params, order_by_params}) {
    return this.apiService.post(`dynamic/prescribedserviceappointment/list`, {}, { values_params, filter_params, per_page: meta.per_page, order_by_params });
  }

  completeAppointment(params) {
    return this.apiService.post(`dynamic/prescribedserviceappointment/update`, {}, params);
  }

  postAppointmentsSetValues({ appointment_id, fieldValues }) {
    return this.apiService.post(`appointments/${appointment_id}/set-values`, {}, fieldValues);
  }

  postAppointmentsFinish({ appointment_id, services_description, next_visit_date, is_ambulance = false }) {
    return this.apiService.post(`appointments/${appointment_id}/finish`, {}, next_visit_date ? { services_description, next_visit_date, is_ambulance } : { services_description, is_ambulance });
  }

  postAnalytics(params) {
    return this.apiService.post(`protocol_filling_analytics`, {}, params);
  }

  getConclusion(params) {
    return this.apiService.get(`decision-support/conclusion`, params);
  }

  postSetConclusion(params) {
    const { appointment_id, ...other } = params;
    return this.apiService.post(`appointments/${appointment_id}/set-conclusion`, {}, other);
  }

  postInvoiceForPayment({ appointment_id }) {
    return this.apiService.post(`invoice-for-payment`, {}, { appointment_id });
  }

  getFavoritesAppointmentList() {
    return this.apiService.get("favorite-appointments");
  }

  addFavoriteAppointment(appointment_id) {
    return this.apiService.post(`favorite-appointments`, {}, {
      appointment_id
    });
  }

  deleteFavoriteAppointment(appointment_id) {
    return this.apiService.delete(`favorite-appointments/${appointment_id}`);
  }

  getFinishCheckUp(folderId) {
    return this.apiService.get(`appointments/finish-check-up`, { folder_id: folderId });
  }

  getAppointmentPrescribedService(appointmentId) {
    return this.apiService.get(`appointments/prescribed-services`, {appointment_id: appointmentId});
  }

  postAppointmentPrescribedService(appointmentId, data) {
    return this.apiService.post(`appointments/prescribed-services`, {appointment_id: appointmentId}, data);
  }

}

export default new Appointments();
