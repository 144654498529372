//@flow
import React, { useMemo } from "react";
import { Route, Switch } from "react-router-dom";
import { observer } from "mobx-react";

import { GreetingContent } from "../greeting/GreetingContent";
import { AppointmentHistory } from "../appointment-history/AppointmentHistory";
import { HospitalPage } from "../hospital-page/HospitalPage";
import { ScheduleContent } from "../schedule-page/ScheduleContent";
import { AppointmentFeedback } from "../appointment-feedback/AppointmentFeedback";

import styled from "styled-components";
import { GreetingContentFeedback } from "../greeting/GreetingContentFeedback";
import { GreetingContentHospital } from "../greeting/GreetingContentHospital";
import headerStore from "../../stores/headerStore";
import { GreetingContentSchedule } from "../greeting/GreetingContentSchedule";
import { BaseLayout } from "../layouts/BaseLayout";
import { AppointmentHistoryNew } from "../appointment-history/AppointmentHistoryNew";
import {
  FEATURE,
  useApplicationFeatureEnabled,
  useApplicationFeaturesInitialized
} from "../../utils/applicationFeatures";

const ContentContainer = styled.div`
  position: relative;
`;

const renderGreeting = (activeTab, isHospital: boolean, isNurse: boolean, canEditSchedule: boolean) => {
  if ((activeTab === "hospital" && isHospital) || isNurse) {
    return <GreetingContentHospital />;
  }

  if (activeTab === "feedback") {
    return <GreetingContentFeedback />;
  }

  if (activeTab === "schedule" && canEditSchedule) {
    return <GreetingContentSchedule />;
  }

  return <GreetingContent />;
};

export const ScheduleSwitchPage = observer(() => {
  const featureInitialized = useApplicationFeaturesInitialized();
  const isAppointmentHistoryNew = useApplicationFeatureEnabled(FEATURE.APPOINTMENT_HISTORY_NEW);
  const canEditSchedule = useApplicationFeatureEnabled(FEATURE.EDIT_SCHEDULE);
  const canBlockChat = useApplicationFeatureEnabled(FEATURE.CAN_BLOCK_CHAT_FOR_A_DAY);
  const greeting = renderGreeting(
    headerStore.activeTab,
    headerStore.isHospitalApproved,
    headerStore.isNurse,
    canEditSchedule || canBlockChat
  );

  const historyComponent = useMemo(() => {
    if (headerStore.isNurse) {
      return HospitalPage;
    }
    if (!featureInitialized) {
      return null;
    }
    if (isAppointmentHistoryNew) {
      return AppointmentHistoryNew;
    } else {
      return AppointmentHistory;
    }
  }, [headerStore.isNurse, featureInitialized]);

  return (
    <ContentContainer>
      <BaseLayout contentWidth={headerStore.isNurse ? 1157 : 844} greeting={greeting}>
        <Switch>
          <Route
            exact
            path="/schedule"
            component={headerStore.isNurse ? HospitalPage : ScheduleContent}
          />
          <Route
            exact
            path="/schedule/history"
            component={historyComponent}
          />
          <Route
            exact
            path="/schedule/feedback"
            component={headerStore.isNurse ? HospitalPage : AppointmentFeedback}
          />
          {headerStore.isHospitalApproved && (
            <Route exact path="/schedule/hospital" component={HospitalPage} />
          )}
        </Switch>
      </BaseLayout>
    </ContentContainer>
  );
});
