import { Avatar, Box, useMediaQuery } from "@mui/material";
import { CorporateSubTitle } from "../styled/CorporateTypography.styled";
import React from "react";
import { styled } from "@mui/material/styles";
import { shortName } from "../utils/shortName";
import { breakpoints } from "../styled/common";
import { getLastNameWithInitials } from "../../domain/services/doctor";

const User = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  .MuiBox-root {
    display: flex;
    flex-direction: column;
  }
  .MuiAvatar-root {
    font-size: 1rem;
  }
`;

export const CorporateUserCard = ({ src = "", user, isWithInitials = false, children }) => {
  const isTabletDevice = useMediaQuery(breakpoints.tablet);

  return (
    <User>
      <Avatar src={src}>
        {shortName({ lastName: user.last_name, firstName: user.first_name })}
      </Avatar>
      <Box>
        <CorporateSubTitle modifiers={"md"}>
          {!isWithInitials && !isTabletDevice
            ? `${user.last_name} ${user.first_name} ${user.middle_name}`
            : getLastNameWithInitials(user)}
        </CorporateSubTitle>
        {children}
      </Box>
    </User>
  );
};
