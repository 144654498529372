import React, { Fragment } from "react";
import { List, ListItemButton, ListItemIcon, ListItemText, useMediaQuery } from "@mui/material";
import useReactRouter from "use-react-router";
import { AsideContainer, AsideHeader, AsideLayout, AsideScrollableContentContainer } from "./AsideLayout";
import { BackToScheduleButton } from "../appointment-switch-page/BackToScheduleButton";
import { PushNotificationTestModal } from '../doctor/PushNotificationTestModal';
import BugReportIcon from '@mui/icons-material/BugReport';
import StarIcon from '@mui/icons-material/StarOutline';
import ProfileIcon from "../../assets/icons/profile.inline.svg";
import BrushIcon from '@mui/icons-material/BrushOutlined';
import FavoriteAppointmentsIcon from '@mui/icons-material/FavoriteBorderOutlined';
import NotificationsIcon from '@mui/icons-material/NotificationsNoneOutlined';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import WalletIcon from "../../assets/icons/wallet.inline.svg";
import TicketIcon from "../../assets/icons/ticket.inline.svg";
import { Avatar, AvatarPlaceholder } from "../greeting/greetingCommon";
import { useStore } from "effector-react";
import { currentUser } from "../../stores/auth";
import styled from "styled-components";
import doctorsStore from '../../stores/doctorsStore';
import { FEATURE, useApplicationFeatureEnabled } from "../../utils/applicationFeatures";

const UserInfo = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 10px;
`;

const ListProfile = styled.div`
`;

export const ProfileLayout = ({children, activeIndexMenu}) => {
  const isMobileDevice = useMediaQuery('(max-width: 768px)');
  const { history } = useReactRouter();
  const user = useStore(currentUser);
  const SHOW_MY_TICKETS_PAGE = useApplicationFeatureEnabled(FEATURE.SHOW_MY_TICKETS_PAGE);
  const SHOW_POPULAR_LAYOUTS = useApplicationFeatureEnabled(FEATURE.SHOW_POPULAR_LAYOUTS);
  const SHOW_NOTICE_HEADER = useApplicationFeatureEnabled(FEATURE.SHOW_NOTICE_HEADER);
  const SHOW_BONUS_SCHEMA = useApplicationFeatureEnabled(FEATURE.BONUS_SCHEMA);
  const [selectedIndex, setSelectedIndex] = React.useState(activeIndexMenu);

  const handleClickMenu = (link, indexItem) => {
    setSelectedIndex(indexItem);
    window.scrollTo(0, 0);
    history.push(link);
  }

  const navigationItems = [
    {
      primary: 'Профиль',
      handlerOnClick: () => handleClickMenu('/profile', 0),
      icon: <ProfileIcon />,
      selected: (selectedIndex) => selectedIndex === 0,
      show: true,
    },
    {
      primary: "Моя зарплата",
      handlerOnClick: () => handleClickMenu("/profile/salary", 1),
      icon: <WalletIcon />,
      selected: selectedIndex => selectedIndex === 1,
      show: true,
    },
    {
      primary: "Мой прогресс",
      handlerOnClick: () => handleClickMenu("/profile/progress", 2),
      icon: <AutorenewIcon />,
      selected: selectedIndex => selectedIndex === 2,
      show: SHOW_BONUS_SCHEMA,
    },
    {
      primary: "Мои обращения",
      handlerOnClick: () => handleClickMenu("/profile/tickets", 3),
      icon: <TicketIcon />,
      selected: selectedIndex => selectedIndex === 3,
      show: SHOW_MY_TICKETS_PAGE,
    },
    {
      primary: "Популярные шаблоны",
      handlerOnClick: () => handleClickMenu("/profile/popular-layouts", 4),
      icon: <StarIcon />,
      selected: selectedIndex => selectedIndex === 4,
      show: SHOW_POPULAR_LAYOUTS,
    },
    {
      primary: "Избранные приемы",
      handlerOnClick: () => handleClickMenu("/profile/favorites-appointment", 5),
      icon: <FavoriteAppointmentsIcon />,
      selected: selectedIndex => selectedIndex === 5,
      show: true,
    },
    {
      primary: "Уведомления",
      handlerOnClick: () => handleClickMenu("/profile/notifications", 6),
      icon: <NotificationsIcon />,
      selected: selectedIndex => selectedIndex === 6,
      show: SHOW_NOTICE_HEADER,
    },
    {
      primary: "Проверить пуш уведомление",
      handlerOnClick: () => doctorsStore.sendTestPushNotification(),
      icon: <BugReportIcon />,
      selected: () => false,
      show: true,
    },
    {
      isHidden: !window.ReactNativeWebView,
      primary: "Вернуться на старый дизайн",
      handlerOnClick: () => window.ReactNativeWebView.postMessage("switchDesignType"),
      icon: <BrushIcon />,
      selected: () => false,
      show: true,
    }
  ].filter((item) => !item.isHidden);

  const aside = (
    <AsideContainer width={248}>
      <AsideHeader>
        {!isMobileDevice && <BackToScheduleButton />}
        <UserInfo>
          {user.doctor.photo ? (
            <Avatar avatar={user.doctor.photo} />
          ) : (
            <AvatarPlaceholder>
              {(user.doctor.last_name ? user.doctor.last_name.slice(0, 1) : "") + user.doctor.first_name.slice(0, 1)}
            </AvatarPlaceholder>
          )}
          {`${user.doctor.last_name} ${user.doctor.first_name}`}
        </UserInfo>
      </AsideHeader>
      <AsideScrollableContentContainer>
        <ListProfile>
          <List
            sx={{ width: '100%' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            {navigationItems.map(({ icon, primary, selected, handlerOnClick, show }, index) => (
              <Fragment key={index}>
                {show && (
                  <ListItemButton
                      selected={selected(selectedIndex)}
                      style={{
                          display: "flex",
                          alignItems: 'center'
                      }}
                      alignItems={"center"}
                      onClick={handlerOnClick}>
                      <ListItemIcon style={{
                          minWidth: 40,
                          margin: 0
                      }}>
                          {icon}
                      </ListItemIcon>
                      <ListItemText primary={primary} />
                  </ListItemButton>
                )}
              </Fragment>
            ))}
          </List>
        </ListProfile>
      </AsideScrollableContentContainer>
      <PushNotificationTestModal/>
    </AsideContainer>
  );


  return (
    <section>
      <AsideLayout background="#F9F4F4" aside={aside}>
        {children}
      </AsideLayout>
    </section>
  )
}
