// @flow
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import ticketStore from "../../stores/ticketStore";
import { Divider, Select, MenuItem, FormControl } from "@mui/material";
import _ from 'lodash';
import { READABLE_DATE_TIME_FORMAT } from "../../utils/dates";
import * as moment from "moment";
import apiv2 from "../../apiv2";

const DMSInfoContainer = styled.div`
  margin-bottom: 30px;
`;

const DMSInfoItem = styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: ${props => props.alignItems ? props.alignItems : "flex-start"};
    gap: 10px;
    justify-content: ${props => props.justifyContent ? props.justifyContent : ''};
    ${props => props.wrap === "true"
      ? `
        flex-direction: column;
        flex-wrap: wrap;
        width: calc(50% - 10px);
      `
      : `
        flex-wrap: nowrap;
      `
    }}
    @media (max-width: 768px) {
      width: 100%;
    }
    @media (max-width: 480px) {
      flex-wrap: wrap;
    }
`;

const DMSInfoBlockTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
`;

const DMSInfoBlock = styled.div`
  ${DMSInfoBlockTitle} {
    margin-bottom: 15px;
  }
`;

const DMSInfoItemLabel = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-wrap: nowrap;
`;

const DMSInfoBlockContent = styled.div`
  display: flex;
  gap: 10px;
  ${props => props.contentDirection === "row"
    ? `
      flex-direction: row;
      justify-content: space-between;
    `
    : `
      flex-direction: column;
    `
  };
  flex-wrap: wrap;
  @media (max-width: 768px) {
    gap: 13px;
  }
  ${props => props.contentBacklight 
    ? `
        gap: 0 !important;
        ${DMSInfoItem} {
          padding: 8px 6px;
          &:nth-child(2n + 1) {
            background: #FAFAFA;
            &:not(:first-child) {
              border-top: 1px solid #EBEBEB;
            }
            &:not(:last-child) {
              border-bottom: 1px solid #EBEBEB;
            }
          }
        }
      ` 
    : ``};
`;

const FormControlStyled = styled(FormControl)`
  &.MuiFormControl-root {
    min-width: 200px;
    @media (max-width: 480px) {
      min-width: auto;
      width: 100%;
    }
  }
`;

const DMSInfoItemValue = styled.div``;

const DMSInfoBlockContainer = ({ title = "", contentDirection = "", contentBacklight = "", children }) => {
  return (
    <DMSInfoBlock>
      {!!title && <DMSInfoBlockTitle>{title}</DMSInfoBlockTitle>}
      <DMSInfoBlockContent contentDirection={contentDirection} contentBacklight={contentBacklight}>{children}</DMSInfoBlockContent>
    </DMSInfoBlock>
  )
}

export const TicketDMSInfo = observer(
  () => {
    const [data, setData] = useState(_.cloneDeep(ticketStore.ticket));

    const fetchPrescribedServices = async () => {
      const response = await apiv2.appointments.getAppointmentPrescribedService(data.appointment__id);
      ticketStore.setDMSPrescribedServices(response);
    };

    useEffect(() => {
      (async () => {
        await fetchPrescribedServices()
      })();
    }, []);


    let primaryDiagnosesName = '';
    if (data.appointment__primary_diagnoses__diagnosis__name) {
      data.appointment__primary_diagnoses__diagnosis__name.forEach((item, index) => {
        if (item !== null) {
          primaryDiagnosesName += index === 0 ? item : `, ${item}`;
        }
      })
    }
    let primaryDiagnosesTextName = '';
    if (data.appointment__primary_diagnoses__comment) {
      data.appointment__primary_diagnoses__comment.forEach((item, index) => {
        if (item !== null) {
          primaryDiagnosesTextName += index === 0 ? item : `, ${item}`;
        }
      })
    }

    return (
      <>
        {!!ticketStore.DMSPrescribedServices.length && (
          <DMSInfoContainer>
            <DMSInfoBlockContainer title="Информация о пациенте">
              {data.patient_fullname && (
                <DMSInfoItem>
                  <DMSInfoItemLabel>Фио пациента:</DMSInfoItemLabel>
                  <DMSInfoItemValue>{data.patient_fullname}</DMSInfoItemValue>
                </DMSInfoItem>
              )}
              {data.appointment__medical_file__patient__birthdate && (
                <DMSInfoItem>
                  <DMSInfoItemLabel>Дата рождения:</DMSInfoItemLabel>
                  <DMSInfoItemValue>{data.appointment__medical_file__patient__birthdate}</DMSInfoItemValue>
                </DMSInfoItem>
              )}
            </DMSInfoBlockContainer>
            <Divider sx={{ mt: 2, mb: 2 }}/>
            <DMSInfoBlockContainer title="Информация о страховании" contentDirection="row">
              {data.appointment__medical_file__patient__policies__org_name.map((orgName, index) => {
                return (
                  <DMSInfoItem wrap="true" key={index}>
                    <DMSInfoItem>
                      <DMSInfoItemLabel>Название СК:</DMSInfoItemLabel>
                      <DMSInfoItemValue>{orgName}</DMSInfoItemValue>
                    </DMSInfoItem>
                    <DMSInfoItem>
                      <DMSInfoItemLabel>Номер полиса:</DMSInfoItemLabel>
                      <DMSInfoItemValue>{data.appointment__medical_file__patient__policies__number[index]}</DMSInfoItemValue>
                    </DMSInfoItem>
                    <DMSInfoItem>
                      <DMSInfoItemLabel>Начало действия полиса:</DMSInfoItemLabel>
                      <DMSInfoItemValue>{moment(data.appointment__medical_file__patient__policies__start_date[index]).format(READABLE_DATE_TIME_FORMAT)}</DMSInfoItemValue>
                    </DMSInfoItem>
                    <DMSInfoItem>
                      <DMSInfoItemLabel>Заканчивается действия полиса:</DMSInfoItemLabel>
                      <DMSInfoItemValue>{moment(data.appointment__medical_file__patient__policies__end_date[index]).format(READABLE_DATE_TIME_FORMAT)}</DMSInfoItemValue>
                    </DMSInfoItem>
                  </DMSInfoItem>
                )
              })}
            </DMSInfoBlockContainer>
            <Divider sx={{ mt: 2, mb: 2 }}/>
            <DMSInfoBlockContainer title="Информация о приеме">
              {data.doctor_fullname && (
                <DMSInfoItem>
                  <DMSInfoItemLabel>Фио врача:</DMSInfoItemLabel>
                  <DMSInfoItemValue>{data.doctor_fullname}</DMSInfoItemValue>
                </DMSInfoItem>
              )}
              {data.created_at && (
                <DMSInfoItem>
                  <DMSInfoItemLabel>Дата приема:</DMSInfoItemLabel>
                  <DMSInfoItemValue>{moment(data.created_at).format(READABLE_DATE_TIME_FORMAT)}</DMSInfoItemValue>
                </DMSInfoItem>
              )}
              {data.appointment__layout__name && (
                <DMSInfoItem>
                  <DMSInfoItemLabel>Прием:</DMSInfoItemLabel>
                  <DMSInfoItemValue>{data.appointment__layout__name}</DMSInfoItemValue>
                </DMSInfoItem>
              )}
              {!!primaryDiagnosesName && (
                <DMSInfoItem>
                  <DMSInfoItemLabel>Диагноз по МКБ:</DMSInfoItemLabel>
                  <DMSInfoItemValue>{primaryDiagnosesName}</DMSInfoItemValue>
                </DMSInfoItem>
              )}
              {data.appointment__id && (
                <DMSInfoItem>
                  <DMSInfoItemLabel>Ссылка на протокол:</DMSInfoItemLabel>
                  <DMSInfoItemValue>
                    <a target="_blank" href={`/appointment/${data.appointment__id}`} rel="noreferrer">Прием</a>
                  </DMSInfoItemValue>
                </DMSInfoItem>
              )}
              {!!primaryDiagnosesTextName && (
                <DMSInfoItem>
                  <DMSInfoItemLabel>Диагноз:</DMSInfoItemLabel>
                  <DMSInfoItemValue>{primaryDiagnosesTextName}</DMSInfoItemValue>
                </DMSInfoItem>
              )}
            </DMSInfoBlockContainer>
            <Divider sx={{ mt: 2, mb: 2 }}/>
            {ticketStore.DMSPrescribedServices && (
              <DMSInfoBlockContainer title="Услуги для согласования" contentBacklight>
                {ticketStore.DMSPrescribedServices.map((prescribedService, index) => {
                  return (
                    <React.Fragment key={index}>
                      <DMSInfoItem justifyContent="space-between" alignItems="center">
                        <DMSInfoItemValue>{prescribedService.service.name}</DMSInfoItemValue>
                        <FormControlStyled size="small">
                          <Select
                            placeholder={'Статус'}
                            value={prescribedService.dms_status}
                            onChange={(event) => ticketStore.changeDMSPrescribedServices(prescribedService, event.target.value)}
                          >
                            <MenuItem value={'AGREED'}>Согласованно</MenuItem>
                            <MenuItem value={'REJECTED'}>Отклонено</MenuItem>
                            <MenuItem value={'ON_APPROVAL'}>На согласовании</MenuItem>
                          </Select>
                        </FormControlStyled>
                      </DMSInfoItem>
                    </React.Fragment>
                  )
                })}
              </DMSInfoBlockContainer>
            )}
          </DMSInfoContainer>
        )}
      </>
    );
  }
);
