// @flow
import React from "react";
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import styled from "styled-components";
import useReactRouter from "use-react-router";
import { observer } from "mobx-react";
import { SurveyEmployeePage } from "./SurveyEmployeePage";

const Container = styled.div`
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
    position: relative;
`;

export const SurveyResultPage = observer(() => {
  // const [activeTab, setActiveTab] = React.useState('surveyEmployee');
  //
  // const handleChangeTab = (event, newValue) => {
  //   setActiveTab(newValue);
  // };

  return (
    // <Container>
    //   <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
    //     <Tabs value={activeTab} onChange={handleChangeTab} aria-label="basic tabs example">
    //       <Tab label="Анкентирование сотрудников" value={'surveyEmployee'} />
    //     </Tabs>
    //   </Box>
    //   <div style={{ display: activeTab !== 'surveyEmployee' ? 'none' : 'block' }}>
    //     <SurveyEmployeePage />
    //   </div>
    // </Container>
    <SurveyEmployeePage />
  );
});
