// @flow
import React, { useMemo } from "react";
import dayjs from "dayjs";
import { SERVER_DATE_FORMAT } from "../../../utils/dates";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { DynamicForm } from "../../common/dynamic-form/DynamicForm";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const getDate = (number, unit = "day") =>
  dayjs()
    .add(number, unit)
    .format(SERVER_DATE_FORMAT);

const INTERVALS = {
  WEEK: getDate(7),
  TWO_WEEKS: getDate(14),
  MONTH: getDate(30),
  NEVER: getDate(1000, "month")
};

export const ClosingChatsModal = ({ isShow, onClose, onSubmit }) => {
  const submitHandler = params => onSubmit(params);

  const fields = useMemo(
    () => [
      {
        type: "radio",
        name: "date",
        label: "Через сколько закрыть чат?",
        xs: 12,
        options: [
          { value: INTERVALS.WEEK, label: "Неделя" },
          { value: INTERVALS.TWO_WEEKS, label: "2 недели" },
          { value: INTERVALS.MONTH, label: "Месяц" },
          { value: INTERVALS.NEVER, label: "Никогда" }
        ]
      }
    ],
    []
  );

  const defaultData = {
    date: INTERVALS.NEVER
  };

  return (
    <Dialog open={isShow} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle sx={{ m: 0, p: 2 }}>Автоматическое закрытие чатов</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500]
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DynamicForm
          textButton="Сохранить"
          submitHandler={submitHandler}
          data={defaultData}
          fields={fields}
          hiddenButtonCancel={true}
        />
      </DialogContent>
    </Dialog>
  );
};
