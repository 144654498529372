// @flow
import React from "react";
import { styled, ThemeProvider } from "@mui/material/styles";
import { Paper, Unstable_Grid2 as Grid } from "@mui/material";
import { scrollbars } from "../../../components/chats/style-elements";
import { THEME } from "../../theme";
import { CorporateHeader } from "../CorporateHeader";
import { CorporateNavigation } from "../../modules/navigation/CorporateNavigation";
import { CorporateSideBarLayout } from "./CorporateSideBarLayout";

const Container = styled(Paper)(
  ({ theme }) => `
    position: relative;
    box-sizing: border-box;
    height: 100vh;
    overflow-x: auto;
    background: ${theme.palette.background.layout};
    ${scrollbars};
  `
);

const ContentContainer = styled(Grid)(
  ({ theme }) => `
    padding: 30px;
    ${theme.breakpoints.down("lg")} {
      padding: 25px;
    }
    ${theme.breakpoints.down("sm")} {
      padding: 10px;
    }
  `
);

export const CorporatePortalLayout = ({ children }) => {
  return (
    <ThemeProvider theme={THEME}>
      <Container>
        <CorporateHeader />
        <ContentContainer container columnSpacing={{ xs: 1, sm: 2, lg: 3 }} disableEqualOverflow>
          <CorporateSideBarLayout>
            <CorporateNavigation />
          </CorporateSideBarLayout>
          {children}
        </ContentContainer>
      </Container>
    </ThemeProvider>
  );
};
