// @flow
import * as React from "react";

import { getNounEndingByNumber } from "../../utils/getNounEndingByNumber";
import { HeadingH2 } from "../styleguide/typography";
import {
  Avatar,
  AvatarPlaceholder,
  Container,
  ContainerWrapper,
  InnerContainer,
  Text,
  TextContainer
} from "./greetingCommon";

export const Greeting = (props: {|
  firstName: string,
  lastName?: string,
  avatar: ?string,
  numberOfPatients: ?number,
  page?: string
|}) => {
  const { firstName, lastName, numberOfPatients, page } = props;

  return (
    <Container>
      <ContainerWrapper>
        {props.avatar ? (
          <Avatar avatar={props.avatar} />
        ) : (
          <AvatarPlaceholder>
            {(lastName ? lastName.slice(0, 1) : "") + firstName.slice(0, 1)}
          </AvatarPlaceholder>
        )}
        <InnerContainer>
          <HeadingH2>Мое расписание</HeadingH2>
          <TextContainer>
            <Text as="span">
              Добрый день, {firstName}! Сегодня {page === "hospital" ? "в стационаре" : "у вас"}{" "}
            </Text>
            {numberOfPatients ? (
              <Text as="span" modifiers={["bold", "color"]}>
                {numberOfPatients}{" "}
                {getNounEndingByNumber(numberOfPatients, ["пациент", "пациента", "пациентов"])}
              </Text>
            ) : (
              <Text as="span">нет пациентов</Text>
            )}
          </TextContainer>
        </InnerContainer>
      </ContainerWrapper>
    </Container>
  );
};
