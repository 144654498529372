import React, { createRef, useRef } from "react";
import styled from "styled-components";
import { colors } from "../styleguide/colors";
import { LinearGradientBackground } from "../styleguide/LinearGradientBackground";
import { NavigationTabs } from "../navigation-tabs/NavigationTabs";
import { Header } from "../header/Header";
import { useUpdateMessage } from "../update-message/useUpdateMessage";
import { useFeedbackCountMessage } from "../appointment-feedback/useFeedbackCountMessage";
import { UpdateVersionModal } from "../update-version-modal/UpdateVersionModal";
import commonStore from "../../stores/commonStore";
import { useMediaQuery } from "@mui/material";
import { useCalcHeightContainer } from "../schedule-page/useCalcHeightContainer";
import headerStore, { TABS } from "../../stores/headerStore";
import { withModifiers } from "with-modifiers";

const ContentContainer = styled.div`
  position: relative;

  @media screen and (max-width: 576px) {
    width: 100vw;
    height: 100vh;
  }
`;

const pageBackground = "rgb(244, 223, 226)";

const Container = styled(LinearGradientBackground)`
  background: ${pageBackground};
  display: grid;
  grid-template-columns: 1fr ${props => (props.contentWidth ? props.contentWidth : 844)}px 1fr;
  grid-template-rows: auto auto 1fr;
  grid-gap: 0 64px;
  grid-template-areas:
    "header header header"
    "greeting greeting greeting"
    "tabs main .";

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto auto 1fr;
    grid-template-areas:
      "header header header"
      "greeting greeting greeting"
      "tabs tabs tabs"
      "main main main";
  }

  @media screen and (max-width: 768px) {
    grid-gap: 0;
  }
`;

const HeaderContainer = styled.div`
  grid-area: header;
  position: sticky;
  top: 0;
  background: ${pageBackground};
  z-index: 10;
  padding-bottom: 36px;
  @media screen and (max-width: 1200px) {
    padding-bottom: ${props => props.notPadding ? "0px" : "23px"};
  }
`;

const GreetingContainer = styled.div`
  padding-top: 10px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  grid-area: greeting;
  background: ${pageBackground};
  position: sticky;
  top: 63px;
  z-index: 10;

  @media screen and (max-width: 1200px) {
    padding: 10px 30px;
    margin: 0;
    top: 65px;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const GreetingWrapper = styled.div`
  width: ${props => (props.contentWidth ? props.contentWidth : 844)}px;
  margin: 0 auto;
  position: relative;

  @media screen and (max-width: 768px) {
    width: 100%;
    min-height: 49px;

    > div {
      width: 100%;
      flex-direction: column;
      align-items: center;

      button {
        margin-top: 5px;
      }
    }
  }
`;

const MainContainer = withModifiers({
  notPadding: () => `
    padding: 0 !important;
  `
})(
  modifiers => styled.div`
    grid-area: main;
    margin-bottom: 44px;
    
    @media screen and (max-width: 1200px) and (min-width: 769px) {
      margin-bottom: 0;
      > div {
        width: 100%;
      }
    }
    @media (max-width: 768px) {
      height: ${props => props?.height || "auto"};
      padding: 0 40px 40px;
      margin-bottom: 0;
    }
    @media (max-width: 480px) {
      padding: 0 20px 40px;
    }
      
    ${modifiers};
  `
);

export const TabsContainer = styled.div`
  grid-area: tabs;
  justify-self: flex-end;
  align-self: flex-start;
  background: ${pageBackground};
  position: sticky;
  top: 206px;
    
  @media screen and (max-width: 1200px) and (min-width: 769px) {
    width: 100%;
    top: ${props => props?.hasGreeting ? 152 : 0}px;
    justify-self: center;
    > div {
      width: calc(100% - 40px);
      padding-inline: 20px;
      gap: 10px;
      justify-content: space-between;
    }
  }

  @media screen and (max-width: 768px) {
    position: fixed;
    top: auto;
    bottom: 0;
    margin-top: 0;
    background: ${colors.white};
    box-shadow: 0 -3px 10px 0 rgba(0, 0, 0, .05);
    z-index: 10;
  }
`;

export const BaseLayout = ({ contentWidth, greeting, children }) => {
  const isTablet = useMediaQuery("(max-width: 768px)");
  const isMobile = useMediaQuery("(max-width: 480px)");
  const baseLayoutRef = useRef({
    headerRef: createRef(),
    mainRef: createRef(),
    navigationsRef: createRef()
  });
  const isFullScreen = isMobile && [TABS.CHATS, TABS.SCHEDULE, TABS.HISTORY].includes(headerStore.activeTab);
  const isShowHeader = !isMobile || (isMobile && headerStore.activeTab !== TABS.CHATS);
  const heightMainContainer = useCalcHeightContainer({ ...baseLayoutRef.current, isFullScreen, isShowHeader });

  useUpdateMessage();
  useFeedbackCountMessage();
  commonStore.checkShowedUpdateVersion();

  return (
    <ContentContainer>
      <Container contentWidth={contentWidth}>
        {isShowHeader && (
          <HeaderContainer ref={baseLayoutRef.current.headerRef} notPadding={!!greeting}>
            <Header withLogo />
          </HeaderContainer>
        )}
        {greeting && (
          <GreetingContainer>
            <GreetingWrapper contentWidth={contentWidth}>{greeting}</GreetingWrapper>
          </GreetingContainer>
        )}
        <MainContainer
          ref={baseLayoutRef.current.mainRef}
          height={isTablet ? heightMainContainer : "auto"}
          modifiers={[ isFullScreen && "notPadding" ]}
        >
          {children}
        </MainContainer>
        <TabsContainer hasGreeting={greeting} ref={baseLayoutRef.current.navigationsRef}>
          <NavigationTabs isMobileDevice={isMobile || isTablet} />
        </TabsContainer>
      </Container>
      <UpdateVersionModal />
    </ContentContainer>
  );
};
