import React from "react";
import styled from "styled-components";
import Markdown from "markdown-to-jsx";

const Container = styled.div`
    p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: revert;
        padding: revert;
        border: revert;
        font-size: revert;
        font: revert;
        vertical-align: revert;
        list-style: revert;
    }
    font-family: "Graphik LCG" !important;
`;

export const MarkdownBlock = ({ data }) => {
  return (
    <Container>
      {/* eslint-disable-next-line react/no-children-prop */}
      <Markdown children={data} />
    </Container>
  );
};
