// @flow
import * as React from "react";
import { useState, useMemo } from "react";
import { ModalPortal } from "../modal/ModalPortal";
import { useConfirm } from "../alert-warning/useConfirm";
import { AlertWarning } from "../alert-warning/AlertWarning";
import { AlertWarningPrimaryButton, AlertWarningWhiteButton } from "../buttons/AlertButton";
import { HeadingH3 } from "../styleguide/typography";
import { SidebarHeader } from "../sidebar/SidebarHeader";
import { SidebarLayout } from "../sidebar/SidebarLayout";
import { SidebarFade } from "../sidebar/SidebarFade";
import { useSidebarFade } from "../sidebar/useSidebarFade";
import {
  ShortcutsFormLayout,
  ShortcutsDefaultInputField,
  ShortcutsTextareaField,
  ShortcutsPrimaryButton,
  ShortcutsButton,
  ShortcutsChecklistField, ShortcutsPrescribedServicesField
} from "./ShortcutsFormLayout";
import { closeShortcutsSidebar } from "../../stores/shortcuts/shortcutsSections";
import type { TChecklistDestination } from "../../domain/value-objects/TShortcuts";
import type { TChecklistShortcutArray } from "../../domain/value-objects/TShortcuts";
import { observer } from 'mobx-react';
import serviceStore from '../../stores/serviceStore';

export const ShortcutsCreate = observer((props: {
  initialValue?: string,
  fieldName?: TChecklistDestination,
  onSubmit: (name: string, value: string, checklist?: TChecklistShortcutArray) => mixed,
  isChecklist?: boolean,
  isServices?: boolean,
  isAppointmentServices?: boolean,
  checklist?: TChecklistShortcutArray,
}) => {
  const { initialValue, onSubmit, isChecklist, isServices, isAppointmentServices, checklist, fieldName } = props;
  const shouldStartTransition = useSidebarFade();

  const [name, setName] = useState("");
  const [value, setValue] = useState(initialValue || "");

  const cancelConfirm = useConfirm();

  const onCancel = async () => {
    if (!cancelConfirm.requestConfirm) {
      return;
    }

    try {
      const confirmResult = await cancelConfirm.requestConfirm();
      if (!confirmResult) {
        return;
      }
      closeShortcutsSidebar();
    } catch (e) {
      console.log(e);
    }
  };

  const header = <SidebarHeader onClose={onCancel} title="Создать заготовку" />;

  const fields = useMemo(() => {
    if (isServices) {
      return (
        <>
          <ShortcutsDefaultInputField
            legend="Название заготовки"
            value={name}
            handleChange={setName}
          />
          <ShortcutsPrescribedServicesField
            /* $FlowFixMe */
            fieldName={fieldName}
            /* $FlowFixMe */
            services={serviceStore.prescribedServices}
            legend="Содержание заготовки"
          />
        </>
      );
    }
    if (isAppointmentServices) {
      return (
        <>
          <ShortcutsDefaultInputField
            legend="Название заготовки"
            value={name}
            handleChange={setName}
          />
          <ShortcutsPrescribedServicesField
            /* $FlowFixMe */
            fieldName={fieldName}
            /* $FlowFixMe */
            services={serviceStore.appointmentServices}
            legend="Содержание заготовки"
          />
        </>
      );
    }
    if (isChecklist) {
      return (
        <>
          <ShortcutsDefaultInputField
            legend="Название заготовки"
            value={name}
            handleChange={setName}
          />
          <ShortcutsChecklistField
            /* $FlowFixMe */
            fieldName={fieldName}
            /* $FlowFixMe */
            checklist={checklist}
            legend="Содержание заготовки"
          />
        </>
      );
    } else {
      return (
        <>
          <ShortcutsDefaultInputField
            legend="Название заготовки"
            value={name}
            handleChange={setName}
          />
          <ShortcutsTextareaField legend="Текст заготовки" value={value} handleChange={setValue} />
        </>
      );
    }
  }, [isChecklist, name, fieldName, checklist, value]);

  const buttons = (
    <>
      <ShortcutsPrimaryButton
        disabled={isChecklist || isServices || isAppointmentServices ? !name : !name || !value}
        onClick={() => {
          if (isChecklist) {
            return onSubmit(name, value, checklist);
          }
          if (isServices) {
            return onSubmit(name, value, serviceStore.prescribedServices);
          }
          if (isAppointmentServices) {
            return onSubmit(name, value, serviceStore.appointmentServices);
          }
          return onSubmit(name, value);
        }}>
        Сохранить
      </ShortcutsPrimaryButton>
      <ShortcutsButton onClick={onCancel}>Отменить</ShortcutsButton>
    </>
  );

  const cancelAlertWarning = (
    <AlertWarning
      isNeedConfirm={cancelConfirm.isNeedConfirm}
      sendConfirm={cancelConfirm.sendConfirm}
      heading={<HeadingH3 modifiers="center">Изменения не будут сохранены</HeadingH3>}
      cancelButton={onClick => (
        <AlertWarningWhiteButton onClick={onClick}>Отменить</AlertWarningWhiteButton>
      )}
      confirmButton={onClick => (
        <AlertWarningPrimaryButton onClick={onClick}>Хорошо</AlertWarningPrimaryButton>
      )}
    />
  );

  return (
    <>
      <SidebarFade>
        {shouldStartTransition && (
          <SidebarLayout header={header}>
            <ShortcutsFormLayout fields={fields} buttons={buttons} />
          </SidebarLayout>
        )}
      </SidebarFade>
      <ModalPortal>{cancelAlertWarning}</ModalPortal>
    </>
  );
});
