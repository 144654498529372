import { ChartComponent } from "../common/charts/ChartComponent";
import reviewStatisticsStore from "../../stores/reviewStatisticsStore";
import { Box, Paper, Stack } from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import moment from "moment";
import { LabelWithFiltersModal } from "../common/widgets-components-common/LabelWithFiltersModal";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import _ from "lodash";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { getLocalStorage, setLocalStorage } from "../../utils/localStorageUtils";
import { observer } from "mobx-react";
import randomColors from "../../utils/randomColors";
import organizationsStore from "../../stores/organizationsStore";
import * as yup from "yup";
import { filterValidation } from "../common/widgets-components-common/constants";
import { AbsoluteLoader } from "../loader/Loader";

const NAME_WIDGET = 'widgetTags';

const transformTagDiagram = ({data, interval, isCollapseData, organizations}) => {
  const groupedDataDate = _.groupBy(_.sortBy(data, 'date_trunc'), 'date_trunc');
  const groupedDataNameTag = _.groupBy(_.sortBy(data, 'tag'), 'tag');
  const groupedDataOrganizations = _.groupBy(_.sortBy(data, 'organization_id'), 'organization_id');
  const groupedDataDateOrganizations = Object.keys(groupedDataDate).reduce((acc, key) => {
    acc[key] = _.groupBy(_.sortBy(groupedDataDate[key], 'organization_id'), 'organization_id');
    return acc;
  }, {})
  const labels = Object.keys(groupedDataDate);
  const organizationsData = Object.keys(groupedDataOrganizations).reduce((acc, key) => {
    acc[key] = {
      label: '',
    };
    Object.keys(groupedDataNameTag).forEach((tagName) => {
      acc[key][tagName] = [];
    })
    return acc;
  }, {});
  labels.forEach((labelDate) => {
    Object.keys(groupedDataOrganizations).forEach((organizationId) => {
      const dataOrganizations = groupedDataDateOrganizations[labelDate][organizationId];
      if (!organizationsData[organizationId].label && dataOrganizations) {
        const organizationsName = organizations.find((item) => item.id === parseInt(organizationId))?.name;
        organizationsData[organizationId].label = organizationsName;
      }
      if (dataOrganizations) {
        const groupedDataTag = _.groupBy(dataOrganizations, 'tag');
        Object.keys(groupedDataNameTag).forEach((tagName) => {
          if (groupedDataTag[tagName]?.length) {
            organizationsData[organizationId][tagName].push(groupedDataTag[tagName].reduce((acc, item) => {
              acc += item.good_percentage;
              return acc;
            }, 0) / groupedDataTag[tagName].length);
          } else {
            const prevValue = organizationsData[organizationId][tagName][organizationsData[organizationId][tagName].length - 1];
            organizationsData[organizationId][tagName].push(prevValue || prevValue === 0 ? prevValue : null);
          }
        });
      } else {
        Object.keys(groupedDataNameTag).forEach((tagName) => {
          const prevValue = organizationsData[organizationId][tagName][organizationsData[organizationId][tagName].length - 1];
          organizationsData[organizationId][tagName].push(prevValue || prevValue === 0 ? prevValue : null);
        })
      }
    })
  })
  const tags_set = [];
  const datasets = Object.keys(organizationsData).reduce((acc, key, index) => {
    const data = organizationsData[key];
    Object.keys(groupedDataNameTag).forEach((tagName) => {
      if (isCollapseData) {
        if (!tags_set.includes(tagName)) {
          acc.push({
            label: data.label + ' - ' + tagName,
            data: data[tagName] || [0],
            color: `rgb(${randomColors()})`,
            highlightScope: {
              highlighted: 'series',
              faded: 'global',
            }
          })
          tags_set.push(tagName);
        } else {
          acc[tags_set.indexOf(tagName)].label += ', ' + data.label + ' - ' + tagName;
          data[tagName].forEach((count, index) => {
            let value = null;
            if (!acc[tags_set.indexOf(tagName)].data[index]) {
              value = count;
            } else {
              value = count === null ? acc[tags_set.indexOf(tagName)].data[index] : (acc[tags_set.indexOf(tagName)].data[index] + count) / 2;
            }
            acc[tags_set.indexOf(tagName)].data[index] = value;
          })
        }
      } else {
        acc.push({
          label: data.label + ' - ' + tagName,
          data: data[tagName] || [0],
          color: `rgb(${randomColors()})`,
          highlightScope: {
            highlighted: 'series',
            faded: 'global',
          }
        })
      }
    });

    return acc;
  }, []);
  return { labels, datasets };
}

const transformTableData = (data, organization_ids, valueName, organizations) => {
  const returnData = [];
  if (Array.isArray(data)) {
    const organizationsData = _.groupBy(data, 'organization_id');
    Object.keys(organizationsData).forEach((organizationId) => {
      const tagData = _.groupBy(organizationsData[organizationId], 'tag');
      Object.keys(tagData).forEach((key) => {
        returnData.push(
          {
            organizationId,
            organization: organizations.find((organization) => parseInt(organization.id) === parseInt(organizationId))?.name,
            tagName: key,
            count: tagData[key].reduce((acc, item) => {
              acc += item[valueName];
              return acc;
            }, 0)
          }
        )
      })
    })
  }
  return returnData;
};
export const FILTERS_FIELDS_WIDGET_TAGS = () => [
  {
    type: 'select',
    name: 'type',
    label: 'Вид графика',
    xs: 12,
    options: [
      {value: 'line', label: 'Линейный'},
      {value: 'bar', label: 'Столбцами'},
      {value: 'table', label: 'Таблицей'},
    ]
  },
  {
    type: 'select',
    name: 'map_names',
    label: 'Источник',
    multiple: true,
    xs: 12,
    options: reviewStatisticsStore.mapNamesOptions
  },
  {
    type: 'checkbox',
    name: 'isCollapse',
    label: 'Объединить выбранные организации (только для графиков)',
    xs: 12,
  },
  {
    type: 'text',
    name: 'label',
    label: 'Название графика',
    xs: 12,
  },
  {
    type: 'select',
    name: 'organization_ids',
    label: 'Организации',
    multiple: true,
    needSelectAll: true,
    valueName: 'id',
    labelName: 'name',
    xs: 12,
    options: organizationsStore.organizations,
  },
];

export const WidgetTags = observer((props: {
  filtersData: () => {},
  rewriteFilters: () => {},
  keyWidget: String
}) => {

  const styledPaper = {
    padding: '20px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }

  const [filtersDataDiagram, setFiltersDataDiagram] = useState({});
  const [filterFields, setFilterFields] = useState({});
  const [tableData, setTableData] = useState([]);
  const [chartsData, setChartsData] = useState();
  const [label, setLabel] = useState('График по тэгам');
  const [isInitialized, setIsInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const validateSchema = yup.object().shape({
    organization_ids: yup.array().required("организации").min(1, "организации")
  });

  useEffect(() => {
    (async () => {
      await organizationsStore.getOrganizations();
      if (getLocalStorage(NAME_WIDGET + props.keyWidget)) {
        setLocalStorage(NAME_WIDGET + props.keyWidget + location.pathname.replaceAll('/', ''), getLocalStorage(NAME_WIDGET + props.keyWidget));
        localStorage.removeItem(NAME_WIDGET + props.keyWidget);
      }
      const filterDiagramLocaleStorage = getLocalStorage(NAME_WIDGET + props.keyWidget + location.pathname.replaceAll('/', ''));
      if (filterDiagramLocaleStorage && moment(filterDiagramLocaleStorage.date_end).isBefore(moment())) {
        filterDiagramLocaleStorage.date_end = moment();
      }
      const filters = props.rewriteFilters || filterDiagramLocaleStorage || props.filtersData || {};
      if (filters.label) {
        setLabel(filters.label);
      }
      setFiltersDataDiagram(filters)
      setFilterFields([...FILTERS_FIELDS_WIDGET_TAGS()]);
      setIsInitialized(true);
    })()
  }, []);

  useEffect(() => {
    (async () => {
      if (_.size(filtersDataDiagram)) {
        setLoading(true);
        setLocalStorage(NAME_WIDGET + props.keyWidget + location.pathname.replaceAll('/', ''), filtersDataDiagram);
        if (filtersDataDiagram.label) {
          setLabel(filtersDataDiagram.label);
        }
        const response = await reviewStatisticsStore.getTagsDiagram(filtersDataDiagram);
        if (response.error) {
          filterValidation({ validateSchema, filtersDataDiagram, setErrors });
        }
        setChartsData(transformTagDiagram({data: response, interval: filtersDataDiagram.interval || 'day', isCollapseData: filtersDataDiagram.isCollapse, organizations: organizationsStore.organizations}));
        setTableData(transformTableData(response, filtersDataDiagram.organization_ids, 'count', organizationsStore.organizations));
        setLoading(false);
      }
    })()
  }, [filtersDataDiagram]);

  useEffect(() => {
    if (props.rewriteFilters) {
      setFiltersDataDiagram({...filtersDataDiagram, ...props.rewriteFilters});
    }
  }, [props.rewriteFilters]);

  const submitHandler = (filters, callback) => {
    setErrors([]);
    setFiltersDataDiagram(filters);
    callback();
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'organization', //access nested data with dot notation
        header: 'Название организации',
      },
      {
        accessorKey: 'tagName', //access nested data with dot notation
        header: 'Название тэга',
        muiTableBodyCellProps: ({ cell }) => {
          if (!cell.getValue()) {
            return;
          }
          return {
            onClick: () => {
              const localDateFilterParams = getLocalStorage("widgetFilterParams");
              reviewStatisticsStore.setChangedFilterParams({
                date: {
                  date__gte: localDateFilterParams.date_start,
                  date__lte: localDateFilterParams.date_end,
                },
                columns: [
                  { id: "tags__name", value: [cell.row.original.tagName] },
                  { id: "organization", value: [parseInt(cell.row.original.organizationId)] }
                ]
              })
            },
            sx: {
              ":hover": {
                cursor: "pointer",
                outline: "1px solid #9e9e9e"
              }
            }
          }
        }
      },
      {
        accessorKey: 'count',
        header: 'Количество',
        aggregationFn: 'sum',
        AggregatedCell: ({ cell }) => (
          <>
            <Box
              sx={{ color: 'info.main', display: 'inline', fontWeight: 'bold' }}
            >
              {cell.getValue()}
            </Box>
          </>
        ),
        Footer: () => (
          <Stack>
            <Box color="primary.main">Всего: {tableData.reduce((acc, item) => {
              acc += item.count;
              return acc;
            }, 0)}</Box>
          </Stack>
        )
      },
    ],
    [tableData],
  );

  const table = useMaterialReactTable({
    columns: columns,
    data: tableData,
    enableFullScreenToggle: false,
    enableGrouping: true,
    initialState: {
      density: 'compact',
      grouping: ['organization'],
      pagination: {pageIndex: 0, pageSize: 300}
    },
    localization: MRT_Localization_RU,
    isMultiSortEvent : ( ) => true,
  });

  return (
    <Paper style={styledPaper}>
      {filterFields && (
        <LabelWithFiltersModal
          fullWidth={true}
          type={"filter"}
          filtersData={filtersDataDiagram}
          filtersFields={filterFields}
          label={label}
          textButtonFilter={"Применить"}
          submitHandler={submitHandler}
        />
      )}
      {(loading || !isInitialized) && <AbsoluteLoader />}
      {isInitialized && (
        <>
          {filtersDataDiagram.type !== 'table' && chartsData && (
            <ChartComponent
              textButtonFilter="Применить"
              type={filtersDataDiagram.type || 'bar'}
              nameDiagram="ReviewDiagramTags"
              data={chartsData}
              interval={filtersDataDiagram?.interval || 'day'}
              errors={errors}
            />
          )}
          {filtersDataDiagram.type === 'table' && tableData && (
            <div style={{
              maxHeight: '100%',
              overflow: "auto"
            }} className={'material-table'}>
              <MaterialReactTable table={table} />
            </div>
          )}
        </>
      )}
    </Paper>
  )
});
