import { makeAutoObservable } from "mobx";
import Binder from "../../utils/binder";
import { generatedCompanyInfoData } from "../utils/company/generatedCompanyData";

class CorporateCompanyStore {
  /** Табы */
  isInitialized = false;
  tabs = [];
  selectedTab = {
    index: 0,
    info: null
  };
  /** Контент */
  allCompanyInfo = null;
  companyInfoByTab = null;

  constructor() {
    Binder.bind(this, CorporateCompanyStore);
    makeAutoObservable(this);
  }

  setInitialized(flag) {
    this.isInitialized = flag;
  }

  getCompanyInfo() {
    const response = generatedCompanyInfoData;
    setTimeout(() => {
      response.forEach(item => {
        this.tabs.push({ id: item.title, name: item.title });
        this.allCompanyInfo = { ...this.allCompanyInfo, [item.title]: item.template };
      });

      this.changeSelectedTab(0);
      // ToDo для тестовой среды, удалить после интеграции
      this.setInitialized(true);
    }, 500);
  }

  changeSelectedTab(key) {
    this.selectedTab = {
      index: key,
      info: this.tabs[key]
    };
    this.setCompanyInfoByTab();
  }

  setCompanyInfoByTab() {
    this.companyInfoByTab = this.allCompanyInfo[this.selectedTab.info.name];
  }
}

export default new CorporateCompanyStore();
