import { useMemo, useEffect } from 'react';
import {makeAutoObservable} from 'mobx';
import {useStore} from 'effector-react';

import {currentUser} from '../stores/auth';
import apiv2 from '../apiv2';

export const FEATURE = {
  PRINT_LOGO_IN_DOCTOR_VERSION: 'PRINT_LOGO_IN_DOCTOR_VERSION',
  PRESCRIBED_SERVICES: 'PRESCRIBED_SERVICES',
  DOCUMENT_SIDEBAR: 'DOCUMENT_SIDEBAR',
  COMMENT_IN_SERVICES: 'COMMENT_IN_SERVICES',
  PRICE_IN_PRINT: 'PRICE_IN_PRINT',
  CATEGORIES_IN_SERVICE: 'CATEGORIES_IN_SERVICE',
  CHECK_DIAGNOSIS_FOR_DMS: 'CHECK_DIAGNOSIS_FOR_DMS',
  IVB__DOCTOR__ID: 'IVB__DOCTOR__ID',
  IVB__DOCTOR__NAME: 'IVB__DOCTOR__NAME',
  PEDIATRICIAN__DOCTOR__ID: 'PEDIATRICIAN__DOCTOR__ID',
  PEDIATRICIAN__DOCTOR__NAME: 'PEDIATRICIAN__DOCTOR__NAME',
  CHAT: 'CHAT',
  CHAT_FILTERS: 'CHAT_FILTERS',
  APPOINTMENT_PRINT_DOCTOR_TEMPLATE_ID: 'APPOINTMENT_PRINT_DOCTOR_TEMPLATE_ID',
  APPOINTMENT_PRINT_PATIENT_TEMPLATE_ID: 'APPOINTMENT_PRINT_PATIENT_TEMPLATE_ID',
  HC_QUESTIONNAIRE_USER_ID: 'HC_QUESTIONNAIRE_USER_ID',
  HC_QUESTIONNAIRE_LAYOUT_ID: 'HC_QUESTIONNAIRE_LAYOUT_ID',
  HC_QUESTIONNAIRE_SECTION_FEMALE_ID: 'HC_QUESTIONNAIRE_SECTION_FEMALE_ID',
  PROFILE: 'PROFILE',
  GENERAL_SCHEDULE_NEW: 'GENERAL_SCHEDULE_NEW',
  MEDICAL_GENOMICS: 'MEDICAL_GENOMICS',
  PATIENTS: 'PATIENTS',
  TABLE_PRESCRIBED_SERVICES: 'TABLE_PRESCRIBED_SERVICES',
  REQUIRED_FIELD_DIAGNOSIS_MKB: 'REQUIRED_FIELD_DIAGNOSIS_MKB',
  SHOW_MY_TICKETS_PAGE: 'SHOW_MY_TICKETS_PAGE',
  SHOW_CHATS_SETTING: 'SHOW_CHATS_SETTING',
  SHOW_POPULAR_LAYOUTS: 'SHOW_POPULAR_LAYOUTS',
  TICKET_DOCTOR_ASSIGNEE_MEDICAL_ERROR: 'TICKET_DOCTOR_ASSIGNEE_MEDICAL_ERROR',
  TICKET_DOCTOR_ASSIGNEE_COMMON: 'TICKET_DOCTOR_ASSIGNEE_COMMON',
  PAYMENT_VIA_APP: 'PAYMENT_VIA_APP',
  AMBULANCE: 'AMBULANCE',
  APPOINTMENT_HISTORY_NEW: 'APPOINTMENT_HISTORY_NEW',
  EDIT_SCHEDULE: 'EDIT_SCHEDULE',
  CAN_BLOCK_CHAT_FOR_A_DAY: 'CAN_BLOCK_CHAT_FOR_A_DAY',
  BONUS_SCHEMA: 'BONUS_SCHEMA',
  CORPORATE_PORTAL: 'CORPORATE_PORTAL',
  SHOW_NOTICE_HEADER: 'SHOW_NOTICE_HEADER',
  SURVEY_EMPLOYEE_RESULT: 'SURVEY_EMPLOYEE_RESULT',
  CHANGED_CHATROOM_CALCULATION: 'CHANGED_CHATROOM_CALCULATION',
  ENABLE_VOX_TEMPLATE_T_FEEDBACK_NEW: 'ENABLE_VOX_TEMPLATE_T_FEEDBACK_NEW',
  EDIT_PREV_SERVICES: 'EDIT_PREV_SERVICES',
};

class ApplicationFeatures {
  initialized = false;
  config = {};
  constructor() {
    makeAutoObservable(this);
  }

  *updateConfig() {
    const newConfig = yield apiv2.config.getConfig();
    if (newConfig) {
      this.config = newConfig;
      this.initialized = true;
    }
  }

  value(feature) {
    if (!this.initialized) {
      return false;
    }
    const user = currentUser.getState();

    if (!user) {
      return this.config[feature];
    }

    const userFeature = this.config[`${feature}__USER__${user.id}`];
    if (userFeature !== undefined) {
      return userFeature;
    }

    const userOrganizationIdHasFeature = user.doctor?.organizations?.find(org_id => this.config[`${feature}__ORG__${org_id}`] !== undefined);
    if (userOrganizationIdHasFeature !== undefined) {
      return this.config[`${feature}__ORG__${userOrganizationIdHasFeature}`];
    }

    return this.config[feature];
  }

  enabled(feature) {
    return !!this.value(feature);
  }

  valueForOrganization(feature, organizationId) {
    if (!this.initialized || organizationId === undefined) {
      return false;
    }

    if (`${feature}__ORG__${organizationId}` in this.config) {
      return this.config[`${feature}__ORG__${organizationId}`];
    }

    return this.config[feature];
  }

  enabledForOrganization(feature, organizationId) {
    return !!this.valueForOrganization(feature, organizationId);
  }

  valueFeature(feature) {
    return this.value(feature);
  }

}

const applicationFeatures = new ApplicationFeatures();

export const useApplicationFeaturesInitialized = () => {
  return useMemo(() => {
    return applicationFeatures.initialized;
  }, [applicationFeatures.initialized]);
};

export const useApplicationFeatures = () => {
  useEffect(() => {
    applicationFeatures.updateConfig();
  }, []);
};

export const applicationFeatureEnabled = (feature) => {
  return applicationFeatures.enabled(feature);
};

export const useApplicationFeatureEnabled = (feature) => {
  const user = useStore(currentUser);

  return useMemo(() => {
    return applicationFeatures.enabled(feature);
  }, [applicationFeatures.initialized, applicationFeatures.config, user]);
};

export const useApplicationFeatureValue = (feature) => {
  const user = useStore(currentUser);

  return useMemo(() => {
    return applicationFeatures.value(feature);
  }, [applicationFeatures.initialized, applicationFeatures.config, user]);
};

export const featureEnabledForOrganization = (feature, organizationId) => {
  return applicationFeatures.enabledForOrganization(feature, organizationId);
};

export const featureValue = (feature) => {
  return applicationFeatures.valueFeature(feature);
};

export const useApplicationFeatureValueForOrganization = (feature, organizationId) => {
  return useMemo(() => {
    return applicationFeatures.valueForOrganization(feature, organizationId);
  }, [applicationFeatures.initialized, applicationFeatures.config, organizationId]);
};


